<script setup lang="ts">
import {
  breakpointsTailwind,
  useBreakpoints,
  useElementBounding,
  useIntersectionObserver,
} from '@vueuse/core';
import { computed, onBeforeUnmount, onMounted, onServerPrefetch, ref, watch } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { Store, useStore } from 'vuex';

import fallbackLogo from '@/assets/yellow_box_logo.svg';
import TheOverlay from '@/components/base/layout/TheOverlay.vue';
import CartAddedModal from '@/components/cart/CartAddedModal.vue';
import CmsContent from '@/components/cms/CmsContent.vue';
import AdminCartLookupForm from '@/components/layout/header/AdminCartLookupForm.vue';
import BaseNav from '@/components/layout/header/BaseNav.vue';
import MainActions from '@/components/layout/header/MainActions.vue';
import MobileMenuNav from '@/components/layout/header/MobileMenuNav.vue';
import UtilityBar from '@/components/layout/header/UtilityBar.vue';
import LoginModal from '@/components/login/LoginModal.vue';
import { useAuth } from '@/composables/useAuth';
import { useCart } from '@/composables/useCart';
import { useCheckout } from '@/composables/useCheckout';
import { ContentModel, useCms } from '@/composables/useCms';
import { useCustomer } from '@/composables/useCustomer';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useLoginModal } from '@/composables/useLoginModal';
import { staticAccountLinks } from '@/data/nav/accountLinks';
import { staticCategoryMenu } from '@/data/nav/categories';
import { useOverlay } from '@/stores/overlay';
import {
  contentRequiresVariationIdFromCookie,
  DataModel,
  getVariationOrControl,
  HeaderAnnouncements,
  HeaderLogoResponse,
} from '@/utils/cms';
import { SiteNavigation, SiteNavigationResult } from '@/utils/navMenu';
import { waitABit } from '@/utils/waitABit';

const store = useStore();
const { permissions } = useAuth(store);

const { addedLineItem, isCartAddedOpen, loadCart, loadLineItemExpansions } = useCart(store);
const { cxMode } = useCheckout(store);
const { customer, customerOrdersCount } = useCustomer(store);
const { flags } = useFeatureFlags(store);
const isSignedIn = computed(() => !!customer.value || cxMode.value);

const { content: promotionBanner } = useCms(store, 'promotional-messaging-bar', 'setup');
const { content: announcementBar } = useCms<DataModel<HeaderAnnouncements>>(
  store,
  'header-announcement-bar',
  'setup',
);
const { content: logo } = useCms<DataModel<HeaderLogoResponse>>(store, 'header-logo', 'setup');
const headerAnnouncements = computed(() => announcementBar.value.result?.data?.headerAnnouncements);

const headerLogo = computed(
  () => logo.value.result?.data?.headerLogo.value?.data || { logo: fallbackLogo },
);

const isMenuOpen = ref(false);

const { commitTestDecision, content: siteNavigationContent } = useCms<
  DataModel<SiteNavigationResult>
>(store, 'site-navigation', 'setup');
const siteNavigation = computed<SiteNavigation & { id?: string }>(() => {
  const { data, id } =
    getVariationOrControl<SiteNavigationResult>(siteNavigationContent.value.result) ?? {};

  const accountLinks = data?.accountLinks?.value?.data?.links ?? staticAccountLinks;

  let topMenus: SiteNavigation['topMenus'];
  if (data?.topMenus?.length) {
    topMenus = [];
    data.topMenus.forEach((choice) => {
      if ('largeMenu' in choice && choice.largeMenu.value?.data) {
        topMenus!.push({ ...choice.largeMenu.value.data, type: 'LargeMenu' });
      }
      if ('smallMenu' in choice && choice.smallMenu.value?.data) {
        topMenus!.push({ ...choice.smallMenu.value.data, type: 'SmallMenu' });
      }
    });
  }

  const categories = data?.categories.value?.data ?? staticCategoryMenu;

  return {
    accountLinks: accountLinks.filter(({ link }) => {
      if (!link.url.endsWith('/refer')) return true;
      return flags.referral && isSignedIn.value && customerOrdersCount.value > 0;
    }),
    categories: categories.menus.menus,
    id: contentRequiresVariationIdFromCookie(siteNavigationContent.value.result) ? id : undefined,
    featuredPage: data?.featuredPage,
    topMenus,
  };
});

const mainActionsBar = ref();
const { height: actionBarHeightOffset } = useElementBounding(mainActionsBar);

const { handleClose, state: signIn } = useLoginModal();

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller('lg');
const zIndex = computed(() => (isMenuOpen.value && isMobile.value ? 'z-30' : 'z-10'));

onServerPrefetch(() => {
  if (siteNavigation.value.id) {
    commitTestDecision(siteNavigation.value.id);
  }
});

onMounted(() => {
  waitABit().then(() =>
    Promise.all([
      store.dispatch('headerModule/getDynamicHeaderContent'),
      loadCart().then(() => loadLineItemExpansions()),
    ]),
  );

  const overlay = useOverlay();
  watch([isMenuOpen, isMobile], () => {
    if (isMenuOpen.value && isMobile.value) {
      window.Kustomer?.stop();
      overlay.show();
    } else {
      overlay.hide();
      window.Kustomer?.start();
    }
  });
});

watch(isCartAddedOpen, () => {
  if (isCartAddedOpen.value) {
    window.Kustomer?.stop();
  } else {
    window.Kustomer?.start();
  }
});

onBeforeUnmount(() => {
  handleClose();
  isCartAddedOpen.value = false;
});

const target = ref<HTMLElement>();
const targetIsVisible = ref(true);

useIntersectionObserver(target, ([{ isIntersecting }]) => {
  targetIsVisible.value = isIntersecting;
});

defineOptions({
  async criticalData(forwardedStore: Store<any>, to: RouteLocationNormalized) {
    const models: ContentModel[] = [
      'header-announcement-bar',
      'promotional-messaging-bar',
      'header-logo',
    ];
    const promises: Promise<any>[] = models.map((key) =>
      useCms(forwardedStore, key, 'criticalData').loadCmsContent(to.path, to.query),
    );
    if (typeof window === 'undefined') {
      const { loadDyFlags } = useFeatureFlags(forwardedStore);
      loadDyFlags('[A/B Test] Product Badges', ['badgeRedesign']);
      promises.push(
        useCms<DataModel<SiteNavigationResult>>(
          forwardedStore,
          'site-navigation',
          'criticalData',
        ).loadCmsContent(to.path, to.query),
      );
    }

    await Promise.all(promises);
  },
});
</script>

<template>
  <div id="appHeader" class="relative z-20 header">
    <AdminCartLookupForm v-if="permissions.checkOutAsCustomer" />
    <CmsContent :content="promotionBanner" model="promotional-messaging-bar" />
    <UtilityBar :announcements="headerAnnouncements" />
    <MainActions
      v-model="isMenuOpen"
      ref="mainActionsBar"
      :accountLinks="siteNavigation.accountLinks"
      :actionBarHeightOffset="actionBarHeightOffset"
      :isFixed="!targetIsVisible"
    >
      <template #logo>
        <div class="overflow-hidden rounded">
          <img
            :src="headerLogo.logo"
            alt="ixclkxk.shop"
            itemprop="logo"
            class="h-10 lg:h-12"
            data-test="site-logo"
          />
        </div>
      </template>
    </MainActions>
    <div :class="{ 'main-actions-height': !targetIsVisible }" />
    <span ref="target" />
    <BaseNav
      :actionBarHeightOffset="actionBarHeightOffset"
      :isFixed="!targetIsVisible"
      :isOpen="isMenuOpen"
      :siteNavigation="siteNavigation"
    />
    <Teleport to="body" v-if="signIn.isOpen">
      <LoginModal
        :callback="signIn.callback"
        :destination="signIn.destination"
        :isCheckout="signIn.isCheckout"
        :initialStep="signIn.initialStep"
        :selectBusiness="signIn.selectBusiness"
        :isOpen="signIn.isOpen"
        @handle-close="handleClose"
      />
    </Teleport>
    <Teleport v-if="flags.cartAddedModal && isCartAddedOpen && addedLineItem" to="body">
      <CartAddedModal
        :isOpen="isCartAddedOpen"
        :lineItem="addedLineItem"
        @handle-close="isCartAddedOpen = false"
      />
    </Teleport>
  </div>
  <TheOverlay :class="zIndex" />
  <Transition name="slide-from-left">
    <MobileMenuNav v-show="isMenuOpen" v-model="isMenuOpen" :siteNavigation="siteNavigation" />
  </Transition>
</template>

<style lang="scss" scoped>
.slide-from-left-enter-active,
.slide-from-left-leave-active {
  @apply transition ease-in-out duration-500 sm:duration-300;
}
.slide-from-left-enter-from,
.slide-from-left-leave-to {
  @apply -translate-x-full sm:-translate-x-80;
}
.main-actions-height {
  height: v-bind('`${actionBarHeightOffset}px`');
}
</style>
