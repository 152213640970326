<script setup lang="ts">
import UnstyledButton from '@/components/base/UnstyledButton.vue';

const emit = defineEmits(['click']);
</script>

<template>
  <UnstyledButton
    class="flex cursor-pointer lg:px-2 lg:py-2 lg:rounded-full hover:lg:bg-background-secondary flex-col min-w-[3rem] items-center justify-center lg:flex-row"
    @click="emit('click')"
  >
    <slot />
  </UnstyledButton>
</template>
