/* eslint-disable import/prefer-default-export */
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { useCheckout } from '@/composables/useCheckout';
import { useCustomer } from '@/composables/useCustomer';
import { useDiscount } from '@/composables/useDiscount';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useLoginModal } from '@/composables/useLoginModal';
import { useNotifications } from '@/stores/notifications';
import { CheckoutEvents, gtag } from '@/utils/analytics';
import { getAnalyticsProducts, getCheckoutEvent, getCheckoutType } from '@/utils/analyticsPayloads';
import { NutsRedirect } from '@/utils/exceptions';

interface Options {
  onError?: () => any | Promise<() => any>;
  onSuccess?: () => any | Promise<() => any>;
}

export function useProceedToCheckout() {
  const route = useRoute();
  const store = useStore();

  const { loadCart, lineItems, productKeysById } = useCart(store);
  const { cxMode, determineEntryLocation, estimatedAmountToCharge, prepareCartForCheckout } =
    useCheckout(store);
  const { customer } = useCustomer(store);
  const { appliedDiscountCodes } = useDiscount(store);
  const { loadDyFlags } = useFeatureFlags(store);
  const { handleOpen } = useLoginModal();
  const { navigateTo } = useRouteChange(useRouter());
  const { addNotifications } = useNotifications();

  const goToCheckout = useCallback(async (options?: Options) => {
    try {
      await prepareCartForCheckout();
      const entryLocation = determineEntryLocation();

      if (options?.onSuccess) await options.onSuccess();
      return navigateTo(entryLocation);
    } catch (error) {
      if (error instanceof NutsRedirect) {
        if (route?.path !== error.url) {
          await navigateTo(error.url);
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          loadCart(true);
        }

        if (options?.onError) await options.onError();
        const { errors } = error;
        return addNotifications({ errors });
      }
      console.error(error);
      return undefined;
    }
  });

  const analyticsProducts = computed(() => getAnalyticsProducts(lineItems.value));
  const checkoutType = computed(() => getCheckoutType(cxMode.value, customer.value));
  const checkoutEvent = computed(() =>
    getCheckoutEvent(
      lineItems.value,
      checkoutType.value === 'Guest' ? undefined : checkoutType.value,
      estimatedAmountToCharge.value,
      appliedDiscountCodes.value,
      productKeysById.value,
    ),
  );

  const handleProceedToCheckout = useCallback(async (options?: Options) => {
    gtag('event', 'begin_checkout', checkoutEvent.value);
    if (!customer.value && !cxMode.value) {
      await loadDyFlags('[A/B Test] Guest Checkout Last Option', ['guestCheckoutLastOption']);
    }
    if (customer.value || cxMode.value) {
      await goToCheckout.execute(options);
    } else {
      CheckoutEvents.step(1, analyticsProducts.value);
      handleOpen({ callback: useCallback(() => goToCheckout.execute(options)), isCheckout: true });
    }
  });

  const isPending = computed(() => handleProceedToCheckout.isPending || goToCheckout.isPending);

  return {
    handleProceedToCheckout,
    isPending,
  };
}
