<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { googleOneTap } from 'vue3-google-login';
import { useStore } from 'vuex';

import { getConfigEntry } from '@/api/config';
import { useAuth } from '@/composables/useAuth';
import { useCart } from '@/composables/useCart';
import { useCustomer } from '@/composables/useCustomer';
import { useDynamicYield } from '@/composables/useDynamicYield';

export default defineComponent({
  name: 'GoogleOneTap',
  setup() {
    onMounted(async () => {
      try {
        const store = useStore();
        const { googleCredentialSignIn } = useAuth(store);
        const { loadCart } = useCart(store);
        const { customer } = useCustomer(store);
        const { reportEngagement } = useDynamicYield(store);

        // We don't need to show one-tap if user is already signed in
        if (customer.value) return;

        const { clientId } = getConfigEntry('google').oauth;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const isLoggedOut = urlSearchParams.get('logged_out') === '1';
        const response = await googleOneTap({ clientId, autoLogin: !isLoggedOut });
        const signInResponse = await googleCredentialSignIn(response);

        if (signInResponse) {
          await Promise.all([
            loadCart(true),
            store.dispatch('headerModule/getDynamicHeaderContent'),
          ]);
          reportEngagement('[A/B Test] Google One Tap');
        }
      } catch (error) {
        console.error('Google One Tap:', error);
      }
    });
  },
});
</script>
