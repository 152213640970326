export function scrollToTarget(targetOrSelector: HTMLElement | string, scrollOffset = 90) {
  const appHeader = document.querySelector('#appHeader') as HTMLElement;
  const navOffset = appHeader?.offsetHeight ?? 0;
  const target =
    typeof targetOrSelector === 'string'
      ? document.querySelector(targetOrSelector)
      : targetOrSelector;
  if (target) {
    let { top } = target.getBoundingClientRect();
    const { scrollY = 0 } = window;
    top += scrollY;
    top -= navOffset + scrollOffset;
    window.scroll({ top, behavior: 'smooth' });
  }
}

export default {};
