<template>
  <div
    data-test="dy-server-side-renderer"
    :data-decision-id="decisionId"
    class="dynamic-content"
    @click="delegateLinksToRouter"
  >
    <div v-if="isValidVariation" data-test="variation-container">
      <Component :is="variationType" :variation="variation" />
    </div>
    <div v-else data-test="default-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import DyHtmlCssVariation from '@/components/dynamic-yield/DyHtmlCssVariation.vue';
import routerLinkDelegationMixin from '@/mixins/routerLinkDelegation';

export default {
  name: 'DyServerSideRenderer',
  props: {
    variationType: { type: String, required: true },
    variation: { type: Object, required: false },
  },
  components: {
    DyHtmlCssVariation,
  },
  mixins: [routerLinkDelegationMixin],
  computed: {
    decisionId() {
      return this.isValidVariation ? this.variation.decisionId : '';
    },
    isValidVariation() {
      return !!this.variation && this.variation !== {};
    },
  },
};
</script>
