<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<script setup lang="ts">
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import {
  breakpointsTailwind,
  onClickOutside,
  useBreakpoints,
  useFocus,
  useMutationObserver,
  useScroll,
  useStorage,
  useVModel,
} from '@vueuse/core';
import { SearchResponse } from 'instantsearch.js';
import { computed, inject, nextTick, onMounted, ref, watch, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

import {
  AlgoliaCategory,
  AlgoliaSuggestedSearch,
  AlgoliaVariant,
  init as initAlgoliaClient,
} from '@/api/algolia';
import RouteLink from '@/components/base/RouteLink.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCallback } from '@/composables/useCallback';
import { useState } from '@/composables/useState';
import { useOverlay } from '@/stores/overlay';
import { gtag } from '@/utils/analytics';
import { ProductCard } from '@/utils/productCard';
import { scrollToTarget } from '@/utils/scrollTo';

const props = defineProps<{ modelValue: string }>();
const emit = defineEmits(['update:modelValue']);
const query = useVModel(props, 'modelValue', emit);

const algoliaClient = inject('algoliaClient', () => initAlgoliaClient(), true);
const { navigateTo, route } = useRouteChange(useRouter());
const isSearchPage = computed(() => route?.name === 'Search');
const breakpoints = useBreakpoints(breakpointsTailwind);
const mobileScreen = computed(() => breakpoints.smaller('md').value);

const { query: synchronizedQuery } = useState('synchronizedSearchBar', () => ({ query: '' }));

const searchInput = ref<HTMLElement>();
const { focused: searchInputFocus } = useFocus(searchInput);
const showAllRecentSearches = ref(false);
const showSuggestions = ref(false);
const featuredCategories = ref<SearchResponse<AlgoliaCategory>>();
const productResults = ref<SearchResponse<AlgoliaVariant>>();
const suggestedSearches = ref<SearchResponse<AlgoliaSuggestedSearch>>();
const trendingSearches = ref<SearchResponse<AlgoliaSuggestedSearch>>();
const trendingProducts = ref<SearchResponse<AlgoliaVariant>>();
const mobileSearch = computed(() => mobileScreen.value && showSuggestions.value);
const recentSearches = useStorage<string[]>('recentSearches', []);
const focusableElements = ref<NodeListOf<HTMLElement> | HTMLElement[]>([]);
const firstFocusableElement = computed(() => focusableElements.value[0]);
const lastFocusableElement = computed(
  () => focusableElements.value[focusableElements.value.length - 1],
);

const scrollContainer = ref<HTMLElement>();
const containerOverflowing = ref(false);
const { arrivedState } = useScroll(scrollContainer);
const scrollByDirection = (
  direction: 'next' | 'prev',
  behavior: ScrollBehavior,
  offset: number,
) => {
  if (!scrollContainer.value) return;
  const scrollFactor = direction === 'prev' ? -1 : 1;
  scrollContainer.value.scrollBy({ left: offset * scrollFactor, behavior });
};
const prev = () => scrollByDirection('prev', 'smooth', scrollContainer.value!.offsetWidth);
const next = () => scrollByDirection('next', 'smooth', scrollContainer.value!.offsetWidth);

const globalSearch = ref<HTMLElement>();
const suggestions = ref<HTMLElement>();

const refreshFocusableElements = () => {
  focusableElements.value =
    globalSearch.value?.querySelectorAll<HTMLElement>(
      `[href], input:not([disabled]),
      select:not([disabled]), textarea:not([disabled]):not([name="g-recaptcha-response"]),
      [tabindex]:not([tabindex="-1"]):not([disabled])`,
    ) ?? [];
};
const dismissSuggestions = () => {
  searchInputFocus.value = false;
  showSuggestions.value = false;
};
onClickOutside(globalSearch, dismissSuggestions);
const { isScrolling } = useScroll(suggestions);

const formatSuggestionHitHighlight = (
  suggestion: SearchResponse<AlgoliaSuggestedSearch>['hits'][0],
) => {
  // eslint-disable-next-line no-underscore-dangle
  if (!suggestion._highlightResult?.query) return suggestion.query;
  // eslint-disable-next-line no-underscore-dangle
  const normalizedHighlight = suggestion._highlightResult.query.value.replace(
    '<span>',
    '<span class="font-normal">',
  );
  // CSS wants to incorrectly capitalize partial words without this wrapping span, e.g.
  // `<strong class="font-semibold"><span class="font-normal">se</span>arch<strong>` results in "SeArch" rendered.
  return `<span class="capitalize">${normalizedHighlight}</span>`;
};

const handleAnalyticsList = (items: AlgoliaVariant[], eventName: string, index?: number) => {
  gtag('event', eventName, {
    item_list_name: 'Autocomplete',
    items: items.map((item, i) => {
      const productCard = ProductCard.fromAlgolia(item);
      return {
        index: eventName === 'select_item' ? index : i,
        indexName: 'Products',
        item_id: item.Product.key,
        item_name: item.Product.name,
        item_variant: item.sku,
        item_variant_name: item.shortVariantName,
        price: item.singlePiecePrice,
        price_before_discount: item.singlePiecePrice,
        cost: item.cost,
        coupon: productCard.totalSavings?.description?.en,
        discount: dollars(productCard.totalSavings?.value || from(0)),
        quantity: 1,
        autodelivery_interval: undefined,
        merchandising_category: item.Product.merchandisingCategory,
        weight: item.weight,
      };
    }),
  });
};

onMounted(async () => {
  const departmentsIndex = algoliaClient.initIndex('Departments');
  const productsIndex = algoliaClient.initIndex('Products');
  const suggestedSearchesIndex = algoliaClient.initIndex('Products_query_suggestions');

  const loadCategories = useCallback(async () => {
    featuredCategories.value = await departmentsIndex.search<AlgoliaCategory>('', {
      distinct: true,
      hitsPerPage: 9,
      filters: 'idealForSuggestions:true',
      ruleContexts: ['featuredCategories'],
    });
  });
  const loadProducts = async () => {
    productResults.value = await productsIndex.search<AlgoliaVariant>(query.value, {
      analytics: !!query.value,
      distinct: true,
      hitsPerPage: 6,
      filters: 'traits:searchable',
      getRankingInfo: true,
    });
    gtag('event', 'content_decision', {
      cms: 'Algolia',
      content_id: productResults.value?.abTestID,
      test_variation_id: productResults.value?.abTestVariantID,
    });
  };

  const [searches, products] = await Promise.all([
    suggestedSearchesIndex.search<AlgoliaSuggestedSearch>('', {
      hitsPerPage: 9,
    }),
    productsIndex.search<AlgoliaVariant>('', {
      distinct: true,
      hitsPerPage: 6,
      getRankingInfo: true,
    }),
  ]);
  trendingSearches.value = searches;
  trendingProducts.value = products;

  const loadSuggestedSearches = useCallback(async () => {
    suggestedSearches.value = await suggestedSearchesIndex.search<AlgoliaSuggestedSearch>(
      query.value,
      { hitsPerPage: 9 },
    );
  });
  watch(
    () => route?.fullPath,
    () => {
      dismissSuggestions();
      query.value = '';
    },
  );
  watch([productResults, scrollContainer], async () => {
    await nextTick();
    if (scrollContainer.value?.childElementCount && scrollContainer.value?.firstElementChild) {
      if (
        scrollContainer.value.childElementCount *
          (scrollContainer.value.firstElementChild.offsetWidth + 16) >
        scrollContainer.value?.offsetWidth
      ) {
        containerOverflowing.value = true;
      } else {
        containerOverflowing.value = false;
      }
    }
  });

  watch(
    productResults,
    (results) => {
      if (results?.hits.length) {
        handleAnalyticsList(results.hits, 'view_item_list');
      }
    },
    { deep: true },
  );

  watch(isScrolling, (value) => {
    if (value) {
      searchInputFocus.value = false;
    }
  });

  watch(mobileSearch, () => {
    if (mobileSearch.value) {
      window.Kustomer?.stop();
    } else {
      window.Kustomer?.start();
    }
  });

  const overlay = useOverlay();
  watchEffect(async () => {
    if (showSuggestions.value) {
      overlay.show();
    } else {
      overlay.hide();
    }

    if (!featuredCategories.value?.hits.length && !loadCategories.isPending) {
      loadCategories.execute();
    }

    loadSuggestedSearches.execute();
    if (!query.value) {
      productResults.value = undefined;
      return;
    }
    await loadProducts();
  });
  const root = document.querySelector(':root');
  const documentHeight = () => {
    root?.style.setProperty('--screenHeight', `${window.innerHeight}px`);
  };
  window.addEventListener('resize', documentHeight);
  documentHeight();
});

const removeRecentSearch = (searchString: string) => {
  recentSearches.value = recentSearches.value.filter((search) => search !== searchString);
};

const addToRecentSearches = (searchString: string) => {
  if (recentSearches.value.includes(searchString) || recentSearches.value === '') return;
  if (recentSearches.value.length >= 5) {
    recentSearches.value = [searchString, ...recentSearches.value.slice(0, 4)];
  } else {
    recentSearches.value = [searchString, ...recentSearches.value];
  }
};

const search = (_?: Event, recentSearchString?: string) => {
  let href = '/search/instant';
  const searchTerm = recentSearchString || query.value.trim();
  addToRecentSearches(searchTerm);
  if (searchTerm) {
    synchronizedQuery.value = searchTerm;
    href += `?query=${searchTerm}`;
  }
  if (isSearchPage.value && searchTerm === route?.query.query) {
    dismissSuggestions();
  }
  navigateTo(href);
  scrollToTarget('.ais-Hits');
};

const handleForwardKeyTab = (event: Event) => {
  if (lastFocusableElement.value === document.activeElement) {
    event.preventDefault();
    firstFocusableElement.value?.focus();
  }
};
const handleBackwardKeyTab = (event: Event) => {
  if (firstFocusableElement.value === document.activeElement) {
    event.preventDefault();
    lastFocusableElement.value?.focus();
  }
};

const handleFocus = async () => {
  showSuggestions.value = true;
  if (mobileSearch.value) {
    await nextTick();
    searchInput.value?.click();
  }
};

const handleCompositionUpdate = (e: CompositionEvent) => {
  query.value = e.data;
};

useMutationObserver(globalSearch, refreshFocusableElements, {
  attributes: true,
  childList: true,
  subtree: true,
});
</script>

<template>
  <div>
    <Teleport to="body" :disabled="!mobileSearch" class="overscroll-contain">
      <form
        ref="globalSearch"
        :class="[
          mobileSearch
            ? 'fixed top-0 z-50 flex w-full bg-white screenHeight overflow-hidden flex-col'
            : 'relative flex',
        ]"
        @keydown.escape="dismissSuggestions"
        @submit.prevent="search"
        @keydown.exact.tab="handleForwardKeyTab"
        @keydown.shift.tab="handleBackwardKeyTab"
      >
        <div
          class="flex w-full"
          :class="{ 'border-solid border-b border-nuts-neutral-200 h-14': mobileSearch }"
          data-test="search-bar"
        >
          <input
            autocomplete="off"
            :autofocus="mobileSearch"
            ref="searchInput"
            v-model="query"
            @compositionupdate="handleCompositionUpdate"
            class="w-full h-10 px-4 py-2 my-2 text-base rounded-3xl bg-background-secondary outline-1 focus-visible:outline-black input-border"
            :class="{ 'ml-2': mobileSearch }"
            :placeholder="
              mobileSearch ? 'Find anything snacks' : 'Find anything Snacks, Pantry or Gifts'
            "
            type="search"
            aria-label="Search"
            data-test="search-input"
            @focus="handleFocus"
            @focusin="handleFocus"
          />
          <button
            class="absolute z-10 self-center cursor-pointer unstyled-button"
            :class="[mobileSearch ? 'right-[72px]' : 'right-0']"
            type="submit"
            data-test="search-button"
          >
            <img src="@/assets/yellow_search_icon.svg" alt="ixclkxk.shop" class="p-1" />
          </button>
          <span v-if="mobileSearch" @click="dismissSuggestions" class="h-10 px-3 py-2 my-2">
            Cancel
          </span>
        </div>
        <div
          v-if="showSuggestions"
          ref="suggestions"
          class="z-10 w-full py-4 bg-white"
          :class="
            mobileSearch
              ? 'mt-0 overscroll-contain overflow-y-scroll'
              : 'absolute mt-1 shadow-2xl top-14 rounded-2xl'
          "
          id="search-suggestions"
          data-test="search-suggestions"
        >
          <template v-if="recentSearches.length && query.length === 0">
            <SmallBodyText class="px-4 mt-2 font-proxima-nova text-true-gray-500">{{
              'Recent Searches'
            }}</SmallBodyText>
            <ul class="flex flex-col gap-2 mt-3 lg:gap-0">
              <li
                v-for="(searchString, i) in recentSearches"
                :key="i"
                class="flex justify-between w-full px-4 py-1 hover:bg-true-gray-100"
                data-test="recent-searches"
                :class="[i > 2 && !showAllRecentSearches ? 'hidden md:flex md:visible' : 'flex']"
              >
                <button
                  class="flex items-center w-full cursor-pointer unstyled-button focus:no-underline hover:no-underline"
                  @click="search(undefined, searchString)"
                >
                  <img
                    src="@/assets/search/recent-search.svg"
                    alt="Search ixclkxk.shop"
                    class="w-5 h-5 mr-3"
                  />
                  <BaseBodyText class="text-black capitalize font-proxima-nova">
                    {{ searchString }}
                  </BaseBodyText>
                </button>
                <button class="ml-3 unstyled-button" @click="removeRecentSearch(searchString)">
                  <img
                    src="@/assets/search/close-x.svg"
                    alt="Remove this saved search"
                    class="w-6 h-6 cursor-pointer"
                    data-test="remove-recent-search"
                  />
                </button>
              </li>
            </ul>
            <button
              class="flex items-center justify-center w-full my-2 text-sm unstyled-button text-true-gray-500 md:hidden"
              :class="{ hidden: recentSearches.length <= 3 }"
              @click.prevent="showAllRecentSearches = !showAllRecentSearches"
            >
              {{ showAllRecentSearches ? 'See Less' : 'See More' }}
              <img
                src="@/assets/search/chevron-down.svg"
                :alt="`${showAllRecentSearches ? 'less' : 'more'} results`"
                class="w-6 h-6 ml-3 cursor-pointer"
                :class="showAllRecentSearches ? 'scale-y-[-1]' : 'scale-y-[1]'"
              />
            </button>
          </template>
          <template v-if="query.length">
            <SmallBodyText class="px-4 mt-2 font-proxima-nova text-true-gray-500">{{
              'Search Suggestions'
            }}</SmallBodyText>
            <ul class="flex flex-col gap-2 mt-3 lg:gap-0">
              <template v-if="query.length">
                <li
                  v-for="(searchString, i) in recentSearches.filter((searchString) =>
                    searchString.includes(query),
                  )"
                  :key="i"
                  class="flex justify-between w-full px-4 py-1 cursor-pointer hover:bg-true-gray-100"
                  :class="[i > 2 && !showAllRecentSearches ? 'hidden md:flex md:visible' : 'flex']"
                >
                  <button
                    class="flex items-center w-full cursor-pointer unstyled-button focus:no-underline hover:no-underline"
                    @click="search(undefined, searchString)"
                  >
                    <img
                      src="@/assets/search/recent-search.svg"
                      alt="recent search"
                      class="w-5 h-5 mr-3"
                    />
                    <BaseBodyText class="text-black capitalize font-proxima-nova">
                      {{ searchString }}
                    </BaseBodyText>
                  </button>
                  <button class="ml-3 unstyled-button" @click="removeRecentSearch(searchString)">
                    <img
                      src="@/assets/search/close-x.svg"
                      alt="Remove this saved search"
                      class="w-6 h-6 cursor-pointer"
                    />
                  </button>
                </li>
              </template>
              <li
                v-if="!suggestedSearches?.hits.length"
                class="block px-4 py-1 hover:bg-true-gray-100"
              >
                <RouteLink
                  class="flex items-center focus:no-underline hover:no-underline"
                  :to="`/search/instant?query=${query}`"
                  @click="synchronizedQuery = query"
                >
                  <img
                    src="@/assets/search/magnifying-glass.svg"
                    alt="Search ixclkxk.shop"
                    class="w-5 h-5 mr-3"
                  />
                  <BaseBodyText class="text-black capitalize font-proxima-nova grow">
                    {{ query }}
                  </BaseBodyText>
                  <img
                    src="@/assets/search/arrow-top-left.svg"
                    alt="Search ixclkxk.shop"
                    class="w-6 h-6 ml-3"
                  />
                </RouteLink>
              </li>
              <template v-else>
                <li
                  v-for="(suggestion, i) in suggestedSearches?.hits"
                  :key="suggestion.objectID"
                  class="flex w-full px-4 py-1 cursor-pointer hover:bg-true-gray-100"
                  :class="[i > 6 ? 'hidden lg:block lg:visible' : 'block']"
                >
                  <button
                    class="flex items-center justify-between w-full cursor-pointer unstyled-button"
                    data-test="trending-searches"
                    @click.prevent="search(undefined, suggestion.query)"
                  >
                    <img
                      src="@/assets/search/magnifying-glass.svg"
                      alt="Search ixclkxk.shop"
                      class="w-5 h-5 mr-3"
                    />
                    <BaseBodyText class="text-black capitalize font-proxima-nova grow text-start">
                      <template v-if="query.length && suggestion._highlightResult?.query">
                        <strong
                          class="font-semibold"
                          v-html="formatSuggestionHitHighlight(suggestion)"
                        />
                      </template>
                      <template v-else>
                        {{ suggestion.query }}
                      </template>
                    </BaseBodyText>
                    <img
                      src="@/assets/search/arrow-top-left.svg"
                      alt="Search ixclkxk.shop"
                      class="w-6 h-6 ml-3"
                      data-test="trending-searches-arrow"
                    />
                  </button>
                </li>
              </template>
            </ul>
          </template>
          <template v-if="!suggestedSearches?.hits.length || !query.length">
            <SmallBodyText class="px-4 mt-2 font-proxima-nova text-true-gray-500">
              Trending Searches
            </SmallBodyText>
            <ul class="flex flex-col gap-2 mt-3 lg:gap-0">
              <li
                v-for="(suggestion, i) in trendingSearches?.hits"
                :key="suggestion.objectID"
                class="px-4 py-1 hover:bg-true-gray-100"
                :class="[i > 6 ? 'hidden lg:block lg:visible' : 'block']"
              >
                <button
                  class="flex items-center justify-between w-full cursor-pointer unstyled-button"
                  @click.prevent="search(undefined, suggestion.query)"
                >
                  <img
                    src="@/assets/search/magnifying-glass.svg"
                    alt="Search ixclkxk.shop"
                    class="w-5 h-5 mr-3"
                  />
                  <BaseBodyText class="text-black capitalize font-proxima-nova grow text-start">
                    {{ suggestion.query }}
                  </BaseBodyText>
                  <img
                    src="@/assets/search/arrow-top-left.svg"
                    alt="Search ixclkxk.shop"
                    class="w-6 h-6 ml-3"
                  />
                </button>
              </li>
            </ul>
          </template>
          <div class="relative px-4">
            <button
              class="absolute self-center hidden w-12 h-12 bg-white border border-solid rounded-full cursor-pointer border-true-gray-200 left-2 top-16"
              :class="{ 'md:block md:visible': !(arrivedState.left || !containerOverflowing) }"
              @click.prevent="prev"
              data-test="prev-arrow"
            >
              <img src="@/assets/search/chevron-right.svg" class="-scale-x-100" alt="chevron" />
            </button>
            <button
              class="absolute self-center hidden w-12 h-12 bg-white border border-solid rounded-full cursor-pointer border-true-gray-200 right-2 top-16"
              :class="{ 'md:block md:visible': !(arrivedState.right || !containerOverflowing) }"
              @click.prevent="next"
              data-test="next-arrow"
            >
              <img src="@/assets/search/chevron-right.svg" alt="chevron" />
            </button>
            <template
              v-if="(productResults?.hits.length || trendingProducts?.hits.length) && query.length"
            >
              <SmallBodyText
                class="mt-6 font-proxima-nova text-true-gray-500"
                :class="{ 'mt-6': suggestedSearches?.hits.length }"
              >
                {{ productResults?.hits.length ? 'Popular Products' : 'Trending Products' }}
              </SmallBodyText>
              <ul
                v-if="productResults?.hits.length"
                ref="scrollContainer"
                class="grid w-full grid-cols-2 gap-4 mt-3 md:flex md:overflow-x-scroll scrollbar-hide"
                data-test="popular-products-scroll-container"
              >
                <li v-for="(product, i) in productResults.hits" :key="product.objectID">
                  <RouteLink
                    :to="product.path"
                    class="flex flex-col items-end w-full h-full p-2 border border-solid rounded-lg cursor-pointer link hover:no-underline border-true-gray-200 md:shrink-0 md:w-36"
                    @click="handleAnalyticsList([product], 'select_item', i)"
                  >
                    <img
                      class="object-contain lg:h-20"
                      :src="product.Product.listingImageUrl"
                      :alt="`popular product ${product.Product.name}`"
                    />
                    <SmallBodyText
                      class="w-full overflow-hidden text-xs text-left text-black clamp font-proxima-nova h-min text-ellipsis md:font-semibold lg:text-sm"
                    >
                      {{ product.Product.name }}
                    </SmallBodyText>
                  </RouteLink>
                </li>
              </ul>
              <ul
                v-else-if="trendingProducts?.hits.length"
                ref="scrollContainer"
                class="grid w-full grid-cols-3 gap-4 mt-3 md:flex md:overflow-x-scroll scrollbar-hide"
              >
                <li v-for="(product, i) in trendingProducts.hits" :key="product.objectID">
                  <RouteLink
                    :to="product.path"
                    class="flex flex-col items-end w-full h-full p-2 border border-solid rounded-lg link hover:no-underline border-true-gray-200 md:shrink-0 md:w-36"
                    @click="handleAnalyticsList([product], 'select_item', i)"
                  >
                    <img
                      class="object-contain lg:h-20"
                      :src="product.Product.listingImageUrl"
                      :alt="`popular product ${product.Product.name}`"
                    />
                    <SmallBodyText
                      class="w-full overflow-hidden text-xs text-black clamp h-min text-ellipsis md:font-semibold lg:text-sm"
                    >
                      {{ product.Product.name }}
                    </SmallBodyText>
                  </RouteLink>
                </li>
              </ul>
            </template>
            <template v-if="featuredCategories?.hits.length && !query.length">
              <SmallBodyText
                class="mt-6 font-proxima-nova text-true-gray-500"
                :class="{ 'mt-6': suggestedSearches?.hits.length }"
              >
                Featured Categories
              </SmallBodyText>
              <ul
                ref="scrollContainer"
                class="grid w-full grid-cols-3 gap-4 mt-3 md:flex md:overflow-x-scroll scrollbar-hide"
                data-test="featured-categories-scroll-container"
              >
                <li v-for="category in featuredCategories.hits" :key="category.objectID">
                  <RouteLink
                    :to="category.path"
                    class="flex flex-col items-end w-full link hover:no-underline md:w-24 md:shrink-0 lg:w-28"
                  >
                    <img
                      :src="category.image_url"
                      :alt="`category ${category.name}`"
                      class="object-contain w-full h-full mb-2 border border-solid rounded-full aspect-square border-true-gray-200 md:w-24 md:h-24 lg:w-28 lg:h-28"
                    />
                    <span
                      class="w-full overflow-hidden text-xs text-center text-black line-clamp-2 h-min text-ellipsis md:font-semibold lg:text-sm"
                      >{{ category.name }}</span
                    >
                  </RouteLink>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </form>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.unstyled-button {
  background-color: transparent;
  border-width: 0;
  padding: 0;
}
p {
  @apply mb-0;
}
link {
  @apply text-black;
}
show-more {
  @apply text-black;
}
.screenHeight {
  height: var(--screenHeight);
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.input-border {
  border-color: #d4d4d4;
  border-style: solid;
}
</style>
