<template>
  <transition name="fade">
    <div v-if="inputFailed" class="absolute pt-1 pl-px text-xs leading-3 text-error" role="alert">
      {{ errors[0] }}
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, watch } from 'vue';

export default {
  name: 'InlineError',
  props: {
    errors: { required: true, type: Array },
    fieldName: { required: false, type: String },
  },
  emits: ['input-failed'],
  setup(props, { emit }) {
    watch(
      () => props.errors,
      (currentError, previousError) => {
        if (currentError.length > 0 && currentError[0] !== previousError[0]) {
          emit('input-failed', { field: props.fieldName, error: currentError[0] });
        }
      },
      { deep: true },
    );

    return {
      inputFailed: computed(() => props.errors.length > 0),
    };
  },
};
</script>
