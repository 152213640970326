import {
  Cart,
  CartAddItemShippingAddressAction,
  CartUpdateAction,
  ItemShippingDetailsDraft,
  Order,
} from '@commercetools/platform-sdk';
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import createDebug from 'debug';
import omit from 'lodash/omit';
import { computed, Ref, ref } from 'vue';
import { Store } from 'vuex';

import { FromNutsJsonOptions, KeyedError, webstore } from '@/api';
import webstoreCartApi from '@/api/cart';
import { getPaymentMethods, NewCustomer } from '@/api/customer';
import { placeOrder, PlaceOrderRequest } from '@/api/placeOrder';
import { createApi, getOrderByToken } from '@/api/proxiedCommercetools';
import { getRestrictedCountrySkus, RestrictedCountrySkusItem } from '@/api/restrictedCountrySkus';
import { useAddressBook } from '@/composables/useAddressBook';
import { useAuth } from '@/composables/useAuth';
import { useCart } from '@/composables/useCart';
import { useCustomer } from '@/composables/useCustomer';
import { useDelivery } from '@/composables/useDelivery';
import { useDiscount } from '@/composables/useDiscount';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { orderTags, usePayment } from '@/composables/usePayment';
import { useState } from '@/composables/useState';
import { Notification, useNotifications } from '@/stores/notifications';
import { NutsAddress } from '@/utils/address';
import { CheckoutEvents } from '@/utils/analytics';
import {
  buildPresetShipmentActions,
  buildSetShippingAddressActions,
  isGiftCertificateLineItem,
  isGreetingCardLineItem,
  isPresetDelivery,
  isPresetDeliveryLineItem,
  needEmailAddress,
  needPhysicalAddress,
  NutsLineItem,
} from '@/utils/cart';
import { NutsRedirect } from '@/utils/exceptions';
import { getCookie } from '@/utils/isomorphic/cookie';
import { reportError } from '@/utils/reportError';

// temporarily exported to avoid breaking dependencies
export { orderTags } from '@/composables/usePayment';
export type { AvailablePaymentMethod, PaymentItem } from '@/composables/usePayment';
export {
  buildSetLineItemShippingDetailsActions,
  containsGiftCertificate,
  findListingImage,
  hasOnlyGiftCertificate,
  isGiftCertificateLineItem,
  isGiftLineItem,
} from '@/utils/cart';
export type { NutsLineItem, Shipment } from '@/utils/cart';
export { NutsRedirect } from '@/utils/exceptions';

// Temporarily add field that is missing from package
declare module '@commercetools/platform-sdk' {
  interface CartAddCustomLineItemAction {
    shippingDetails?: ItemShippingDetailsDraft;
  }
}

const debug = createDebug('nuts:useCheckout');

export function useCheckout(store: Store<any>, alternativeCart?: Ref<Cart | undefined>) {
  const { cxMode, isB2B, manuallyEnteredAddresses, restrictedCountrySkus } = useState<{
    cxMode: boolean;
    isB2B: boolean;
    manuallyEnteredAddresses: string[];
    restrictedCountrySkus: RestrictedCountrySkusItem;
  }>('checkout', () => ({
    cxMode: true,
    isB2B: false,
    manuallyEnteredAddresses: [],
    restrictedCountrySkus: {},
  }));
  const order = ref<Order>();

  const altCart = alternativeCart ?? ref<Cart | undefined>();
  const orderOrAlternativeCart = computed({
    get() {
      return order.value ?? alternativeCart?.value;
    },
    set(value: Order | Cart | undefined) {
      if (value && 'orderState' in value) {
        order.value = value;
      } else {
        altCart.value = value;
      }
    },
  });

  const { addAddress } = useAddressBook(store);
  const { createCustomer, permissions } = useAuth(store);
  const {
    cartCopiedFromOrder,
    createdFromOrderUpload,
    containsAutoDelivery,
    customerEmail,
    customLineItems,
    loadCart,
    loadImagesForNonPrimaryVariants,
    loadSafcToken,
    giftCertificate,
    lineItems,
    listingImageUrlsByProductId,
    cart,
    safcToken,
    setCopiedFromOrderNumber,
    setCustomerEmail,
    setGiftCertificate,
    setCart,
    shippingAddress,
    unassignedLineItems,
    updateCart,
  } = useCart(store, orderOrAlternativeCart);
  const {
    customer,
    customerAddresses,
    customerMemberSince,
    customerRegionalCarriersAllowed,
    loadCreditAccounts,
    loadCustomerInfo,
    loadGuestContactInfo,
    offerNewsletter,
    setCustomerInfo,
    storeCredit,
  } = useCustomer(store);
  const {
    addShipment,
    applyShippingOffers,
    calculateShippingOffers,
    futureDeliveryDates,
    getGreetingCards,
    getPickupShippingOffer,
    getShipDates,
    hasMultipleShipments,
    isPickup,
    nextMultishipSerial,
    removeShipment,
    requestedShipDate,
    setRequestedShipDate,
    setShippingAddress,
    shipDates,
    shipments,
    specialDeliveryShipments,
    standardShipments,
    updateRemainingQuantity,
    updateShipment,
  } = useDelivery(store, orderOrAlternativeCart);
  const { appliedDiscountCodes, applyDiscountCode, availableDiscountCodes, removeDiscountCode } =
    useDiscount(store, orderOrAlternativeCart);
  const { flags, loadDyFlags } = useFeatureFlags(store);
  const { addNotifications } = useNotifications();
  const {
    determineAvailablePaymentMethods,
    estimatedAmountToCharge,
    estimatedGiftCertificateCharge,
    getSavedPaymentMethods,
    paymentItems,
    payments,
    setAdjustment,
  } = usePayment(store, cxMode, orderOrAlternativeCart);

  if (cxMode.value && !permissions.value.checkOutAsCustomer) {
    cxMode.value = false;
  }

  // completion indicators
  const addressComplete = computed<boolean>(() => {
    if (!shipments.value.length || unassignedLineItems.value.length) return false;
    if (isPickup.value) return true;
    const countriesBySku = Object.entries(restrictedCountrySkus.value);
    return standardShipments.value.every((shipment) => {
      if (shipment.needEmailAddress) {
        if (!shipment.address.email) return false;
        if (!shipment.needPhysicalAddress) return true;
      }
      if (shipment.needPhysicalAddress) {
        const shipmentSkus = shipment.lineItems.map((l) => l.variant.sku);
        if (
          countriesBySku.some(
            ([sku, restrictedCountries]) =>
              shipmentSkus.includes(sku) && restrictedCountries.includes(shipment.address.country),
          )
        ) {
          return false;
        }
        return !!shipment.address.street1;
      }
      debug('shipment not flagged as `needEmailAddress` or `needPhysicalAddress`: %o', shipment);
      return !!(shipment.address.street1 || shipment.address.email);
    });
  });
  const deliveryMethodComplete = computed<boolean>(
    () =>
      shipments.value.length > 0 &&
      shipments.value.filter((s) => s.needPhysicalAddress).every((s) => !!s.customFields),
  );

  const steps = computed(() => {
    const baseSteps = [
      {
        complete: addressComplete.value,
        componentName: 'Address',
        enabled: true,
        includeSummary: true,
        key: 'address',
        title: 'Email & Shipping Info',
      },
      {
        complete: deliveryMethodComplete.value,
        componentName: 'Delivery',
        enabled: addressComplete.value,
        includeSummary: true,
        key: 'delivery',
        title: 'Delivery & Gift Options',
      },
      {
        complete: false,
        componentName: 'Payment',
        enabled: addressComplete.value && deliveryMethodComplete.value,
        includeSummary: false,
        key: 'payment',
        title: 'Payment Method',
      },
    ];
    if (!isPresetDelivery(lineItems.value)) return baseSteps;
    return baseSteps.filter((step) => {
      if (step.key === 'address') return !customer.value;
      if (step.key === 'delivery') return false;
      return true;
    });
  });

  const resetCheckout = async (resetCustomer = false) => {
    await updateCart(() => {
      const actions: CartUpdateAction[] = [];

      const targetedLineItems = lineItems.value.filter(
        (l) => !isPresetDeliveryLineItem(l) && l.shippingDetails?.targets.length,
      );
      actions.push(
        ...targetedLineItems.map(
          (lineItem): CartUpdateAction => ({
            action: 'setLineItemShippingDetails',
            lineItemId: lineItem.id,
            shippingDetails: {
              targets: [],
            },
          }),
        ),
      );
      const flattenedChildLineItems = targetedLineItems.flatMap((parent) => parent.children ?? []);
      actions.push(
        ...flattenedChildLineItems.map(
          (childLineItem: NutsLineItem): CartUpdateAction => ({
            action: 'setLineItemShippingDetails',
            lineItemId: childLineItem.id,
            shippingDetails: {
              targets: [],
            },
          }),
        ),
      );

      const greetingCardCartLineItems = lineItems.value.filter(isGreetingCardLineItem);
      actions.push(
        ...greetingCardCartLineItems.map(
          (lineItem): CartUpdateAction => ({
            action: 'removeLineItem',
            lineItemId: lineItem.id,
          }),
        ),
      );

      actions.push(
        ...customLineItems.value.map(
          (lineItem): CartUpdateAction => ({
            action: 'removeCustomLineItem',
            customLineItemId: lineItem.id,
          }),
        ),
      );

      actions.push(
        ...standardShipments.value.map(
          (shipment): CartUpdateAction => ({
            action: 'removeItemShippingAddress',
            addressKey: shipment.key,
          }),
        ),
      );

      if (shippingAddress.value && standardShipments.value.length) {
        if (cart.value?.shippingInfo) {
          actions.push({ action: 'setShippingMethod' });
        }
        // also unsets taxes
        actions.push({ action: 'setShippingAddress' });
      } else if (cart.value?.shippingMode === 'Multiple') {
        actions.push(
          ...standardShipments.value.map(
            (shipment): CartUpdateAction => ({
              action: 'removeShippingMethod',
              shippingKey: shipment.key,
            }),
          ),
        );
      }

      if (resetCustomer) {
        actions.push({ action: 'setCustomerEmail', email: undefined as unknown as string });
      }

      return actions;
    });
  };

  const toggleCXMode = () => {
    cxMode.value = !cxMode.value;
  };

  const tryCreateCustomerOrSignIn = async (
    customerToAdd: Omit<NewCustomer, 'discardCustomerCart'> & {
      onMessages?: Exclude<FromNutsJsonOptions['onMessages'], 'default'>;
    },
    stepName: string,
  ) => {
    const { triggerSetPasswordFlow } = customerToAdd;
    if (customer.value) return;
    if (
      !customerToAdd.email ||
      !customerToAdd.name ||
      (!customerToAdd.password && !triggerSetPasswordFlow)
    ) {
      return;
    }

    const logKeyedMessage = (action: string, key: string) =>
      CheckoutEvents.event({
        action,
        category: 'Account',
        label: `Checkout: ${stepName} - ${key}`,
      });
    const messageTap: Exclude<FromNutsJsonOptions['onMessages'], 'default'> = (messages) => {
      const keyedNotice = messages.notices?.find(({ key }) => !!key);
      if (keyedNotice) logKeyedMessage('Create', keyedNotice.key!);
      customerToAdd.onMessages?.(messages);
    };

    try {
      const newCustomer = {
        ...customerToAdd,
        discardCustomerCart: true,
        password: triggerSetPasswordFlow ? '' : customerToAdd.password,
        triggerSetPasswordFlow,
      };
      await createCustomer(newCustomer, messageTap);
    } catch (error) {
      reportError(error);
      if (error instanceof KeyedError) {
        const { key, message } = error;
        addNotifications({ warnings: [{ key, message }] });
        logKeyedMessage('Creation Failure', key);
      }
    }

    const addressToAdd = cart.value && !hasMultipleShipments.value && shippingAddress.value;
    if (customer.value && addressToAdd && !cxMode.value) {
      try {
        await addAddress(addressToAdd);
      } catch (error) {
        reportError(error);
      }
    }
  };

  const isDigitalGiftRedemption = computed(() =>
    customLineItems.value.find((item) => item.slug.endsWith('-amount')),
  );

  return {
    addressComplete,
    addShipment,
    appliedDiscountCodes,
    applyDiscountCode,
    applyShippingOffers,
    availableDiscountCodes,
    calculateShippingOffers,
    cartCopiedFromOrder,
    containsAutoDelivery,
    customerEmail,
    customerMemberSince,
    customerRegionalCarriersAllowed,
    cxMode,
    deliveryMethodComplete,
    determineAvailablePaymentMethods,
    estimatedAmountToCharge,
    estimatedGiftCertificateCharge,
    futureDeliveryDates,
    getGreetingCards,
    getPickupShippingOffer,
    getSavedPaymentMethods,
    getShipDates,
    giftCertificate,
    isB2B,
    isDigitalGiftRedemption,
    isGiftCertificateLineItem,
    isPickup,
    lineItems,
    listingImageUrlsByProductId,
    manuallyEnteredAddresses,
    nextMultishipSerial,
    offerNewsletter,
    orderTags,
    paymentItems,
    payments,
    cart,
    order,
    removeDiscountCode,
    removeShipment,
    requestedShipDate,
    resetCheckout,
    restrictedCountrySkus,
    setAdjustment,
    setCustomerEmail,
    setGiftCertificate,
    setRequestedShipDate,
    setShippingAddress,
    shipDates,
    shipments,
    shippingAddress,
    steps,
    toggleCXMode,
    tryCreateCustomerOrSignIn,
    unassignedLineItems,
    updateCart,
    updateRemainingQuantity,
    updateShipment,

    async clearCheckout() {
      setCustomerInfo();
      setCopiedFromOrderNumber(null);
      await webstoreCartApi.clearCopiedOrder();
      await resetCheckout(true);
      await getPaymentMethods();
    },

    determineEntryLocation(ignoreSignInStep = false) {
      if (createdFromOrderUpload.value) {
        return '/checkout/payment';
      }
      const clientSideNoCustomer =
        typeof window !== 'undefined' && !customer.value && !permissions.value.checkOutAsCustomer;
      const cxNeedsLookup =
        cxMode.value && !cartCopiedFromOrder.value && !createdFromOrderUpload.value;
      if (!ignoreSignInStep && (clientSideNoCustomer || cxNeedsLookup)) {
        return '/checkout/signin';
      }

      let step = steps.value[0].key;
      if (ignoreSignInStep && !customerEmail.value) {
        return `/checkout/${step}`;
      }

      if (steps.value.some((s) => s.complete)) {
        const lastCompletedStep = steps.value.filter((s) => s.complete).pop()!;
        const latestIncompleteStep = steps.value.find((s) => {
          if (!s.enabled || s.complete) return false;
          // standard shipments require the UI to actually set shipping offers, for now
          if (s.key === 'payment' && !isPresetDelivery(lineItems.value)) return false;
          return true;
        });
        step = (latestIncompleteStep ?? lastCompletedStep).key;
      }

      if (step === 'address' && hasMultipleShipments.value) {
        return `/checkout/${step}/multi`;
      }

      return `/checkout/${step.replace('delivery', 'shipping')}`;
    },

    async loadOrder(token: string) {
      const api = createApi(webstore);
      order.value = await getOrderByToken(api, token);
    },

    async prepareCartForCheckout(email?: string) {
      isB2B.value = false;
      const promises = [loadCart()];

      if (cxMode.value) {
        if ((createdFromOrderUpload.value || cartCopiedFromOrder.value) && customer.value) {
          promises.push(loadCustomerInfo(customer.value.email));
        } else {
          setCustomerInfo();
        }
      } else if (permissions.value.checkOutAsCustomer || customer.value) {
        promises.push(
          // maybe session expired? but info has been cleared so we can continue
          loadCustomerInfo().catch(() => {}),
        );
      } else if (email) {
        promises.push(loadGuestContactInfo(email).catch(() => {}));
      }

      await Promise.all(promises);

      if (!lineItems.value.length) {
        throw new NutsRedirect('/cart');
      }

      // No need to wait for this to finish, and doing it after the others is nicer to the server
      loadSafcToken();

      order.value = undefined;

      await loadImagesForNonPrimaryVariants();
      const actions: CartUpdateAction[] = [];
      if (customer.value) {
        actions.push({
          action: 'setCustomerEmail',
          email: customer.value.email,
        });
      }

      const defaultAddress: NutsAddress = { email: customer.value?.email ?? '', country: 'US' };
      const need = {
        emailAddress: needEmailAddress(lineItems.value),
        physicalAddress: needPhysicalAddress(lineItems.value),
        specialDelivery: lineItems.value.every(isPresetDeliveryLineItem),
      };
      const [shipment] = standardShipments.value;
      if ((need.physicalAddress || need.emailAddress) && !hasMultipleShipments.value && !shipment) {
        const address =
          need.physicalAddress && customerAddresses.value.length
            ? customerAddresses.value[0]
            : defaultAddress;

        const existingShipments = {
          standardShipments: standardShipments.value,
          allShipments: shipments.value,
        };
        actions.push(
          ...buildSetShippingAddressActions(
            address,
            existingShipments,
            lineItems.value.filter(
              (l) => !isPresetDeliveryLineItem(l) && !isGiftCertificateLineItem(l),
            ),
            cart.value?.shippingMode,
          ),
        );
      }

      const addItemShippingAddressAction = (
        update: CartUpdateAction,
      ): update is CartAddItemShippingAddressAction => update.action === 'addItemShippingAddress';
      actions.push(
        ...buildPresetShipmentActions(
          unassignedLineItems.value.filter(isPresetDeliveryLineItem),
          shipments.value,
          actions
            .filter(addItemShippingAddressAction)
            .filter((a) => !!a.address.key)
            .map((a) => a.address.key!),
          cart.value?.shippingMode,
        ),
      );
      if (need.specialDelivery) {
        if (cart.value?.shippingMode === 'Single') {
          actions.push(
            {
              action: 'setShippingAddress',
              address: defaultAddress,
            },
            {
              action: 'setCustomShippingMethod',
              shippingMethodName: 'ixclkxk.shop Shipping',
              shippingRate: {
                price: from(0),
              },
            },
          );
        }
        actions.push(
          ...standardShipments.value
            .filter((s) => !s.lineItems.length)
            .map<CartUpdateAction>((emptyShipment) => ({
              action: 'removeItemShippingAddress',
              addressKey: emptyShipment.key,
            })),
        );
        if (cart.value?.shippingMode === 'Multiple') {
          actions.push(
            ...standardShipments.value
              .filter((s) => !s.lineItems.length)
              .map<CartUpdateAction>((emptyShipment) => ({
                action: 'removeShippingMethod',
                shippingKey: emptyShipment.key,
              })),
          );
        }
      }

      const iterableEmailCampaignId = getCookie('iterableEmailCampaignId', false);
      const iterableTemplateId = getCookie('iterableTemplateId', false);

      if (iterableEmailCampaignId) {
        actions.push({
          action: 'setCustomField',
          name: 'iterableEmailCampaignId',
          value: Number(iterableEmailCampaignId),
        });
      }

      if (iterableTemplateId) {
        actions.push({
          action: 'setCustomField',
          name: 'iterableTemplateId',
          value: Number(iterableTemplateId),
        });
      }

      // make sure we finished checking with DY
      await loadDyFlags('[A/B Test] Delivery Date', ['deliveryDate']);

      const existingSurchargeLineItem = customLineItems.value.find((l) => l.slug === 'surcharge');
      if (existingSurchargeLineItem) {
        actions.push({
          action: 'removeCustomLineItem',
          customLineItemId: existingSurchargeLineItem.id,
        });
      }

      if (standardShipments.value.length === 1 && unassignedLineItems.value.length) {
        unassignedLineItems.value
          .filter((l) => !isPresetDeliveryLineItem(l))
          .forEach((lineItem) => {
            actions.push({
              action: 'setLineItemShippingDetails',
              lineItemId: lineItem.id,
              shippingDetails: {
                targets: [
                  {
                    addressKey: standardShipments.value[0].key,
                    quantity: lineItem.quantity,
                  },
                ],
              },
            });
          });
      }

      const initializeRestrictedCountrySkus = async () => {
        const skus = lineItems.value.map((li) => li.variant.sku);
        restrictedCountrySkus.value = await getRestrictedCountrySkus(skus);
      };
      await Promise.all([
        initializeRestrictedCountrySkus(),
        updateCart(() => [
          {
            action: 'recalculate',
            updateProductData: true,
          },
          !cart.value!.custom && {
            action: 'setCustomType',
            type: { typeId: 'type', key: 'cart' },
          },
          ...actions,
        ]),
      ]);

      const nonPurchasableLines = lineItems.value.filter(
        (l) => l.variant.backordered || !l.variant.active,
      );
      if (nonPurchasableLines.length) {
        const errors: Notification[] = [];
        const message = 'Sorry, this item is out of stock and has been removed from your cart.';
        await updateCart(() =>
          nonPurchasableLines.map((lineItem) => {
            errors.push({ message: `${lineItem.name.en}: ${message}` });
            return {
              action: 'removeLineItem',
              lineItemId: lineItem.id,
            };
          }),
        );
        throw new NutsRedirect('/cart', { errors });
      }
    },

    async placeOrder(payload: Omit<PlaceOrderRequest, 'cartId' | 'version'>) {
      if (lineItems.value.some((l) => !l.shippingDetails?.valid)) {
        throw new NutsRedirect('/cart');
      }

      const customerToAdd = {
        email: customerEmail.value ?? '',
        name: payload.billingAddress?.name ?? '',
        password: payload.triggerSetPasswordFlow && cxMode.value ? '' : payload.newPassword ?? '',
        onMessages: addNotifications,
        isB2b: payload.isB2b,
        triggerSetPasswordFlow: payload.triggerSetPasswordFlow && cxMode.value,
      };

      await tryCreateCustomerOrSignIn(customerToAdd, 'Payment');

      const { id: cartId, version } = cart.value!;

      let customerId;
      if (cxMode.value) customerId = customer.value?.id ?? null;

      let { paymentNonces } = payload;
      if (giftCertificate.value?.remainingValue.centAmount) {
        paymentNonces = [giftCertificate.value.nonce, ...paymentNonces];
      }
      if (storeCredit.value?.balance.centAmount) {
        paymentNonces = [storeCredit.value.nonce, ...paymentNonces];
      }

      const { data, errors, notices, warnings } = await placeOrder({
        ...payload,
        billingAddress: payload.billingAddress ? omit(payload.billingAddress, 'email') : undefined,
        cartId,
        customerId,
        newPassword: undefined, // TODO: Remove once the lambda isn't looking for it
        paymentNonces,
        safcToken: safcToken.value,
        version,
      }).catch(async (error) => {
        reportError(error);
        if (error?.response?.status === 409) {
          console.error('CME when trying to place order; redirecting to /cart');
          console.error('local cart', cart.value);
          const ct = createApi(webstore.sessionSpecific);
          const { body } = await ct.carts().withId({ ID: cartId }).get().execute();
          console.error('latest cart', body);
          throw new NutsRedirect('/cart');
        } else {
          throw error;
        }
      });

      if ('cart' in data) {
        setCart(data.cart);
      }

      const toThrow = errors?.shift();
      addNotifications({ errors, notices, warnings });
      if (toThrow) {
        throw new Error(toThrow.message);
      }

      if ('order' in data) {
        loadCreditAccounts();
        setGiftCertificate();
        return data;
      }
      throw new Error('No order but no errors?');
    },

    async purgeGiftOptions() {
      const greetingCardCartLineItems = lineItems.value.filter(isGreetingCardLineItem);
      await updateCart(() => [
        ...greetingCardCartLineItems.map(
          (lineItem): CartUpdateAction => ({
            action: 'removeLineItem',
            lineItemId: lineItem.id,
          }),
        ),
        ...customLineItems.value.map(
          (lineItem): CartUpdateAction => ({
            action: 'removeCustomLineItem',
            customLineItemId: lineItem.id,
          }),
        ),
      ]);
    },
  };
}

export default {};
