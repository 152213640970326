<template>
  <label
    class="flex items-center my-0 cursor-pointer"
    :class="{ 'relative inline-flex': appearanceNone }"
    data-test="radio-label"
  >
    <input
      v-model="selection"
      class="inline-block w-4 h-4 m-0 align-middle cursor-pointer base-radio accent-bg-green"
      :class="{
        'mr-3': $slots.default,
        'absolute top-0 left-0 m-0 appearance-none h-full w-full': appearanceNone,
      }"
      :name="$attrs.name"
      type="radio"
      :value="value"
    />
    <slot />
  </label>
</template>

<script lang="ts">
import { useVModel } from '@vueuse/core';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Radio',
  props: {
    appearanceNone: { required: false, type: Boolean, default: false },
    modelValue: { required: false, type: [String, Boolean, Object] },
    value: { required: false, type: [String, Boolean, Object] },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selection = useVModel(props, 'modelValue', emit);
    return {
      selection,
    };
  },
});
</script>

<style lang="scss" scoped>
.base-radio {
  accent-color: #4a8500;
}
</style>
