<template>
  <VueRecaptcha
    v-if="scriptLoaded"
    :load-recaptcha-script="false"
    ref="recaptchaRef"
    :sitekey="sitekey"
    size="invisible"
    tabindex="-1"
    @expired="onExpired"
    @render="onRender"
    @verify="onSubmit"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';

import { getConfigEntry } from '@/api/config';
import { waitRecaptcha } from '@/utils/captcha';

export interface CaptchaPublicInstance {
  /**
   * Executes the captcha verification
   */
  execute(): void;

  /**
   * Resets the captcha
   */
  reset(): void;
}

export default defineComponent({
  name: 'Captcha',
  components: {
    VueRecaptcha,
  },
  props: {
    /**
     * If true, captcha is executed on component mount
     */
    immediate: { required: false, type: Boolean, default: false },
  },
  emits: {
    expired: null,
    verify: (_: string) => true,
  },
  setup(props, { emit, expose }) {
    const recaptchaRef = ref();
    const scriptLoaded = ref(false);

    const execute = async () => {
      await waitRecaptcha;
      if (recaptchaRef.value) recaptchaRef.value.execute();
    };

    const reset = async () => {
      await waitRecaptcha;
      if (recaptchaRef.value) recaptchaRef.value.reset();
    };

    const onRender = () => {
      if (props.immediate) execute();
    };

    onMounted(async () => {
      await waitRecaptcha;
      scriptLoaded.value = true;
      if (props.immediate) execute();
    });

    expose({ execute, reset });

    return {
      execute,
      onExpired: () => emit('expired'),
      onRender,
      onSubmit: (response: string) => emit('verify', response),
      recaptchaRef,
      reset,
      scriptLoaded,
      sitekey: getConfigEntry('google').recaptcha.invisible.sitekey,
    };
  },
});
</script>
