<script setup lang="ts">
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { computed, toRef } from 'vue';
import { useStore } from 'vuex';

import Modal from '@/components/base/layout/Modal.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import Header3 from '@/components/base/typography/Header3.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import { useCustomer } from '@/composables/useCustomer';
import { useProductDetail } from '@/composables/useProductDetail';
import { getPrice, NutsProduct, NutsProductVariant, priceForQuantity } from '@/utils/product';

const props = defineProps<{ isOpen: boolean; product: NutsProduct; showB2bCTA: boolean }>();

const emit = defineEmits<{
  (e: 'open-modal'): void;
  (e: 'close-modal'): void;
}>();

const store = useStore();
const { customer, hasBusinessAccount } = useCustomer(store);

const { calculateCostSavings } = useProductDetail(toRef(props, 'product'));

const variants = computed(() => props.product?.variants.filter((v) => v.bulk && !v.wholesale));

const getDiscount = (variant: NutsProductVariant, minimumQuantity: number) => {
  const discounts = calculateCostSavings(variant, { quantity: minimumQuantity });
  return discounts?.find((discount) => discount.type === 'bulk')?.value;
};

const getPriceForQuantity = (variant: NutsProductVariant, quantity: number) => {
  const price = priceForQuantity(variant, { quantity }, true);
  return money(price);
};
</script>

<template>
  <div>
    <UnstyledButton
      class="mt-0"
      data-promo="1"
      data-promo-creative="View bulk discounts"
      data-promo-name="Infographic"
      @click="emit('open-modal')"
    >
      <slot>View bulk discount</slot>
    </UnstyledButton>
    <Modal
      anchor="right"
      borderRadius="rounded-t-xl md:rounded-none"
      data-test="bulk-discounts-modal"
      :hasControls="false"
      :isOpen="isOpen"
      skipFirstElementOnFocus
      width="w-full md:max-w-lg"
      @handle-close="emit('close-modal')"
    >
      <template #header>
        <Header3 headerTag="span" class="text-black"> Order in bulk</Header3>
      </template>
      <template #body>
        <table
          v-for="variant in variants"
          :key="variant.id"
          class="mt-6 text-base leading-5 text-center border-separate rounded font-sofia-pro table-border"
        >
          <thead class="font-semibold rounded">
            <tr class="bg-black text-gray-50 md:text-xl md:leading-6">
              <th colspan="3">{{ variant.unitName }}</th>
            </tr>
            <tr class="text-black bg-true-gray-100">
              <th class="table-border">Minimum</th>
              <th class="table-border">Discount</th>
              <th class="table-border">Price</th>
            </tr>
          </thead>
          <tbody class="font-medium leading-6 text-neutral-500">
            <tr v-for="tier in getPrice(variant).tiers" :key="tier.minimumQuantity">
              <td class="table-border">{{ tier.minimumQuantity }}</td>
              <td class="table-border">{{ getDiscount(variant, tier.minimumQuantity) }}%</td>
              <td class="table-border">
                {{ getPriceForQuantity(variant, tier.minimumQuantity) }}/lb
              </td>
            </tr>
          </tbody>
        </table>
        <p class="mt-4 text-sm md:mt-8 md:text-base text-neutral-500 font-proxima-nova">
          *Bulk discount is only eligible for
          {{ variants.map((v) => `${v.weight}lb`).join(' & ') }} bags.
        </p>
        <div
          v-if="showB2bCTA && !(customer && hasBusinessAccount)"
          class="py-6 mt-8 text-center rounded-md md:px-24 md:mr-5 md:absolute bg-green-50 md:bottom-3"
        >
          <p class="block text-xl font-semibold leading-6 md:text-2xl md:leading-7 font-sofia-pro">
            Are you a business?
          </p>
          <p class="block mt-2 text-sm font-semibold text-neutral-600 font-proxima-nova">
            Sign up for a business account with ixclkxk.shop
          </p>
          <RouteLink class="block mt-2" to="/business-signup">
            <span class="text-sm font-semibold text-black underline">Learn more</span>
          </RouteLink>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style scoped>
p {
  @apply mb-0;
}
th,
td {
  @apply py-1;
}
.table-border {
  @apply border border-solid border-true-gray-300;
}
</style>
