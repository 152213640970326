<script setup lang="ts">
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import RouteLink from '@/components/base/RouteLink.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import ApplePayButton from '@/components/cart/ApplePayButton.vue';
import ExpressCheckout from '@/components/cart/ExpressCheckout.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { useCheckout } from '@/composables/useCheckout';
import { useCustomer } from '@/composables/useCustomer';
import { CartDiscountWithSiteMessages, useDiscount } from '@/composables/useDiscount';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useLoginModal } from '@/composables/useLoginModal';
import { usePayment } from '@/composables/usePayment';
import money from '@/filters/money';
import { useNotifications } from '@/stores/notifications';
import { CheckoutEvents, formatPurchaseItem, gtag } from '@/utils/analytics';
import { getAnalyticsProducts, getCheckoutEvent, getCheckoutType } from '@/utils/analyticsPayloads';
import { ClickEvent } from '@/utils/browser';
import { NutsRedirect } from '@/utils/exceptions';
import { Money } from '@/utils/money';

const props = withDefaults(defineProps<{ showMiniCart?: boolean }>(), { showMiniCart: false });
const emit = defineEmits(['toggle-mini-cart']);

const store = useStore();
const { customer } = useCustomer(store);
const { flags, loadDyFlags } = useFeatureFlags(store);
const { addNotifications } = useNotifications();
const {
  loadCart,
  loadProductUrlPaths,
  preCheckoutLineItems: lineItems,
  productKeysById,
} = useCart(store);
const { cxMode, determineEntryLocation, prepareCartForCheckout } = useCheckout(store);
const { appliedDiscountCodes, appliedCartDiscountIds, cartDiscountsForMessages } =
  useDiscount(store);
const { handleOpen, state: signIn } = useLoginModal();
const { navigateTo, route } = useRouteChange(useRouter());
const { cartDiscountAmount, estimatedAmountToCharge, estimatedTotal } = usePayment(
  store,
  ref(false),
);

const cartContents = ref<HTMLElement>();
const miniCartStyle = ref('');

const cartDiscountMessage = computed(() => {
  let discount: CartDiscountWithSiteMessages | undefined;
  const latestAppliedTier: string | undefined =
    appliedCartDiscountIds.value[appliedCartDiscountIds.value.length - 1];
  if (latestAppliedTier) {
    let index = cartDiscountsForMessages.value.findIndex((d) => d.id === latestAppliedTier);
    if (index > -1 && index < cartDiscountsForMessages.value.length - 1) {
      index += 1;
    }
    discount = cartDiscountsForMessages.value[index];
  }
  if (!discount) {
    discount = cartDiscountsForMessages.value.find((d) => d.custom?.fields.miniCartMessage);
  }
  return discount?.custom?.fields.miniCartMessage ?? '';
});

const promoBanner = computed(() => ({
  backgroundColor: store.state.headerModule.cartBanner.background_color ?? undefined,
  message: [
    store.state.headerModule.cartBanner.message_html ?? undefined,
    cartDiscountMessage.value,
  ]
    .filter(Boolean)
    .join('<br/>'),
}));

const showDiscountAmount = computed(() => dollars(cartDiscountAmount.value) !== 0);

const dollarsOrFree = (amount: Money) => (dollars(amount) ? dollars(amount).toFixed(2) : 'FREE');

const toggleMiniCart = () => emit('toggle-mini-cart');

const goToCheckout = useCallback(async () => {
  try {
    await prepareCartForCheckout();
    return navigateTo(determineEntryLocation());
  } catch (error) {
    if (error instanceof NutsRedirect) {
      if (route?.path !== error.url) {
        await navigateTo(error.url);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        loadCart(true);
      }
      toggleMiniCart();
      const { errors } = error;
      return addNotifications({ errors });
    }
    console.error(error);
    return undefined;
  }
});

const scrollDown = () => {
  const elem = cartContents.value;
  const topPos = elem!.scrollHeight - elem!.clientHeight;
  elem!.scrollTo({ top: topPos, behavior: 'smooth' });
};

const setMiniCartStyle = (el: HTMLElement) => {
  if (el.clientHeight > window.innerHeight) {
    miniCartStyle.value = `height: ${window.innerHeight}px`;
  }
  setTimeout(scrollDown, 0);
};

const clickOutsideHandler = (e: ClickEvent) => {
  const miniCart = cartContents.value;
  if (!miniCart || miniCart.contains(e.target) || miniCart === e.target) return;
  if ((!e.target.offsetParent || !e.target.offsetParent.matches('li.cart')) && !signIn.isOpen) {
    toggleMiniCart();
  }
};

const analyticsProducts = computed(() => getAnalyticsProducts(lineItems?.value));

const checkoutType = computed(() => getCheckoutType(cxMode.value, customer.value));

const checkoutEvent = computed(() =>
  getCheckoutEvent(
    lineItems.value,
    checkoutType.value === 'Guest' ? undefined : checkoutType.value,
    estimatedAmountToCharge.value,
    appliedDiscountCodes.value,
    productKeysById.value,
  ),
);

const handleGoToCheckout = async () => {
  gtag('event', 'begin_checkout', checkoutEvent.value);
  if (!customer.value && !cxMode.value) {
    await loadDyFlags('[A/B Test] Guest Checkout Last Option', ['guestCheckoutLastOption']);
  }
  if (customer.value || cxMode.value || lineItems.value.length === 0) {
    goToCheckout.execute();
  } else {
    CheckoutEvents.step(1, analyticsProducts.value);
    handleOpen({ callback: goToCheckout, isCheckout: true });
  }
};

onMounted(() => {
  loadDyFlags('[A/B Test] Express Checkout', ['expressCheckout']);
  document.addEventListener('click', clickOutsideHandler as EventListener);

  watch(
    () => lineItems.value.length,
    () => {
      if (!route?.path.startsWith('/cart')) {
        loadProductUrlPaths();
      }
    },
    { immediate: true },
  );
});
onBeforeUnmount(() => {
  document.removeEventListener('click', clickOutsideHandler as EventListener);
});

watchEffect(() => {
  if (props.showMiniCart) {
    gtag('event', 'view_cart', {
      location: route?.path ?? window.location.pathname,
      currency: 'USD',
      value: money(estimatedAmountToCharge?.value),
      items: lineItems.value.map((li) => formatPurchaseItem(li, productKeysById.value)),
    });
  }
});
</script>

<template>
  <div class="fixed inset-x-0 z-10 top-20 sm:top-11" data-test="mini-cart">
    <div class="container relative">
      <Transition
        name="bounce"
        enter-active-class="bounceInDown"
        leave-active-class="bounceOutUp"
        @after-enter="setMiniCartStyle"
        @after-leave="miniCartStyle = ''"
      >
        <div
          v-if="showMiniCart"
          class="absolute right-0 z-10 px-3 pb-3 overflow-x-hidden overflow-y-auto w-80 -top-9 pt-11 animated cart-dropdown ptn-cart-summary"
          data-test="cart-dropdown"
          ref="cartContents"
          :style="miniCartStyle"
        >
          <div class="cart-contents">
            <table role="presentation" v-if="lineItems?.length">
              <tr v-for="lineItem in lineItems" :key="lineItem.id">
                <td class="p-2.5" data-test="quantity">{{ lineItem.quantity }}</td>
                <td class="p-2.5 leading-none">
                  <p
                    class="text-black escape-mb"
                    :class="{ 'mt-1': lineItem !== lineItems[0] }"
                    data-test="product-name"
                  >
                    <RouteLink
                      :to="lineItem.productPath ?? '#'"
                      class="text-base font-semibold leading-none font-sofia-pro color-inherit"
                      @click="toggleMiniCart"
                    >
                      {{ lineItem.name.en }}
                    </RouteLink>
                  </p>
                  <p
                    class="text-sm leading-none text-black escape-mb"
                    :class="{ 'mt-1.5': lineItem.variant.variantName?.en }"
                    data-test="unit-name"
                  >
                    {{ lineItem.variant.variantName?.en }}
                  </p>
                </td>
                <td class="p-2.5 text-right text-sm leading-none" data-test="line-total">
                  <div v-if="lineItem.totalSavings" class="flex-col">
                    <SmallBodyText :class="{ 'text-error ': lineItem.totalSavings?.onSale }">
                      {{ dollarsOrFree(lineItem.totalPriceBeforeCartLevelDiscount) }}
                    </SmallBodyText>
                    <span v-if="lineItem.totalSavings" class="sr-only">
                      Discounted from original price of
                    </span>
                    <del class="text-xs text-true-gray-500">{{
                      money(
                        lineItem.totalSavings?.comparisonPrice ??
                          lineItem.totalPriceBeforeCartLevelDiscount,
                      )
                    }}</del>
                  </div>
                  <template v-else>
                    {{ dollarsOrFree(lineItem.totalPriceBeforeCartLevelDiscount) }}
                  </template>
                </td>
              </tr>
              <tr v-if="showDiscountAmount">
                <td colspan="2" class="px-2.5 font-bold">
                  {{
                    appliedDiscountCodes.length
                      ? appliedDiscountCodes[0].label.toUpperCase()
                      : 'Applied Promos'
                  }}
                </td>
                <td class="p-2.5 text-right leading-normal font-bold" data-test="total-price">
                  {{ money(cartDiscountAmount) }}
                </td>
              </tr>
              <tr class="font-bold uppercase">
                <td colspan="2" class="p-2.5">Estimated Total</td>
                <td class="p-2.5 text-right leading-normal" data-test="total-price">
                  {{ money(estimatedTotal) }}
                </td>
              </tr>
            </table>
          </div>
          <div class="controls">
            <RouteLink
              to="/cart"
              class="w-[45%] hover:text-white visited:text-white bg-nuts-cyan-600 button"
              data-test="edit-cart"
              @click="toggleMiniCart"
            >
              <span class="text-white">Edit Cart</span>
            </RouteLink>
            &nbsp;
            <button
              class="button primary"
              name="button[checkout]"
              value="Check out"
              data-test="checkout"
              :disabled="goToCheckout.isPending"
              @click.prevent="handleGoToCheckout"
            >
              <img
                v-if="goToCheckout.isPending"
                class="w-4 h-4 -ml-2 animate-spin"
                src="@/assets/spinner.svg"
                alt="spinner"
              />
              Check Out
            </button>
            <ExpressCheckout
              v-if="flags.expressCheckout"
              miniCart
              trackingEventLocation="Minicart"
            />
            <ApplePayButton v-else componentName="Minicart" />
            <p>
              <a
                href="#"
                class="continue-shopping"
                data-test="toggle-mini-cart"
                @click.prevent="toggleMiniCart"
                >Or continue shopping</a
              >
            </p>
          </div>
          <div
            v-if="promoBanner.message"
            class="p-3 -m-3 text-center promo-banner"
            :style="{ backgroundColor: promoBanner.backgroundColor }"
            data-test="promo-banner"
          >
            <p class="text-white escape-mb" v-html="promoBanner.message" />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.continue-shopping {
  @apply visited:text-[#135A6E] text-[#135A6E];
}
.cart-contents {
  font-size: 15px;
  &:before,
  &:after {
    background-image: none;
  }
}
.controls {
  text-align: center;
  padding: 15px 0 5px 0;

  p {
    padding-top: 10px;
  }
}
.escape-mb {
  @apply mb-0;
}
.promo-banner {
  background: #79ac39;
}

.ptn-cart-summary {
  background-color: $mini-cart-dropdown-background-color;
}

:deep(.tertiary) {
  width: 45%;
}

:deep(.primary) {
  width: 45%;
}

:deep(.apple-pay) {
  width: 91%;
  margin-top: 4px;
}
table {
  background: $mini-cart-contents-background-color;
}
</style>
