<script setup lang="ts"></script>

<template>
  <p
    class="font-semibold leading-3 uppercase font-proxima-nova lg:text-black text-neutral-500 text-[8px] md:text-tiny lg:normal-case lg:text-sm"
  >
    <slot />
  </p>
</template>

<style scoped>
p {
  @apply mb-0;
}
</style>
