import createDebug from 'debug';

import { getSsrState } from '@/utils/ssrStorage';

const debug = createDebug('nuts:cookie');

export const getSerializedCookies = () => {
  if (typeof window !== 'undefined' && window.document) {
    return window.document.cookie;
  }
  const ssrStorage = getSsrState();
  return ssrStorage.cookie ?? '';
};

export const getCookie = <T = string>(name: string, parseJSON = true): T | undefined => {
  try {
    const cookies = getSerializedCookies();
    if (cookies) {
      const cookie = cookies.split('; ').find((val) => val.startsWith(`${name}=`));
      if (cookie) {
        const value = cookie.split('=')[1];
        if (value) {
          const decoded = decodeURIComponent(value);
          return parseJSON ? JSON.parse(decoded) : decoded;
        }
      }
    }
  } catch (err) {
    /* invalid cookies count as nonexistent cookies */
    debug('error parsing cookie %s:', name, err);
  }
  return undefined;
};

export const getSessionId = () => getCookie('sid', false);
