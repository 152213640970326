import { Customer, TypedMoney } from '@commercetools/platform-sdk';
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';

import money from '@/filters/money';
import { AnalyticsProduct, formatPurchaseItem } from '@/utils/analytics';
import { NutsLineItem } from '@/utils/cart';

export const getAnalyticsProducts = (lineItems: NutsLineItem[] | undefined) =>
  lineItems?.map(
    (lineItem): AnalyticsProduct => ({
      id: `0${lineItem.variant.sku}`,
      name: lineItem.name.en,
      quantity: lineItem.quantity,
      autoDeliveryInterval: lineItem.custom?.fields.autoDeliveryInterval,
      price: dollars(lineItem.price.value),
      primaryMerchandisingCategory: lineItem.variant.attributes?.find(
        (a: { name: string }) => a.name === 'merchandisingCategory',
      )?.value.label,
    }),
  ) ?? [];

export const getCheckoutEvent = (
  lineItems: NutsLineItem[] | undefined,
  checkoutType: string | undefined,
  estimatedAmountToCharge: TypedMoney,
  appliedDiscountCodes: {
    code: string;
    label: string;
  }[],
  productKeysById: { [p: string]: string },
) => ({
  checkout_type: checkoutType,
  currency: 'USD',
  value: money(estimatedAmountToCharge),
  coupon: appliedDiscountCodes?.[0]?.code,
  items: lineItems?.map((li) => formatPurchaseItem(li, productKeysById)),
});

export const getCheckoutType = (cxMode: boolean, customer: Customer | undefined) => {
  if (cxMode) return 'CX';
  return customer ? 'Customer' : 'Guest';
};
