/* eslint-disable max-classes-per-file */
import DyError from '@/api/dy/client/error';

class Payload {
  constructor(userId, sessionId) {
    this.userId = userId;
    this.sessionId = sessionId;
  }

  get() {
    return {
      user: {
        dyid: this.userId,
        dyid_server: this.userId,
      },
      session: {
        dy: this.sessionId,
      },
    };
  }
}

class ChoosePayload extends Payload {
  constructor(userId, sessionId, campaignNames, context, previewIds, options) {
    super(userId, sessionId);

    if (!context) {
      throw new DyError('Invalid context');
    }

    this.campaignNames = campaignNames;
    this.context = context;
    this.previewIds = previewIds;
    this.options = options;
  }

  get() {
    return {
      ...super.get(),
      context: this.context.get(),
      options: this.options,
      selector: {
        names: this.campaignNames,
        ...(this.previewIds && { preview: { ids: [this.previewIds] } }),
      },
    };
  }
}

class EngagementPayload extends Payload {
  constructor({ userId, sessionId, decisionId = null, slotId = null }) {
    super(userId, sessionId);

    if (!decisionId && !slotId) {
      throw new DyError('The decision or slot ID must be valid.');
    }
    this.engagements = [];

    if (decisionId) {
      const decision = {
        type: 'CLICK',
        decisionId,
      };
      this.engagements.push(decision);
    }

    if (slotId) {
      const slot = {
        type: 'SLOT_CLICK',
        slotId,
      };
      this.engagements.push(slot);
    }
  }

  get() {
    return {
      ...super.get(),
      engagements: this.engagements,
    };
  }
}

export { Payload, ChoosePayload, EngagementPayload };
