/* eslint-disable import/prefer-default-export */

import { Customer, DiscountCode } from '@commercetools/platform-sdk';
import { computed } from 'vue';
import { Store } from 'vuex';

import { CreditAccount, DescribeCustomerResponse, findExistingCustomer } from '@/api/customer';
import { useCart } from '@/composables/useCart';
import { NutsAddress } from '@/utils/address';
import { getCookie } from '@/utils/isomorphic/cookie';
import mascots from '@/utils/mascots';

function getFreeShippingThresholdFromAlstTag(customer?: Customer) {
  const tags: string[] = customer?.custom?.fields?.tags ?? [];
  const thresholds = tags.flatMap((tag) => tag.match(/alst-(\d+)-free-shipping/)?.[1] ?? []);
  if (!thresholds.length) return undefined;
  const threshold = Math.min(...thresholds.map(Number));
  return `$${threshold}`;
}

export function uuidToInteger(uuid: string) {
  return uuid
    .split('-')
    .map((part) => parseInt(part, 16))
    .reduce((total, part) => total + part, 0);
}

export function useCustomer(store: Store<any>) {
  const { loadSafcToken } = useCart(store);

  const carrierOptions = computed(() => store.state.customerModule.carrierOptions);

  const creditAccount = computed<CreditAccount | undefined>(
    () => store.state.customerModule.creditAccount ?? undefined,
  );

  const customer = computed<Customer | undefined>(
    () => store.state.customerModule.customer ?? undefined,
  );
  const customerAddresses = computed<NutsAddress[]>(
    () => store.state.customerModule.addresses ?? [],
  );
  const customerDiscountCodes = computed<DiscountCode[]>(
    () => store.state.customerModule.customerDiscountCodes ?? [],
  );
  const customerMemberSince = computed(() => store.state.customerModule.signedUpAt);
  const customerProfileImage = computed<string | undefined>(
    // When work has been done to support profile images, check should be added here.
    () => (customer.value?.id && mascots[uuidToInteger(customer.value.id) % 17]) || undefined,
  );
  const customerRegionalCarriersAllowed = computed(
    () =>
      (carrierOptions.value?.allowBlueStreak &&
        carrierOptions.value?.allowCdl &&
        carrierOptions.value?.allowGrandHusky &&
        carrierOptions.value?.allowLasership &&
        carrierOptions.value?.allowTforce &&
        carrierOptions.value?.allowUds &&
        carrierOptions.value?.allowOntrac) ??
      true,
  );

  const businessIndustry = computed(() => store.state.customerModule.businessIndustry);
  const hasBusinessAccount = computed(() => store.state.customerModule.hasBusinessAccount);
  const customerOrdersCount = computed(() => {
    const orderCount = Number(getCookie('_co', false) || '0');
    return Number.isNaN(orderCount) ? 0 : orderCount;
  });

  const offerNewsletter = computed(() => {
    const existingUnsubscribedUser = !!customer.value && !store.state.customerModule.subscribed;
    const existingUnsubscribedContact =
      !customer.value &&
      store.state.customerModule.subscribed !== null &&
      !store.state.customerModule.subscribed;
    return existingUnsubscribedUser || existingUnsubscribedContact;
  });

  const storeCredit = computed<CreditAccount | undefined>(
    () => store.state.customerModule.storeCredit ?? undefined,
  );

  const uniqueAddresses = computed<NutsAddress[]>(
    () => store.getters['customerModule/uniqueAddresses'],
  );

  return {
    businessIndustry,
    creditAccount,
    customer,
    customerAddresses,
    customerDiscountCodes,
    customerMemberSince,
    customerOrdersCount,
    customerProfileImage,
    customerRegionalCarriersAllowed,
    hasBusinessAccount,
    offerNewsletter,
    storeCredit,
    uniqueAddresses,

    async convertToBusinessAccount() {
      await store.dispatch('customerModule/convertToBusinessAccount');
    },

    async getAvailableUniqueAddresses() {
      await store.dispatch('customerModule/getAvailableUniqueAddresses');
    },

    async loadCreditAccounts() {
      await store.dispatch('customerModule/loadCreditAccounts');
    },

    async loadCustomerInfo(email?: string) {
      await store.dispatch('customerModule/loadCustomerInfo', email);
      loadSafcToken(email);
    },

    async loadGuestContactInfo(email: string) {
      await store.dispatch('customerModule/loadCustomerInfo', email);
    },

    setBusinessUnitIndustry(industry: string) {
      store.commit('customerModule/SET_BUSINESS_UNIT_INDUSTRY', industry);
    },

    setBusinessAccountInfo(businessAccountInfo: {
      hasBusinessAccount: boolean;
      industry: string | null;
    }) {
      store.commit(
        'customerModule/SET_HAS_BUSINESS_ACCOUNT',
        businessAccountInfo.hasBusinessAccount,
      );
      store.commit('customerModule/SET_BUSINESS_UNIT_INDUSTRY', businessAccountInfo.industry);
    },

    setCustomer(maybeCustomer?: Customer) {
      store.commit('customerModule/SET_CUSTOMER', maybeCustomer ?? null);
      store.commit(
        'shippingModule/SET_FREE_SHIPPING_THRESHOLD',
        getFreeShippingThresholdFromAlstTag(maybeCustomer),
      );
    },

    setCustomerInfo(customerDescription?: DescribeCustomerResponse) {
      store.commit('customerModule/SET_CUSTOMER_INFO', customerDescription);
      store.commit(
        'shippingModule/SET_FREE_SHIPPING_THRESHOLD',
        getFreeShippingThresholdFromAlstTag(customerDescription?.customer),
      );
    },

    async findExistingCustomer(email: string, captchaResponse: string) {
      return findExistingCustomer(email, captchaResponse);
    },
  };
}
