<script setup lang="ts">
import debounce from 'lodash/debounce';
import { computed, ref, watch } from 'vue';

import OutlinedSelect from '@/components/base/form/OutlinedSelect.vue';

const props = defineProps<{
  hasBorderRadius?: boolean;
  id?: string;
  maximumPiecesPerOrder?: number;
  modelValue: number;
}>();

const emit = defineEmits(['under-threshold', 'update:modelValue']);

const showAltQuantity = ref(props.modelValue >= 10);

const updateDebouncedQuantity = debounce((val) => {
  emit('update:modelValue', Math.max(1, val));
}, 500);

const quantity = computed<number | string>({
  get: () => props.modelValue,
  set: (val) => {
    if (val === '') return;
    if (val >= 10) showAltQuantity.value = true;
    if (val < 1) {
      emit('under-threshold');
    } else if (showAltQuantity.value) {
      updateDebouncedQuantity(val);
    } else {
      emit('update:modelValue', val);
    }
  },
});

watch(quantity, (val) => {
  if (val >= 10) showAltQuantity.value = true;
});

const options = computed(() => {
  const limit = !props.maximumPiecesPerOrder ? 9 : Math.min(9, props.maximumPiecesPerOrder);
  const showMore = (props.maximumPiecesPerOrder ?? 10) >= 10;

  const list = Array(limit)
    .fill(null)
    .map((_, idx) => ({
      label: `${idx + 1}`,
      value: idx + 1,
    }));

  if (showMore) list.push({ label: 'more...', value: 10 });

  return list;
});
</script>

<template>
  <div class="w-full quantity-selector-container">
    <span class="sr-only" :id="`label-${id}`">quantity</span>
    <OutlinedSelect
      v-if="!showAltQuantity"
      v-model="quantity"
      :id="id"
      aria-label="quantity"
      class="font-semibold"
      :class="{ 'rounded-lg': hasBorderRadius }"
      :options="options"
      data-test="quantity-options"
    />
    <input
      v-else
      v-model.number="quantity"
      class="w-full px-4 py-4 bg-transparent border border-solid border-true-gray-300 h-11"
      data-test="more-quantity"
      :aria-labelledby="`sr-${id}`"
      :max="maximumPiecesPerOrder"
      min="1"
      required
      type="number"
    />
    <span class="sr-only" :id="`sr-${id}`">Quantity</span>
  </div>
</template>
