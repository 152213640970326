/* eslint-disable import/prefer-default-export */
import { computed } from 'vue';
import { RouteLocationNormalizedLoaded, Router, useRoute } from 'vue-router';

export const useCurrentRoute = (url: string, route?: RouteLocationNormalizedLoaded) => {
  const path = computed(() => (!route ? new URL(url).pathname : route.path));

  const query = computed(() => (!route ? new URL(url).searchParams : route.query));

  return {
    path,
    query,
  };
};
