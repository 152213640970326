/* eslint-disable import/prefer-default-export */
import AutoDeliveryIcon from '@/assets/auto-delivery.svg';
import FavoritesIcon from '@/assets/favorites.svg';
import OrderHistoryIcon from '@/assets/order-history.svg';
import ReferralIcon from '@/assets/refer.svg';
import EasyReorderIcon from '@/assets/reorder.svg';
import SettingsIcon from '@/assets/settings.svg';
import { SiteNavigation } from '@/utils/navMenu';

export const staticAccountLinks: SiteNavigation['accountLinks'] = [
  {
    icon: EasyReorderIcon,
    link: {
      text: 'Easy Reorder',
      url: '/account/easy-reorder',
    },
  },
  {
    icon: ReferralIcon,
    link: {
      text: 'Refer a Friend, Get $10',
      url: '/account/refer',
    },
  },
  {
    icon: OrderHistoryIcon,
    link: {
      text: 'Order History',
      url: '/account/order-history',
    },
  },
  {
    icon: AutoDeliveryIcon,
    link: {
      text: 'Auto-Delivery',
      url: '/account/auto-delivery',
    },
  },
  {
    icon: FavoritesIcon,
    link: {
      text: 'Favorites',
      url: '/account/favorites',
    },
  },
  {
    icon: SettingsIcon,
    link: {
      text: 'Account Settings',
      url: '/account',
    },
  },
];
