<template>
  <div :class="mountedClass">
    <GoogleOneTap v-if="useGoogleOneTap" />
    <YotpoCustomerIdentification />
    <VueAnnouncer class="sr-only" />
    <template v-if="displayHeader">
      <RouterView v-if="hasHeaderOverride" name="pageHeader" />
      <BaseHeader v-else-if="flags.headerRedesign" />
      <PageHeader
        v-else
        :freeShippingThreshold="freeShippingThreshold"
        :logoPath="logoPath"
        :isAdmin="isAdmin"
        :fallBackLogoPath="fallBackLogoPath"
      />
    </template>
    <FlashMessages v-if="!disableFlashMessages" />
    <div class="sitewide-zone" v-once />
    <div ref="pageContent">
      <RouterView role="main" />
    </div>
    <div v-if="inTransition" @mousemove="inTransition = false" class="transition-cursor-blocker" />
    <template v-if="displayFooter">
      <RouterView v-if="hasFooterOverride" name="pageFooter" />
      <PageFooter v-else />
    </template>
  </div>
</template>

<script lang="ts">
import { templateRef, useEventListener, useMutationObserver } from '@vueuse/core';
import { HeadAttrs, useHead } from '@vueuse/head';
import compact from 'lodash/compact';
import { computed, defineComponent, onMounted, provide, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { init as initAlgoliaClient } from '@/api/algolia';
import { getConfigEntry } from '@/api/config';
import PageFooter from '@/components/layout/footer/Footer.vue';
import BaseHeader from '@/components/layout/header/BaseHeader.vue';
import PageHeader from '@/components/layout/header/Header.vue';
import FlashMessages from '@/components/layout/notifications/FlashMessages.vue';
import GoogleOneTap from '@/components/social/GoogleOneTap.vue';
import YotpoCustomerIdentification from '@/components/yotpo/YotpoCustomerIdentification.vue';
import { useCustomer } from '@/composables/useCustomer';
import { useDelivery } from '@/composables/useDelivery';
import { useDynamicYield } from '@/composables/useDynamicYield';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { PageTypeMetadata } from '@/router/routes';
import { SpaMode as DySpaMode } from '@/store/modules/dynamicYield';
import { useNotifications } from '@/stores/notifications';
import { gtag } from '@/utils/analytics';
import { ClickEvent } from '@/utils/browser';
import { waitABit } from '@/utils/waitABit';

export default defineComponent({
  name: 'App',
  props: {
    // Header props -- temporary
    logoPath: { required: false, type: String },
    isAdmin: { required: false, type: Boolean, default: false },
    fallBackLogoPath: { required: false, type: String },
  },
  components: {
    BaseHeader,
    GoogleOneTap,
    FlashMessages,
    PageFooter,
    PageHeader,
    YotpoCustomerIdentification,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { customer } = useCustomer(store);
    const { freeShippingThreshold } = useDelivery(store);
    const { setDyPageContext } = useDynamicYield(store);
    const { flags, loadDyFlags } = useFeatureFlags(store);
    const { dismissAllNotifications, dismissAllUnkeyedNotifications } = useNotifications();

    provide('algoliaClient', initAlgoliaClient());
    provide('isAdmin', props.isAdmin);

    const inTransition = ref(false);
    const mountedClass = ref('');

    const disableFlashMessages = computed(() =>
      ['Checkout', 'CheckoutReceipt', 'SignIn'].includes(route.name as string),
    );

    const fonts = [
      nutshell['fonts/proxima-nova-regular.woff2'],
      nutshell['fonts/proxima-nova-semibold.woff2'],
      nutshell['fonts/sofia-pro-semi-bold.woff2'],
      nutshell['fonts/sofia-pro-bold.woff2'],
      // sofia-pro-regular skipped because we barely use it
    ].map<HeadAttrs>((href) => ({
      rel: 'preload',
      as: 'font',
      type: 'font/woff2',
      crossorigin: true,
      href,
    }));

    const hasFooterOverride = computed(() => 'pageFooter' in (route.matched[0]?.components ?? {}));
    const hasHeaderOverride = computed(() => 'pageHeader' in (route.matched[0]?.components ?? {}));

    const { sitekey } = getConfigEntry('google').recaptcha.v3.invisible;

    useHead({
      title: 'ixclkxk.shop',
      link: computed(() => {
        const resolvedCss = compact((route.meta as PageTypeMetadata['meta']).webstoreAssets?.css);
        return [
          ...fonts,
          {
            rel: 'preload',
            as: 'style',
            href: 'https://use.typekit.net/qjh0ikv.css',
          },
          {
            rel: 'stylesheet',
            href: nutshell['css/main.css'],
          },
          ...resolvedCss.map((href) => ({
            rel: 'stylesheet',
            href,
          })),
        ];
      }),
      meta: [
        {
          name: 'og:description',
          content:
            'ixclkxk.shop is a family-owned business offering the highest quality nuts, snacks, dried fruit and pantry staples at home, in the office and on-the-go!',
        },
        {
          name: 'og:image',
          content: '/images/auto/801x534/assets/11e396e470741b57.jpg',
        },
        {
          name: 'og:title',
          content:
            'ixclkxk.shop | Bulk & Snack-size Nuts, Dried Fruits, Sweets, Specialty Baking Products & More!',
        },
        {
          name: 'og:url',
          content: '/',
        },
      ],
      script: [
        {
          src: `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`,
          async: true,
        },
        {
          src: `https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit`,
          async: true,
        },
      ],
      bodyAttrs: {
        class: Object.keys(flags).filter((flag) => flags[flag]),
      },
    });

    const pageContent = templateRef<HTMLElement>('pageContent');
    const sentPromoContentEvents = new Set<string>();
    onMounted(() => {
      mountedClass.value = 'page-mounted';

      const trackPromoContent = (
        element: Element,
        eventName: Parameters<typeof gtag>[1] = 'view_content',
      ) => {
        const name = element.getAttribute('data-promo-creative');
        const slot = element.getAttribute('data-promo-name');
        const sentKey = `${eventName}|${name}|${slot}`;

        if (!sentPromoContentEvents.has(sentKey)) {
          const creativeParams = {
            creative_name: name,
            creative_slot: slot,
          };
          gtag('event', eventName, creativeParams);
          sentPromoContentEvents.add(sentKey);
        }
      };

      const promotionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) return;
            trackPromoContent(entry.target);
            promotionObserver.unobserve(entry.target);
          });
        },
        { root: null, threshold: 0.5 },
      );
      const findPromoTags = () => document.querySelectorAll('[data-promo="1"]');
      let promoTags = findPromoTags();
      promoTags.forEach((tag) => promotionObserver.observe(tag));

      useEventListener<ClickEvent>(document, 'click', (e) => {
        const promotionalContent = e.target?.closest('[data-promo="1"]');
        if (!promotionalContent) return;
        trackPromoContent(promotionalContent, 'select_content');
      });

      router.beforeResolve(() => {
        if (window.innerWidth > 768) {
          inTransition.value = true;
        }
      });

      watch(
        () => route.fullPath,
        () => {
          inTransition.value = false;
        },
      );
      watch(
        () => route.path,
        (dest) => {
          if (dest.startsWith('/checkout/')) dismissAllUnkeyedNotifications();
          else dismissAllNotifications();
        },
      );
      useMutationObserver(
        pageContent,
        (mutations) => {
          if (mutations.length) {
            promoTags.forEach((tag) => promotionObserver.unobserve(tag));
            promoTags = findPromoTags();
            promoTags.forEach((tag) => promotionObserver.observe(tag));
          }
        },
        { childList: true, subtree: true },
      );
    });

    const canUseGoogleOneTap = computed(() => {
      if (customer.value) return false;
      return route.meta.gtagPageType === 'HP' || route.meta.gtagPageType === 'PDP';
    });
    const useGoogleOneTap = computed(() => {
      if (!canUseGoogleOneTap.value) return false;
      if (route.meta.gtagPageType === 'HP') return flags.googleOneTapHomepage;
      if (route.meta.gtagPageType === 'PDP') return flags.googleOneTapPDP;
      return false;
    });
    const googleOneTapTriggered = ref(false);
    onMounted(() => {
      waitABit().then(() => {
        watchEffect(async () => {
          if (canUseGoogleOneTap.value && !googleOneTapTriggered.value) {
            googleOneTapTriggered.value = true;
            loadDyFlags('[A/B Test] Google One Tap', ['googleOneTapHomepage', 'googleOneTapPDP']);
          }
        });
      });
    });

    return {
      disableFlashMessages,
      displayFooter: computed(() => store.state.displayFooter),
      displayHeader: computed(() => store.state.displayHeader),
      setDyPageContext,
      flags,
      freeShippingThreshold,
      hasFooterOverride,
      hasHeaderOverride,
      inTransition,
      mountedClass,
      useGoogleOneTap,
    };
  },
  mounted() {
    Object.assign(this.$router, {
      DySpaMode,
      setDyPageContext: this.setDyPageContext,
      dyCountAsPageview: false,
    });
  },
});
</script>
<style>
.grecaptcha-badge {
  visibility: hidden;
}
</style>
