import {
  Address,
  Cart,
  CartDiscount,
  CartUpdateAction,
  CustomFields,
  CustomLineItem,
  FieldContainer,
  Image,
  ItemShippingDetailsDraft,
  ItemShippingTarget,
  LineItem,
  LocalizedString,
  Price,
  ProductVariant,
  ShippingMode,
} from '@commercetools/platform-sdk';
import {
  INutsVariantAttributes as NutsVariantAttributes,
  pivotAttributeValues,
} from '@nuts/auto-delivery-sdk/dist/utils/helpers';
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import mapKeys from 'lodash/mapKeys';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import upperFirst from 'lodash/upperFirst';

import { getStaticPickupShippingOffer, ShippingOffer } from '@/api/shippingCalculator';
import { CostSaving } from '@/composables/useProductDetail';
import money from '@/filters/money';
import {
  CartLineItemFields,
  GreetingCardCartLineItemFields,
  ImageUrlsByProductId,
  PackingSlipMessageCustomLineItemFields,
} from '@/store/modules/cart';
import { NutsAddress } from '@/utils/address';
import { DateString } from '@/utils/dateTime';
import { ImageBySize } from '@/utils/image';
import proxiedImageUtil from '@/utils/imageProxied';
import {
  CartSavingsSummary,
  CartSavingsSummaryBreakdownItem,
  computeSavings,
  LineSavingsBreakdownItem,
  LineSavingsSummary,
  Money,
} from '@/utils/money';
import { reportError } from '@/utils/reportError';

export interface DigitalGiftRecipient {
  email: string;
  message?: string;
  name: string;
}

export interface DigitalGiftingFields {
  occasionImageUrl: string;
  occasionName: string;
  recipients: DigitalGiftRecipient[];
  sendAt: DateString;
  senderName: string;
}

export interface AddToCartPayload {
  cart_id?: string;
  cart_sku: {
    auto_delivery_interval_weeks?: number | null;
    auto_delivery_offer_location?: string | null;
    auto_delivery_offer_type?: string | null;
    custom?: {
      key: string;
      fields: { [key: string]: any | null };
    };
    digital_gifting_fields?: {
      occasion_image_url: string;
      occasion_name: string;
      recipients: DigitalGiftRecipient[];
      send_at: string;
      sender_name: string;
    };
    distribution_channel_key?: string | null;
    dynamic_bundle_skus?: string[];
    list_metadata?: string;
    marked_as_gift?: boolean;
    quantity: number;
    sku_external_id: string;
    variations?: number[];
  };
}
export interface AddToCartResponse {
  cart?: Cart;
  cart_line?: {
    id: string;
    quantity_added: number;
  };
}

export interface FixedAddress extends NutsAddress {
  groupShipments: boolean;
  skipFulfillment: boolean;
  shippingMethod: {
    carrier: string;
    carrierCode: string;
  };
}

export interface LineItemChanges {
  autoDeliveryInterval?: number | null;
  autoDeliveryOfferLocation?: string | null;
  autoDeliveryOfferType?: string | null;
  distributionChannelKey?: string | null;
  markedAsGift?: boolean;
  quantity?: number;
}

export interface NutsLineItem<T extends CartLineItemFields = CartLineItemFields> extends LineItem {
  readonly children?: NutsLineItem[];
  readonly custom?: CustomFields & {
    readonly fields: FieldContainer & T;
  };
  // readonly customizationsDescription?: string;
  readonly piecePrice: Money;
  readonly totalSavings?: LineSavingsSummary;
  readonly productKey?: string;
  readonly productPath?: string;
  readonly titleImage?: {
    readonly [size in keyof ImageBySize]: ImageBySize[size];
  };
  readonly totalPriceBeforeCartLevelDiscount: Money;
  readonly variant: ProductVariant & {
    readonly fixedAddress?: string;
    readonly pieceCost?: Money;
    readonly sku: string;
  } & Readonly<NutsVariantAttributes>;
}

/**
 * Ephemeral representation of the set of things we call a Shipment
 */
export interface Shipment {
  readonly key: string;
  readonly address: NutsAddress;
  readonly greetingCardLineItem?: NutsLineItem<GreetingCardCartLineItemFields>;
  readonly hasPhysical: boolean;
  readonly isFixedAddress: boolean;
  readonly isFixedEmailAddress: boolean;
  /** Does not include the greetingCardLineItem, if any */
  readonly lineItems: NutsLineItem[];
  readonly multishipSerial?: number;
  readonly needEmailAddress: boolean;
  readonly needPhysicalAddress: boolean;
  readonly packingSlipMessage?: string;
  readonly customFields?: FieldContainer;
}

export const njWarehouseAddress: NutsAddress = {
  street1: '125 Moen Ave',
  city: 'Cranford',
  state: 'NJ',
  postalCode: '07016',
  country: 'US',
  residential: false,
};

export function adaptDynamicDiscountMessaging(
  message: string,
  cartPredicate: CartDiscount['cartPredicate'],
  cartTotal: Money,
) {
  if (!/totalPrice >/.test(cartPredicate) || !/\[neededForThreshold\]/.test(message)) {
    return !/\[neededForThreshold\]/.test(message) ? message : '';
  }
  const thresholdMatcher = /totalPrice >=? "(?<thresholdString>\d+\.\d{2}) USD"/;
  const match = thresholdMatcher.exec(cartPredicate);
  if (!match || !match.groups) return '';
  const threshold = from(Number(match.groups.thresholdString));
  const neededForThreshold = Money.subtract(threshold, cartTotal);
  if (dollars(neededForThreshold) <= 0) return '';
  return message
    .replace('[neededForThreshold]', money(neededForThreshold))
    .replace('[threshold]', money(threshold));
}

// temporary during transition to new savings structure
export function adaptSavings(totalSavings: CartSavingsSummary): CostSaving[] {
  return totalSavings.breakdown.map((entry) => ({
    label: entry.description.en,
    type: entry.onSale ? 'product' : 'auto-delivery',
  }));
}

export function applyPriceTier(price: Price, quantity: number): Price {
  const tier = price.tiers?.filter((t) => t.minimumQuantity <= quantity).pop();
  return tier ? { ...price, value: tier.value } : price;
}

export function buildAddCustomShippingMethodAction(
  shippingKey: string,
  shippingAddress: NutsAddress,
  price: Money,
): CartUpdateAction {
  return {
    action: 'addCustomShippingMethod' as const,
    deliveries: [],
    // @ts-ignore
    externalTaxRate: {
      name: 'ixclkxk.shop Shipping Tax',
      amount: 0,
      country: 'US',
    },
    shippingAddress,
    shippingKey,
    shippingMethodName: 'ixclkxk.shop Shipping',
    shippingRate: { price },
  };
}

export const buildSetLineItemShippingDetailsActions = (
  lineItems: NutsLineItem[],
  addressKey: string,
  lineQuantities: { [lineItemId: string]: number },
) => {
  const actionForLineItem = (lineItem: NutsLineItem, parentLineItemId?: string) => {
    const { id: lineItemId, shippingDetails } = lineItem;
    return {
      action: 'setLineItemShippingDetails' as const,
      lineItemId,
      shippingDetails: {
        targets: [
          ...(shippingDetails?.targets.filter((t) => t.addressKey !== addressKey) ?? []),
          ...(lineQuantities[parentLineItemId ?? lineItemId]
            ? [{ addressKey, quantity: lineQuantities[parentLineItemId ?? lineItemId] }]
            : []),
        ],
      },
    };
  };

  const actions: ReturnType<typeof actionForLineItem>[] = [];
  actions.push(...lineItems.map((lineItem) => actionForLineItem(lineItem)));

  const flattenedChildLineItems = lineItems.flatMap((parent) => parent.children ?? []);

  actions.push(
    ...flattenedChildLineItems.map((childLineItem) => {
      const parent = lineItems.find(
        (p) => p.custom?.fields.externalId === childLineItem.custom?.fields.parentExternalId,
      );
      return actionForLineItem(childLineItem, parent?.id);
    }),
  );

  return actions;
};

export function buildRemoveGreetingCardAction(
  existingLineItem: NutsLineItem<GreetingCardCartLineItemFields>,
  shippingDetails?: ItemShippingDetailsDraft,
): CartUpdateAction {
  return {
    action: 'removeLineItem',
    lineItemId: existingLineItem.id,
    quantity: existingLineItem.quantity,
    shippingDetailsToRemove: shippingDetails,
  };
}

export function buildRemovePackingSlipAction(existingLineItem: CustomLineItem): CartUpdateAction {
  return {
    action: 'removeCustomLineItem',
    customLineItemId: existingLineItem.id,
  };
}

export function buildSetShippingAddressActions(
  address: NutsAddress,
  shipments: {
    standardShipments: Shipment[];
    allShipments: Shipment[];
  },
  lineItems: NutsLineItem[],
  shippingMode: ShippingMode | undefined,
): CartUpdateAction[] {
  const { standardShipments, allShipments } = shipments;
  const lineQuantities = lineItems.reduce(
    (accumulator, item) => ({
      ...accumulator,
      [item.id]: item.quantity,
    }),
    {},
  );

  const actions: CartUpdateAction[] = [];
  if (shippingMode === 'Single') {
    actions.push({ action: 'setShippingAddress', address: NutsAddress.toCt(address) });
  }
  // line items are pre-filtered to omit Special Delivery lines
  if (lineItems.length) {
    let shipmentNumber = 1;
    const exists = () => allShipments.find((s) => s.key === `shipment-${shipmentNumber}`);
    while (exists()) {
      shipmentNumber += 1;
    }
    let { key } = standardShipments[0] ?? {};
    if (!key) key = `shipment-${shipmentNumber}`;
    if (shippingMode === 'Multiple') {
      if (standardShipments.length) {
        actions.push({
          action: 'removeShippingMethod',
          shippingKey: key,
        });
      }
      actions.push(buildAddCustomShippingMethodAction(key, NutsAddress.toCt(address), from(0)));
    }
    actions.push(
      {
        action: standardShipments.length ? 'updateItemShippingAddress' : 'addItemShippingAddress',
        address: NutsAddress.toCt({ ...address, key }),
      },
      ...buildSetLineItemShippingDetailsActions(lineItems, key, lineQuantities),
    );
  }
  return actions;
}

export function buildSetShippingOfferOnItemShippingAddressAction(
  offer: ShippingOffer,
  key: string,
): CartUpdateAction {
  return {
    action: 'setItemShippingAddressCustomType',
    addressKey: key,
    type: {
      key: 'shippingAddress',
      typeId: 'type',
    },
    fields: pickBy({
      ...pick(offer, [
        'earliestArrivalOn',
        'flatRate',
        'latestArrivalOn',
        'price',
        'requestedShipOn',
        'requestedDeliveryOn',
      ]),
      componentOffersJson: offer.componentOffers.length && JSON.stringify(offer.componentOffers),
      ...mapKeys(offer.shipmentPickup, (_, field) => `shipmentPickup${upperFirst(field)}`),
    }),
  };
}

export function buildUpdateCustomShippingMethodActions(
  shippingKey: string,
  shippingAddress: NutsAddress,
  price: Money,
): CartUpdateAction[] {
  return [
    { action: 'removeShippingMethod', shippingKey },
    buildAddCustomShippingMethodAction(shippingKey, shippingAddress, price),
  ];
}

export function buildUpdateGreetingCardAction(
  existingLineItem?: NutsLineItem<GreetingCardCartLineItemFields>,
  sku?: string,
  message?: string,
  quantity?: number,
  shippingDetails?: ItemShippingDetailsDraft,
) {
  const actions: CartUpdateAction[] = [];
  if (existingLineItem) {
    actions.push(buildRemoveGreetingCardAction(existingLineItem, shippingDetails));
  }
  if (sku && message && quantity) {
    actions.push({
      action: 'addLineItem',
      custom: {
        type: { typeId: 'type', key: 'greetingCardCartLineItem' },
        fields: { greetingCardMessage: message },
      },
      quantity,
      sku,
      shippingDetails,
    });
  }
  return actions;
}

export function buildUpdatePackingSlipAction(
  existingLineItem?: CustomLineItem,
  message?: string,
  slug?: string,
  shippingDetails?: ItemShippingDetailsDraft,
) {
  const actions: CartUpdateAction[] = [];
  if (existingLineItem) {
    actions.push(buildRemovePackingSlipAction(existingLineItem));
  }
  if (message && slug) {
    actions.push({
      action: 'addCustomLineItem',
      name: { en: '' },
      money: from(0),
      slug,
      custom: {
        type: { typeId: 'type', key: 'packingSlipMessageCustomLineItem' },
        fields: { packingSlipMessage: message },
      },
      quantity: 1,
      shippingDetails,
    });
  }
  return actions;
}

export function isFixedAddressLineItem(lineItem: NutsLineItem) {
  return lineItem.variant.attributes?.some((a) => a.name === 'fixedAddress') ?? false;
}

export function isFixedEmailAddressLineItem(lineItem: NutsLineItem) {
  return !!lineItem.custom?.fields.recipientsJson;
}

export function isGiftCertificateLineItem(lineItem: NutsLineItem) {
  return lineItem.variant.attributes?.some(
    (a) => a.name === 'customProduct' && a.value.key === 'Gift Certificate',
  );
}

export function isGiftLineItem(lineItem: Pick<LineItem, 'lineItemMode'>) {
  return lineItem.lineItemMode === 'GiftLineItem';
}

export function isPresetDeliveryLineItem(lineItem: NutsLineItem) {
  return isFixedAddressLineItem(lineItem) || isFixedEmailAddressLineItem(lineItem);
}

export function buildPresetShipmentActions(
  lineItems: NutsLineItem[],
  shipments: Shipment[],
  pendingShipmentKeys: string[],
  shippingMode: ShippingMode = 'Single',
) {
  const actions: CartUpdateAction[] = [];
  let serialOffset = 0;
  const shipmentKeys = shipments.map((s) => s.key).concat(pendingShipmentKeys);
  const nextShipmentKey = (): string => {
    serialOffset += 1;
    const key = `shipment-${shipments.length + serialOffset}`;
    return shipmentKeys.includes(key) ? nextShipmentKey() : key;
  };
  lineItems.forEach((lineItem) => {
    const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
    if (isFixedEmailAddressLineItem(lineItem)) {
      const key = nextShipmentKey();
      const shippingOffer = getStaticPickupShippingOffer(DateString(tomorrow));
      const address = NutsAddress.toCt({ ...njWarehouseAddress, key });

      actions.push(
        ...(shippingMode === 'Multiple'
          ? [buildAddCustomShippingMethodAction(key, address, from(0))]
          : []),
        { action: 'addItemShippingAddress', address },
        ...buildSetLineItemShippingDetailsActions([lineItem], key, {
          [lineItem.id]: lineItem.quantity,
        }),
      );
      actions.push(buildSetShippingOfferOnItemShippingAddressAction(shippingOffer, key));
    } else if (isFixedAddressLineItem(lineItem)) {
      const fixedAddress: FixedAddress = JSON.parse(lineItem.variant.fixedAddress!);
      const shipmentsRequired = fixedAddress.groupShipments ? 1 : lineItem.quantity;
      const shippingTargets: ItemShippingTarget[] = [...(lineItem.shippingDetails?.targets ?? [])];
      new Array(shipmentsRequired).fill(fixedAddress).forEach((address) => {
        const key = nextShipmentKey();
        actions.push(
          ...(shippingMode === 'Multiple'
            ? [buildAddCustomShippingMethodAction(key, address, from(0))]
            : []),
          {
            action: 'addItemShippingAddress',
            address: NutsAddress.toCt({ ...address, key }),
          },
        );
        const quantity = fixedAddress.groupShipments ? lineItem.quantity : 1;
        shippingTargets.push({ addressKey: key, quantity });

        let shippingOffer = getStaticPickupShippingOffer(DateString(tomorrow));
        if (!fixedAddress.skipFulfillment) {
          const { shippingMethod } = fixedAddress;
          shippingOffer = {
            ...shippingOffer,
            ...shippingMethod,
            shipmentPickup: {
              ...shippingOffer.shipmentPickup,
              ...shippingMethod,
              shippingOption: 'Ground',
            },
          };
        }
        actions.push(buildSetShippingOfferOnItemShippingAddressAction(shippingOffer, key));
      });
      actions.push({
        action: 'setLineItemShippingDetails',
        lineItemId: lineItem.id,
        shippingDetails: { targets: shippingTargets },
      });
    }
  });
  return actions;
}

function withSummedChildren(parent: NutsLineItem, children: NutsLineItem[]): NutsLineItem {
  const all = [parent, ...children];
  const totalPriceBeforeCartLevelDiscount = Money.sumBy(
    all,
    (c) => c.totalPriceBeforeCartLevelDiscount,
  );
  let totalSavings: LineSavingsSummary | undefined;

  const childSavings = all.flatMap((c) => c.totalSavings ?? []);
  if (childSavings.length) {
    const comparisonPrice = Money.sumBy(
      all,
      (c) => c.totalSavings?.comparisonPrice ?? c.totalPriceBeforeCartLevelDiscount,
    );
    let balance = comparisonPrice;
    const breakdown = childSavings
      .flatMap((cs) => cs.breakdown)
      .map((b) => {
        const newPrice = Money.subtract(balance, b.value);
        const { percent } = computeSavings(balance, newPrice);
        balance = newPrice;
        return { ...b, percent };
      });
    if (!Money.equals(balance, totalPriceBeforeCartLevelDiscount)) {
      reportError('balance != totalPriceBeforeCartLevelDiscount (in withSummedChildren)');
    }
    totalSavings = {
      ...computeSavings(comparisonPrice, totalPriceBeforeCartLevelDiscount),
      breakdown,
      comparisonPrice,
      // the first we find will do for now, until we have a clear use case
      description: childSavings.find((cs) => cs.description)?.description,
      onSale: childSavings.some((cs) => cs.onSale),
    };
  }
  return {
    ...parent,
    children,
    piecePrice: Money.sumBy(all, (c) => c.piecePrice),
    taxedPrice: parent.taxedPrice && {
      totalGross: Money.sumBy(all, (c) => c.taxedPrice?.totalGross),
      totalNet: Money.sumBy(all, (c) => c.taxedPrice?.totalNet),
    },
    totalPrice: Money.sumBy(all, (c) => c.totalPrice),
    totalPriceBeforeCartLevelDiscount,
    totalSavings,
  };
}

export function collapseChildren(lineItems: NutsLineItem[]) {
  const { parents, ...childrenByParentId } = groupBy(
    lineItems,
    (li) => li.custom?.fields.parentExternalId ?? 'parents',
  );

  return (parents ?? []).map((parent) => {
    const children = childrenByParentId[parent.custom?.fields.externalId ?? ''];
    return children ? withSummedChildren(parent, children) : parent;
  });
}

export function containsGiftCertificate(lineItems: NutsLineItem[]) {
  return lineItems.some(isGiftCertificateLineItem);
}
export function findListingImage(
  images: Pick<Image, 'label' | 'url'>[],
): Pick<Image, 'label' | 'url'> | undefined {
  return images.find((i) => i.label?.includes('in list')) ?? images[0];
}

export const buildTitleImage = (lineItem: LineItem, imageUrlsByProductId: ImageUrlsByProductId) => {
  const {
    productId,
    variant: { images },
  } = lineItem;
  const url = (images && findListingImage(images)?.url) ?? imageUrlsByProductId[productId];
  return url ? proxiedImageUtil.getVariants([{ url }])[0] : undefined;
};

export function hasGiftCertificate(lineItems: NutsLineItem[]) {
  return lineItems.some(isGiftCertificateLineItem);
}

export function hasOnlyGiftCertificate(lineItems: NutsLineItem[]) {
  return lineItems.every(isGiftCertificateLineItem);
}

function isPhysical(lineItem: NutsLineItem) {
  return lineItem.variant.weight > 0;
}

export function hasDigital(lineItems: NutsLineItem[]) {
  return lineItems.some((l) => !isPhysical(l));
}

export function hasPhysical(lineItems: NutsLineItem[]) {
  return lineItems.some(isPhysical);
}

export function isFixedAddress(lineItems: NutsLineItem[]) {
  return !!lineItems.length && lineItems.every(isFixedAddressLineItem);
}

export function isFixedEmailAddress(lineItems: NutsLineItem[]) {
  return !!lineItems.length && lineItems.every(isFixedEmailAddressLineItem);
}

export function isGreetingCardLineItem(
  lineItem: NutsLineItem,
): lineItem is NutsLineItem<GreetingCardCartLineItemFields> {
  return !!lineItem.custom && 'greetingCardMessage' in lineItem.custom.fields;
}

export function isPackingSlipMessageCustomLineItem(
  lineItem: CustomLineItem,
): lineItem is CustomLineItem & { custom: { fields: PackingSlipMessageCustomLineItemFields } } {
  return !!lineItem.custom && 'packingSlipMessage' in lineItem.custom.fields;
}

export function isPresetDelivery(lineItems: NutsLineItem[]) {
  return isFixedAddress(lineItems) || isFixedEmailAddress(lineItems);
}

export function findMessage(
  cardLineItem?: NutsLineItem<GreetingCardCartLineItemFields>,
  customLineItems?: CustomLineItem[],
) {
  return (
    cardLineItem?.custom?.fields.greetingCardMessage ??
    customLineItems?.find(isPackingSlipMessageCustomLineItem)?.custom.fields.packingSlipMessage
  );
}

export function lineItemAndChildren(lineItem: NutsLineItem) {
  return [lineItem].concat(lineItem.children ?? []);
}

export function linesForKey<T extends NutsLineItem | CustomLineItem>(key: string, lines: T[]): T[] {
  return lines.flatMap((line) => {
    const target = line.shippingDetails?.targets.find((t) => t.addressKey === key);
    if (!target) {
      return [];
    }
    if (target.quantity === line.quantity) {
      return line;
    }

    const portion = (amount: Money) => Money.multiply(amount, target.quantity / line.quantity);

    const totalPrice = portion(line.totalPrice);

    let totalPriceBeforeCartLevelDiscount: Money | undefined;
    if ('totalPriceBeforeCartLevelDiscount' in line) {
      totalPriceBeforeCartLevelDiscount = portion(line.totalPriceBeforeCartLevelDiscount);
    }

    let totalSavings: LineSavingsSummary | undefined;
    if ('totalSavings' in line && line.totalSavings) {
      totalSavings = {
        ...line.totalSavings,
        breakdown: line.totalSavings.breakdown.map((b) => ({ ...b, value: portion(b.value) })),
        comparisonPrice: portion(line.totalSavings.comparisonPrice),
        value: portion(line.totalSavings.value),
      };
    }

    return {
      ...line,
      quantity: target.quantity,
      totalPrice,
      totalPriceBeforeCartLevelDiscount,
      totalSavings,
    };
  });
}

export function needEmailAddress(lineItems: NutsLineItem[]) {
  return isPresetDelivery(lineItems) ? false : hasGiftCertificate(lineItems);
}

export function needPhysicalAddress(lineItems: NutsLineItem[]) {
  return isPresetDelivery(lineItems) ? false : hasPhysical(lineItems);
}

export function buildLineSavingsSummary({
  lineItemMode,
  price,
  priceMode,
  quantity,
  variant,
}: LineItem) {
  const pieceComparisonPrice = variant.prices?.find((p) => !p.channel);
  if (isGiftLineItem({ lineItemMode }) || priceMode !== 'Platform' || !pieceComparisonPrice) {
    return undefined;
  }
  const totalComparisonPrice = Money.multiply(pieceComparisonPrice.value, quantity);
  const totalPriceBeforeCartLevelDiscount = Money.multiply(
    price.discounted?.value ?? price.value,
    quantity,
  );
  const breakdown: LineSavingsBreakdownItem[] = [];
  let balance = totalComparisonPrice;

  const recordBreakdownItem = (
    type: LineSavingsBreakdownItem['type'],
    newPiecePrice: Money,
    description?: LocalizedString,
    onSale = false,
  ) => {
    const newTotalPrice = Money.multiply(newPiecePrice, quantity);
    breakdown.push({
      type,
      ...computeSavings(balance, newTotalPrice),
      description,
      onSale,
    });
    balance = newTotalPrice;
  };

  if (price.channel) {
    const channelPrice = variant.prices?.find((p) => p.channel?.id === price.channel!.id);
    // We don't currently expand price.channel except on receipt page, so check
    // variant.prices[*].channel too if needed.
    const channel =
      price.channel.obj ??
      variant.prices?.find((p) => p.channel?.obj?.id === price.channel!.id)?.channel?.obj;
    if (channelPrice) {
      const { discountPercent } = channel?.custom?.fields ?? {};
      recordBreakdownItem('Channel', channelPrice.value, {
        en: discountPercent ? `${discountPercent}% off Auto-Delivery` : 'Auto-Delivery',
      });
    }
  }
  if (price.discounted) {
    recordBreakdownItem(
      'ProductDiscount',
      price.discounted.value,
      price.discounted.discount?.obj?.description ?? { en: 'Sale Discount' },
      true,
    );
  } else if (price.tiers) {
    const tier = price.tiers?.find((t) => Money.equals(price.value, t.value));
    if (tier) {
      recordBreakdownItem('PriceTier', tier.value, { en: 'Bulk Discount' });
    }
  }
  if (!Money.equals(balance, totalPriceBeforeCartLevelDiscount)) {
    reportError(`balance != totalPriceBeforeCartLevelDiscount`);
  }
  return breakdown.length
    ? <LineSavingsSummary>{
        ...computeSavings(totalComparisonPrice, totalPriceBeforeCartLevelDiscount),
        breakdown,
        comparisonPrice: totalComparisonPrice,
        description: breakdown.find((b) => b.description)?.description,
        onSale: breakdown.some((b) => b.onSale),
      }
    : undefined;
}

export const NutsLineItem = {
  fromCt(lineItem: LineItem): NutsLineItem {
    const discountedOrActivePrice = lineItem.price.discounted ?? lineItem.price;
    const piecePrice = isGiftLineItem(lineItem) ? from(0) : discountedOrActivePrice.value;
    return {
      ...lineItem,
      piecePrice,
      totalPriceBeforeCartLevelDiscount: Money.multiply(piecePrice, lineItem.quantity),
      totalSavings: buildLineSavingsSummary(lineItem),
      // customizationsDescription: '',
      variant: {
        ...lineItem.variant,
        fixedAddress: lineItem.variant.attributes?.find((a) => a.name === 'fixedAddress')?.value,
        pieceCost: lineItem.variant.attributes?.find((a) => a.name === 'pieceCost')?.value,
        sku: lineItem.variant.sku ?? '',
        ...pivotAttributeValues(lineItem.variant.attributes).variant,
      },
    };
  },
};

export function parseAddressKey(addressKey?: string) {
  if (!addressKey) return 1;
  return Number(addressKey.match(/\d+/)![0]);
}

export function sortByAddressKey(address: Address[]): Address[];
export function sortByAddressKey(shippingTargets: ItemShippingTarget[]): ItemShippingTarget[];
export function sortByAddressKey(shipments: Shipment[]): Shipment[];
export function sortByAddressKey(
  shipmentsOrTargets: Address[] | ItemShippingTarget[] | Shipment[],
) {
  return sortBy(shipmentsOrTargets, (entry: Address | ItemShippingTarget | Shipment) => {
    const key = 'addressKey' in entry ? entry.addressKey : entry.key;
    return parseAddressKey(key);
  });
}

export function summarizeSavings(
  lineItems: NutsLineItem[],
  cartDiscountAmount?: Money,
  cartDiscountLabel?: string,
): CartSavingsSummary | undefined {
  const incomingBreakdowns = lineItems.flatMap((li) => li.totalSavings?.breakdown ?? []);

  const byTypeAndDescription = groupBy(
    incomingBreakdowns,
    ({ description, type }) => `${type}-${description?.en}`,
  );

  const breakdown = Object.values(byTypeAndDescription).map(
    (lineBreakdowns): CartSavingsSummaryBreakdownItem => ({
      description: lineBreakdowns.find((b) => b.description)?.description ?? {
        en: lineBreakdowns[0].type,
      },
      onSale: lineBreakdowns.some((b) => b.onSale),
      value: Money.sumBy(lineBreakdowns, (b) => b.value),
    }),
  );

  if (cartDiscountAmount?.centAmount) {
    breakdown.push({
      description: { en: cartDiscountLabel ?? 'Discount' },
      onSale: false,
      value: cartDiscountAmount,
    });
  }

  if (!breakdown.length) return undefined;

  return {
    breakdown,
    value: Money.sumBy(breakdown, (b) => b.value),
  };
}

function buildLineItemShippingReductionActions(
  lineItem: NutsLineItem,
  quantity: number,
  cart: Cart,
) {
  const actions: CartUpdateAction[] = [];
  if (!lineItem.shippingDetails) return actions;

  const delta = quantity - lineItem.quantity;
  const lineItems = collapseChildren(cart.lineItems.map(NutsLineItem.fromCt));
  const shippingTargets = sortByAddressKey(lineItem.shippingDetails.targets);
  let appliedDelta = lineItem.quantity - sumBy(lineItem.shippingDetails.targets, 'quantity');
  const removals: CartUpdateAction[] = [];
  const revisedTargets: ItemShippingTarget[] = [];
  while (appliedDelta < Math.abs(delta)) {
    const target = shippingTargets.pop()!;
    let adjustedQuantity = target.quantity + delta + appliedDelta;
    if (adjustedQuantity <= 0) {
      adjustedQuantity = 0;
      const shipmentLineItems = linesForKey(target.addressKey, lineItems);
      if (shipmentLineItems.filter((l) => !isGreetingCardLineItem(l)).length === 1) {
        const greetingCard = shipmentLineItems.find(isGreetingCardLineItem);
        if (greetingCard) {
          removals.push(
            buildRemoveGreetingCardAction(greetingCard, {
              targets: [{ addressKey: target.addressKey, quantity: greetingCard.quantity }],
            }),
          );
        }
        removals.push(
          ...linesForKey(target.addressKey, cart.customLineItems).map<CartUpdateAction>(
            (customLineItem) => ({
              action: 'removeCustomLineItem',
              customLineItemId: customLineItem.id,
            }),
          ),
          {
            action: 'removeItemShippingAddress',
            addressKey: target.addressKey,
          },
        );
        if (cart.shippingMode === 'Multiple') {
          removals.push({
            action: 'removeShippingMethod',
            shippingKey: target.addressKey,
          });
        }
      }
    }
    const targetDelta = adjustedQuantity - target.quantity;
    revisedTargets.push({
      ...target,
      quantity: target.quantity - Math.abs(targetDelta),
    });
    appliedDelta += Math.abs(targetDelta);
  }
  revisedTargets.reverse();
  const targets = [...shippingTargets, ...revisedTargets].filter((t) => t.quantity);
  actions.push(
    ...lineItemAndChildren(lineItem).map<CartUpdateAction>((item) => ({
      action: 'setLineItemShippingDetails',
      lineItemId: item.id,
      shippingDetails: {
        targets,
      },
    })),
    ...removals,
  );

  return actions;
}

function buildRemoveLineItemActions(lineItem: NutsLineItem, cart: Cart) {
  const actions: CartUpdateAction[] = [];

  actions.push(
    ...lineItemAndChildren(lineItem).map<CartUpdateAction>((item) => ({
      action: 'removeLineItem',
      lineItemId: item.id,
    })),
  );

  const addressToKeep = sortByAddressKey(cart.itemShippingAddresses ?? []).shift();
  const lineItems = collapseChildren(cart.lineItems.map(NutsLineItem.fromCt));
  let removeAddressCount = 0;
  sortByAddressKey(lineItem.shippingDetails?.targets ?? [])
    .reverse()
    .forEach((target) => {
      const shipmentLineItems = linesForKey(target.addressKey, lineItems);
      if (shipmentLineItems.filter((l) => !isGreetingCardLineItem(l)).length === 1) {
        const greetingCard = shipmentLineItems.find(isGreetingCardLineItem);
        if (greetingCard) {
          actions.push(
            buildRemoveGreetingCardAction(greetingCard, {
              targets: [{ addressKey: target.addressKey, quantity: greetingCard.quantity }],
            }),
          );
        }
        actions.push(
          ...linesForKey(target.addressKey, cart.customLineItems).map<CartUpdateAction>(
            (customLineItem) => ({
              action: 'removeCustomLineItem',
              customLineItemId: customLineItem.id,
            }),
          ),
        );

        if (
          target.addressKey === addressToKeep?.key &&
          cart.itemShippingAddresses?.length === removeAddressCount + 1
        )
          return;

        actions.push({
          action: 'removeItemShippingAddress',
          addressKey: target.addressKey,
        });
        if (cart.shippingMode === 'Multiple') {
          actions.push({
            action: 'removeShippingMethod',
            shippingKey: target.addressKey,
          });
        }

        removeAddressCount += 1;
      }
    });

  return actions;
}

export function buildChangeLineItemQuantityActions(
  lineItem: NutsLineItem,
  quantity: number,
  cart: Cart,
) {
  const actions: CartUpdateAction[] = [];
  if (quantity > 0) {
    actions.push(
      ...lineItemAndChildren(lineItem).map<CartUpdateAction>((item) => ({
        action: 'changeLineItemQuantity',
        externalPrice: item.priceMode === 'ExternalPrice' ? item.price.value : undefined,
        lineItemId: item.id,
        quantity,
      })),
    );
    if (lineItem.shippingDetails) {
      if (lineItem.shippingDetails.targets.length === 1 && lineItem.shippingDetails.valid) {
        const [{ addressKey }] = lineItem.shippingDetails.targets;
        actions.push(
          ...buildSetLineItemShippingDetailsActions([lineItem], addressKey, {
            [lineItem.id]: quantity,
          }),
        );
      } else if (quantity < lineItem.quantity) {
        actions.push(...buildLineItemShippingReductionActions(lineItem, quantity, cart));
      }
    }
  } else {
    actions.push(...buildRemoveLineItemActions(lineItem, cart));
  }

  return actions;
}
