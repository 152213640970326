<script setup lang="ts">
import { computed, ref } from 'vue';

import RouteLink from '@/components/base/RouteLink.vue';
import { staticMegaMenu } from '@/data/nav/megaMenu';
import routerLinkDelegationMixin from '@/mixins/routerLinkDelegation';
import { ClickEvent } from '@/utils/browser';
import { MegaMenu, MegaMenuGroup } from '@/utils/navMenu';

const props = defineProps<{ megaMenuContent?: MegaMenu }>();
defineOptions({ mixins: [routerLinkDelegationMixin] });

const megaMenu = computed<MegaMenu>(() => props.megaMenuContent ?? staticMegaMenu);

const forceClearMegamenuHover = ref(false);
const forceClearMegamenuHoverOnNavigation = (e: ClickEvent) => {
  if (e.target.tagName !== 'A') return;
  forceClearMegamenuHover.value = true;
  setTimeout(() => {
    forceClearMegamenuHover.value = false;
  }, 300);
};

const renderDataPromoCreative = (menuGroup: MegaMenuGroup, ...pieces: (string | undefined)[]) =>
  [menuGroup.header.text].concat(pieces.filter<string>(Boolean)).join(' > ');
</script>

<template>
  <div class="header-nav enable-megamenu" data-test="main-nav">
    <div class="container relative">
      <nav id="main-nav" class="table-caption hidden-xs" role="navigation" aria-label="Main">
        <ul class="table-row ws-md:flex" :class="{ 'force-clear': forceClearMegamenuHover }">
          <li
            v-for="(menuGroup, i) in megaMenu.menuGroups"
            :key="menuGroup.header.url"
            class="table-cell ws-md:list-item menu-group"
          >
            <div class="flex items-center menu-item-wrapper" data-test="menu-item-wrapper">
              <RouteLink
                :to="menuGroup.header.url"
                class="menu-item-bar"
                data-promo="1"
                data-promo-name="Megamenu Nav Links"
                :data-promo-creative="menuGroup.header.text"
              >
                <span v-if="menuGroup.headerHtml" class="block" v-html="menuGroup.headerHtml" />
                <template v-else>
                  {{ menuGroup.header.text }}
                </template>
              </RouteLink>
            </div>
            <div class="megamenu" data-test="mega-menu">
              <nav class="sub-nav" :aria-label="`sub-nav-${i}`">
                <div class="nav-content">
                  <div
                    class="flex items-stretch row primary-surrogate-component"
                    @click="
                      forceClearMegamenuHoverOnNavigation($event), delegateLinksToRouter($event)
                    "
                  >
                    <div
                      v-for="(column, ci) in menuGroup.columns"
                      :key="ci"
                      class="col-md-2"
                      :class="{ 'border-l border-true-gray-200 border-solid': ci > 0 }"
                    >
                      <ul
                        v-for="({ group: linkGroup }, gi) in column.linkGroups"
                        :key="linkGroup.headerText || gi"
                      >
                        <li v-if="linkGroup.headerText">
                          <a
                            v-if="linkGroup.headerUrl"
                            :href="linkGroup.headerUrl"
                            class="gtm-nav-click bold-link"
                            data-promo="1"
                            data-promo-name="Megamenu Nav Links"
                            :data-promo-creative="
                              renderDataPromoCreative(menuGroup, linkGroup.headerText)
                            "
                          >
                            {{ linkGroup.headerText }}
                          </a>
                          <span v-else>
                            {{ linkGroup.headerText }}
                          </span>
                        </li>
                        <li v-for="{ emphasized, link } in linkGroup.links" :key="link.url">
                          <a
                            :href="link.url"
                            class="gtm-nav-click"
                            :class="{ 'bold-link': emphasized }"
                            data-promo="1"
                            data-promo-name="Megamenu Nav Links"
                            :data-promo-creative="
                              renderDataPromoCreative(menuGroup, linkGroup.headerText, link.text)
                            "
                          >
                            {{ link.text }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="border-l border-solid col-md-4 promo-zone border-true-gray-200">
                      <div class="promo">
                        <div :class="menuGroup.promoZone.top.cssClass">
                          <a
                            :href="menuGroup.promoZone.top.url"
                            class="gtm-nav-click"
                            :aria-label="`${menuGroup.promoZone.top.text} Image`"
                            data-promo="1"
                            data-promo-name="Megamenu Nav Links"
                            :data-promo-creative="`${menuGroup.header.text} > ${menuGroup.promoZone.top.text}`"
                          >
                            <img
                              :src="menuGroup.promoZone.top.image"
                              :alt="`${menuGroup.promoZone.top.text} Image`"
                              loading="lazy"
                            />
                          </a>
                          <a
                            :href="menuGroup.promoZone.top.url"
                            class="gtm-nav-click"
                            data-promo="1"
                            data-promo-name="Megamenu Nav Links"
                            :data-promo-creative="`${menuGroup.header.text} > ${menuGroup.promoZone.top.text}`"
                            >{{ menuGroup.promoZone.top.text }}
                          </a>
                        </div>
                      </div>
                      <hr class="bg-true-gray-200" />
                      <div class="promo">
                        <div :class="menuGroup.promoZone.bottom.cssClass">
                          <a
                            :href="menuGroup.promoZone.bottom.url"
                            class="gtm-nav-click"
                            :aria-label="`${menuGroup.promoZone.bottom.text} Image`"
                            data-promo="1"
                            data-promo-name="Megamenu Nav Links"
                            :data-promo-creative="`${menuGroup.header.text} > ${menuGroup.promoZone.bottom.text}`"
                          >
                            <img
                              :src="menuGroup.promoZone.bottom.image"
                              :alt="`${menuGroup.promoZone.bottom.text} Image`"
                              loading="lazy"
                            />
                          </a>
                          <a
                            :href="menuGroup.promoZone.bottom.url"
                            class="gtm-nav-click"
                            data-promo="1"
                            data-promo-name="Megamenu Nav Links"
                            :data-promo-creative="`${menuGroup.header.text} > ${menuGroup.promoZone.bottom.text}`"
                            >{{ menuGroup.promoZone.bottom.text }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-nav {
  background-color: $expanded-category-nav-background-color;
}
#main-nav {
  :not(.force-clear) {
    @include respond-min($screen-md-min) {
      .menu-group {
        &:hover {
          .megamenu {
            display: block;
          }
          .menu-item-wrapper > :deep(a) {
            color: $sunglow;
          }
        }
      }
    }
  }
}
.megamenu {
  position: absolute;
  left: 0;
  right: 0;
  top: 49px;
  padding-top: 10px;
  z-index: 999999;
  background: transparent;
  display: none;
}
.menu-group {
  @include respond-min($screen-md-min) {
    padding: 0;
  }
  @include respond-max($screen-sm-max) {
    padding: 0 10px;
    position: relative;
    text-align: center;
    vertical-align: middle;
  }
  &:after {
    content: ' ';
    @include retina-sprite($sprite-main-nav-separator-1x);
    opacity: 0.75;

    @include respond-min-max($screen-sm-min, $screen-sm-max) {
      position: absolute;
      top: 4px;
      right: -1px;
    }
  }
  &:first-of-type {
    @include respond-min-max($screen-sm-min, $screen-sm-max) {
      padding-left: 0;
    }
    .menu-item-wrapper {
      @include respond-min($screen-md-min) {
        padding-left: 0;

        :deep(.menu-item-bar) {
          &:after {
            left: -10%;
            width: 90%;
          }
        }
      }
    }
  }
  &:last-of-type {
    @include respond-min($screen-sm-min) {
      padding-right: 0;
      &:after {
        background: none;
      }
    }
    @include respond-min($screen-md-min) {
      .menu-item-wrapper {
        &:after {
          background: none;
        }
      }
    }
  }
  &:hover {
    @include respond-min($screen-md-min) {
      :deep(.menu-item-bar) {
        &:after {
          display: block;
        }
      }
    }
  }
}
:deep(.menu-item-bar) {
  font-family: $font-family-sofia-pro;
  font-style: normal;
  font-weight: 600;
  color: $main-nav-link-color;
  font-size: 20px;
  line-height: 1;
  text-decoration: none;
  @include respond-min-max($screen-sm-min, $screen-sm-max) {
    font-size: 16px;
  }

  &:after {
    bottom: -35%;
    left: 10%;
    border: none;
    content: ' ';
    height: 5px;
    width: 80%;
    position: absolute;
    pointer-events: none;
    margin-left: 0%;
    background: $sunglow;
    display: none;
  }
}
.menu-item-wrapper {
  @include respond-min($screen-md-min) {
    position: relative;
    padding: 0 24px;
    vertical-align: middle;
    text-align: center;
    min-height: 44px;
    height: 44px;
    margin: 0;

    &:after {
      content: ' ';
      @include retina-sprite($sprite-main-nav-separator-1x);
      opacity: 0.75;
      position: absolute;
      top: 8px;
      right: -1px;
      @include respond-min($screen-lg-min) {
        position: absolute;
        top: 12px;
        right: -1px;
      }
    }
  }
  @include respond-min-max($screen-md-min, $screen-md-max) {
    padding: 0 11px;
  }
}
.nav-content {
  background-color: #fff;
  border: none;
  padding: 15px 20px 20px 20px;
  text-align: left;
  border-radius: 3px 3px 15px 15px;
  @include respond-min-max($screen-md-min, $screen-lg-min) {
    padding: 5px 10px 10px 10px;
  }

  span {
    color: $gray-dark;
    font-family: $font-family-sofia-pro;
    font-weight: 600;
    font-size: 16px;
    cursor: default;
    line-height: 16px;
    @include respond-min-max($screen-md-min, $screen-lg-min) {
      font-size: 14px;
      line-height: 14px;
    }
  }
  ul {
    list-style: none;
    display: block;
    padding: 0;
    margin: 10px 0 40px 0;
    width: auto;
    @include respond-max($screen-lg-min) {
      margin: 10px 0 20px 0;
    }
  }
  li {
    display: block;
    min-height: auto;
    text-align: left;
    margin: 18px 0;
    padding: 0;
    cursor: pointer;

    a {
      color: $gray-dark;
      font-family: $font-family-proxima;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      @include respond-min-max($screen-md-min, $screen-lg-min) {
        font-size: 12px;
        line-height: 12px;
      }

      &:hover {
        text-decoration: underline;
      }

      &.bold-link {
        color: $eastern-blue;
        font-family: $font-family-sofia-pro;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        @include respond-min-max($screen-md-min, $screen-lg-min) {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
  }
}
.promo-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  &:deep(a) {
    display: block;
    color: $eastern-blue;
    font-family: $font-family-sofia-pro;
    font-size: 16px;
    margin: 5px auto;
    text-transform: none;
    @include respond-min-max($screen-md-min, $screen-lg-min) {
      font-size: 14px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    border: none;
    height: 1px;
    width: 100%;
  }

  :deep(.promo) {
    text-align: center;

    img {
      border: none;
      width: 260px;
      @include respond-min-max($screen-md-min, $screen-lg-min) {
        width: 200px;
      }
    }
  }
}
.sub-nav {
  background: #fff;
  box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.15), 5px 5px 5px -5px rgba(0, 0, 0, 0.15),
    -5px 5px 5px -5px rgba(0, 0, 0, 0.15);
  border-radius: 3px 3px 15px 15px;
}
nav {
  @include respond-min($screen-lg-min) {
    margin: 12px auto 15px auto;
  }
  @include respond-min-max($screen-sm-min, $screen-md-max) {
    margin: 15px auto 15px auto;
  }
}
</style>
