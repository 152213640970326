<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { MultipleQueriesResponse } from '@algolia/client-search';
import { useAnnouncer } from '@vue-a11y/announcer';
import createDebug from 'debug';
import { computed, inject, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { AlgoliaCategory, AlgoliaProduct, init as initAlgoliaClient } from '@/api/algolia';
import DepartmentList from '@/components/layout/header/DepartmentList.vue';
import ProductList from '@/components/layout/header/ProductList.vue';
import SearchBarStaticContent from '@/components/layout/header/SearchBarStaticContent.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { gtag } from '@/utils/analytics';

const debug = createDebug('nuts:AutoComplete');

const algoliaClient = inject('algoliaClient', () => initAlgoliaClient(), true);
const { navigateTo } = useRouteChange(useRouter());

const query = ref('');
const results = ref<MultipleQueriesResponse<AlgoliaCategory | AlgoliaProduct>['results']>([]);
const searchForm = ref<HTMLElement>();
const showSuggestion = ref(true);
const input = ref<HTMLElement>();
const suggestionsModal = ref<HTMLElement>();
const mutationObserver = ref<MutationObserver>();
const showSuggestionModal = computed(
  () => showSuggestion.value && results.value.some((i) => i.hits.length),
);

const { polite } = useAnnouncer();

const focusableElements = ref<NodeListOf<HTMLElement> | HTMLElement[]>([]);

const firstFocusableElement = computed(() => focusableElements.value[0]);
const lastFocusableElement = computed(
  () => focusableElements.value[focusableElements.value.length - 1],
);

const handleForwardKeyTab = (event: Event) => {
  if (lastFocusableElement.value === document.activeElement) {
    event.preventDefault();
    firstFocusableElement.value?.focus();
  }
};

const handleBackwardKeyTab = (event: Event) => {
  if (firstFocusableElement.value === document.activeElement) {
    event.preventDefault();
    lastFocusableElement.value?.focus();
  }
};

const refreshFocusableElements = () => {
  focusableElements.value =
    suggestionsModal.value?.querySelectorAll<HTMLElement>(
      `[href], input:not([disabled]),
    select:not([disabled]), textarea:not([disabled]):not([name="g-recaptcha-response"]),
    [tabindex]:not([tabindex="-1"]):not([disabled])`,
    ) ?? [];
};

const autoComplete = async () => {
  const distinct = 1;
  const hitsPerPage = 5;
  try {
    results.value = (
      await algoliaClient.search<AlgoliaCategory | AlgoliaProduct>([
        {
          indexName: 'Departments',
          query: query.value,
          params: {
            analytics: !!query.value,
            distinct,
            hitsPerPage,
            filters: 'idealForSuggestions:true',
          },
        },
        {
          indexName: 'Products',
          query: query.value,
          params: {
            analytics: !!query.value,
            distinct,
            hitsPerPage,
            filters: 'traits:searchable',
            getRankingInfo: true,
          },
        },
      ])
    ).results;

    gtag('event', 'content_decision', {
      cms: 'Algolia',
      content_id: results.value[1]?.abTestID,
      test_variation_id: results.value[1]?.abTestVariantID,
    });
  } catch (err) {
    debug('failed to get Algolia results', err);
    results.value = [];
  }
  showSuggestion.value = true;
  polite('Suggestions modal opened, use tab to navigate');
};

const hideAutocompleteSuggestions = () => {
  showSuggestion.value = false;
  polite('Suggestions modal closed');
};

const search = () => {
  let href = '/search/instant';
  if (query.value) {
    href += `?query=${query.value}`;
  }
  navigateTo(href);
};

const clickOutsideHandler = (e) => {
  const form = searchForm.value;
  if (!form || form.contains(e.target) || form === e.target) return;
  if (showSuggestion.value === true) {
    hideAutocompleteSuggestions();
  }
};
onMounted(() => {
  document.addEventListener('click', clickOutsideHandler);
});
onBeforeUnmount(() => {
  document.removeEventListener('click', clickOutsideHandler);
  mutationObserver.value?.disconnect();
});

watch(showSuggestionModal, (value) => {
  if (value) {
    setTimeout(() => {
      if (suggestionsModal.value) {
        refreshFocusableElements();
        const observer = new MutationObserver(refreshFocusableElements);
        mutationObserver.value = observer;
        observer.observe(suggestionsModal.value, {
          childList: true,
          subtree: true,
        });
      }
    }, 50);
  } else {
    mutationObserver.value?.disconnect();
  }
});
</script>

<template>
  <form ref="searchForm" class="relative ws-sm:static" @submit.prevent="search">
    <div class="twitter-typeahead">
      <div>
        <input
          type="search"
          v-model="query"
          placeholder="Search for a product"
          @input="autoComplete"
          class="search-field"
          aria-label="search-field"
          ref="input"
          title="search-field"
        />
        <div
          v-if="query && results.length > 0"
          id="suggestion-list"
          class="hidden suggestion-list lg:block"
          @keydown.esc.prevent="
            hideAutocompleteSuggestions();
            input?.focus();
          "
          @keydown.exact.tab="handleForwardKeyTab"
          @keydown.shift.tab="handleBackwardKeyTab"
          ref="suggestionsModal"
          v-show="showSuggestionModal"
        >
          <div
            v-for="(group, i) in results"
            :key="group.index"
            :class="`tt-dataset-${i} ${group.index}-results`"
          >
            <DepartmentList
              v-if="group.index === 'Departments'"
              :index="group"
              @navigating="hideAutocompleteSuggestions"
            />
            <ProductList v-else :index="group" @navigating="hideAutocompleteSuggestions" />
          </div>
        </div>
      </div>
    </div>
    <SearchBarStaticContent />
  </form>
</template>

<style lang="scss" scoped>
.search-field {
  border: 0;
  border-radius: 4px;
  padding: 12px 100px 12px 12px;
  width: 314px;
  height: 39px;
  box-sizing: border-box;
  @include respond-min($screen-lg-min) {
    width: 370px;
  }
  @include respond-max($screen-xs-max) {
    width: 100%;
    border: 1px solid $eastern-blue;
  }
}
.suggestion-list {
  background-color: $white;
  width: 600px;
  right: 47px;
  position: absolute;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.hidden {
  visibility: visible;
}
</style>
