<template>
  <ProductRecommendations
    v-if="list?.length"
    v-bind="attributes"
    :buttonTheme="buttonTheme"
    :controlsColor="controlsColor"
    :flushRightOnMobile="flushRightOnMobile"
    :layout="layout"
    :list="list"
    :title="title"
    :link="link"
    :spacingDirection="spacingDirection"
    :spacingLevel="spacingLevel"
    :subtitle="subtitle"
    :trackingEventLocation="trackingEventLocation"
    clickTrackable
    impressionTrackable
    :enableAddToCart="adaptLegacyBoolean(enableAddToCart)"
    :analyticsList="analyticsList"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onServerPrefetch, PropType } from 'vue';
import { useStore } from 'vuex';

import ProductRecommendations from '@/components/recommendations/ProductRecommendations.vue';
import { buttonStyleProps } from '@/composables/useButtonStyles';
import { hasProducts, Recommendation, useDynamicYield } from '@/composables/useDynamicYield';
import { spacingInputRegistration, spacingProps } from '@/composables/useSpacing';
import {
  adaptLegacyBoolean,
  BooleanOrBooleanString,
  CmsRegisteredComponent,
  TWColorsText,
} from '@/utils/cms';

const DyProductRecommendationsRow = defineComponent({
  name: 'DyProductRecommendationsRow',
  props: {
    analyticsList: { required: false, type: String, default: '' },
    attributes: { required: false, type: Object },
    buttonTheme: buttonStyleProps.theme,
    controlsColor: { required: false, type: String },
    enableAddToCart: {
      required: false,
      type: [Boolean, String] as PropType<BooleanOrBooleanString>,
    },
    flushRightOnMobile: { required: false, type: Boolean, default: true },
    layout: { required: false, type: String, default: 'row' },
    link: { required: false, type: String },
    prefetch: { required: false, type: [Boolean, String] as PropType<BooleanOrBooleanString> },
    productList: { required: false, type: Array as PropType<Recommendation[]> },
    selector: { required: false, type: String },
    ...spacingProps,
    title: { required: false, type: String },
    subtitle: { required: false, type: String },
    trackingEventLocation: { required: false, type: String, default: '' },
  },
  components: {
    ProductRecommendations,
  },
  setup(props) {
    const store = useStore();
    const { fetchDyVariations, getDyVariations } = useDynamicYield(store, props.selector);

    const list = computed<Recommendation[] | undefined>(() =>
      hasProducts(props.productList)
        ? props.productList
        : getDyVariations.value[props.selector ?? ''],
    );

    const loadRecommendations = async () => {
      if (hasProducts(list.value)) return;
      await fetchDyVariations();
    };

    onMounted(loadRecommendations);
    if (adaptLegacyBoolean(props.prefetch)) onServerPrefetch(loadRecommendations);

    return {
      adaptLegacyBoolean,
      list,
    };
  },
});

export const DyProductRecommendationsRowRegistration: CmsRegisteredComponent = {
  component: DyProductRecommendationsRow,
  name: 'DY Product Recommendations',
  inputs: [
    {
      name: 'controlsColor',
      friendlyName: '(Optional) Controls Color',
      type: 'string',
      enum: Object.keys(TWColorsText),
      defaultValue: 'black',
    },
    {
      name: 'selector',
      friendlyName: 'Experience Selector',
      type: 'string',
      defaultValue: '[TEST] Default Recommendations',
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Recommendations',
    },
    {
      name: 'subtitle',
      type: 'string',
      defaultValue: '(Optional) Recommendations Subtitle',
    },
    ...spacingInputRegistration,
    {
      name: 'link',
      friendlyName: 'View All link',
      type: 'string',
    },
    {
      name: 'enableAddToCart',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'prefetch',
      type: 'boolean',
      helperText:
        'Load prominent recommendations at the top of the page; otherwise loads after page is rendered',
    },
    {
      advanced: true,
      defaultValue: true,
      friendlyName: 'Mobile: flush with right',
      helperText: 'Scroll entries to be flush with the right side of mobile devices',
      name: 'flushRightOnMobile',
      type: 'boolean',
    },
    {
      name: 'analyticsList',
      friendlyName: 'Analytics: List Name',
      type: 'string',
      helperText:
        'Helps filter clicks/impressions in reporting; e.g. "GLP Seasonal Recommendations"',
      advanced: true,
    },
    {
      name: 'trackingEventLocation',
      friendlyName: 'Analytics: Page Type',
      type: 'string',
      helperText: 'Helps filter clicks/impressions in reporting; e.g. "PDP"',
      advanced: true,
    },
    {
      name: 'layout',
      friendlyName: 'Row Layout',
      type: 'string',
      defaultValue: 'row',
      enum: ['row', 'grid'],
      helperText: 'Product reccomendations row can be a grid or single row',
    },
    {
      name: 'buttonTheme',
      friendlyName: '(Optional) Button Theme',
      type: 'string',
      defaultValue: 'green',
      enum: ['gray', 'green', 'red', 'transparent', 'white', 'yellow'],
    },
  ],
  noWrap: true,
};

export default DyProductRecommendationsRow;
</script>
