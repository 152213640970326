<script setup lang="ts">
import arrowDown from '@/assets/interface/essential/arrow-down.svg';
import { LargeMenu, SiteNavigation, SmallMenu } from '@/utils/navMenu';

defineProps<{
  active: boolean;
  extendedNavigation?: boolean;
  header: LargeMenu['header'] | NonNullable<SiteNavigation['featuredPage']> | SmallMenu['header'];
  showSubMenu: boolean;
}>();

const arrowDownUrl = `url(${arrowDown})`;
</script>

<template>
  <div class="flex items-center">
    <img
      v-if="header.icon"
      class="w-5 h-5 mr-2.5"
      :class="{ 'opacity-50': !active && showSubMenu }"
      alt="Emphasized top menu link icon"
      :src="header.icon"
    />
    <span
      class="text-black"
      :class="{ 'opacity-50': !active && showSubMenu }"
      :style="{ color: header.color }"
    >
      {{ header.text }}
      <span class="sr-only">Press arrowdown key to enter submenu</span>
    </span>
    <div
      v-if="extendedNavigation"
      class="flex items-center justify-center w-4 h-4 mt-1 ml-1 masked-arrow"
      :class="[
        active && showSubMenu ? 'bg-black' : 'bg-nuts-neutral-400',
        { 'bg-nuts-neutral-400/50': showSubMenu && !active },
      ]"
    />
  </div>
</template>

<style lang="scss" scoped>
.masked-arrow {
  mask: v-bind(arrowDownUrl) no-repeat center;
}
</style>