<template>
  <div>
    <GoogleLogin :class="{ 'w-full': fullWidth }" :callback="signIn.execute" :clientId="clientId">
      <ThemedButton
        class="sm:h-12 h-11"
        :fullWidth="fullWidth"
        :isLoading="signIn.isPending"
        theme="white"
      >
        <img
          v-if="!signIn.isPending"
          aria-hidden="true"
          alt="google"
          class="object-contain w-6 h-6 mr-2 shrink-0"
          src="@/assets/social/google.svg"
        />
        Continue with Google
      </ThemedButton>
    </GoogleLogin>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { CallbackTypes, GoogleLogin } from 'vue3-google-login';
import { useStore } from 'vuex';

import { getConfigEntry } from '@/api/config';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { useAuth } from '@/composables/useAuth';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';

export default defineComponent({
  name: 'GoogleSignInButton',
  components: {
    GoogleLogin,
    ThemedButton,
  },
  props: {
    fullWidth: { required: false, type: Boolean, default: false },
    isCheckout: { required: false, type: Boolean, default: false },
  },
  emits: ['signed-in'],
  setup(props, { emit }) {
    const store = useStore();
    const { googleCodeSignIn } = useAuth(store);
    const { loadCart } = useCart(store);

    const signIn = useCallback(async (response: CallbackTypes.CodePopupResponse) => {
      const signInResponse = await googleCodeSignIn(response, { preserveCart: props.isCheckout });
      if (signInResponse) {
        emit('signed-in');
        await Promise.all([loadCart(true), store.dispatch('headerModule/getDynamicHeaderContent')]);
      }
    });

    return {
      clientId: getConfigEntry('google').oauth.clientId,
      signIn,
    };
  },
});
</script>

<style scoped>
.api-loading {
  opacity: 100% !important;
  pointer-events: auto !important;
}
</style>
