<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  adaptGtmDataForFacebookAddToCart,
  GtmAddToCartPayload,
  sendConversionEvent,
} from '@/api/trackingEvents/facebook';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { buttonStyleProps } from '@/composables/useButtonStyles';
import { useCart } from '@/composables/useCart';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import analytics, { gtag, ListMetadata } from '@/utils/analytics';

const emit = defineEmits(['added']);
const props = defineProps({
  autoDeliveryIntervalWeeks: { required: false, type: Number },
  disabled: { required: false, type: Boolean, default: false },
  distributionChannelKey: { required: false, type: String },
  dynamicBundleSkus: { required: false, type: Array as PropType<string[]> },
  eventObj: { required: false, type: Object as PropType<GtmAddToCartPayload> },
  fullWidth: { required: false, type: Boolean, default: false },
  googleEventObj: { required: false, type: Object },
  inputType: { required: false, type: String, default: 'button' },
  isParentPending: { required: false, type: Boolean, default: false },
  listMetadata: { type: Object as PropType<ListMetadata> },
  location: { required: true, type: String },
  quantity: { required: true, type: Number },
  size: buttonStyleProps.size,
  skipCartAdded: { required: false, type: Boolean },
  sku: { required: true, type: String },
  theme: buttonStyleProps.theme,
});

const store = useStore();
const router = useRouter();

const isMobile = useBreakpoints(breakpointsTailwind).smaller('md');
const { addToCart } = useCart(store);
const { flags } = useFeatureFlags(store);

const isPending = ref(false);

const buttonDisabled = computed(() => isPending.value || props.isParentPending || props.disabled);
const buttonLoading = computed(() => isPending.value || props.isParentPending);

const addSkuToCart = async () => {
  analytics.sendEvent('addToCart', props.location, props.eventObj);
  gtag('event', 'add_to_cart', props.googleEventObj ?? {});
  const facebookData = adaptGtmDataForFacebookAddToCart(props.eventObj);
  if (facebookData) {
    sendConversionEvent('AddToCart', facebookData);
  }
  if (props.inputType !== 'submit') {
    isPending.value = true;

    const isBYOB = props.sku === '3719-01';

    const postAddToCartCallback =
      (!isMobile.value && flags.cartAddedModal && !isBYOB) || props.skipCartAdded
        ? () => null
        : undefined;

    await addToCart(
      {
        cart_sku: {
          auto_delivery_interval_weeks: props.autoDeliveryIntervalWeeks,
          auto_delivery_offer_type: props.autoDeliveryIntervalWeeks ? 'Standard' : null,
          auto_delivery_offer_location: props.autoDeliveryIntervalWeeks ? props.location : null,
          distribution_channel_key: props.distributionChannelKey,
          dynamic_bundle_skus: props.dynamicBundleSkus,
          list_metadata: JSON.stringify(props.listMetadata),
          quantity: props.quantity,
          sku_external_id: props.sku,
        },
      },
      {
        postAddToCartCallback,
        router,
        skipCartAdded: isMobile.value || !flags.cartAddedModal || isBYOB || props.skipCartAdded,
      },
    );
    isPending.value = false;
    emit('added');
  }
};
</script>

<template>
  <div class="add-to-cart-button-box">
    <slot name="price-and-quantity-section"></slot>
    <div class="w-full button-container">
      <ThemedButton
        class="add-to-cart-button"
        data-test="add-to-cart-button"
        :disabled="buttonDisabled"
        :type="inputType"
        :fullWidth="fullWidth"
        :isLoading="buttonLoading"
        :size="size"
        :theme="theme"
        @click="addSkuToCart"
      >
        <slot>Add to Cart</slot>
      </ThemedButton>
    </div>
  </div>
</template>
