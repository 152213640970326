<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { computed } from 'vue';

import Select from '@/components/base/form/OutlinedSelect.vue';
import Caption from '@/components/base/typography/Caption.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';

interface AvailableSize {
  label: string;
  value: string;
}

const props = defineProps<{
  hasSiblings: boolean;
  selectedSku: string;
  shortUnitName: string;
  sizesAvailable?: AvailableSize[];
}>();

const emit = defineEmits<{
  (e: 'update:selectedSku', value: string): void;
}>();

const selection = useVModel(props, 'selectedSku', emit);
</script>

<template>
  <Select
    v-if="hasSiblings && sizesAvailable"
    v-model="selection"
    :options="sizesAvailable"
    size="small"
    class="mt-1"
    aria-label="sizes-available"
    data-test="product-sizes-available"
  />
  <SmallBodyText v-else class="py-2 text-true-gray-500">{{ shortUnitName }}</SmallBodyText>
</template>
