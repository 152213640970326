<template>
  <div
    class="secondary-nav hidden-xs primary-surrogate-component"
    data-test="secondary-nav"
    @click="delegateLinksToRouter"
    role="complementary"
    aria-label="Secondary navigation"
  >
    <DySecondaryNav>
      <ul id="secondary-nav-ul">
        <li>
          <a
            href="/organic/"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Organic"
            >Organic</a
          >
        </li>
        <li>
          <a
            href="/tag/raw"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Raw"
            >Raw</a
          >
        </li>
        <li>
          <a
            href="/gluten-free"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Gluten-free"
            >Gluten-free</a
          >
        </li>
        <li>
          <a
            href="/tag/dark+chocolate"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Dark Chocolate"
            >Dark Chocolate</a
          >
        </li>
        <li>
          <a
            href="/cms/pantry-staples"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Pantry"
            >Pantry</a
          >
        </li>
        <li>
          <a
            href="/tag/superfoods"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Superfoods"
            >Superfoods</a
          >
        </li>
        <li>
          <a
            href="/nuts/single-serve/"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Single Serves"
            >Single Serves</a
          >
        </li>
        <li>
          <a
            href="/"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Wholesale"
            >Wholesale</a
          >
        </li>
        <li>
          <a
            href="/"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Office Snacks"
            >Office Snacks</a
          >
        </li>
        <li>
          <a
            href="/tag/new+products"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: New Products"
            >New Products</a
          >
        </li>
        <li>
          <a
            href="/top_sellers"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Secondary Nav: Top Sellers"
            >Top Sellers</a
          >
        </li>
      </ul>
    </DySecondaryNav>
    <span id="skiptocontent" class="sr-only">Main Content</span>
  </div>
</template>

<script>
import DySecondaryNav from '@/components/dynamic-yield/DySecondaryNav.vue';
import routerLinkDelegationMixin from '@/mixins/routerLinkDelegation';

export default {
  name: 'SecondaryNav',
  components: {
    DySecondaryNav,
  },
  mixins: [routerLinkDelegationMixin],
};
</script>

<style lang="scss" scoped>
.secondary-nav {
  background-color: $white-smoke;
  border-bottom: 1px solid $secondary-nav-bottom-border-color;
  padding-bottom: 10px;
  @include respond-max($screen-md-max) {
    text-align: center;
  }

  &:deep(li) {
    display: inline-block;
    margin: 10px 10px 0 0;
    padding-left: 10px;
    border-left: 1px solid $secondary-nav-link-separator-color;
    &:first-child {
      padding-left: 0;
      border-left: 0;
    }

    a {
      color: $blue-stone;
      font-size: $font-size-small;
      line-height: $line-height-small;
    }
  }
}
</style>
