/* eslint-disable no-shadow */
import { MutationTree } from 'vuex';

const namespaced = true;

export interface Permissions {
  administrate?: true;
  auditFraud?: true;
  checkOutAsCustomer?: true;
  configureSite?: true;
  createOrderManualAdjustment?: true;
  editCatalog?: true;
  editPermissions?: true;
  employeeOrFormer?: true;
  grantCreditAccount?: true;
  login?: true;
  offsiteLogin?: true;
  print?: true;
  recordCreditAccountCredit?: true;
  report?: true;
  uploadNutritionFacts?: true;
}

export const state = () => ({
  email: null as string | null,
  permissions: {} as Permissions,
});

export type SessionState = ReturnType<typeof state>;

export const mutations: MutationTree<SessionState> = {
  SET_EMAIL(state, email: string | null) {
    state.email = email;
  },
  SET_PERMISSIONS(state, permissions: Permissions) {
    state.permissions = permissions;
  },
};

export default {
  mutations,
  namespaced,
  state,
};
