<template>
  <div :class="{ 'opacity-50 pointer-events-none': isLoading }">
    <div v-if="isCheckout && !containsAutoDelivery">
      <ContinueAsGuestButton
        v-if="!flags.guestCheckoutLastOption"
        class="mt-6"
        :email="emailValue"
      />
    </div>
    <div v-else class="inline-flex items-center mt-6">
      <p class="text-base font-semibold">Don't have an account?</p>
      <button class="p-0 ml-1 bg-transparent border-none cursor-pointer" @click="changeEmail">
        <span class="text-sm font-semibold underline text-cyan-700 font-proxima-nova sm:text-base">
          Sign up
        </span>
      </button>
    </div>
    <p v-if="isCheckout && containsAutoDelivery" class="mt-2 text-base">
      Auto-Delivery items are not eligible for guest checkout. Create an account and apply your
      preferred payment method to proceed.
    </p>
    <SeparatorWithText v-if="!flags.guestCheckoutLastOption" class="mt-6">
      Sign in or sign up with email
    </SeparatorWithText>
    <div class="relative mt-6">
      <Form v-slot="{ meta: formEmailMeta }" @submit="onSubmit">
        <FormInput
          v-model="emailValue"
          :inputAttributes="formInputAttributes.email"
          showLabel
          :validator="rules.email"
          dataTest="sign-in"
        />
        <EmailTypoSuggestion v-model="emailValue" />
        <ThemedButton
          type="submit"
          class="w-full px-8 py-4 mt-8 cursor-pointer h-11 sm:h-12"
          :isLoading="isLoading"
          theme="gray"
          :disabled="(formEmailMeta.touched && validatorFailed(formEmailMeta)) ?? false"
          data-test="continue-button"
        >
          Continue
        </ThemedButton>
      </Form>
    </div>
    <SeparatorWithText class="mt-6">Sign in or sign up with social account</SeparatorWithText>
    <GoogleSignInButton
      class="mt-6"
      fullWidth
      :isCheckout="isCheckout"
      @signed-in="onSocialSignIn"
    />
    <FacebookSignInButton
      class="mt-4"
      fullWidth
      :isCheckout="isCheckout"
      @signed-in="onSocialSignIn"
    />
    <AppleSignInButton
      class="mt-4"
      fullWidth
      :isCheckout="isCheckout"
      @signed-in="onSocialSignIn"
    />
    <div v-if="flags.guestCheckoutLastOption && isCheckout && !containsAutoDelivery">
      <SeparatorWithText class="mt-6">Or</SeparatorWithText>
      <ContinueAsGuestButton class="mt-6" :email="emailValue" />
    </div>
    <p class="mt-6 text-xs leading-5 sm:text-sm">
      By clicking on Continue with Google, Facebook or Apple, you represent that you are 18+ years
      of age and have read and agreed to the ixclkxk.shop
      <RouteLink class="underline" to="/terms-and-conditions">Terms &amp; Conditions</RouteLink>,
      <RouteLink class="underline" to="/privacy">Privacy Policy</RouteLink>
      and
      <RouteLink class="underline" to="/california-privacy-notice">CA Privacy Notice</RouteLink>.
      ixclkxk.shop may send you communications. You may change your preferences in your account
      preferences at any time.
    </p>
    <Captcha ref="captcha" @verify="onCaptchaVerification" />
  </div>
</template>

<script lang="ts">
import { email, required } from '@vee-validate/rules';
import { useVModel } from '@vueuse/core';
import { Form } from 'vee-validate';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import EmailTypoSuggestion from '@/components/base/form/EmailTypoSuggestion.vue';
import FormInput, { InputAttributes } from '@/components/base/form/FormInput.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import SeparatorWithText from '@/components/base/SeparatorWithText.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import ContinueAsGuestButton from '@/components/login/ContinueAsGuestButton.vue';
import AppleSignInButton from '@/components/social/AppleSignInButton.vue';
import Captcha, { CaptchaPublicInstance } from '@/components/social/Captcha.vue';
import FacebookSignInButton from '@/components/social/FacebookSignInButton.vue';
import GoogleSignInButton from '@/components/social/GoogleSignInButton.vue';
import { useCart } from '@/composables/useCart';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';

interface EmailStepData {
  email: string;
}

export default defineComponent({
  name: 'EmailStep',
  props: {
    isCheckout: { required: false, type: Boolean, default: false },
    isPending: { required: false, type: Boolean, default: false },
    modelValue: { required: true, type: String },
  },
  components: {
    AppleSignInButton,
    Captcha,
    ContinueAsGuestButton,
    EmailTypoSuggestion,
    FacebookSignInButton,
    Form,
    FormInput,
    GoogleSignInButton,
    RouteLink,
    SeparatorWithText,
    ThemedButton,
  },
  emits: {
    'change-email': (_: string) => true,
    'social-sign-in': null,
    'update:modelValue': () => true,
    submit: (_: string) => true,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { containsAutoDelivery } = useCart(store);
    const { flags } = useFeatureFlags(store);

    const emailValue = useVModel(props, 'modelValue', emit);
    const { errorMessages, validatorFailed } = useForm();

    const emailInput = ref(null);
    const captcha = ref<CaptchaPublicInstance>();
    const isCaptchaPending = ref(false);

    const isLoading = computed(() => isCaptchaPending.value || props.isPending);

    const formInputAttributes: Record<keyof EmailStepData, InputAttributes> = {
      email: {
        autocomplete: 'username',
        name: 'email',
        placeholder: 'Email',
        type: 'email',
      },
    };

    const rules: Record<keyof EmailStepData, FlexibleMessageValidator<string>> = {
      email: (value) => {
        if (!required(value)) return errorMessages.email.required;
        if (!email(value)) return errorMessages.email.email;
        return true;
      },
    };

    const onSubmit = () => {
      isCaptchaPending.value = true;
      captcha.value?.execute();
    };

    const onCaptchaVerification = (response: string) => {
      emit('submit', response);
      isCaptchaPending.value = false;
    };

    return {
      captcha,
      changeEmail: () => emit('change-email', ''),
      containsAutoDelivery,
      emailInput,
      emailValue,
      flags,
      formInputAttributes,
      isLoading,
      onCaptchaVerification,
      onSocialSignIn: () => emit('social-sign-in'),
      onSubmit,
      rules,
      validatorFailed,
    };
  },
});
</script>

<style lang="scss" scoped>
p {
  @apply mb-0;
}
</style>
