<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { RouteLocationNormalized, useRoute } from 'vue-router';
import { Store, useStore } from 'vuex';

import CartAddedModal from '@/components/cart/CartAddedModal.vue';
import CmsContent from '@/components/cms/CmsContent.vue';
import DyClientSideRenderer from '@/components/dynamic-yield/DyClientSideRenderer.vue';
import AdminCartLookupForm from '@/components/layout/header/AdminCartLookupForm.vue';
import MainNav from '@/components/layout/header/MainNav.vue';
import Masthead from '@/components/layout/header/Masthead.vue';
import MiniCart from '@/components/layout/header/MiniCart.vue';
import MobileMenu from '@/components/layout/header/MobileMenu.vue';
import SecondaryNav from '@/components/layout/header/SecondaryNav.vue';
import TopShelf from '@/components/layout/header/TopShelf.vue';
import LoginModal from '@/components/login/LoginModal.vue';
import { useCart } from '@/composables/useCart';
import { useCms } from '@/composables/useCms';
import { useDynamicYield } from '@/composables/useDynamicYield';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useLoginModal } from '@/composables/useLoginModal';
import { DataModel } from '@/utils/cms';
import { MegaMenu, MobileNav } from '@/utils/navMenu';
import { waitABit } from '@/utils/waitABit';

defineProps<{
  fallBackLogoPath?: string;
  freeShippingThreshold?: string;
  isAdmin?: boolean;
  logoPath?: string;
}>();

const route = useRoute();
const store = useStore();

const { addedLineItem, isCartAddedOpen, loadCart, loadLineItemExpansions } = useCart(store);

const { fetchDyVariations } = useDynamicYield(store);
const { content: sitewideBanner } = useCms(store, 'sitewide-banner', 'setup');

const { flags } = useFeatureFlags(store);

const { handleClose, state: signIn } = useLoginModal();

const { content: megaMenu } = useCms<DataModel<MegaMenu>>(store, 'mega-menu', 'setup');
const { content: mobileMenu } = useCms<DataModel<MobileNav>>(store, 'mobile-menu', 'setup');

const mountedClass = ref('');
const showMiniCart = ref(false);
const showMobileMenu = ref(false);
const showMobileSearch = ref(false);

onMounted(() => {
  waitABit().then(() =>
    Promise.all([
      store.dispatch('headerModule/getDynamicHeaderContent'),
      loadCart().then(() => loadLineItemExpansions()),
    ]),
  );

  fetchDyVariations(['[NAV] Secondary Nav'], false);

  mountedClass.value = 'mounted';
});

watch(
  () => route?.path,
  () => {
    showMobileMenu.value = false;
    showMobileSearch.value = false;
  },
);

watch(isCartAddedOpen, () => {
  if (isCartAddedOpen.value) {
    window.Kustomer?.stop();
  } else {
    window.Kustomer?.start();
  }
});

onBeforeUnmount(() => {
  handleClose();
  isCartAddedOpen.value = false;
});

const toggleMiniCart = () => {
  showMiniCart.value = !showMiniCart.value;
};
const toggleMobileMenu = () => {
  showMobileMenu.value = !showMobileMenu.value;
};
const toggleMobileSearch = () => {
  showMobileSearch.value = !showMobileSearch.value;
};

defineOptions({
  async criticalData(forwardedStore: Store<any>, to: RouteLocationNormalized) {
    const promises: Promise<any>[] = [
      useCms(forwardedStore, 'sitewide-banner', 'criticalData').loadCmsContent(to.path, to.query),
    ];
    if (typeof window === 'undefined') {
      promises.push(
        useFeatureFlags(forwardedStore).loadDyFlags('[A/B Test] Product Badges', ['badgeRedesign']),
        useCms(forwardedStore, 'mega-menu', 'criticalData').loadCmsContent(to.path, to.query),
        useCms(forwardedStore, 'mobile-menu', 'criticalData').loadCmsContent(to.path, to.query),
        useDynamicYield(forwardedStore).fetchDyVariations(['[NAV] Secondary Nav'], false),
      );
    }

    await Promise.all(promises);
  },
});
</script>

<template>
  <div id="appHeader" class="relative z-20 header" :class="mountedClass">
    <AdminCartLookupForm v-if="isAdmin" />
    <TopShelf
      :showMiniCart="showMiniCart"
      @toggle-mobile-menu="toggleMobileMenu"
      @toggle-mini-cart="toggleMiniCart"
      @toggle-mobile-search="toggleMobileSearch"
    />
    <MiniCart :showMiniCart="showMiniCart" @toggle-mini-cart="toggleMiniCart" />
    <div class="masthead" data-test="masthead">
      <Masthead
        :fallBackLogoPath="fallBackLogoPath"
        :freeShippingThreshold="freeShippingThreshold"
        :logoPath="logoPath"
        :showMobileSearch="showMobileSearch"
      />
      <MainNav :megaMenuContent="megaMenu?.result?.data" />
    </div>
    <MobileMenu
      v-show="showMobileMenu"
      :mobileMenuContent="mobileMenu?.result?.data"
      @toggle-mobile-menu="toggleMobileMenu"
    />
    <DyClientSideRenderer tag-name="div" id="dy-promo-banner" data-test="dy-header-promo-banner" />
    <SecondaryNav />
    <CmsContent :content="sitewideBanner" model="sitewide-banner" />
    <Teleport to="body" v-if="signIn.isOpen">
      <LoginModal
        :callback="signIn.callback"
        :destination="signIn.destination"
        :isCheckout="signIn.isCheckout"
        :initialStep="signIn.initialStep"
        :selectBusiness="signIn.selectBusiness"
        :isOpen="signIn.isOpen"
        @handle-close="handleClose"
      />
    </Teleport>
    <Teleport v-if="flags.cartAddedModal && isCartAddedOpen && addedLineItem" to="body">
      <CartAddedModal
        :isOpen="isCartAddedOpen"
        :lineItem="addedLineItem"
        @handle-close="isCartAddedOpen = false"
      />
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.masthead {
  background: $header-background-color;
  font-size: $font-size-small;
  margin-top: $topshelf-height;

  &:deep(.ptn-search-box) {
    padding-right: 47px;
    z-index: 2;
    position: absolute;
    top: 35px;
    left: 390px;
    @include respond-min($screen-lg-min) {
      padding-right: 55px;
    }
    @include respond-max($screen-xs-max) {
      display: none;
      background: $wild-sand;
      box-shadow: 0px 0px 10px $boulder;
      padding: 62px 20px 20px 20px;
      position: fixed;
      left: 0;
      width: 100%;
      z-index: 10;
      top: 36px;

      a {
        font-size: 14px;
      }
    }
    @include respond-min-max($screen-sm-min, $screen-sm-max) {
      top: 39px;
      left: 280px;
    }
    @include respond-min-max($screen-md-min, $screen-md-max) {
      top: 20px;
      left: 315px;
      width: 366px;

      .search-field {
        width: 324px;
      }
    }
  }
}
</style>
