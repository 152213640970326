<template>
  <div>
    <div class="container relative">
      <header id="trk-header" class="clearfix">
        <transition
          name="bounce"
          enter-active-class="bounceInDown"
          leave-active-class="bounceOutUp"
        >
          <SearchBox v-if="showMobileSearch" class="animated visible-xs" />
        </transition>
        <SearchBox class="hidden-xs visible-sm visible-md visible-lg" />
        <div class="logo">
          <RouteLink
            to="/"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Site logo"
          >
            <img :src="logoPath" :onerror="fallBackLogoPath" alt="ixclkxk.shop" itemprop="logo" />
          </RouteLink>
        </div>
        <ZoneThree @zone3-details-toggle="toggleShippingDetails">
          <div class="free-shipping-dots hidden-xs hidden-sm" />
          <div class="free-shipping-blurb">
            <span>FREE shipping on orders over {{ freeShippingThreshold }}!</span>
            <small>
              <a
                role="button"
                :aria-expanded="showDetails"
                href="#"
                aria-label="expand shipping details"
              >
                <i :class="showDetails ? 'opened' : 'closed'" />
              </a>
            </small>
          </div>
        </ZoneThree>
      </header>
    </div>
    <div class="free-shipping-easter-egg" v-show="showDetails">
      <div class="container">
        <div class="details">
          <DetailShippingContent :freeShippingThreshold="freeShippingThreshold" v-once />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteLink from '@/components/base/RouteLink.vue';
import SearchBox from '@/components/layout/header/SearchBox.vue';
import ZoneThree from '@/components/layout/header/ZoneThree.vue';
import DetailShippingContent from '@/components/shipping/DetailShippingContent.vue';

export default {
  name: 'Masthead',
  props: {
    fallBackLogoPath: { required: false, type: String },
    freeShippingThreshold: { required: false, type: String },
    logoPath: { required: false, type: String },
    showMobileSearch: { required: false, type: Boolean, default: false },
  },
  components: {
    DetailShippingContent,
    RouteLink,
    SearchBox,
    ZoneThree,
  },
  data() {
    return {
      freeShippingDots: `url(${nutshell['img/rasterized/free-shipping-dots@1x.png']})`,
      showDetails: false,
    };
  },
  methods: {
    toggleShippingDetails() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include respond-max($screen-xs-max) {
    padding-left: 0;
    padding-right: 0;
  }
}
.details {
  color: $black;
  font-size: 12px;
  padding: 10px;
}
.free-shipping-blurb {
  width: 160px;
  padding: 0px 8px;
  float: right;
  @include respond-min($screen-lg-min) {
    padding: 10px;
  }
  @include respond-min-max($screen-sm-min, $screen-sm-max) {
    float: none;
    width: inherit;
    display: inline-flex;
  }
  @include respond-min-max($screen-md-min, $screen-md-max) {
    margin-top: 8px;
  }
  @include respond-max($screen-xs-max) {
    width: inherit;
    text-align: center;
    float: none;
  }
  span {
    color: $atoll-alt;
    font-size: initial;
    font-weight: bold;
    line-height: 20px;
    display: block;
    text-align: center;
    @include respond-min($screen-lg-min) {
      font-size: 15px;
    }
    @include respond-min-max($screen-md-min, $screen-md-max) {
      font-size: 16px;
    }
    @include respond-min-max($screen-sm-min, $screen-sm-max) {
      font-size: 13px;
      display: inline;
      margin-right: 5px;
    }
    @include respond-max($screen-xs-max) {
      font-size: 13px;
      display: inline;
    }
  }
  small {
    display: block;
    font-size: 11px;
    @include respond-min($screen-lg-min) {
      margin-top: 5px;
    }
    @include respond-max($screen-xs-max) {
      display: inline-flex;
      vertical-align: middle;
      padding-left: 5px;
    }
    @include respond-min-max($screen-sm-min, $screen-md-max) {
      margin-top: 4px;
    }
    @include respond-min-max($screen-sm-min, $screen-sm-max) {
      display: inline-flex;
      margin: 0;
    }
    @include respond-min-max($screen-md-min, $screen-md-max) {
      padding-top: 4px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    i {
      background-size: 14px;
      height: 14px;
      width: 14px;
      display: inline-block;
      &.closed {
        background-image: url(/images/email/2017/Expand-Icon-Open_NC@2x.53d837ae.png);
      }
      &.opened {
        background-image: url(/images/email/2017/Expand-Icon-Close_NC@2x.6c4c923f.png);
      }
    }
  }
}
.free-shipping-dots {
  background-image: v-bind(freeShippingDots);
  background-position: center;
  width: 100%;
  height: 18px;
}
.free-shipping-easter-egg {
  background-color: $scooter;
  &:deep(a) {
    &:active,
    &:hover {
      text-decoration: none;
      color: $sunglow;
    }
  }
}
.logo {
  @include respond-max($screen-xs-max) {
    width: 100%;
    text-align: center;
    margin: 30px 0 5px;
    padding-top: 12px;
  }
  @include respond-min-max($screen-sm-min, $screen-sm-max) {
    margin: 36px 0 5px;
    padding-bottom: 10px;
  }
  img {
    width: 340px;
    min-height: 48px;
    @include respond-min($screen-lg-min) {
      min-height: 108px;
    }
    @include respond-max($screen-xs-max) {
      width: 150px;
    }
    @include respond-min-max($screen-sm-min, $screen-sm-max) {
      width: 150px;
    }
    @include respond-min-max($screen-md-min, $screen-md-max) {
      width: 280px;
    }
  }
}
header {
  display: table;
  @include respond-min($screen-sm-min) {
    padding: 12px 0;
  }
  @include respond-max($screen-xs-max) {
    width: 100%;
  }
}
</style>
