<template>
  <ProductRecommendations
    :analyticsList="analyticsList"
    easyReorder
    :enableAddToCart="enableAddToCart"
    :link="link"
    :list="reorderRecommendations"
    spacingLevel="none"
    :title="title"
    :trackingEventLocation="trackingEventLocation"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

import { fromAxiosNutsJson, webstore } from '@/api';
import ProductRecommendations from '@/components/recommendations/ProductRecommendations.vue';
import {
  Recommendation,
  WebstoreRecommendation,
  webstoreToDyRecommendations,
} from '@/composables/useDynamicYield';
import { CmsRegisteredComponent } from '@/utils/cms';

const ReorderRecommendations = defineComponent({
  name: 'ReorderRecommendations',
  components: {
    ProductRecommendations,
  },
  props: {
    analyticsList: { required: false, type: String, default: '' },
    enableAddToCart: { required: false, type: Boolean, default: true },
    limit: { required: false, type: Number, default: 20 },
    link: { required: false, type: String },
    title: { required: false, type: String, default: 'Buy It Again' },
    trackingEventLocation: { required: false, type: String, default: '' },
  },
  setup(props) {
    const reorderRecommendations = ref<Recommendation[]>();

    onMounted(async () => {
      const { recommendations } = await fromAxiosNutsJson<{
        recommendations: WebstoreRecommendation[];
      }>(
        webstore.sessionSpecific.get(`/api/recommendations/recent-purchases?limit=${props.limit}`),
        {},
      );
      reorderRecommendations.value = webstoreToDyRecommendations(recommendations);
    });

    return {
      reorderRecommendations,
    };
  },
});

export const ReorderRecommendationsRegistration: CmsRegisteredComponent = {
  component: ReorderRecommendations,
  name: 'ReorderRecommendations',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Buy It Again',
    },
    {
      name: 'limit',
      friendlyName: 'Number of Product Recommendations',
      type: 'number',
      defaultValue: 20,
    },
    {
      name: 'link',
      friendlyName: 'View All link',
      type: 'string',
    },
    {
      name: 'enableAddToCart',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'analyticsList',
      friendlyName: 'Analytics: List Name',
      type: 'string',
      helperText: 'Helps filter clicks/impressions in reporting; e.g. "Easy Reorder"',
      advanced: true,
    },
    {
      name: 'trackingEventLocation',
      friendlyName: 'Analytics: Page Type',
      type: 'string',
      helperText: 'Helps filter clicks/impressions in reporting; e.g. "HP"',
      advanced: true,
    },
  ],
};

export default ReorderRecommendations;
</script>
