/* eslint-disable no-shadow */
const namespaced = true;

export const state = () => ({
  freeShippingThreshold: '$59',
});

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations = {
  SET_FREE_SHIPPING_THRESHOLD(state, value) {
    state.freeShippingThreshold = value ?? state.freeShippingThreshold;
  },
};

export default {
  namespaced,
  state,
  mutations,
};
