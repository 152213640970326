<template>
  <div aria-live="polite">
    <component :is="headerTag" class="text-xl font-bold capitalize font-sofia-pro sm:text-2xl">
      {{ text }} Sent
    </component>
    <div class="mt-6 text-base sm:text-lg">
      <img src="@/assets/emails/mail-letter.svg" alt="email letter icon" aria-hidden="true" />
      <p class="mt-4">
        A {{ text }} has been sent to your email <strong>{{ email }}</strong
        >.
      </p>
      <p class="mt-6">
        If you don't receive the email, check your email address and request a new link by
        <button
          class="shrink-0 p-0 bg-transparent border-0 cursor-pointer"
          type="button"
          @click="handleResend"
        >
          <span class="text-base text-black underline font-proxima-nova sm:text-lg">
            &thinsp;clicking here.
          </span>
        </button>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { TypographyTags } from '@/utils/accessibility';

type EmailType = 'password-reset' | 'sign-in-link';

const typeToText: Record<EmailType, string> = {
  'password-reset': 'password reset link',
  'sign-in-link': 'sign in link',
};

export default defineComponent({
  name: 'EmailSentConfirmation',
  props: {
    email: { required: true, type: String },
    headerTag: { required: false, type: String as PropType<TypographyTags>, default: 'span' },
    type: { required: true, type: String as PropType<EmailType> },
  },
  emits: ['resend'],
  setup(props, { emit }) {
    const text = computed(() => typeToText[props.type]);

    return {
      handleResend: () => emit('resend'),
      text,
    };
  },
});
</script>

<style scoped>
p {
  @apply mb-0;
}
</style>
