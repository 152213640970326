<script setup lang="ts">
import { dollars, from as fromConversion } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { computed, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import AddToCartOrCustomize from '@/components/base/add-to-cart/AddToCartOrCustomize.vue';
import RatingStars from '@/components/base/assets/RatingStars.vue';
import Checkbox from '@/components/base/form/Checkbox.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import Slider from '@/components/base/Slider.vue';
import TagBadge from '@/components/base/TagBadge.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import WindowObserver from '@/components/base/WindowObserver.vue';
import PriceStrikethrough from '@/components/product-card/PriceStrikethrough.vue';
import { buttonStyleProps } from '@/composables/useButtonStyles';
import { useCart } from '@/composables/useCart';
import { Recommendation } from '@/composables/useDynamicYield';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { spacingProps, useSpacing } from '@/composables/useSpacing';
import money from '@/filters/money';
import analytics, { gtag, setListMetadata } from '@/utils/analytics';
import { displayPrice, ProductCard, ProductCardData } from '@/utils/productCard';

dayjs.extend(relativeTime);

const props = defineProps({
  analyticsList: { required: false, type: String, default: '' },
  buttonTheme: buttonStyleProps.theme,
  clickTrackable: { required: false, type: Boolean, default: true },
  controlsColor: { required: false, type: String },
  easyReorder: { required: false, type: Boolean, default: false },
  enableAddToCart: { required: false, type: Boolean, default: false },
  flushRightOnMobile: { required: false, type: Boolean },
  impressionTrackable: { required: false, type: Boolean, default: true },
  layout: { required: false, type: String, default: 'row' },
  link: { required: false, type: String },
  list: { required: false, type: Array as PropType<Recommendation[]> },
  ...spacingProps,
  subtitle: { required: false, type: String },
  title: { required: false, type: String },
  trackingEventLocation: { required: false, type: String, default: '' },
});
const router = useRouter();
const store = useStore();
const { flags } = useFeatureFlags(store);

const isMobile = useBreakpoints(breakpointsTailwind).smaller('md');
const { addToCart } = useCart(store);
const { spacing } = useSpacing(props);

const recommendations = computed(() =>
  (props.list ?? []).map((recommendation) => {
    const productCard = ProductCard.fromDY(recommendation);
    return {
      displayPrice: displayPrice(productCard),
      productCard,
      recommendation,
    };
  }),
);

const bulkCheckout = ref<Recommendation[]>([]);

const addAllToCart = async () => {
  await Promise.all(
    bulkCheckout.value.map((product) =>
      addToCart(
        {
          cart_sku: {
            sku_external_id: product.sku,
            quantity: 1,
          },
        },
        {
          postAddToCartCallback: () => null,
          skipCartAdded: isMobile.value || !flags.cartAddedModal,
        },
      ),
    ),
  );
  if (isMobile.value || !flags.cartAddedModal) {
    router.push('/cart');
  }
};

const addToBulkCheckout = (product: Recommendation) => {
  bulkCheckout.value.push(product);
};

const removeFromBulkOrder = (product: Recommendation) => {
  bulkCheckout.value = bulkCheckout.value.filter((item) => item.sku !== product.sku);
};

const handleCheckbox = (checked: Boolean, product: Recommendation) => {
  if (checked) {
    addToBulkCheckout(product);
  } else {
    removeFromBulkOrder(product);
  }
};

const microData = (product: Recommendation, position: number) => ({
  id: `0${product.sku}`,
  list: props.analyticsList || props.title,
  name: product.name,
  position,
  price: product.price,
});
const reportDyEngagement = (context: object) =>
  store.dispatch('dynamicYieldModule/reportDyEngagement', context);
const reportClick = (product: Recommendation, index: number) => {
  if (!props.clickTrackable) return;
  reportDyEngagement({
    decisionId: product.decisionId,
    slotId: product.slotId,
  });

  const productCardData = ProductCard.fromDY(product);

  analytics.sendEvent('productClick', props.analyticsList || props.title || '', {
    ecommerce: {
      click: {
        actionField: { list: props.analyticsList || props.title },
        products: [microData(product, index)],
      },
    },
  });
  setListMetadata({
    list: props.analyticsList || props.title || '',
    position: index,
  });
  gtag('event', 'select_item', {
    items: [
      {
        item_id: product.product_key,
        item_list_name: props.analyticsList || props.title,
        item_name: product.name,
        item_variant: product.sku,
        item_variant_name: product.unit_name,
        price: product.price,
        price_before_discount: dollars(
          productCardData.totalSavings?.comparisonPrice ?? productCardData.piecePrice,
        ),
        cost: product.cost,
        coupon: productCardData.totalSavings?.description?.en,
        discount: dollars(productCardData.totalSavings?.value || fromConversion(0)),
        quantity: 1,
        autodelivery_interval: undefined,
        merchandising_category: product.primary_merchandising_category,
        weight: undefined,
      },
    ],
  });
};
const impressionLogged = ref(new Map<string, boolean>());
const reportVisibility = (product: Recommendation, productCard: ProductCardData, index: number) => {
  if (!props.impressionTrackable || impressionLogged.value.has(product.sku)) return;
  impressionLogged.value.set(product.sku, true);
  analytics.sendEvent('productImpressions', props.title ?? '', {
    ecommerce: {
      currencyCode: 'USD',
      impressions: [microData(product, index)],
    },
  });
  gtag('event', 'view_item_list', {
    items: [
      {
        item_id: product.product_key,
        item_list_name: props.analyticsList || props.title,
        item_name: product.name,
        item_variant: product.sku,
        item_variant_name: product.unit_name,
        price: product.price,
        price_before_discount: dollars(
          productCard.totalSavings?.comparisonPrice ?? productCard.piecePrice,
        ),
        cost: product.cost,
        coupon: productCard.totalSavings?.description?.en,
        discount: dollars(productCard.totalSavings?.value || fromConversion(0)),
        quantity: 1,
        autodelivery_interval: undefined,
        merchandising_category: product.primary_merchandising_category,
        weight: undefined,
      },
    ],
  });
};

const purchasedAt = (date: string) => dayjs().to(dayjs(date));
const requiresCustomization = (product: Recommendation) =>
  product.requires_customization === 'true';
const totalPrice = computed(() =>
  bulkCheckout.value.map((x) => x.price).reduce((prev, curr) => Number(prev) + Number(curr), 0),
);
</script>

<template>
  <div
    v-if="recommendations.length"
    class="flex items-end"
    :class="spacing"
    data-test="recommendations"
  >
    <Slider
      :controlsColor="controlsColor"
      :flushRightOnMobile="flushRightOnMobile"
      :list="recommendations"
      :title="title"
      :layout="layout"
      :link="link"
      :responsiveGaps="{ mobile: 8, tablet: 12 }"
      :subtitle="subtitle"
      useResponsiveGaps
    >
      <div
        v-for="({ displayPrice, productCard, recommendation }, idx) in recommendations"
        :key="productCard.productKey"
        class="relative flex flex-col justify-between p-3 overflow-hidden bg-white slide rounded-xl"
        :class="{ 'slide-card': easyReorder }"
      >
        <Checkbox
          v-if="easyReorder"
          class="absolute p-2 right-1 top-1 easy-reorder"
          @update:modelValue="handleCheckbox($event, recommendation)"
        />
        <TagBadge
          v-if="!easyReorder"
          :tags="productCard.totalSavings ? ['badge-onsale'] : productCard.keywords ?? []"
          class="absolute"
          :class="flags.badgeRedesign ? 'right-0 top-0 rounded-tr-none' : 'right-3 top-3'"
        />
        <div>
          <WindowObserver @intersect="reportVisibility(recommendation, productCard, idx)">
            <RouteLink
              :to="productCard.path"
              class="no-underline dy-pdp-link hover:no-underline"
              @click="reportClick(recommendation, idx)"
            >
              <div class="flex justify-center mb-2.5 h-24 md:h-28" data-test="photo-container">
                <img
                  :src="productCard.imageUrl"
                  :alt="`photo of ${productCard.name}`"
                  class="object-contain"
                  loading="lazy"
                />
              </div>
              <BaseBodyText
                class="overflow-hidden font-semibold text-black font-sofia-pro h-9"
                data-test="product-recommendation-title"
              >
                {{ productCard.name }}
              </BaseBodyText>
              <RatingStars
                v-if="!easyReorder"
                :rating="productCard.averageRating || 0"
                :class="{ 'opacity-0': productCard.totalRatings === 0 }"
              />
              <div
                v-if="productCard.totalReviews && !easyReorder"
                class="inline-block text-xs align-super text-zambezi"
                :class="{ 'opacity-0': productCard.totalReviews === 0 }"
                data-test="rating-note"
              >
                <span class="sr-only">{{ productCard.totalReviews }} ratings</span>
                <span aria-hidden="true">({{ productCard.totalReviews }})</span>
              </div>
              <div v-if="easyReorder && recommendation.purchased_at" class="mb-2 text-sm">
                {{ `Bought ${purchasedAt(recommendation.purchased_at)}` }}
              </div>
              <div
                v-if="productCard.totalSavings && !productCard.hidePrice"
                class="flex flex-wrap items-center sm:flex-nowrap"
              >
                <PriceStrikethrough
                  :comparisonPrice="productCard.totalSavings.comparisonPrice"
                  :discountPercent="`${productCard.totalSavings.percent}%`"
                  discountType="product"
                  displayDiscount
                  :onePoundBulk="productCard.onePoundBulk"
                  :piecePrice="productCard.piecePrice"
                />
              </div>
              <p
                v-else
                class="text-sm sm:text-base font-semibold mb-0.5 text-black escape-legacy price"
              >
                <template v-if="productCard.hidePrice">Build your own</template>
                <template v-else>{{ displayPrice }}</template>
              </p>
              <SmallBodyText v-if="productCard.totalSavings?.description" class="mt-2 text-black">
                {{ productCard.totalSavings.description.en }}
              </SmallBodyText>
              <SmallBodyText
                v-else-if="productCard.unitName && !productCard.onePoundBulk"
                class="mt-2 text-black"
              >
                {{ productCard.unitName }}
              </SmallBodyText>
            </RouteLink>
          </WindowObserver>
        </div>
        <AddToCartOrCustomize
          v-if="enableAddToCart"
          class="mt-4 mb-2 add-to-cart-or-customize"
          :cost="productCard.cost"
          :coupon="productCard.totalSavings?.description?.en"
          data-test="add-to-cart-or-customize"
          :discount="productCard.totalSavings?.value"
          :listMetadata="{ list: analyticsList || title || '', position: idx }"
          :listName="analyticsList || title"
          :merchandisingCategory="productCard.merchandisingCategory"
          :name="productCard.name"
          :outOfStock="!productCard.inStock"
          :path="productCard.path"
          :productKey="productCard.productKey"
          :requiresCustomization="requiresCustomization(recommendation)"
          :singlePiecePrice="productCard.price"
          :theme="buttonTheme"
          :trackingEventLocation="trackingEventLocation"
          :variantName="recommendation.unit_name"
          :variantSku="productCard.sku"
        />
      </div>
    </Slider>
    <div
      v-if="easyReorder"
      class="flex flex-col justify-between w-full p-3 ml-2 bg-white slide-card easy-reorder"
    >
      <div class="flex w-full text-xl font-bold">Easy Reorder</div>
      <div class="flex justify-center w-full">
        <div class="w-20 h-20 rounded-full circle bg-blue" />
      </div>
      <div class="flex self-center justify-between pt-3 border-t border-black border-solid w-36">
        <div class="flex items-center text-sm font-semibold">Est. Price</div>
        <div class="text-lg font-semibold">
          {{ money(Math.abs(totalPrice)) }}
        </div>
      </div>
      <ThemedButton
        @click="addAllToCart"
        :disabled="bulkCheckout.length === 0"
        size="small"
        theme="gray"
        class="w-full mb-2"
      >
        Add All to Cart
      </ThemedButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.align-super {
  vertical-align: super;
}
.slide-card {
  scroll-snap-align: start;
  min-width: 154px;
  max-width: 173px;
  border: 1px solid #e8e8e8;
}
.text-zambezi {
  color: $zambezi;
}
.slide {
  scroll-snap-align: start;
  min-width: 154px;
  max-width: 173px;
  @include respond-min($screen-sm-min) {
    min-width: 169px;
    max-width: 198px;
  }
  @include respond-min($screen-md-min) {
    min-width: 173px;
    max-width: 198px;
  }
  @include respond-min($screen-lg-min) {
    min-width: 198px;
    max-width: 220px;
  }
  border: 1px solid $mercury-approx;

  a {
    p {
      font-size: 16px;
      line-height: 17px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include respond-max($screen-xs-max) {
        font-size: 14px;
      }
    }
  }
  .ptn-rating-stars {
    width: 64px;
    height: 17px;
    margin-bottom: 10px;
    margin-right: 6px;
    display: inline-block;
    &:before,
    &:after {
      $ratingScale: 0.35;
      -ms-transform: scale(#{$ratingScale}, #{$ratingScale});
      -webkit-transform: scale(#{$ratingScale}, #{$ratingScale});
      transform: scale(#{$ratingScale}, #{$ratingScale});
      transform-origin: left;
      top: 0;
    }
  }
}
.easy-reorder {
  display: none;
}
.circle {
  background: #6fcee4 url('/images/pdp/2022/shop-bag.e53f4a9a.png') no-repeat
    center/50px 50px;
}

@media screen and (min-width: 1100px) {
  .slide-card {
    max-width: 198px;
    height: 322px;
  }
  .easy-reorder {
    display: flex;
  }
}
</style>
