import {
  BuilderContent,
  BuilderContentVariation,
  Component as BuilderComponent,
} from '@builder.io/sdk';
import { email, min, required } from '@vee-validate/rules';
import type { SetRequired } from 'type-fest';
import { Component } from 'vue';
import { LocationQuery } from 'vue-router';

import { getCookie } from '@/utils/isomorphic/cookie';

export type BooleanOrBooleanString = boolean | 'true' | 'false';

export type { Component as BuilderComponent } from '@builder.io/sdk';

export interface CmsRegisteredComponent extends BuilderComponent {
  component: Component;
}

export interface DataModel<T extends BuilderContent['data']> extends BuilderContent {
  data?: T;
  variations?: {
    [key: string]: (BuilderContentVariation & { data?: T }) | undefined;
  };
}

export interface ExpandableReference<T extends BuilderContent> {
  '@type': '@builder.io/core:Reference';
  id: string;
  model: string;
  value?: T;
}

export interface HeaderAnnouncements {
  headerAnnouncements: {
    announcement: string;
    link: string;
  }[];
}

export interface HeaderLogoResponse {
  headerLogo: ExpandableReference<
    DataModel<{
      name: string;
      logo: string;
    }>
  >;
}

export function adaptLegacyBoolean(val?: BooleanOrBooleanString): boolean | undefined {
  if (typeof val === 'boolean') return val;
  if (typeof val === 'string') return val === 'true';
  return undefined;
}

export function contentHasVariations(
  content: BuilderContent,
): content is SetRequired<BuilderContent, 'variations'> {
  return Object.values(content.variations ?? {})?.length > 0;
}

interface BuilderContentWithVariationsBruteForced extends BuilderContent {
  testVariationId: undefined;
  variations: Exclude<BuilderContent['variations'], undefined>;
}

export function contentRequiresVariationIdFromCookie(
  content?: BuilderContent | null,
): content is BuilderContentWithVariationsBruteForced {
  if (!content) return false;
  if (!contentHasVariations(content)) return false;
  return content.testVariationId === undefined;
}

function prioritizeWinner<T extends BuilderContent['data']>(content: DataModel<T>) {
  if (content.testRatio === 1) return content;
  return Object.values(content.variations ?? {}).find((v) => v?.testRatio === 1);
}

export function getContentVariationInfoFromCookie(
  content: BuilderContentWithVariationsBruteForced,
) {
  // Builder's SDK currently fails to prioritize the winner with visual content
  const winner = content.data?.blocks ? undefined : prioritizeWinner(content);

  const cookieName = `builder.tests.${content.id}`;
  const testVariationId: BuilderContent['testVariationId'] =
    winner?.id ?? getCookie(cookieName, false);
  if (!testVariationId) {
    throw new Error(
      `CMS content with variations SSR'd has not stored a decision for content: ${content.id}`,
    );
  }
  if (!content.variations?.[testVariationId]) {
    return { testVariationId: content.id, testVariationName: 'Default' };
  }
  const testVariationName: BuilderContent['testVariationName'] =
    content.variations?.[testVariationId]?.name;
  return { testVariationId, testVariationName };
}

export function getVariationOrControl<T extends BuilderContent['data']>(
  content?: DataModel<T> | null,
) {
  if (!content || !contentHasVariations(content)) return content;
  const winner = prioritizeWinner<T>(content);
  if (winner) return winner;

  const { id, testVariationId, variations } = content;
  const chosenVariationId = testVariationId ?? getCookie(`builder.tests.${id}`, false);
  if (chosenVariationId) {
    return variations[chosenVariationId] ?? content;
  }

  const choice = Object.values(variations).find(
    (candidate) => candidate?.testRatio && Math.random() <= candidate.testRatio,
  );

  return choice ?? content;
}

export function normalizeQueryParam(urlQuery?: LocationQuery | URLSearchParams, key?: string) {
  if (!urlQuery || !key) return '';
  if (urlQuery instanceof URLSearchParams) {
    return urlQuery.get(key) ?? '';
  }
  let value = urlQuery[key] ?? '';
  if (Array.isArray(value)) {
    value = value.join(',');
  }
  return value;
}

export const TWColorsBackground: { readonly [key: string]: string } = {
  'baby blue': 'bg-baby-blue',
  'baby blue (light)': 'bg-baby-blue-lighter',
  background: 'bg-background',
  'background (secondary)': 'bg-background-secondary',
  'background (tertiary)': 'bg-background-tertiary',
  black: 'bg-black',
  blue: 'bg-blue',
  'pink (candy)': 'bg-candy-pink',
  cream: 'bg-cream',
  'chocolate (dark)': 'bg-dark-chocolate',
  error: 'bg-error',
  'chocolate (milk)': 'bg-milk-chocolate',
  green: 'bg-green',
  'green (dark)': 'bg-green-darker',
  navy: 'bg-navy',
  'nuts-sky': 'bg-nuts-sky-600',
  orange: 'bg-orange',
  pistachio: 'bg-pistachio',
  white: 'bg-white',
  yellow: 'bg-yellow',
  kale: 'bg-kale',
  red: 'bg-red',
  'red (dark)': 'bg-red-darker',
  'red (secondary)': 'bg-red-secondary',
  'true-gray': 'bg-true-gray',
  'true-gray (light)': 'bg-true-gray-lighter',
  'true-gray (bright)': 'bg-true-gray-bright',
};

export const TWColorsText: { readonly [key: string]: string } = {
  'baby blue': 'text-baby-blue',
  'baby blue (light)': 'text-baby-blue-lighter',
  background: 'text-background',
  'background (secondary)': 'text-background-secondary',
  'background (tertiary)': 'text-background-tertiary',
  black: 'text-black',
  blue: 'text-blue',
  'pink (candy)': 'text-candy-pink',
  cream: 'text-cream',
  'chocolate (dark)': 'text-dark-chocolate',
  error: 'text-error',
  'chocolate (milk)': 'text-milk-chocolate',
  green: 'text-green',
  'green (dark)': 'text-green-darker',
  navy: 'text-navy',
  orange: 'text-orange',
  pistachio: 'text-pistachio',
  white: 'text-white',
  yellow: 'text-yellow',
  kale: 'text-kale',
  red: 'text-red',
  'red (dark)': 'text-red-darker',
  'red (secondary)': 'text-red-secondary',
  'true-gray': 'text-true-gray',
  'true-gray (light)': 'text-true-gray-lighter',
  'true-gray (bright)': 'text-true-gray-bright',
};

export const validationRules = {
  email: (value: string) => {
    if (!email(value)) return 'Please enter a valid email';
    if (!required(value)) return 'This field is required';
    return true;
  },
  required: (value: string) => required(value) || 'This field is required',
  phone: (value: string) => min(value, [10]) || 'Please enter a valid phone number',
};
