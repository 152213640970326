<template>
  <Modal
    aria-labelledby="login-modal-label"
    date-test="login-modal"
    anchor="mobile-bottom"
    borderRadius="rounded-t-xl sm:rounded-xl"
    :class="{ 'pointer-events-none': signInCallback.isPending }"
    :hasControls="false"
    :isOpen="isOpen"
    skipFirstElementOnFocus
    width="w-full sm:max-w-md"
    @handle-close="handleClose"
  >
    <template #body>
      <transition name="fade" mode="out-in">
        <SignInForm
          v-if="currentStep === 'sign-in'"
          :isCheckout="isCheckout"
          :submitCallback="signInCallback"
          @change-email="(email) => onChangeEmail('sign-up', email)"
        >
          <template #header="{ title }">
            <span class="text-xl font-bold sm:text-2xl font-sofia-pro" id="login-modal-label">
              {{ title }}
            </span>
          </template>
        </SignInForm>
        <SignUpForm
          v-else
          :email="email"
          :isCheckout="isCheckout"
          :selectBusiness="selectBusiness"
          @signed-in="signInCallback.execute"
          @change-email="(email) => onChangeEmail('sign-in', email)"
        >
          <template #header="{ title }">
            <span class="text-xl font-bold sm:text-2xl font-sofia-pro" id="login-modal-label">
              {{ title }}
            </span>
          </template>
        </SignUpForm>
      </transition>
    </template>
  </Modal>
</template>

<script lang="ts">
import { useAnnouncer } from '@vue-a11y/announcer';
import { defineComponent, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Modal from '@/components/base/layout/Modal.vue';
import SignInForm from '@/components/login/SignInForm.vue';
import SignUpForm from '@/components/login/SignUpForm.vue';
import { UseCallback, useCallback } from '@/composables/useCallback';
import { useCustomer } from '@/composables/useCustomer';
import { useAutoDelivery } from '@/stores/autoDelivery';

type Step = 'sign-in' | 'sign-up';

export default defineComponent({
  name: 'LoginModal',
  props: {
    callback: { required: false, type: Object as PropType<UseCallback> },
    destination: { required: false, type: String },
    initialStep: { required: false, type: String as PropType<Step>, default: 'sign-in' },
    isCheckout: { required: false, type: Boolean, default: false },
    selectBusiness: { required: false, type: Boolean, default: false },
    isOpen: { required: true, type: Boolean, default: false },
  },
  components: {
    Modal,
    SignInForm,
    SignUpForm,
  },
  emits: ['handle-close', 'open-b2b-registration'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const { polite } = useAnnouncer();
    const { customer } = useCustomer(store);

    const currentStep = ref<Step>(props.initialStep);
    const email = ref('');

    const handleClose = () => emit('handle-close');

    const signInCallback = useCallback(async (options?: { isBusinessAccount: boolean }) => {
      polite('You have successfully signed in.');
      useAutoDelivery().initSdkClient();
      store.dispatch('headerModule/getDynamicHeaderContent');

      const isSpa = !!router;
      const navigateFn = isSpa ? router.push : window.location.assign;

      if (props.destination && !options?.isBusinessAccount) {
        return navigateFn(props.destination);
      }
      if (props.callback) {
        await props.callback.execute();
      }

      if (options?.isBusinessAccount && !props.isCheckout) {
        sessionStorage.setItem('B2BAccountSource', 'Account Creation');
        return navigateFn('/business/register');
      }

      handleClose();

      return undefined;
    });

    const onChangeEmail = (step: Step, newEmail?: string) => {
      email.value = newEmail ?? '';
      currentStep.value = step;
    };

    return {
      currentStep,
      customer,
      email,
      handleClose,
      onChangeEmail,
      signInCallback,
    };
  },
});
</script>
