/* eslint-disable no-shadow */
import { Cart, Money } from '@commercetools/platform-sdk';
import uniq from 'lodash/uniq';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { fromAxiosNutsJson } from '@/api';
import { adaptMiniCartResponse } from '@/api/cart';
import { getSafcToken } from '@/api/checkout/payment';
import { GiftCertificateResponse } from '@/api/giftCertificates';
import { productKeysById } from '@/api/productKeysById';
import type { RootState } from '@/store/createStore';
import { DateString } from '@/utils/dateTime';

// for now these are pasted from the replatforming repo but should move to a package soon
export interface CartLineItemFields {
  readonly autoDeliveryInterval?: number;
  readonly autoDeliveryOfferLocation?: string;
  readonly autoDeliveryOfferType?: string;
  readonly customizationsDescription?: string;
  readonly externalId?: string;
  readonly markedAsGift?: boolean;
  readonly occasionImageUrl?: string;
  readonly occasionName?: string;
  readonly parentExternalId?: string;
  /** stringified @type {DigitalGiftRecipient[]} */
  readonly recipientsJson?: string;
  readonly sendAt?: DateString;
  readonly senderName?: string;
}
export interface GreetingCardCartLineItemFields extends CartLineItemFields {
  readonly greetingCardMessage: string;
}
export interface PackingSlipMessageCustomLineItemFields {
  readonly packingSlipMessage: string;
}

export interface ImageUrlsByProductId {
  [id: string]: string | undefined;
}

const namespaced = true;

export const state = () => ({
  rawCart: undefined as Cart | undefined,
  couponMessage: null as string | null,
  giftCertificate: null as GiftCertificateResponse | null,
  listingImageUrlsByProductId: {} as ImageUrlsByProductId,
  maxQuantity: 4999,
  productKeysById: {} as { [productId: string]: string },
  productPathsById: {} as { [productId: string]: string },
  safcToken: null as string | null,
  totalDiscountedAmount: null as Money | null,
  copiedFromOrderNumber: null as number | null,
});

export type CartState = ReturnType<typeof state>;

/** ********** */
// GETTERS //
/** ********** */
export const getters: GetterTree<CartState, RootState> = {};

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations: MutationTree<CartState> = {
  SET_RAW_CART(state, rawCart: Cart) {
    state.rawCart = rawCart;
  },
  SET_GIFT_CERTIFICATE(state, giftCertificate?: GiftCertificateResponse) {
    state.giftCertificate = giftCertificate ?? null;
  },
  SET_FROM_DYNAMIC_CONTENT_MINICART(state, miniCart: any) {
    state.couponMessage = adaptMiniCartResponse(miniCart).couponMessage;
  },
  SET_SAFC_TOKEN(state, safcToken?: string) {
    state.safcToken = safcToken ?? null;
  },
  SET_COPIED_FROM_ORDER_NUMBER(state, orderNumber: number | null) {
    state.copiedFromOrderNumber = orderNumber;
  },
  UPDATE_PRODUCT_KEYS_BY_ID(state, keysById: { [productId: string]: string }) {
    state.productKeysById = {
      ...state.productKeysById,
      ...keysById,
    };
  },
  UPDATE_PRODUCT_PATHS_BY_ID(state, pathsById: { [productId: string]: string }) {
    state.productPathsById = {
      ...state.productPathsById,
      ...pathsById,
    };
  },
  UPDATE_VARIANT_IMAGE_CACHE(state, imageMap: ImageUrlsByProductId) {
    state.listingImageUrlsByProductId = {
      ...state.listingImageUrlsByProductId,
      ...imageMap,
    };
  },
};

/** ******** */
// ACTIONS //
/** ******** */
export const actions: ActionTree<CartState, RootState> = {
  cacheProductImageMap({ commit }, imageMap: ImageUrlsByProductId) {
    commit('UPDATE_VARIANT_IMAGE_CACHE', imageMap);
  },

  async fetchSafcToken({ commit }, email?: string) {
    try {
      const { safcToken } = await fromAxiosNutsJson(getSafcToken(email), {
        onMessages: 'default',
      });
      commit('SET_SAFC_TOKEN', safcToken);
    } catch (e) {
      /* it's okay, if not signed in or not privileged, there won't be a token */
    }
  },

  async loadProductKeysById({ commit }, ids: string[]) {
    const keysById = await productKeysById(uniq(ids));
    commit('UPDATE_PRODUCT_KEYS_BY_ID', keysById);
  },

  setGiftCertificate({ commit }, giftCertificate?: GiftCertificateResponse) {
    commit('SET_GIFT_CERTIFICATE', giftCertificate);
  },

  setCopiedFromOrderNumber({ commit }, orderNumber: number | null) {
    commit('SET_COPIED_FROM_ORDER_NUMBER', orderNumber);
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
