<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ThemedButton from '@/components/base/ThemedButton.vue';
import ActionItemsPopover from '@/components/layout/header/ActionItemsPopover.vue';
import ActionItemsText from '@/components/layout/header/ActionItemsText.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCheckout } from '@/composables/useCheckout';
import { useCustomer } from '@/composables/useCustomer';
import { useLoginModal } from '@/composables/useLoginModal';
import { ClickEvent } from '@/utils/browser';
import { SiteNavigation } from '@/utils/navMenu';

dayjs.extend(relativeTime);

defineProps<{ accountLinks: SiteNavigation['accountLinks'] }>();

const store = useStore();
const { navigateTo } = useRouteChange(useRouter());
const { cxMode } = useCheckout(store);
const { handleOpen } = useLoginModal();

const { customer, customerProfileImage } = useCustomer(store);
const isSignedIn = computed(() => !!customer.value || cxMode.value);

const userCreatedAt = computed<string | undefined>(
  () => store.state.headerModule.userCreatedAt ?? undefined,
);

const userFirstName = computed<string | undefined>(
  () => store.state.headerModule.userFirstName ?? undefined,
);

const currentDate = dayjs().startOf('day');
const createdAtDate = computed(() => dayjs(userCreatedAt.value));
const dateDiffInDays = currentDate.diff(createdAtDate.value, 'days');

const timeWithUs = computed<string>(() => {
  const dateDiffInMonths = currentDate.diff(createdAtDate.value, 'months');
  const yearsWithUs = Math.floor(dateDiffInMonths / 12);
  const leftOverMonths = dateDiffInMonths % 12;

  if (yearsWithUs === 0) {
    if (leftOverMonths === 0) return '';
    return leftOverMonths > 1 ? `${leftOverMonths} months` : `${leftOverMonths} month`;
  }

  return `${yearsWithUs} year${yearsWithUs > 1 ? 's' : ''}${
    leftOverMonths > 0 ? ` ${leftOverMonths} month${leftOverMonths > 1 ? 's' : ''}` : ''
  }`;
});

const personalizedGreeting = computed<string>(() =>
  isSignedIn.value ? `Hello, ${userFirstName.value}!` : 'Hello, Welcome!',
);

const personalizedMessage = computed<string>(() => {
  if (isSignedIn.value) {
    if (timeWithUs.value === '') return 'Welcome back! Great to see you again!';
    return `You've been with us for ${timeWithUs.value}`;
  }
  return 'Sign in for a more personalized shopping experience';
});

const requireUser = (eventOrDestination: ClickEvent | string) => {
  const destination =
    typeof eventOrDestination === 'string'
      ? eventOrDestination
      : eventOrDestination.currentTarget?.getAttribute('href') ?? undefined;
  if (isSignedIn.value) {
    if (!destination) return;
    navigateTo(destination);
  } else {
    handleOpen({ destination });
  }
};

const triggerAttributes: Record<string, any> = {
  'data-test': 'sign-in-action-items-trigger',
  'data-promo': 1,
  'data-promo-name': 'Header Links',
  'data-promo-creative': 'Account',
};

const breakpoints = useBreakpoints(breakpointsTailwind);
const mobileScreen = computed(() => breakpoints.smaller('lg').value);

const handleClick = () => {
  if (mobileScreen.value) {
    requireUser('/account');
  }
};
</script>

<template>
  <ActionItemsPopover
    :buttonName="isSignedIn ? 'My Account' : 'Sign In'"
    :triggerAttributes="triggerAttributes"
    @click="handleClick"
  >
    <template #trigger>
      <img
        v-if="isSignedIn"
        class="object-contain w-6 h-6"
        :src="customerProfileImage"
        alt="Profile Pic Icon"
      />
      <img v-else class="object-contain w-6 h-6" src="@/assets/account.svg" alt="Account Icon" />
      <ActionItemsText class="mt-1 lg:mt-0 lg:ml-2">
        <span class="lg:hidden">Account</span>
        <span class="hidden lg:visible lg:block">
          {{ isSignedIn ? userFirstName : 'Sign In' }}
        </span>
      </ActionItemsText>
    </template>
    <div class="w-64 px-4 py-5" data-test="sign-in-or-create-account">
      <p
        class="text-base font-bold"
        :class="isSignedIn && dateDiffInDays <= 1 ? 'no-mb' : 'force-mb-2'"
      >
        {{ personalizedGreeting }}
      </p>
      <p
        v-if="dateDiffInDays > 1 || !isSignedIn"
        class="text-base no-mb"
        :class="{ 'force-mb-2': !isSignedIn }"
      >
        {{ personalizedMessage }}
      </p>
      <ThemedButton
        v-if="!isSignedIn"
        theme="gray"
        fullWidth
        href=""
        @click.prevent="requireUser"
        class="focus:no-underline hover:no-underline"
        data-promo="1"
        data-promo-name="Header Links"
        data-promo-creative="Account > Sign In or Create Account"
      >
        Sign In or Create Account
      </ThemedButton>
    </div>
    <div class="py-2" data-test="my-account-links">
      <a
        v-for="{ icon, link } in accountLinks"
        :key="link.url"
        :href="link.url"
        @click.prevent="requireUser"
        class="flex items-center py-2 pl-4 cursor-pointer hover:bg-background-secondary no-link-styles"
        data-promo="1"
        data-promo-name="Header Links"
        :data-promo-creative="`Account > Link: ${link.text}`"
      >
        <img class="h-6 mr-4" :src="icon" :alt="`${link.text} Icon`" />
        <span class="text-sm no-mb">{{ link.text }}</span>
      </a>
    </div>
    <div class="flex px-4 py-5" v-if="isSignedIn">
      <p class="mr-1 text-sm no-mb">{{ `Not ${userFirstName}?` }}</p>
      <a class="text-sm sign-out-link-styles" href="/sign/out">Sign Out</a>
    </div>
  </ActionItemsPopover>
</template>

<style lang="scss" scoped>
.no-mb {
  @apply mb-0;
}
.force-mb-2 {
  @apply mb-2;
}
.no-link-styles {
  @apply text-black hover:no-underline focus:no-underline;
}
.sign-out-link-styles {
  @apply text-black underline cursor-pointer;
}
</style>
