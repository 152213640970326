import { fromAxiosNutsJson, webstore } from '@/api';

export const adaptMiniCartResponse = ({ cart = null, couponMessage = null }) => ({
  cart,
  couponMessage,
});

const cart = {
  async addToCart(data) {
    return webstore.post('/cart', data);
  },
  async copyCustomerCart(key, { onMessages, onRedirect }) {
    const data = new FormData();
    data.set('key', key);
    await fromAxiosNutsJson(webstore.post('/cart/customer-cart', data), {
      onMessages,
      onRedirect,
    });
  },
  async lookUpCustomerCart(key, { onMessages }) {
    const { cart: customerCart } = await fromAxiosNutsJson(
      webstore.get(`/cart/customer-cart?key=${key}`),
      {
        onMessages,
      },
    );
    return customerCart;
  },
  async clearCopiedOrder() {
    return webstore.sessionSpecific.post('/checkout/express/clear-copied-order');
  },
};
export default cart;
