<script setup lang="ts">
import {
  breakpointsTailwind,
  useBreakpoints,
  useElementBounding,
  useEventListener,
  useIntervalFn,
  useVModel,
} from '@vueuse/core';
import { computed, onMounted, reactive, ref } from 'vue';

import RouteLink from '@/components/base/RouteLink.vue';
import ActionItemsButton from '@/components/layout/header/ActionItemsButton.vue';
import ActionItemsText from '@/components/layout/header/ActionItemsText.vue';
import CartActionItems from '@/components/layout/header/CartActionItems.vue';
import ReorderActionItems from '@/components/layout/header/ReorderActionItems.vue';
import SearchBar from '@/components/layout/header/SearchBar.vue';
import SignInActionItems from '@/components/layout/header/SignInActionItems.vue';
import { SiteNavigation } from '@/utils/navMenu';

const props = withDefaults(
  defineProps<{
    accountLinks: SiteNavigation['accountLinks'];
    actionBarHeightOffset?: number;
    isFixed?: boolean;
    modelValue?: boolean;
  }>(),
  {
    actionBarHeightOffset: 0,
    isFixed: false,
    modelValue: false,
  },
);

const emit = defineEmits(['update:modelValue']);
const menuOpened = useVModel(props, 'modelValue', emit);
const isMobile = useBreakpoints(breakpointsTailwind).smaller('lg');

const query = ref('');
const searchBarContainer = ref();
const { height: searchBarHeight } = useElementBounding(searchBarContainer);

const toggleMenuOpen = () => {
  menuOpened.value = !menuOpened.value;
};

const scrollData = reactive({ didScroll: false, heightOffset: 0, lastY: 0 });

const actionBarHeightOffsetOverride = computed(() => ({
  negative: `-${props.actionBarHeightOffset}px`,
  positive: `${props.actionBarHeightOffset - scrollData.heightOffset}px`,
}));

const changeHeaderOnScroll = () => {
  const DELTA = props.actionBarHeightOffset - searchBarHeight.value;

  const currentY = window.scrollY || document.documentElement.scrollTop;
  if (currentY < 0) return;

  const isScrollDown = currentY > scrollData.lastY;
  const calculatedDelta = isScrollDown ? DELTA * 3 : DELTA;
  if (Math.abs(scrollData.lastY - currentY) <= calculatedDelta) return;

  if (isMobile.value && currentY > scrollData.lastY && currentY > props.actionBarHeightOffset) {
    scrollData.heightOffset = DELTA;
  } else {
    scrollData.heightOffset = 0;
  }

  scrollData.lastY = currentY;
};

onMounted(() => {
  useEventListener('scroll', () => {
    scrollData.didScroll = true;
  });

  useIntervalFn(() => {
    if (scrollData.didScroll) {
      changeHeaderOnScroll();
      scrollData.didScroll = false;
    }
  }, 250);
});
</script>

<template>
  <div
    class="border-nuts-neutral-200 border-b border-solid pt-2 lg:py-2.5 lg:px-10 bg-white z-20"
    :class="{ 'slide-from-top': isFixed }"
    ref="mainActionsBar"
    data-test="main-actions-bar"
  >
    <div class="flex flex-wrap items-center w-full mx-auto 2xl:max-w-screen-2xl">
      <div class="flex items-center justify-between w-full px-1 lg:justify-start">
        <div class="flex items-center shrink-0">
          <div class="flex flex-col items-center lg:w-auto">
            <ActionItemsButton class="py-1 lg:hidden" @click="toggleMenuOpen">
              <div class="flex flex-col items-center justify-center">
                <img
                  class="object-contain w-6 h-6"
                  src="@/assets/menu-burger.svg"
                  alt="open menu hamburger icon"
                />
                <ActionItemsText class="mt-1 lg:mt-0 lg:ml-2">Shop</ActionItemsText>
              </div>
            </ActionItemsButton>
            <button
              v-if="isFixed"
              class="hidden p-0 mr-4 bg-transparent border-none cursor-pointer lg:visible lg:block w-7"
              @click="toggleMenuOpen"
            >
              <img
                v-show="!menuOpened"
                :aria-hidden="menuOpened"
                class="h-7"
                src="@/assets/menu-burger.svg"
                alt="open menu hamburger icon"
              />
              <img
                v-show="menuOpened"
                :aria-hidden="!menuOpened"
                class="cursor-pointer h-7"
                src="@/assets/close.svg"
                alt="close menu icon"
              />
            </button>
          </div>

          <ReorderActionItems class="lg:hidden" />
        </div>
        <RouteLink
          class="block"
          to="/"
          data-promo="1"
          data-promo-name="Header Links"
          data-promo-creative="Site logo"
        >
          <slot name="logo" />
        </RouteLink>
        <div class="hidden mx-4 lg:block lg:visible grow md:flex-wrap basis-full lg:basis-auto">
          <SearchBar v-model="query" />
        </div>
        <div class="flex items-center lg:ml-auto lg:gap-2">
          <SignInActionItems :accountLinks="accountLinks" />
          <ReorderActionItems class="hidden lg:visible lg:flex" />
          <CartActionItems />
        </div>
      </div>
      <div
        class="px-3 lg:hidden grow md:flex-wrap lg:px-0 basis-full lg:basis-auto"
        ref="searchBarContainer"
      >
        <SearchBar v-model="query" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slide-from-top {
  @apply fixed left-0 right-0 transition ease-out duration-300 drop-shadow;
  top: v-bind('actionBarHeightOffsetOverride.negative');
  transform: translateY(v-bind('actionBarHeightOffsetOverride.positive'));
}
</style>
