<template>
  <form action="/subscribe" method="post">
    <div class="form-group inline-button">
      <input type="hidden" name="detailsForm" value="1" />
      <input type="hidden" name="reason" :value="section" />
      <input
        type="email"
        class="input-email"
        name="inputEmail"
        v-model="typedEmail"
        aria-label="Enter Email Address"
        placeholder="Enter Email Address"
        ref="emailInput"
        title="Sign up for our newsletter"
        data-test="input-email"
      />
      <input
        type="submit"
        value="GO"
        class="text-white button extra-compact bg-nuts-cyan-800"
        data-test="subscribe-submit-button"
      />
      <a
        v-if="suggestedEmail"
        class="suggestion"
        @click.prevent="acceptSuggestion($refs.emailInput)"
      >
        Did you mean {{ suggestedEmail }} ?
      </a>
      <div v-if="isValidEmail">
        <label for="inputFirstName" :class="labelFirstNameClass">
          First name (we like to say hi!):
        </label>
        <br />
        <input
          name="inputFirstName"
          type="text"
          placeholder="(optional)"
          class="form-control"
          :class="inputFirstNameClass"
          title="Subscription First Name"
          aria-label="Enter First Name"
          data-test="inputFirstName"
        />
        <div :class="explanationClass" data-test="explanation">
          (We hate junk mail and spam just like you and will never give your email address to
          anyone. You can
          <a href="/unsubscribe">unsubscribe</a> at any time.)
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';

import { useForm } from '@/composables/useForm';
import validate from '@/utils/validate';

export default defineComponent({
  name: 'EmailSignup',
  props: {
    section: { required: true, type: String, default: '' },
  },
  setup(props) {
    const typedEmail = ref('');

    const { handleEmailTypos } = useForm();
    const { acceptSuggestion, checkForSuggestions, suggestedEmail } = handleEmailTypos(typedEmail);

    const isValidEmail = computed(() => validate.validateEmail(typedEmail.value));
    const inputFirstNameClass = computed(() => `input-firstname-${props.section}`);
    const labelFirstNameClass = computed(() => `label-firstname-${props.section}`);
    const explanationClass = computed(() => `explanation-${props.section}`);

    watch(typedEmail, () => {
      checkForSuggestions();
    });

    return {
      acceptSuggestion,
      explanationClass,
      inputFirstNameClass,
      isValidEmail,
      labelFirstNameClass,
      suggestedEmail,
      typedEmail,
    };
  },
});
</script>
