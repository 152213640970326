<script setup lang="ts">
import { computed } from 'vue';

import Caption from '@/components/base/typography/Caption.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';

export type Size = 'xs' | 'sm';

const props = withDefaults(
  defineProps<{ discountPercent?: string; discountType?: string; size?: Size }>(),
  {
    size: 'xs',
  },
);

const componentType = computed(() => (props.size === 'sm' ? SmallBodyText : Caption));
</script>

<template>
  <component
    :is="componentType"
    class="font-bold px-0.5 py-0.25 text-black mx-1 md:mx-1.5"
    :class="discountType === 'product' ? 'bg-candy-pink text-error' : 'bg-yellow'"
    data-test="discount-badge"
  >
    {{ discountType === 'product' ? `${discountPercent} off` : `SAVE ${discountPercent}` }}
  </component>
</template>
