<script setup lang="ts">
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import AppliedDiscountsPopper from '@/components/base/AppliedDiscountsPopper.vue';
import InfoIcon from '@/components/base/assets/InfoIcon.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import ApplePayButton from '@/components/cart/ApplePayButton.vue';
import ExpressCheckout from '@/components/cart/ExpressCheckout.vue';
import OrderLineItems from '@/components/checkout/summary/LineItems.vue';
import CmsContent from '@/components/cms/CmsContent.vue';
import ActionItemsPopover from '@/components/layout/header/ActionItemsPopover.vue';
import ActionItemsText from '@/components/layout/header/ActionItemsText.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { useCheckout } from '@/composables/useCheckout';
import { useCms } from '@/composables/useCms';
import { useCustomer } from '@/composables/useCustomer';
import { CartDiscountWithSiteMessages, useDiscount } from '@/composables/useDiscount';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useLoginModal } from '@/composables/useLoginModal';
import { usePayment } from '@/composables/usePayment';
import money from '@/filters/money';
import { useNotifications } from '@/stores/notifications';
import { CheckoutEvents, formatPurchaseItem, gtag } from '@/utils/analytics';
import { getAnalyticsProducts, getCheckoutEvent, getCheckoutType } from '@/utils/analyticsPayloads';
import { adaptSavings } from '@/utils/cart';
import { NutsRedirect } from '@/utils/exceptions';
import { Money } from '@/utils/money';

const { navigateTo, route } = useRouteChange(useRouter());
const store = useStore();
const { customer } = useCustomer(store);
const { flags, loadDyFlags } = useFeatureFlags(store);
const { addNotifications } = useNotifications();
const {
  loadCart,
  loadProductUrlPaths,
  preCheckoutLineItems: lineItems,
  productKeysById,
} = useCart(store);
const { cxMode, determineEntryLocation, prepareCartForCheckout } = useCheckout(store);
const { appliedDiscountCodes, appliedCartDiscountIds, cartDiscountsForMessages } =
  useDiscount(store);
const { handleOpen, state: signIn } = useLoginModal();
const {
  cartDiscountAmount,
  estimatedAmountToCharge,
  estimatedTotal,
  preCheckoutSubtotal,
  totalSavings,
} = usePayment(store, ref(false));
const breakpoints = useBreakpoints(breakpointsTailwind);
const mobileScreen = computed(() => breakpoints.smaller('lg').value);

const isSignedIn = computed(() => !!customer.value || cxMode.value);
const cartContents = ref<HTMLElement>();
const { content: cartDiscountMessage } = useCms(store, 'cart-discount-message');

const savingsBreakdown = computed(() =>
  totalSavings.value ? adaptSavings(totalSavings.value) : [],
);

const cartDiscountMessageCT = computed(() => {
  let discount: CartDiscountWithSiteMessages | undefined;
  const latestAppliedTier: string | undefined =
    appliedCartDiscountIds.value[appliedCartDiscountIds.value.length - 1];
  if (latestAppliedTier) {
    let index = cartDiscountsForMessages.value.findIndex((d) => d.id === latestAppliedTier);
    if (index > -1 && index < cartDiscountsForMessages.value.length - 1) {
      index += 1;
    }
    discount = cartDiscountsForMessages.value[index];
  }
  if (!discount) {
    discount = cartDiscountsForMessages.value.find((d) => d.custom?.fields.miniCartMessage);
  }
  return discount?.custom?.fields.miniCartMessage ?? '';
});

const promoBanner = computed(() => cartDiscountMessageCT.value);

const goToCheckout = useCallback(async () => {
  try {
    await prepareCartForCheckout();
    const entryLocation = determineEntryLocation();

    return navigateTo(entryLocation);
  } catch (error) {
    if (error instanceof NutsRedirect) {
      if (route?.path !== error.url) {
        await navigateTo(error.url);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        loadCart(true);
      }
      const { errors } = error;
      return addNotifications({ errors });
    }
    console.error(error);
    return undefined;
  }
});

const analyticsProducts = computed(() => getAnalyticsProducts(lineItems?.value));

const checkoutType = computed(() => getCheckoutType(cxMode.value, customer.value));

const checkoutEvent = computed(() =>
  getCheckoutEvent(
    lineItems.value,
    checkoutType.value === 'Guest' ? undefined : checkoutType.value,
    estimatedAmountToCharge.value,
    appliedDiscountCodes.value,
    productKeysById.value,
  ),
);

const handleGoToCheckout = () => {
  gtag('event', 'begin_checkout', checkoutEvent.value);
  if (customer.value || cxMode.value || lineItems.value.length === 0) {
    goToCheckout.execute();
  } else {
    CheckoutEvents.step(1, analyticsProducts.value);
    handleOpen({ callback: goToCheckout, isCheckout: true });
  }
};

onMounted(() => {
  loadDyFlags('[A/B Test] Express Checkout', ['expressCheckout']);
  watch(
    () => lineItems.value.length,
    () => {
      if (!route?.path.startsWith('/cart')) {
        loadProductUrlPaths();
      }
    },
    { immediate: true },
  );
});

const handlePopoverOpened = () => {
  gtag('event', 'view_cart', {
    location: route?.path ?? window.location.pathname,
    currency: 'USD',
    value: money(estimatedAmountToCharge?.value),
    items: lineItems.value.map((li) => formatPurchaseItem(li, productKeysById.value)),
  });
};
const handleClick = () => {
  if (mobileScreen.value) {
    navigateTo('/cart');
  }
};

const triggerAttributes: Record<string, any> = {
  'data-test': 'cart-action-items-trigger',
  'data-promo': 1,
  'data-promo-name': 'Header Links',
  'data-promo-creative': 'Cart',
};
</script>

<template>
  <ActionItemsPopover
    buttonName="Cart"
    :disabled="mobileScreen"
    :triggerAttributes="triggerAttributes"
    @click="handleClick"
    @popover-opened="handlePopoverOpened"
  >
    <template #trigger>
      <div class="relative flex">
        <img class="object-contain w-6 h-6" src="@/assets/shopping-cart.svg" alt="Cart Icon" />
        <div
          v-show="lineItems.length"
          class="absolute flex items-center justify-center w-4 h-4 rounded-full -top-1 -right-1 shrink-0 bg-yellow"
        >
          <span class="text-xs font-semibold leading-tight text-center text-black">
            {{ lineItems.length }}
          </span>
        </div>
      </div>
      <ActionItemsText class="mt-1 lg:mt-0 lg:ml-2">Cart</ActionItemsText>
    </template>
    <div class="w-80">
      <div class="right-0 z-10 w-80 -top-9 animated" data-test="cart-dropdown" ref="cartContents">
        <div v-if="lineItems.length" class="w-full">
          <div class="p-4 border-b border-solid border-nuts-neutral-200">
            <div class="flex justify-between">
              <RouteLink
                to="/cart"
                data-test="edit-cart"
                class="flex items-center justify-center w-1/2 w-full py-3 mr-1 text-sm font-semibold leading-none tracking-wide text-black transition-colors bg-white border border-solid rounded-full focus:no-underline hover:no-underline link font-proxima-nova hover:border-transparent border-true-gray-300 md:cursor-pointer disabled:cursor-not-allowed text-true-gray hover:bg-true-gray-100 duration-400 hover:shadow-sharp md:text-base md:leading-none px-7 md:px-8"
                aria-hidden="false"
              >
                Edit Cart
              </RouteLink>
              <ThemedButton
                data-test="checkout"
                class="w-1/2 ml-1"
                theme="gray"
                :isLoading="goToCheckout.isPending"
                @click.prevent="handleGoToCheckout"
              >
                Checkout
              </ThemedButton>
            </div>
            <ExpressCheckout
              v-if="flags.expressCheckout"
              miniCart
              trackingEventLocation="Minicart"
            />
            <ApplePayButton v-else componentName="Minicart" class="w-full mt-2" />
          </div>
          <div class="flex justify-center max-h-[21rem] hideScroll overflow-y-scroll">
            <OrderLineItems :lineItems="lineItems" class="w-full" linkToItem />
          </div>
          <div class="p-4 border-t border-solid border-nuts-neutral-200">
            <div
              v-if="!Money.equals(preCheckoutSubtotal, estimatedTotal)"
              class="pb-2 mb-2 border-b border-solid border-neutral-200"
            >
              <div class="flex justify-between w-full">
                <SmallBodyText>Subtotal</SmallBodyText>
                <SmallBodyText>{{ money(preCheckoutSubtotal) }}</SmallBodyText>
              </div>
              <div v-if="dollars(cartDiscountAmount) !== 0" class="flex justify-between w-full">
                <SmallBodyText>
                  {{ appliedDiscountCodes.length ? appliedDiscountCodes[0].label : 'Discount' }}
                </SmallBodyText>
                <SmallBodyText>{{ money(cartDiscountAmount) }}</SmallBodyText>
              </div>
            </div>
            <div>
              <div class="flex justify-between w-full">
                <div class="flex">
                  <BaseBodyText class="mr-1 font-semibold">Estimated Total</BaseBodyText>
                  <BaseBodyText class="text-base">
                    ({{ lineItems.length }} item{{ lineItems.length > 1 ? 's' : '' }})
                  </BaseBodyText>
                </div>
                <div>
                  <BaseBodyText class="relative text-base font-semibold">
                    {{ money(estimatedTotal) }}
                  </BaseBodyText>
                </div>
              </div>
              <div v-if="totalSavings" class="flex justify-between w-full">
                <div class="flex items-center">
                  <SmallBodyText>Total Savings</SmallBodyText>
                  <AppliedDiscountsPopper :discounts="savingsBreakdown">
                    <InfoIcon alt="Applied Discounts Info Icon" :size="16" />
                  </AppliedDiscountsPopper>
                </div>
                <SmallBodyText class="relative flex items-center text-base">
                  {{ money(totalSavings.value) }}
                </SmallBodyText>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="px-4 pt-6">
          <span class="text-base font-bold">Your cart is empty</span>
          <div v-if="isSignedIn" class="flex justify-center py-10">
            <img class="w-40" src="@/assets/nuts-deck.svg" alt="Empty Cart Image" />
          </div>
          <div v-else>
            <p class="mt-2 text-base text-true-gray-500">
              Something missing?
              <span @click="handleOpen" class="font-bold text-black underline cursor-pointer text"
                >Sign in</span
              >
              see items you may have added from another computer or device.
            </p>
          </div>
          <RouteLink
            to="/tag/new+products"
            data-test="edit-cart"
            class="w-1/2 mr-1 focus:no-underline hover:no-underline"
          >
            <ThemedButton theme="white" class="w-full"> Shop New Arrivals </ThemedButton>
          </RouteLink>
        </div>
      </div>
      <div
        v-if="promoBanner"
        class="w-full p-3 text-center text-black bg-cream"
        data-test="promo-banner"
      >
        <p class="escape-mb">
          {{ promoBanner }}
        </p>
      </div>
      <CmsContent v-else :content="cartDiscountMessage" model="cart-discount-message" />
    </div>
  </ActionItemsPopover>
</template>

<style lang="scss" scoped>
.continue-shopping {
  @apply visited:text-[#135A6E] text-[#135A6E];
}
.hideScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.escape-mb {
  @apply mb-0;
}

:deep(.link) {
  @apply text-black;
}
</style>
