import { cents, dollars, fromCents } from '@nuts/auto-delivery-sdk/dist/utils/money';

import singularize from '@/filters/singularize';
import { roundPrecision } from '@/utils/math';

export const discountDecimal = ({ bulk, weight, wholesale }, quantity) => {
  if (wholesale || !bulk) {
    return 0;
  }

  if (weight === 1) {
    if (quantity <= 5) {
      return 0.0;
    }
    return quantity <= 10 ? 0.03 : 0.05;
  }
  if (quantity <= 1) {
    return 0.03;
  }
  return quantity <= 2 ? 0.05 : 0.1;
};

export const getUnitName = (variantName) => {
  if (!variantName) return null;

  let lowercaseName = variantName.toLowerCase();
  let replacementCount = 0;

  // first try "cases of 24 cans" => "case of 24 cans"
  let packagingTest = /^(jars|boxes|cases|containers|packs|packages|tins)(?= of )/;
  lowercaseName = lowercaseName.replace(packagingTest, (match) => {
    replacementCount += 1;
    return singularize(match);
  });
  if (replacementCount) {
    return lowercaseName;
  }

  // if it looks like "12 x 3 ounce bags", it works as singular or plural so leave it
  if (/^\d+ *x /.test(lowercaseName)) {
    return lowercaseName;
  }

  // if that didn't find anything, then try to singularize a trailing noun
  packagingTest =
    /(?:^| )(bags|blocks|boxes|cases|containers|jars|mixes|packages|packs|pies|samples|tins|trays|bars)$/;
  lowercaseName = lowercaseName.replace(packagingTest, (match) => singularize(match));
  return lowercaseName;
};

export const isSkuIndividuallyWrapped = (isBulk, isWholesale, variantName) =>
  !(isBulk && isWholesale) ||
  (variantName ? variantName.toLowerCase().includes('individually wrapped') : false);

export const getShortUnitName = (variantName) => {
  if (!variantName) return null;
  const trimmed = variantName.replace(/ ?\([^(]+\)$/, '');
  let name = getUnitName(trimmed);
  // abbreviate
  if (!name) return null;
  name = name.replace(' ounce', 'oz');
  name = name.replace(' pound', 'lb');
  return name;
};

export const getPackagingType = (variantName) => {
  if (!variantName) return null;
  const shortUnitName = getShortUnitName(variantName);
  let packagingTest = /^(jar|box|case|container|pack|package|tin)(?= of )/;
  let match = packagingTest.exec(shortUnitName);
  if (match) {
    return match[0];
  }
  packagingTest =
    /(?:^| )(baskets|bags|blocks|boxes|cases|containers|jars|mixes|packages|packs|pies|samples|tins|trays|bars)$/;
  match = packagingTest.exec(shortUnitName);
  if (match) {
    match = singularize(match[1]);
    return match.split(' ').pop();
  }
  packagingTest =
    /(?:^| )(basket|bag|block|box|case|container|jar|mix|package|pack|pie|sample|tin|tray|bar)$/;
  match = packagingTest.exec(shortUnitName);
  if (match) {
    return match[1];
  }
  return shortUnitName;
};

/**
 * If `channelKey` is falsy, list price is returned
 */
export const getPriceByChannel = (variant, channelKey) => {
  const { prices } = variant;

  const listPrice = prices.find((p) => !p.channel);

  if (!channelKey) return listPrice;

  return (
    prices.find((p) => p.channel?.key === channelKey || p.channel?.obj?.key === channelKey) ??
    listPrice
  );
};

export const pricePerPoundForQuantity = (variant, quantity, distributionChannel) => {
  const { variationPrice, weight } = variant;
  let pricePerPound;

  const price = getPriceByChannel(variant, distributionChannel);

  if (!price) return NaN;

  if (price.discounted) {
    pricePerPound = dollars(fromCents(cents(price.discounted.value) / weight));
  } else if (price.tiers) {
    const { value } = [{ minimumQuantity: 1, value: price.value }]
      .concat(price.tiers)
      .filter(({ minimumQuantity }) => minimumQuantity <= quantity)
      .pop();
    pricePerPound = dollars(fromCents(cents(value) / weight));
  } else {
    pricePerPound = dollars(fromCents(cents(price.value) / weight));
    pricePerPound *= 1 - discountDecimal(variant, quantity);
  }
  if (variationPrice) {
    pricePerPound += variationPrice / weight;
  }
  return pricePerPound;
};

export const priceForQuantity = (variant, quantity, distributionChannel) => {
  const { bulk, weight, wholesale } = variant;

  const price = getPriceByChannel(variant, distributionChannel);

  if (!price) return NaN;

  if (price.discounted) {
    return dollars(price.discounted.value);
  }
  if (price.tiers) {
    const { value } = [{ minimumQuantity: 1, value: price.value }]
      .concat(price.tiers)
      .filter(({ minimumQuantity }) => minimumQuantity <= quantity)
      .pop();
    return dollars(value);
  }
  if (bulk === true && !wholesale) {
    const subtotal = roundPrecision(
      pricePerPoundForQuantity(variant, quantity, distributionChannel),
    );
    return roundPrecision(subtotal * weight);
  }
  const { variationPrice } = variant;

  const salePrice = dollars(price.value);

  return roundPrecision((salePrice || 0) + (variationPrice || 0));
};

export const priceWithUnit = (variant, quantity = 1) =>
  priceForQuantity(variant, quantity) + (variant.bulk ? '/lb' : '');

export default {};
