<template>
  <div
    class="flex flex-col p-2"
    :class="{ 'cursor-pointer': isClickable }"
    :data-promo="dataPromo"
    :data-promo-name="dataPromoName"
    :data-promo-creative="dataPromoCreative"
    @click="handleClick"
    @keydown.enter="handleClick"
    :tabindex="isClickable ? 0 : -1"
  >
    <img :src="imageSource" class="w-full" :class="{ 'cursor-pointer': isClickable }" alt />
    <div class="mt-6 text-center flex flex-col items-center justify-center">
      <h3 class="text-xl md:text-2xl">
        <slot name="header" />
      </h3>
      <div class="text-base mt-2">
        <slot name="content" />
      </div>
      <span class="mt-2">
        <slot name="cta" />
      </span>
      <div class="text-xs mt-2">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'VerticalImageAndText',
  props: {
    dataPromo: { required: false, type: String },
    dataPromoName: { required: false, type: String },
    dataPromoCreative: { required: false, type: String },
    imageSource: { required: true, type: String },
    isClickable: { required: false, type: Boolean as PropType<Boolean>, default: false },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      if (props.isClickable) {
        emit('clicked');
      }
    };

    return {
      handleClick,
    };
  },
});
</script>
