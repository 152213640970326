import api from '@/api';

async function getAllProductTagCounts() {
  const facet = encodeURIComponent(
    'variants.attributes.tagReferences.id as tagIds counting products',
  );
  const { data } = await api.get(`/product-projections/search?staged=false&limit=0&facet=${facet}`);

  const countsByTag = data.facets.tagIds.terms.reduce((accumulator, obj) => {
    const map = accumulator;
    map[obj.term] = obj.productCount;
    return map;
  }, {});

  return countsByTag;
}

async function getProductTags(key) {
  const { data } = await api.get(
    `/products/key=${key}?expand=masterData.current.masterVariant.attributes[*].value[*]`,
  );

  const tagReferences = data.masterData.current.masterVariant.attributes.find(
    ({ name }) => name === 'tagReferences',
  );

  const tags =
    tagReferences && tagReferences.value.map((expandedReference) => expandedReference.obj);

  return tags || [];
}

export { getAllProductTagCounts, getProductTags };
