<template>
  <DyBanner :banner="bannerVariation" :containerId="containerId" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DyBanner from '@/components/dynamic-yield/DyBanner.vue';

export default {
  name: 'CmsDyBanner',
  props: {
    selector: { type: String, required: true },
    containerId: { type: String, required: true },
    dyPageType: { type: String, required: true },
    prefetch: { type: String, required: false },
  },
  components: {
    DyBanner,
  },
  computed: {
    ...mapGetters('dynamicYieldModule', ['getDyVariations']),
    bannerVariation() {
      return this.getDyVariations[this.selector];
    },
    isValidVariation() {
      return !!this.bannerVariation && this.bannerVariation !== {};
    },
  },
  serverPrefetch() {
    if (this.prefetch === 'true' && !this.isValidVariation) {
      this.setDyPageContext({ type: this.dyPageType, data: [] });
      return this.fetchDyVariations({
        campaigns: [this.selector],
      });
    }
    return null;
  },
  mounted() {
    if (!this.isValidVariation) {
      this.setDyPageContext({ type: this.dyPageType, data: [] });
      this.fetchDyVariations({
        campaigns: [this.selector],
        reportPageView: false,
      });
    }
  },
  methods: {
    ...mapActions('dynamicYieldModule', ['fetchDyVariations', 'setDyPageContext']),
  },
};
</script>
