<template>
  <RouterLink
    :to="link"
    class="flex no-underline color-inherit hover:no-underline link-container h-36 w-36 md:h-48 md:w-48 rounded-md shrink-0"
  >
    <div class="flex h-full flex-col w-full relative">
      <img :src="img" :alt="label" class="object-contain flex flex-1 w-full" loading="lazy" />
    </div>
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { CmsRegisteredComponent } from '@/utils/cms';

const TopCategoryCard = defineComponent({
  name: 'TopCategoryCard',
  props: {
    label: { required: true, type: String, default: 'Headline' },
    link: { required: true, type: String, default: 'ixclkxk.shop' },
    img: { required: true, type: String },
  },
});

export const TopCategoryCardRegistration: CmsRegisteredComponent = {
  component: TopCategoryCard,
  name: 'Top Category Card',
  inputs: [
    {
      name: 'label',
      type: 'string',
      defaultValue: 'Category',
    },
    {
      name: 'link',
      friendlyName: 'Category Link',
      type: 'string',
    },
    {
      name: 'img',
      friendlyName: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
  ],
};

export default TopCategoryCard;
</script>

<style lang="scss" scoped>
.link-container {
  background-color: #f9f3ef;
}
</style>
