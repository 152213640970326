import { ISubscriptionDraft, ProposedDelivery, Subscription } from '@nuts/auto-delivery-sdk';
import { defineStore } from 'pinia';
import { computed, markRaw, readonly, ref } from 'vue';

import { init, orders as getAutoDeliveryOrders } from '@/api/autoDelivery';
import { buildDeliveriesObject, BuiltProposedDelivery } from '@/utils/autoDelivery';

export interface Channel {
  key: string;
  discount: number;
}

export const useAutoDelivery = defineStore('autoDelivery', () => {
  const autoDeliveryChannel = ref<Channel>();
  const autoDeliveryDefaultChannel = ref<Channel>({
    key: 'autoDelivery5Off',
    discount: 5,
  });
  const autoDeliveryIntervalWeeks = ref([1, 2, 3, 4, 6, 8, 10, 12]);
  const inactiveSubscriptions = ref<Subscription[]>([]);
  const nextDelivery = ref<ProposedDelivery>();
  const nextShip = ref<string>();
  const orders = ref<BuiltProposedDelivery[]>([]);
  const shippingAddressId = ref<string>();
  let client = markRaw(init());

  const initSdkClient = () => {
    client = markRaw(init());
  };

  const flattenedSubscriptions = computed(() =>
    orders.value.flatMap((order) => order.subscriptions as Subscription[]),
  );

  const formattedAutoDeliveryIntervals = computed(() =>
    autoDeliveryIntervalWeeks.value.map((week) => ({
      value: week,
      text: `Every ${week} Week${week > 1 ? 's' : ''}`,
    })),
  );

  const nextShipOn = computed(() => orders.value[0]?.shipOn ?? nextShip.value);

  const assignNewAddress = async (assignAddressToSubscriptions: { [x: string]: string[] }) => {
    const queuedAssignments = Object.entries(assignAddressToSubscriptions).reduce<
      [string, string][]
    >((accumulator, assignment) => {
      const [addressId, subscriptionIds] = assignment as [string, string[]];
      const flattened: any = subscriptionIds.map((subscriptionId) => [addressId, subscriptionId]);
      accumulator.push(...flattened);
      return accumulator;
    }, []);
    for (let i = 0; i < queuedAssignments.length; i += 1) {
      const [addressId, subscriptionId] = queuedAssignments[i];
      const subscription = flattenedSubscriptions.value?.find(
        (flattenedSubscription: Subscription) => flattenedSubscription.id === subscriptionId,
      );
      if (subscription) {
        const immediateRefresh = i === queuedAssignments.length - 1;
        // eslint-disable-next-line no-use-before-define
        updateSubscription(
          subscription,
          { shippingAddressId: parseInt(addressId, 10) },
          immediateRefresh,
        );
      }
    }
  };

  const applyShippingAddressForAllShipments = async (id: string) => {
    const subscriptionIds = flattenedSubscriptions.value?.map((s: Subscription) => s.id);
    const assignAddressToSubscriptions = { [id]: subscriptionIds };
    assignNewAddress(assignAddressToSubscriptions);
  };

  const cancelSubscription = async (
    subscription: Subscription,
    reason: string,
    cancellationReasonDetails?: string,
  ) => {
    try {
      await subscription.cancel({ en: reason }, cancellationReasonDetails);
      // eslint-disable-next-line no-use-before-define
      getOrders();
    } catch (err) {
      console.error('failed to cancel auto delivery subscription');
      console.error(err);
    }
  };

  const createSubscription = async (request: ISubscriptionDraft) => {
    try {
      await Subscription.create(request, client, {
        retryConcurrentModification: true,
      });
    } catch (error) {
      console.error('failed to create auto delivery subscription');
      console.error(error);
      throw error;
    }
  };

  const fetchPaymentMethod = async () => {
    await client.fetchPaymentMethod({ force: true });
  };

  const getInactiveSubscriptions = async () => {
    const subscriptions = await client.getSubscriptions({
      includeInactive: true,
      preloadImagesAndPaths: true,
      preloadPaymentMethod: false,
      preloadShippingAddresses: false,
    });
    inactiveSubscriptions.value = subscriptions?.filter(({ active }) => active === false);
  };

  const getImpulseUpsellEligible = async (): Promise<boolean> => {
    try {
      await client.getSubscriptions({
        force: true,
        preloadImagesAndPaths: false,
        preloadPaymentMethod: false,
        preloadShippingAddresses: false,
      });
      const delivery = await client.getImpulseUpsellEligible();
      nextDelivery.value = delivery;
      if (delivery) {
        const { shipOn, shippingAddressId: deliveryShippingAddressId = '' } = delivery;
        nextShip.value = shipOn;
        shippingAddressId.value = deliveryShippingAddressId;
      }
      return Boolean(delivery);
    } catch (e) {
      console.error('Error getting impulse upsell eligible:', e);
      return false;
    }
  };

  const getOrders = async () => {
    const { orders: userOrders } = await getAutoDeliveryOrders(client);
    orders.value = userOrders.map((order: ProposedDelivery) => buildDeliveriesObject(order));
    await getInactiveSubscriptions();
  };

  const reactivateSubscription = async (subscription: Subscription) => {
    try {
      await subscription.resume({ nextShipOn: nextShipOn.value });
      getOrders();
    } catch (err) {
      console.error('failed to resume auto delivery subscription');
      console.error(err);
    }
  };

  const sendDeliveryNow = async (proposedDelivery: BuiltProposedDelivery) => {
    try {
      const newOrders = await proposedDelivery.sendNow();
      const builtOrders = newOrders.map((order: ProposedDelivery) => buildDeliveriesObject(order));
      orders.value = builtOrders;
    } catch (err) {
      console.error('failed to send delivery asap');
      console.error(err);
    }
  };

  const skipDelivery = async (proposedDelivery: BuiltProposedDelivery) => {
    try {
      const newOrders = await proposedDelivery.skip();
      const builtOrders = newOrders.map((order: ProposedDelivery) => buildDeliveriesObject(order));
      orders.value = builtOrders;
    } catch (err) {
      console.error('failed to send delivery asap');
      console.error(err);
    }
  };

  const skipSubscription = async (subscription: Subscription) => {
    try {
      await subscription.skip();
      getOrders();
    } catch (err) {
      console.error('failed to skip subscription');
      console.error(err);
    }
  };

  const updateSubscription = async (
    subscription: Subscription,
    changes: Record<string, any>,
    immediateRefresh = true,
  ) => {
    try {
      await subscription.update(changes, { retryConcurrentModification: true });
      if (immediateRefresh) getOrders();
    } catch (err) {
      console.error('failed to update auto delivery subscription');
      console.error(err);
    }
  };

  return {
    applyShippingAddressForAllShipments,
    assignNewAddress,
    autoDeliveryChannel: autoDeliveryChannel.value || autoDeliveryDefaultChannel.value,
    autoDeliveryDefaultChannel: readonly(autoDeliveryDefaultChannel),
    autoDeliveryIntervalWeeks,
    cancelSubscription,
    client,
    createSubscription,
    fetchPaymentMethod,
    formattedAutoDeliveryIntervals,
    getImpulseUpsellEligible,
    getInactiveSubscriptions,
    getOrders,
    inactiveSubscriptions,
    initSdkClient,
    nextDelivery,
    nextShip,
    nextShipOn,
    orders,
    reactivateSubscription,
    sendDeliveryNow,
    shippingAddressId,
    skipDelivery,
    skipSubscription,
    updateSubscription,
  };
});
