<template>
  <div
    :class="{
      'divide-solid divide-y-2 divide-true-gray-500':
        shipments?.length || specialDeliveryLineItems.length,
    }"
    tabindex="0"
  >
    <template
      v-if="shipments?.length || unassignedLineItems.length || specialDeliveryLineItems.length"
    >
      <div v-if="specialDeliveryLineItems.length">
        <h3 class="mt-8 text-base font-semibold md:text-lg font-sophia-pro text-true-gray">
          Special Delivery Items
        </h3>
        <div class="divide-y divide-solid divide-true-gray-200">
          <LineItem
            v-for="lineItem in specialDeliveryLineItems"
            :key="lineItem.id"
            :lineItem="lineItem"
            :linkToItem="linkToItem"
          />
        </div>
      </div>
      <div v-if="unassignedLineItems.length">
        <LineItem
          v-for="lineItem in unassignedLineItems"
          :key="lineItem.id"
          :lineItem="lineItem"
          :linkToItem="linkToItem"
        />
      </div>
      <div
        v-for="(shipment, i) in shipments"
        :key="shipment.key"
        :class="{
          'pt-4':
            i > 0 ||
            (i === 0 && unassignedLineItems.length) ||
            (i === 0 && specialDeliveryLineItems.length),
        }"
      >
        <div
          v-if="hasMultipleShipments || unassignedLineItems.length"
          class="text-sm font-semibold md:text-base"
        >
          Address {{ shipment.multishipSerial }}:
          {{ shipment.address.name }}
          <template v-if="shipment.address.city"> in {{ shipment.address.city }} </template>
        </div>
        <div class="divide-y divide-solid divide-true-gray-200">
          <LineItem
            v-for="lineItem in shipment.lineItems"
            :key="lineItem.id"
            :lineItem="lineItem"
            :multiship="hasMultipleShipments || unassignedLineItems.length > 0"
            :linkToItem="linkToItem"
          />
          <LineItem
            v-if="shipment.greetingCardLineItem"
            :key="shipment.greetingCardLineItem.id"
            :lineItem="shipment.greetingCardLineItem"
            :multiship="hasMultipleShipments || unassignedLineItems.length > 0"
            :linkToItem="linkToItem"
          />
        </div>
      </div>
    </template>
    <div v-else class="divide-y divide-solid divide-true-gray-200">
      <LineItem
        v-for="lineItem in lineItems"
        :key="lineItem.id"
        :lineItem="lineItem"
        :linkToItem="linkToItem"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { useStore } from 'vuex';

import LineItem from '@/components/checkout/summary/LineItem.vue';
import { useCheckout } from '@/composables/useCheckout';
import { NutsLineItem, Shipment } from '@/utils/cart';

export default defineComponent({
  name: 'LineItems',
  props: {
    containsAutoDelivery: { required: false, type: Boolean, default: false },
    lineItems: { required: true, type: Array as PropType<NutsLineItem[]> },
    linkToItem: { required: false, type: Boolean, default: false },
    shipments: { required: false, type: Array as PropType<Shipment[]> },
    specialDeliveryLineItems: {
      required: false,
      type: Array as PropType<NutsLineItem[]>,
      default: () => [],
    },
    unassignedLineItems: {
      required: false,
      type: Array as PropType<NutsLineItem[]>,
      default: () => [],
    },
  },
  components: {
    LineItem,
  },
  setup(props) {
    const store = useStore();
    const { cxMode } = useCheckout(store);
    const collapsed = ref(false);
    const collapsibleState = computed(() =>
      collapsed.value
        ? {
            text: 'Expand',
            icon: nutshell['img/arrow-down.svg'],
            class: 'md:h-14 md:overflow-hidden',
          }
        : {
            text: 'Collapse',
            icon: nutshell['img/arrow-up.svg'],
            class: '',
          },
    );
    const hasMultipleShipments = computed(() => (props.shipments?.length || 0) > 1);

    onMounted(() => {
      if (window.innerWidth > 768 && !cxMode.value) collapsed.value = true;
    });

    const toggle = () => {
      collapsed.value = !collapsed.value;
    };

    return {
      collapsed,
      collapsibleState,
      hasMultipleShipments,
      panelBorderUtilities: 'md:border md:border-solid md:border-gray-300 md:rounded-3xl',
      toggle,
    };
  },
});
</script>
