<template>
  <div :id="variationId" class="container">
    <ul v-if="hasData" id="secondary-nav-ul" data-test="dy-secondary-nav">
      <li v-for="(item, index) in items" :key="`item-${index}`">
        <a
          :href="item.url"
          data-promo="1"
          data-promo-name="Header Links"
          :data-promo-creative="`Secondary Nav: ${item.name}`"
          >{{ item.name }}</a
        >
      </li>
    </ul>
    <slot v-else></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DySecondaryNav',
  data() {
    return {
      campaignName: '[NAV] Secondary Nav',
    };
  },
  computed: {
    ...mapGetters('dynamicYieldModule', ['getDyVariations']),
    hasData() {
      return this.isValidVariation && !!this.selectedVariation.items;
    },
    isValidVariation() {
      return !!this.selectedVariation;
    },
    items() {
      return this.hasData ? this.selectedVariation.items : [];
    },
    selectedVariation() {
      return this.getDyVariations[this.campaignName];
    },
    variationId() {
      return this.selectedVariation ? `variation-id-${this.selectedVariation.id}` : '';
    },
  },
};
</script>
