<template>
  <div class="mobile-menu-wrapper primary-surrogate-component">
    <div class="mobile-menu-close-wrapper" @click.prevent="toggleMobileMenu">
      <i class="mobile-menu-close"></i>
    </div>
    <div class="new-mobile-nav">
      <nav class="animated mobile-main-menu">
        <ul>
          <li v-for="(menuGroup, index) in mobileMenu.menuGroups" :key="menuGroup.headerText">
            <RouteLink
              v-if="menuGroup.headerUrl"
              :to="menuGroup.headerUrl"
              @click="toggleMobileMenu"
              data-promo="1"
              data-promo-name="Mobile Menu Nav Links"
              :data-promo-creative="menuGroup.headerText"
            >
              <span>{{ menuGroup.headerText }}</span>
            </RouteLink>
            <div v-else @click.prevent="setMenuGroupIndex(index)">
              <span>{{ menuGroup.headerText }}</span>
              <i class="arrow-box-mobile"></i>
            </div>
          </li>
        </ul>
        <hr />
        <ul class="utility-menu">
          <template
            v-for="{ link, dotColor, featureFlags, icon } in mobileMenu.utilityMenu"
            :key="link.url"
          >
            <li v-if="isFeatureSupported(featureFlags)" @click="delegateLinksToRouter">
              <div v-if="dotColor" class="inline-flex items-center">
                <div :style="`background-color: ${dotColor}`" class="w-3 h-3 mr-2 rounded-md" />
                <a
                  :href="link.url"
                  data-promo="1"
                  data-promo-name="Mobile Menu Nav Links"
                  :data-promo-creative="`Utility Links: ${link.text}`"
                >
                  <span>{{ link.text }}</span>
                </a>
              </div>
              <a
                v-else
                :href="link.url"
                data-promo="1"
                data-promo-name="Mobile Menu Nav Links"
                :data-promo-creative="`Utility Links: ${link.text}`"
              >
                <img
                  v-if="icon"
                  :src="icon"
                  class="w-5 mb-1 mr-1 -ml-1"
                  :alt="`icon for ${link.text}`"
                />
                <span>{{ link.text }}</span>
              </a>
            </li>
          </template>
          <li v-if="userFirstName">
            <div class="inline-flex">
              <div class="w-3 h-3 mr-2 bg-black rounded-md"></div>
              <a
                class="g_id_signout"
                href="/sign/out"
                data-promo="1"
                data-promo-name="Mobile Menu Nav Links"
                data-promo-creative="Utility Links: Sign Out"
              >
                <span>Sign Out</span>
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <nav
        class="secondary-category-menu"
        v-for="(menuGroup, index) in mobileMenu.menuGroups"
        :key="index"
        v-show="index === indexRef"
        style="display: block"
        @click="delegateLinksToRouter"
      >
        <div class="nav-content">
          <div class="back-to-main-menu">
            <div class="secondary-category-menu-header-wrapper">
              <i class="arrow-box-mobile" @click.prevent="setMenuGroupIndex()" />
              <span>{{ menuGroup.headerText }}</span>
            </div>
          </div>
          <div class="mt-20">
            <div v-for="({ group }, index) in menuGroup.linkGroups" :key="index" class="mt-2">
              <div v-if="group.headerText">
                <a
                  v-if="group.headerUrl"
                  :href="group.headerUrl"
                  data-promo="1"
                  data-promo-name="Mobile Menu Nav Links"
                  :data-promo-creative="renderDataPromoCreative(menuGroup, group.headerText)"
                >
                  <h6 class="pt-3 pb-4 ml-5">{{ group.headerText }}</h6>
                </a>
                <template v-else>
                  <h6 class="pt-3 pb-4 ml-5">{{ group.headerText }}</h6>
                </template>
                <ul>
                  <li v-for="({ link }, index) in group.links" :key="index">
                    <a
                      :href="link.url"
                      data-promo="1"
                      data-promo-name="Mobile Menu Nav Links"
                      :data-promo-creative="
                        renderDataPromoCreative(menuGroup, group.headerText, link.text)
                      "
                    >
                      <span>{{ link.text }}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div v-else v-for="({ link }, index) in group.links" :key="index">
                <a
                  :href="link.url"
                  data-promo="1"
                  data-promo-name="Mobile Menu Nav Links"
                  :data-promo-creative="
                    renderDataPromoCreative(menuGroup, group.headerText, link.text)
                  "
                >
                  <h6 class="pt-4 pb-4 ml-5">{{ link.text }}</h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import RouteLink from '@/components/base/RouteLink.vue';
import { fromFlagSpec, useFeatureFlags } from '@/composables/useFeatureFlags';
import { staticMobileMenu } from '@/data/nav/mobileNav';
import routerLinkDelegationMixin from '@/mixins/routerLinkDelegation';
import { MobileMenuGroup, MobileNav } from '@/utils/navMenu';

export default defineComponent({
  name: 'MobileMenu',
  props: {
    mobileMenuContent: { type: Object as PropType<MobileNav> },
  },
  components: {
    RouteLink,
  },
  mixins: [routerLinkDelegationMixin],
  emits: ['toggle-mobile-menu'],
  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore();

    const { flags } = useFeatureFlags(store);
    const indexRef = ref<number | undefined>(undefined);
    const userFirstName = computed<string>(() => store.state.headerModule.userFirstName);

    const mobileMenu = computed<MobileNav>(() => props.mobileMenuContent ?? staticMobileMenu);

    const setMenuGroupIndex = (index?: number) => {
      indexRef.value = index;
    };

    const toggleMobileMenu = () => {
      emit('toggle-mobile-menu');
    };

    const isFeatureSupported = (flagsSpec?: string) => {
      if (!flagsSpec) return true;
      const routeFlags = fromFlagSpec(flagsSpec);
      return Object.entries(routeFlags).every(([key, value]) => (value ? flags[key] : !flags[key]));
    };

    const renderDataPromoCreative = (
      menuGroup: MobileMenuGroup,
      ...pieces: (string | undefined)[]
    ) => [menuGroup.headerText].concat(pieces.filter<string>(Boolean)).join(' > ');

    watch(
      () => route?.path,
      () => {
        setMenuGroupIndex();
      },
    );

    return {
      flags,
      indexRef,
      isFeatureSupported,
      mobileMenu,
      renderDataPromoCreative,
      setMenuGroupIndex,
      toggleMobileMenu,
      userFirstName,
    };
  },
});
</script>

<style lang="scss" scoped>
.mobile-main-menu {
  ul {
    margin-top: 10px;

    li {
      padding: 15px 20px;
      position: relative;
      cursor: pointer;

      :deep(a) {
        display: block;

        &:active,
        &:focus,
        &:hover {
          color: $dark-grey;
          text-decoration: none;
        }
      }

      span {
        color: $dark-grey;
        font-size: 13px;
        font-family: $font-family-proxima;
        text-transform: uppercase;
        font-weight: 700;

        &:active {
          color: $dark-grey;
          border-bottom: 1px solid $black;
        }
      }

      &:active,
      &:focus {
        background-color: $sunglow;
      }

      .arrow-box-mobile {
        content: ' ';
        @include retina-sprite($sprite-caret-right-1x);
        position: absolute;
        top: 17px;
        right: 25px;
      }
    }

    &.utility-menu {
      li {
        padding-left: 35px;

        a {
          color: $dark-grey;
          &:active,
          &:focus,
          &:hover {
            text-decoration: none;
          }

          span {
            font-size: 13px;
            font-family: $font-family-proxima;
            font-weight: 400;
          }
        }
      }
    }
  }

  hr {
    height: 3px;
    background-color: $white-smoke;
    border: none;
  }
}
.mobile-menu-wrapper {
  display: none;
  @include respond-max($screen-sm-min) {
    display: block;
  }
}
.mobile-menu-close-wrapper {
  position: fixed;
  top: 5px;
  right: 12px;
  z-index: 15;
  display: inline-block;
  padding-left: 16px;
  padding-top: 5px;

  i {
    position: relative;
    @include retina-sprite($sprite-hamburger-close-x-1x);
    cursor: pointer;
    display: inline-block;
  }
}
.nav-content {
  margin-bottom: 30px;
  position: relative;
}
.new-mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 889;
  background-color: $white;
  overflow: scroll;
  width: 85%;
  height: 100%;
  border-right: 5px solid $sunglow;
  box-shadow: 4px 0px 6px rgba($black, 0.75);
}
.secondary-category-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 14;
  background-color: $white;
  overflow: auto;
  width: 85%;
  border-right: 5px solid $sunglow;
  box-shadow: 4px 0 6px rgba($black, 0.75);

  h6 {
    span {
      &:active {
        color: $dark-grey;
        border-bottom: 1px solid $black;
      }
    }
  }

  ul {
    margin: 10px 0 0 10px;

    li {
      padding: 15px 20px;
      position: relative;
      cursor: pointer;

      a {
        display: block;
        &:active,
        &:focus,
        &:hover,
        &:link,
        &:visited {
          color: $dark-grey;
          text-decoration: none;
        }
      }

      &:active,
      &:focus {
        background-color: $sunglow;
      }

      span {
        color: $dark-grey;
        font-size: 13px;
        font-weight: 400;
        font-family: $font-family-proxima;
        &:active {
          color: $dark-grey;
          border-bottom: 1px solid $black;
        }
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        margin-bottom: 5px;
      }
    }
  }

  .back-to-main-menu {
    width: 84%;
    padding: 15px 20px;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    background-color: $sunglow;
    z-index: 14;
    cursor: pointer;

    .secondary-category-menu-header-wrapper {
      position: relative;
    }

    i {
      content: ' ';
      @include retina-sprite($sprite-caret-left-1x);
      top: 24%;
      left: 7%;
      position: absolute;
    }

    span {
      color: $dark-grey;
      font-size: 13px;
      font-weight: 700;
      font-family: $font-family-proxima;
    }
  }

  .secondary-category-menu-items {
    margin-top: 75px;
    h6 {
      position: relative;
      padding: 15px 0 15px 20px;
      color: $dark-grey;
      font-family: $font-family-proxima;
      font-size: 13px;
      font-weight: 700;

      i.arrow-box-mobile {
        content: ' ';
        @include retina-sprite($sprite-caret-right-1x);
        position: absolute;
        top: 17px;
        right: 25px;
      }

      a {
        display: block;
      }

      a:active,
      a:focus,
      a:hover,
      a:link,
      a:visited {
        color: $dark-grey;
        text-decoration: none;
      }

      &:active,
      &:focus {
        background-color: $sunglow;
      }
    }
  }
}
</style>
