import api, { webstore } from '@/api';

async function product(key) {
  return api.get(`/products/key=${key}`);
}

export function expandProduct(key) {
  return webstore.get(`/api/items/${key}/expand`);
}

export default product;
