import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import fromPairs from 'lodash/fromPairs';

import proxiedImageUtil from '@/utils/imageProxied';
import {
  getPackagingType,
  getShortUnitName,
  isSkuIndividuallyWrapped,
  priceForQuantity,
  pricePerPoundForQuantity,
} from '@/utils/sku';

// const { HTMLCleaner } = import('@/utils/html_cleaner');

const flattenAttributes = (raw) =>
  fromPairs(
    raw.map(({ name, value }) => [
      name,
      (value.label && value.label.en) || value.key || value.en || value,
    ]),
  );

const getAttributeValue = (name, attributes) => {
  if (name && attributes) {
    const results = attributes.filter((x) => x.name === name);
    if (results.length > 0) return results[0].value;
  }
  return null;
};

const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const groupVariants = (variants) => {
  const variantGroups = [];
  const groupedByWeight = groupBy(variants, (variant) => variant.weight);

  groupedByWeight.forEach((groupedVars) => {
    const variantGroup = { ...groupedVars[0] };
    const grindSizeVariation = [];
    const tinColorVariation = [];
    const otherVariations = [];
    const groupedByGrindSize = groupBy(groupedVars, (x) => x.grindSize);
    groupedByGrindSize.forEach((value, key) => {
      if (key) {
        grindSizeVariation.push({
          label: key,
          sku: value[0].sku,
          unitPrice: value[0].unitPrice,
          backordered: value[0].backordered,
        });
      }
    });

    if (grindSizeVariation.length > 0) {
      otherVariations.push({
        type: 'Grind Size',
        variations: grindSizeVariation,
      });
    }

    const groupedByTinColor = groupBy(groupedVars, (x) => x.tinColor);
    groupedByTinColor.forEach((value, key) => {
      if (key) {
        tinColorVariation.push({ label: key, sku: value[0].sku, unitPrice: value[0].unitPrice });
      }
    });

    if (tinColorVariation.length > 0) {
      otherVariations.push({
        type: 'Tin Color',
        variations: tinColorVariation,
      });
    }

    if (otherVariations.length > 0) {
      variantGroup.otherVariations = otherVariations;
    }

    if (groupedVars.length > 1) {
      variantGroup.backordered = groupedVars.every((x) => x.backordered);
    }

    variantGroups.push(variantGroup);
  });
  return variantGroups;
};

const buildVariantObject = (entry) => {
  const { id: variantId, images, sku, prices = [], otherVariations = [], attributesRaw } = entry;

  const attributes = flattenAttributes(attributesRaw);
  attributes.backordered = attributes.backordered === 'yes'; // the only exceptional logic needed

  const expandedImages = proxiedImageUtil.getVariants(images);

  const variant = {
    id: variantId,
    sku,
    prices,
    otherVariations,
    ...attributes,
    get packagingType() {
      return getPackagingType(this.variantName);
    },
    pricePerPound: 0,
    get unitName() {
      return getShortUnitName(this.variantName);
    },
    get salePrice() {
      const listPrice = this.prices.find((price) => !price.channel);
      return listPrice ? dollars(listPrice.value) : undefined;
    },
    get isIndividuallyWrapped() {
      return isSkuIndividuallyWrapped(this.bulk, this.wholesale, this.variantName);
    },
    get unitOfMeasurement() {
      if (!this.variantName) return null;
      const lowercaseName = this.variantName.toLowerCase();
      if (lowercaseName.indexOf('ounce') > -1) {
        return 'oz';
      }
      if (lowercaseName.indexOf('pound') > -1) {
        return 'lb';
      }
      return '';
    },
    unitPrice: 0,
    variationPrice: 0.0,
    images: expandedImages,
    titleImage: expandedImages && expandedImages.length > 0 ? expandedImages[0] : null,
  };
  variant.pricePerPound = pricePerPoundForQuantity(variant, 1);
  variant.unitPrice = priceForQuantity(variant, 1);

  return variant;
};

const buildProductStateObject = async (response, cleanHtml = true, locale = 'en') => {
  const { HTMLCleaner } = await import('@/utils/html_cleaner');
  const {
    key,
    masterData: { current },
  } = response;
  const {
    masterVariant,
    variants = [],
    description,
    metaDescription,
    name,
    categories = [],
  } = current;

  const { images, attributesRaw: masterAttributes } = masterVariant;

  const customPageTitleLocalized = getAttributeValue('customPageTitle', masterAttributes) || {};
  const { [locale]: customPageTitle } = customPageTitleLocalized;
  const primaryCategoryId = getAttributeValue('primaryCategory', masterAttributes).id;
  const primaryCategory = categories.find((x) => x.id === primaryCategoryId);
  let resultantAncestors;
  if (primaryCategory) {
    const { name: catName, ancestors, custom } = primaryCategory;
    const updatedCats = [...ancestors, { name: catName, custom }];
    const filteredAncestors = updatedCats.filter(
      (ancestor) => ancestor.name !== 'ixclkxk.shop Categories',
    );
    let url = '/';
    resultantAncestors = filteredAncestors.map((ancestor) => {
      const categoryUrl = ancestor.custom?.customFieldsRaw?.find(
        (f) => f.name === 'urlName',
      )?.value;
      url += `${categoryUrl}/`;
      return { name: ancestor.name, url };
    });
  }

  const expandedImages = proxiedImageUtil.getVariants(images);
  const allVariants = [masterVariant, ...variants];
  const allowedVariants = allVariants.filter((variant) => {
    const { attributesRaw = [] } = variant;
    const attr = attributesRaw.find((a) => a.name === 'variantName');
    return attr?.value.en !== 'Mini Boxes';
  });
  const formattedVariants = allowedVariants.map((entry) => buildVariantObject(entry));

  const activeVariants = formattedVariants.filter((entry) => entry.active);
  const allBackordered = activeVariants.every((variant) => variant.backordered);
  const backorderedUntil =
    allBackordered && activeVariants.length > 0 ? activeVariants[0].backorderedUntil : '';

  const variantGroups = groupVariants(activeVariants);
  const giftVariants = [];
  const isGiftCertificate =
    variantGroups[0] && variantGroups[0].customProduct === 'Gift Certificate';
  // const listOfGiftVariants = ['$10', '$25', '$35', '$40', '$50', '$75', '$100', '$200'];
  const listOfGiftVariants = [
    { variation: 236, amount: 10 },
    { variation: 5, amount: 25 },
    { variation: 155, amount: 35 },
    { variation: 156, amount: 40 },
    { variation: 6, amount: 50 },
    { variation: 7, amount: 75 },
    { variation: 8, amount: 100 },
    { variation: 9, amount: 200 },
  ];

  if (isGiftCertificate) {
    listOfGiftVariants.forEach((item, index) => {
      const alteredVariant = {
        ...variantGroups[0],
        checked: false,
        id: index + 1,
        unitPrice: item.amount,
        variation: item.variation,
      };
      giftVariants.push(alteredVariant);
    });
    giftVariants[0].checked = true;
  }

  const product = {
    key,
    name,
    description_html: cleanHtml ? HTMLCleaner.clean(description) : '',
    metaDescription,
    customPageTitle,
    attributes: {
      primaryCategory: primaryCategoryId,
      health_tips_html: (() => {
        const val = getAttributeValue('healthTips', masterAttributes);
        const tips = val ? val.en : '';
        return cleanHtml ? HTMLCleaner.clean(tips) : tips;
      })(),
    },
    images: expandedImages,
    titleImage: expandedImages && expandedImages.length > 0 ? expandedImages[0] : null,
    variants: isGiftCertificate ? giftVariants : activeVariants,
    variantGroups: isGiftCertificate ? giftVariants : variantGroups,
    allBackordered,
    ancestors: resultantAncestors,
    backorderedUntil,
  };

  return product;
};

const buildCategoryStateObject = async (response, locale = 'en', cleanHtml = true) => {
  const { HTMLCleaner } = await import('@/utils/html_cleaner');
  const {
    id,
    version,
    key,
    name,
    orderHint,
    description: descriptionRaw,
    assets,
    childCount,
    stagedProductCount,
    custom,
    ancestors,
  } = response;
  const categoryState = {
    id,
    version,
    key,
    name,
    orderHint,
    description: cleanHtml ? HTMLCleaner.clean(descriptionRaw) : '',
    assets,
    childCount,
    stagedProductCount,
    ancestors,
    path: '',
  };

  const customFieldsRaw = custom ? custom.customFieldsRaw : null;

  if (customFieldsRaw) {
    const desiredCustomFields = [
      'isB2b',
      'sidebarTop',
      'sidebarBottom',
      'topDescription',
      'wholesaleDescription',
      'urlName',
      'hidden',
    ];
    const customFields = customFieldsRaw.filter((attribute) =>
      desiredCustomFields.includes(attribute.name),
    );
    customFields.forEach(({ name: attributeName, value: valueObj }) => {
      let value = Object.prototype.hasOwnProperty.call(valueObj, locale)
        ? valueObj[locale]
        : valueObj;

      if (attributeName === 'topDescription' && !value.includes('[noparse]')) {
        value = `[noparse]${value}[/noparse]`;
      }
      if (attributeName === 'topDescription' || attributeName === 'wholesaleDescription') {
        categoryState[attributeName] = cleanHtml ? HTMLCleaner.clean(value) : '';
      } else if (attributeName === 'sidebarTop' || attributeName === 'sidebarBottom') {
        categoryState[attributeName] = value.map((item) => item[locale]);
      } else {
        categoryState[attributeName] = value;
      }
    });
  }

  const path = ancestors
    .map((ancestor) => ancestor.custom?.customFieldsRaw?.find((f) => f.name === 'urlName')?.value)
    .join('/');
  categoryState.path = `${path}/${categoryState.urlName}/`;

  return categoryState;
};

export default {
  buildCategoryStateObject,
  buildProductStateObject,
  buildVariantObject,
  flattenAttributes,
  getAttributeValue,
  groupVariants,
};
