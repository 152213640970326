<template>
  <Modal
    data-test="gift-details-modal"
    :isOpen="isOpen"
    :hasControls="false"
    width="h-5/6"
    @handle-close="handleClose"
  >
    <template v-slot:header>
      <h3 class="text-left">Gift Details</h3>
    </template>
    <template v-slot:body>
      <div class="divide-y-2 divide-solid divide-true-gray">
        <div class="py-4 md:py-6">
          <h4 class="mb-4 text-lg md:text-xl">eCard Style</h4>
          <OccasionFormSummary :summary="occasionSummary" />
        </div>
        <div class="py-4 md:py-6">
          <h4 class="mb-4 text-lg md:text-xl">Recipient Info</h4>
          <RecipientsSummary :summary="recipientsSummary" />
        </div>
        <div class="py-4 md:py-6">
          <h4 class="mb-4 text-lg md:text-xl">Delivery Method</h4>
          <p>
            eCard and gift notifications will be sent
            <span class="font-semibold">{{ calculateSelectedDate }}</span>
          </p>
          <p>
            Your gifts will be delivered via Ground Shipping after your recipients redeem their gift
          </p>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { computed, defineComponent, PropType } from 'vue';

import Modal from '@/components/base/layout/Modal.vue';
import OccasionFormSummary from '@/components/digital-gift/gifter/occasion/OccasionFormSummary.vue';
import RecipientsSummary from '@/components/digital-gift/gifter/recipients/RecipientsSummary.vue';
import { DigitalGiftRecipient } from '@/utils/cart';
import { calculateDaysBetweenDates, DateString } from '@/utils/dateTime';

interface DigitalGiftingFields {
  occasionImageUrl: string;
  senderName: string;
  sendAt: Date;
  recipients: DigitalGiftRecipient[];
}

export default defineComponent({
  name: 'GiftDetailsModal',
  props: {
    isOpen: { required: true, type: Boolean, default: false },
    digitalGiftingFields: { required: true, type: Object as PropType<DigitalGiftingFields> },
  },
  components: {
    Modal,
    OccasionFormSummary,
    RecipientsSummary,
  },
  emits: ['close-details'],
  setup(props, { emit }) {
    const occasionSummary = computed(() => ({
      imageUrl: props.digitalGiftingFields.occasionImageUrl,
      from: props.digitalGiftingFields.senderName,
    }));

    const recipientsSummary = computed(() => props.digitalGiftingFields.recipients);

    const calculateSelectedDate = computed(() => {
      let selectedDate;
      const arrivalDate = dayjs(props.digitalGiftingFields.sendAt, 'YYYY-MM-DD');
      const todaysDate = dayjs().format('YYYY-MM-DD') as DateString;
      const daysfromArrival = calculateDaysBetweenDates(
        todaysDate,
        arrivalDate.format('YYYY-MM-DD') as DateString,
      );

      switch (daysfromArrival) {
        case 0:
          selectedDate = 'immediately';
          break;
        case 1:
          selectedDate = `Tomorrow at ${dayjs(props.digitalGiftingFields.sendAt).format(
            'h:mm A',
          )} EST`;
          break;
        default:
          selectedDate = `${arrivalDate.format('dddd')}, ${arrivalDate.format('MMM D')} at ${dayjs(
            props.digitalGiftingFields.sendAt,
          ).format('h:mm A')} EST`;
          break;
      }
      return selectedDate;
    });

    return {
      calculateSelectedDate,
      handleClose: () => emit('close-details'),
      occasionSummary,
      recipientsSummary,
    };
  },
});
</script>
