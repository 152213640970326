<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints, useCurrentElement } from '@vueuse/core';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import ThemedButton from '@/components/base/ThemedButton.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import overlayScrollLock from '@/utils/overlayScrollLock';

const props = withDefaults(
  defineProps<{
    anchor?: 'center' | 'mobile-bottom' | 'right';
    backgroundColor?: string;
    borderRadius?: string;
    disableConfirmButton?: boolean;
    hasControls?: boolean;
    hasDefaultPadding?: boolean;
    isLoading?: boolean;
    isOpen: boolean;
    skipFirstElementOnFocus?: boolean;
    width?: string;
  }>(),
  {
    anchor: 'center',
    backgroundColor: 'bg-white',
    borderRadius: '',
    disableConfirmButton: false,
    hasControls: true,
    hasDefaultPadding: true,
    isLoading: false,
    isOpen: false,
    width: '',
  },
);

const emit = defineEmits(['handle-cancel', 'handle-close', 'handle-confirm']);

const handleCancel = () => emit('handle-cancel');
const handleClose = () => emit('handle-close');
const handleConfirm = () => emit('handle-confirm');

const isMobile = useBreakpoints(breakpointsTailwind).smaller('lg');

const focusableElements = ref<NodeListOf<HTMLElement> | HTMLElement[]>([]);
const initiatedElement = ref<HTMLElement>();
const isScrollLocked = ref(false);
const modalContainer = useCurrentElement<Element>();
const mutationObserver = ref<MutationObserver>();

const firstFocusableElement = computed(() => focusableElements.value[0]);
const lastFocusableElement = computed(
  () => focusableElements.value[focusableElements.value.length - 1],
);

const closeIcon = nutshell['img/close.svg'];
const customCssClass = [props.backgroundColor, props.borderRadius, props.width].join(' ');

const handleForwardKeyTab = (event: Event) => {
  if (lastFocusableElement.value === document.activeElement) {
    event.preventDefault();
    firstFocusableElement.value?.focus();
  }
};

const handleBackwardKeyTab = (event: Event) => {
  if (firstFocusableElement.value === document.activeElement) {
    event.preventDefault();
    lastFocusableElement.value?.focus();
  }
};

const refreshFocusableElements = () => {
  try {
    focusableElements.value =
      modalContainer.value.querySelectorAll<HTMLElement>(
        `button:not([disabled]), [href], input:not([disabled]),
          select:not([disabled]), textarea:not([disabled]):not([name="g-recaptcha-response"]),
          [tabindex]:not([tabindex="-1"]):not([disabled]),
          details:not([disabled]), summary:not(:disabled)`,
      ) ?? [];
  } catch (e) {
    focusableElements.value = [];
  }
};

const onModalShown = () => {
  if (isMobile.value) window.Kustomer?.stop();

  if (!initiatedElement.value) {
    initiatedElement.value = document.activeElement as HTMLElement;
  }
  setTimeout(() => {
    isScrollLocked.value = true;
    overlayScrollLock.lockOnOpen();

    refreshFocusableElements();
    const observer = new MutationObserver(refreshFocusableElements);
    observer.observe(modalContainer.value, {
      childList: true,
      subtree: true,
    });
    mutationObserver.value = observer;

    const elementToFocus = props.skipFirstElementOnFocus
      ? focusableElements.value[1]
      : firstFocusableElement.value;
    elementToFocus?.focus();
  }, 50);
};

onMounted(() => {
  overlayScrollLock.bindScrollListener();
  if (props.isOpen) onModalShown();
});

onBeforeUnmount(() => {
  if (isScrollLocked.value) overlayScrollLock.unlockOnClose();
  window.Kustomer?.start();
  overlayScrollLock.unbindScrollListener();
  mutationObserver.value?.disconnect();
});

watch(
  () => props.isOpen,
  (val) => {
    if (val) onModalShown();
    else {
      window.Kustomer?.start();
      overlayScrollLock.unlockOnClose();
      mutationObserver.value?.disconnect();
      isScrollLocked.value = false;
      initiatedElement.value?.focus();
    }
  },
);
</script>

<template>
  <div
    v-if="isOpen"
    aria-modal="true"
    class="fixed inset-0 z-50 flex justify-center bg-black/40"
    :class="{
      'items-end sm:items-center': anchor === 'mobile-bottom',
      'items-center': anchor === 'center',
      'items-end md:items-stretch md:justify-end': anchor === 'right',
    }"
    role="dialog"
    @keydown.exact.tab="handleForwardKeyTab"
    @keydown.shift.tab="handleBackwardKeyTab"
    @mousedown.self="handleClose"
  >
    <div
      :class="[
        customCssClass,
        {
          'anchor-bottom-height': anchor === 'mobile-bottom',
          'custom-height': anchor === 'center',
          'right-anchor-height': anchor === 'right',
        },
      ]"
      class="flex-col overflow-auto border border-gray-900 shadow-2xl"
    >
      <div class="sticky top-0 right-0 z-50 flex justify-end text-right">
        <slot name="close-button">
          <UnstyledButton
            class="absolute flex items-center justify-center w-6 h-6 overflow-hidden rounded top-4 right-4"
            @click="handleClose"
          >
            <img
              alt="close"
              class="h-3.5 w-3.5 md:w-4 md:h-4 shrink-0"
              data-test="close-modal"
              :src="closeIcon"
            />
          </UnstyledButton>
        </slot>
      </div>
      <div :class="{ 'px-4 py-6 sm:px-6': hasDefaultPadding }">
        <div :class="{ 'mt-4': hasDefaultPadding }">
          <slot name="header" />
        </div>

        <div>
          <slot name="body" />
        </div>

        <slot name="modal-controls">
          <div v-if="hasControls" class="flex justify-between mt-6 md:mt-8">
            <slot name="cancel-button">
              <ThemedButton
                class="mr-4 md:w-40"
                theme="white"
                type="button"
                @click="handleCancel"
                data-test="cancel"
              >
                <slot name="cancel-label">Cancel</slot>
              </ThemedButton>
            </slot>

            <slot name="confirm-button">
              <ThemedButton
                class="md:w-40"
                :disabled="disableConfirmButton"
                :isLoading="isLoading"
                theme="gray"
                type="button"
                @click="handleConfirm"
                data-test="confirm"
              >
                <slot name="confirm-label">Save</slot>
              </ThemedButton>
            </slot>
          </div>
        </slot>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.anchor-bottom-height {
  max-height: 80vh;
}

.custom-height {
  max-height: 100vh;

  @media screen and (min-width: 768px) {
    max-height: 80vh;
  }
}

.right-anchor-height {
  max-height: 80vh;
  @media screen and (min-width: 768px) {
    max-height: 100vh;
  }
}

.signin-modal-custom-width {
  width: 424px;
}
</style>
