<template>
  <div :class="{ 'opacity-50 pointer-events-none': isLoading }">
    <transition name="fade">
      <slot name="header" :title="isCheckout ? 'Proceed to Checkout' : 'Sign in'">
        <component :is="headerTag" class="mb-0 text-xl font-bold sm:text-2xl font-sofia-pro">
          {{ isCheckout ? 'Proceed to Checkout' : 'Sign up' }}
        </component>
      </slot>
    </transition>
    <transition mode="out-in" name="fade">
      <div>
        <p v-if="email" aria-live="polite" class="mt-6 text-xs font-semibold sm:text-base">
          Looks like you don't have an account with us, follow the steps below to create an account.
        </p>
        <div class="flex w-full mt-6 space-between" aria-label="Account Type" role="radiogroup">
          <Radio
            v-model="user.isB2b"
            aria-label="Personal"
            class="mr-6 text-sm font-normal sm:text-base"
            name="account-type"
            :value="false"
          >
            Personal Account
          </Radio>
          <Radio
            v-model="user.isB2b"
            aria-label="Business"
            class="text-sm font-normal sm:text-base"
            name="account-type"
            :value="true"
          >
            Business Account
          </Radio>
        </div>
        <SeparatorWithText class="mt-5">Sign up with email</SeparatorWithText>
        <div :class="{ 'opacity-50 pointer-events-none': isLoading }">
          <Form v-slot="{ meta: formMeta }" @submit="onSubmit">
            <fieldset>
              <transition name="fade">
                <div
                  v-if="signUserUp.error"
                  v-html="signUserUp.error.message"
                  class="mt-4 font-semibold md:mt-7 text-error"
                />
              </transition>
              <span v-if="!email" class="relative">
                <FormInput
                  v-model="user.email"
                  class="mt-4 md:mt-7"
                  :inputAttributes="formInputAttributes.email"
                  showLabel
                  :validator="rules.email"
                />
                <EmailTypoSuggestion v-model="user.email" />
              </span>
              <EmailDisplay v-else class="mt-5" :email="email" @change-email="changeEmail" />
              <FormInput
                v-model="user.name"
                class="mt-6"
                :inputAttributes="formInputAttributes.name"
                showLabel
                :validator="rules.name"
              />
              <FormInput
                v-model="user.password"
                class="w-full mt-6"
                :helperText="errorMessages.password.min.replace('{length}', '6')"
                :inputAttributes="formInputAttributes.password"
                showLabel
                :validator="rules.password"
              >
                <template #fallbackIcon>
                  <img
                    class="w-4 cursor-pointer opacity-60"
                    alt="hide password"
                    aria-hidden="true"
                    :src="showPassword ? hidePasswordSvg : showPasswordSvg"
                    @click="toggleShowPassword"
                  />
                </template>
              </FormInput>
              <Checkbox
                v-model="options.keepSignedIn"
                class="inline-flex mt-8 text-sm font-normal sm:text-base"
              >
                Remember me
              </Checkbox>
              <ThemedButton
                class="mt-6 sm:h-12 h-11"
                :disabled="validatorFailed(formMeta) ?? false"
                fullWidth
                :isLoading="isLoading"
                theme="gray"
                type="submit"
                data-test="create-account"
              >
                Create Account
              </ThemedButton>
            </fieldset>
          </Form>
        </div>
        <SeparatorWithText class="mt-5">Sign in or sign up with social account</SeparatorWithText>
        <GoogleSignInButton class="mt-5" fullWidth @signed-in="onSignIn" />
        <FacebookSignInButton class="mt-4" fullWidth @signed-in="onSignIn" />
        <AppleSignInButton class="mt-4" fullWidth @signed-in="onSignIn" />
        <div class="flex items-center justify-center mt-4">
          <p class="text-base font-semibold">Already have an account?</p>
          <button class="p-0 ml-1 bg-transparent border-none cursor-pointer" @click="changeEmail">
            <span
              class="text-sm font-semibold underline text-cyan-700 font-proxima-nova sm:text-base"
              data-test="sign-in"
            >
              Sign In
            </span>
          </button>
        </div>
        <p class="mt-4 text-xs sm:text-sm">
          By clicking on Continue with Google, Facebook or Apple, you represent that you are 18+
          years of age and have read and agreed to the ixclkxk.shop
          <RouteLink class="underline" to="/terms-and-conditions">Terms &amp; Conditions</RouteLink
          >,
          <RouteLink class="underline" to="/privacy">Privacy Policy</RouteLink>
          and
          <RouteLink class="underline" to="/california-privacy-notice">CA Privacy Notice</RouteLink
          >. ixclkxk.shop may send you communications. You may change your preferences in your account
          preferences at any time.
        </p>
      </div>
    </transition>
    <Captcha ref="captcha" @verify="signUserUp.execute" />
  </div>
</template>

<script lang="ts">
import { email, min, required } from '@vee-validate/rules';
import { Form } from 'vee-validate';
import { computed, defineComponent, PropType, reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';

import { WebstoreSignUpCustomer, WebstoreSignUpOptions } from '@/api/customer';
import Checkbox from '@/components/base/form/Checkbox.vue';
import EmailTypoSuggestion from '@/components/base/form/EmailTypoSuggestion.vue';
import FormInput, { InputAttributes } from '@/components/base/form/FormInput.vue';
import Radio from '@/components/base/form/Radio.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import SeparatorWithText from '@/components/base/SeparatorWithText.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import EmailDisplay from '@/components/login/EmailDisplay.vue';
import AppleSignInButton from '@/components/social/AppleSignInButton.vue';
import Captcha, { CaptchaPublicInstance } from '@/components/social/Captcha.vue';
import FacebookSignInButton from '@/components/social/FacebookSignInButton.vue';
import GoogleSignInButton from '@/components/social/GoogleSignInButton.vue';
import { useAuth } from '@/composables/useAuth';
import { useCallback } from '@/composables/useCallback';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';
import { TypographyTags } from '@/utils/accessibility';

type SignUpData = Omit<WebstoreSignUpCustomer, 'isB2b'>;

export default defineComponent({
  name: 'SignUpForm',
  props: {
    email: { required: false, type: String },
    headerTag: { required: false, type: String as PropType<TypographyTags>, default: 'span' },
    selectBusiness: { required: false, type: Boolean, default: false },
    isCheckout: { required: false, type: Boolean, default: false },
  },
  components: {
    AppleSignInButton,
    Captcha,
    Checkbox,
    EmailDisplay,
    EmailTypoSuggestion,
    FacebookSignInButton,
    Form,
    FormInput,
    GoogleSignInButton,
    Radio,
    RouteLink,
    SeparatorWithText,
    ThemedButton,
  },
  emits: ['change-email', 'password-visibility', 'signed-in'],
  setup(props, { emit }) {
    const store = useStore();
    const { signUp } = useAuth(store);
    const { errorMessages, validatorFailed } = useForm();

    const captcha = ref<CaptchaPublicInstance>();
    const passwordInput = ref<{ $el: HTMLElement }>();
    const isCaptchaPending = ref(false);
    const captchaResponse = ref('');

    const state = reactive<{
      showPassword: boolean;
      user: WebstoreSignUpCustomer;
      options: Required<WebstoreSignUpOptions>;
    }>({
      showPassword: false,
      user: {
        email: props.email ?? '',
        isB2b: props.selectBusiness,
        name: '',
        password: '',
      },
      options: {
        keepSignedIn: true,
        optInNewsletter: true,
        preserveCart: false,
        attachGuestOrders: true,
      },
    });

    const formInputAttributes = computed<Record<keyof SignUpData, InputAttributes>>(() => ({
      email: {
        autocomplete: 'username',
        name: 'email',
        placeholder: 'Email',
        type: 'email',
      },
      name: {
        autocomplete: 'name',
        name: 'name',
        placeholder: 'Full Name',
        type: 'text',
      },
      password: {
        autocomplete: 'current-password',
        name: 'password',
        placeholder: 'Password',
        type: state.showPassword ? 'text' : 'password',
      },
    }));

    const rules: Record<keyof SignUpData, FlexibleMessageValidator<string>> = {
      email: (value) => {
        if (props.email) return true;
        if (!required(value)) return errorMessages.email.required;
        if (!email(value)) return errorMessages.email.email;
        return true;
      },
      name: (value) => required(value) || errorMessages.name.required,
      password: (value) => {
        if (!required(value)) return errorMessages.password.required;
        if (!min(value, [6])) return errorMessages.password.min.replace('{length}', '6');
        return true;
      },
    };

    const toggleShowPassword = () => {
      state.showPassword = !state.showPassword;
      emit('password-visibility', { visible: state.showPassword });
    };

    const onSignIn = () => emit('signed-in', { isBusinessAccount: state.user.isB2b });

    const signUserUp = useCallback(async (response: string) => {
      if (!response) return;
      captchaResponse.value = response;

      const customer = await signUp(state.user, captchaResponse.value);
      if (customer) onSignIn();
    });

    const onSubmit = () => {
      if (captchaResponse.value) {
        captcha.value?.reset();
        captchaResponse.value = '';
      }
      captcha.value?.execute();
    };

    const isLoading = computed(() => isCaptchaPending.value || signUserUp.isPending);

    return {
      captcha,
      changeEmail: () => emit('change-email'),
      errorMessages,
      formInputAttributes,
      hidePasswordSvg: nutshell['img/hide_password.svg'],
      isLoading,
      onSignIn,
      onSubmit,
      passwordInput,
      rules,
      signUserUp,
      showPasswordSvg: nutshell['img/show_password.svg'],
      toggleShowPassword,
      validatorFailed,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
p {
  @apply mb-0;
}
</style>
