<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { desktopAttribution, SmallMenu } from '@/utils/navMenu';

const emit = defineEmits<{
  (e: 'update:showSubMenu', value: boolean): void;
}>();
const props = defineProps<{ menu: SmallMenu; showSubMenu: boolean }>();
const showSubMenu = useVModel(props, 'showSubMenu', emit);

const linksContainer = ref<HTMLElement>();
// necessary for parent component keyboard accessibility ref list
defineExpose({ linksContainer });
useRouterLinks(linksContainer, useRouter());
</script>

<template>
  <nav
    class="min-w-max max-w-xs pt-2 pb-1.5 bg-white border-b border-solid border-nuts-neutral-200 rounded-md"
    ref="linksContainer"
  >
    <ul
      v-for="({ group }, gi) in menu.linkGroups"
      :key="group.headerText || gi"
      :class="{ 'mt-8': gi > 0 }"
    >
      <li v-if="group.headerText" class="font-semibold">
        <a
          v-if="group.headerUrl"
          :href="group.headerUrl"
          class="text-base promo-link-styles"
          @click="showSubMenu = false"
          v-bind="desktopAttribution(menu, group.headerText)"
          :data-test="`sub-nav-item-${group.headerText}`"
        >
          {{ group.headerText }}
        </a>
        <span v-else class="text-base">
          {{ group.headerText }}
        </span>
      </li>
      <li v-for="linkField in group.links" :key="linkField.link.url">
        <a
          :href="linkField.link.url"
          class="block px-4 py-2 text-base hover:bg-background-secondary no-link-styles"
          :class="{ 'font-semibold promo-link-styles': linkField.emphasized }"
          @click="showSubMenu = false"
          v-bind="desktopAttribution(menu, group.headerText, linkField.link.text)"
          :data-test="`sub-nav-item-${group.headerText}`"
        >
          {{ linkField.link.text }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.no-link-styles {
  @apply text-neutral-600 hover:no-underline focus:no-underline cursor-pointer;
}

.promo-link-styles {
  @apply text-black text-base hover:underline hover:font-semibold decoration-yellow decoration-2 underline-offset-4;
}
</style>