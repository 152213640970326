<template>
  <div>
    <h2 v-if="gridTitle" class="text-xl md:text-3xl font-sofia-pro">{{ gridTitle }}</h2>
    <div
      class="grid gap-4 mt-7"
      :class="[
        numOfColumns <= 3 ? 'grid-cols-1' : 'grid-cols-2',
        {
          'sm:grid-cols-3': numOfColumns === 3,
          'sm:grid-cols-4': numOfColumns === 4,
          'sm:grid-cols-5': numOfColumns === 5,
        },
      ]"
    >
      <CategoryCard v-for="card in cards" :key="card.title" v-bind="card" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import CategoryCard from '@/components/base/layout/CmsCategoryCard.vue';
import { CmsRegisteredComponent } from '@/utils/cms';

interface Card {
  callToActionText: string;
  description: string;
  imgSrc: string;
  link: string;
  title: string;
}

const CardGrid = defineComponent({
  name: 'CardGrid',
  props: {
    cards: { required: false, type: Array as PropType<Card[]> },
    gridTitle: { required: false, type: String },
    numOfColumns: { required: false, type: Number, default: 4 },
  },
  components: {
    CategoryCard,
  },
});

export const CardGridRegistration: CmsRegisteredComponent = {
  component: CardGrid,
  name: 'Card Grid',
  inputs: [
    {
      name: 'gridTitle',
      type: 'string',
      defaultValue: 'Gifts for Everyone',
    },
    {
      name: 'numOfColumns',
      type: 'number',
      defaultValue: 4,
    },
    {
      name: 'cards',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'description',
          type: 'string',
        },
        {
          name: 'callToActionText',
          type: 'string',
        },
        {
          name: 'link',
          type: 'string',
        },
        {
          name: 'imgSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
        },
      ],
    },
  ],
};

export default CardGrid;
</script>
