<template>
  <Component :is="tagName" data-test="dy-client-side-renderer" v-once>
    <slot></slot>
  </Component>
</template>

<script>
export default {
  name: 'DyClientSideRenderer',
  props: {
    tagName: { type: String, required: true },
  },
};
</script>
