<script setup lang="ts">
import { useEventListener, useResizeObserver } from '@vueuse/core';
import { computed, nextTick, onMounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    announcements?: { htmlAnnouncements: string; codeAnnouncements?: string }[];
  }>(),
  {
    announcements: () => [{ htmlAnnouncements: 'Get Free Shipping on orders over $59' }],
  },
);

const scrollContainer = ref<HTMLElement>();
const announcementList = ref(props.announcements);
const showButtons = computed(() => props.announcements.length > 1);
let carouselIndex = 0;
const amount = computed(() => announcementList.value?.length || 0);
const currTransl: number[] = [];
let translationComplete = true;
const moveOffset = 340;
const transitionCompleted = () => {
  translationComplete = true;
};
let touchendX = 0;
let touchstartX = 0;

const scrollByDirection = (direction: 'next' | 'prev') => {
  if (translationComplete === true) {
    translationComplete = false;
    if (direction === 'prev') {
      carouselIndex -= 1;
    }
    if (carouselIndex === -1 && direction === 'prev') {
      carouselIndex = amount.value - 1;
    }
    const outerIndex = carouselIndex % amount.value;
    if (direction === 'next') {
      carouselIndex += 1;
    }
    announcementList.value?.forEach((_, i) => {
      // moveOffset with the slider direction
      const innerMoveOffset = (direction === 'next' ? -1 : 1) * moveOffset;
      const slide = document.getElementsByClassName('announcement-slide')[i] as HTMLElement;
      slide.style.opacity = '1';
      slide.style.transform = `translateX(${currTransl[i] + innerMoveOffset}px)`;
      currTransl[i] += innerMoveOffset;
    });
    const outerMoveOffset = (direction === 'next' ? 1 : -1) * moveOffset * amount.value;
    const outerSlide = document.getElementsByClassName('announcement-slide')[
      outerIndex
    ] as HTMLElement;
    outerSlide.style.transform = `translateX(${currTransl[outerIndex] + outerMoveOffset}px)`;
    // hide carousel card when moving to the end
    outerSlide.style.opacity = '0';
    currTransl[outerIndex] += outerMoveOffset;
  }
};
let autoCarousel: ReturnType<typeof setTimeout>;
const prev = () => {
  clearInterval(autoCarousel);
  scrollByDirection('prev');
};

const next = () => {
  clearInterval(autoCarousel);
  scrollByDirection('next');
};

const handleScroll = () => {
  scrollByDirection('next');
};

const handleGesture = () => {
  if (props.announcements.length <= 1) return;
  const xDiff = touchendX - touchstartX;
  if (xDiff > 0) {
    prev();
  } else {
    next();
  }
};

onMounted(async () => {
  useEventListener(scrollContainer, 'touchstart', () => {
    clearInterval(autoCarousel);
  });
  // handle first carousel shift on mount
  const root = document.querySelector(':root') as HTMLElement;
  if (props.announcements.length > 1) {
    clearInterval(autoCarousel);
    autoCarousel = setInterval(handleScroll, 8000);
    root?.style.setProperty('--moveOffset', `-${moveOffset}px`);
  } else {
    root?.style.setProperty('--moveOffset', '0px');
  }

  useEventListener(scrollContainer, 'touchstart', (event: TouchEvent) => {
    touchstartX = event.changedTouches[0].screenX;
  });

  useEventListener(scrollContainer, 'touchend', (event: TouchEvent) => {
    touchendX = event.changedTouches[0].screenX;
    handleGesture();
  });

  // handle when there are two announcements
  if (props.announcements?.length === 2) {
    announcementList.value = [...props.announcements, ...props.announcements];
  } else {
    announcementList.value = props.announcements;
  }

  await nextTick();
  if (scrollContainer.value) {
    // prevent multiple click when transition
    announcementList.value?.forEach((_, i) => {
      currTransl[i] = -moveOffset;
      const slide = document.getElementsByClassName('announcement-slide')[i];
      ['transitionend', 'webkitTransitionEnd', 'oTransitionEnd', 'MSTransitionEnd'].forEach(
        (eventName) => {
          slide?.addEventListener(eventName, transitionCompleted, true);
        },
      );
    });
  }
});
</script>

<template>
  <div class="flex items-center h-full w-fit w-96">
    <button
      v-if="showButtons"
      class="hidden bg-transparent border-none cursor-pointer lg:flex"
      @click="prev"
    >
      <img src="@/assets/chevron_left.svg" alt="chevron" />
    </button>
    <div class="w-full overflow-hidden announcement-slider w-fit" id="carousel-container">
      <div class="flex flex-row" ref="scrollContainer">
        <div
          v-for="(announcement, i) in announcementList"
          class="flex justify-center text-sm font-semibold text-white announcement-slide animate w-80"
          :key="i"
          tabindex="0"
          data-test="announcement-slide"
        >
          <div
            v-html="
              announcement.codeAnnouncements
                ? announcement.codeAnnouncements
                : announcement.htmlAnnouncements
            "
            class="mb-0"
          />
        </div>
      </div>
    </div>
    <button
      v-if="showButtons"
      class="hidden bg-transparent border-none cursor-pointer lg:flex"
      @click="next"
    >
      <img src="@/assets/chevron_right.svg" alt="chevron" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
:deep(p) {
  @apply mb-0;
}
.announcement-slider {
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 340px;
}

.hidden {
  visibility: visible;
}

.announcement-slide {
  scroll-snap-align: center;
  min-width: 340px;
  max-width: 340px;
  transform: translateX(var(--moveOffset));
}

.animate {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
}
</style>
