import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

import { FeatureFlagSampler } from './utils/FeatureFlagSampler';

function init() {
  const collectorOptions = {
    url: '/v1/traces',
    headers: {},
    concurrencyLimit: 10,
    serviceName: 'webfront-client',
  };

  const exporter = new OTLPTraceExporter(collectorOptions);

  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'webfront-browser',
    }),
    sampler: new FeatureFlagSampler(),
  });

  provider.addSpanProcessor(
    new BatchSpanProcessor(exporter, {
      maxQueueSize: 300,
      maxExportBatchSize: 100,
      scheduledDelayMillis: 500,
      exportTimeoutMillis: 30000,
    }),
  );

  provider.register({
    contextManager: new ZoneContextManager(),
    propagator: new W3CTraceContextPropagator(),
  });

  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-xml-http-request': {
          clearTimingResources: true,
          ignoreUrls: [/^https:\/\/bam.nr-data.net\/.*/],
          propagateTraceHeaderCorsUrls: [/^https:\/\/.*.execute-api.us-east-1.amazonaws.com\/prod/],
        },
        '@opentelemetry/instrumentation-fetch': {
          ignoreUrls: [/^https:\/\/bam.nr-data.net\/.*/],
          propagateTraceHeaderCorsUrls: [/^https:\/\/.*.execute-api.us-east-1.amazonaws.com\/prod/],
        },
      }),
    ],
  });
}

init();
