<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import RouteLink from '@/components/base/RouteLink.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCart } from '@/composables/useCart';
import { useChat } from '@/composables/useChat';
import { useCheckout } from '@/composables/useCheckout';
import { useCustomer } from '@/composables/useCustomer';
import { useLoginModal } from '@/composables/useLoginModal';
import detectIE from '@/utils/browser';

defineProps<{ showMiniCart?: boolean }>();
const emit = defineEmits(['toggle-mini-cart', 'toggle-mobile-menu', 'toggle-mobile-search']);

const store = useStore();

const { lineItems } = useCart(store);
const { displayChatInfoText, initChat, isChatStatusPending, openChat } = useChat();
const { cxMode } = useCheckout(store);
const { customer } = useCustomer(store);
const { handleOpen } = useLoginModal();
const { navigateTo } = useRouteChange(useRouter());

const userFirstName = computed<string>(() => store.state.headerModule.userFirstName);

const toggleMiniCart = () => {
  const isIE = detectIE();
  if (isIE !== false && isIE > 11 && isIE <= 18) {
    window.location.replace('/cart');
  } else {
    emit('toggle-mini-cart');
  }
};

const toggleMobileMenu = () => {
  emit('toggle-mobile-menu');
};

const toggleMobileSearch = () => {
  emit('toggle-mobile-search');
};

onMounted(() => {
  initChat();
});

const requireUser = (event: MouseEvent) => {
  const element = event.target as HTMLElement | null;
  const destination = element?.getAttribute('href') ?? undefined;

  if (customer.value?.id || cxMode.value) {
    if (!destination) return;
    navigateTo(destination);
  } else {
    handleOpen({ destination });
  }
};
</script>

<template>
  <div class="top-shelf" data-test="top-shelf">
    <div class="container" role="navigation" aria-label="Top shelf navigation">
      <a
        href="#skiptocontent"
        class="absolute text-blue-500 sr-only not-sr-only-focus focus:absolute focus:left-5 focus:top-1 left-5 top-1 focus:bg-white focus:text-red-800"
        >Skip to main content</a
      >
      <button class="cat-nav-toggler visible-xs" @click.prevent="toggleMobileMenu">
        <i />
        <span>Shop</span>
      </button>
      <template v-if="!isChatStatusPending">
        <div v-if="displayChatInfoText" class="float-left" data-test="chat-text">
          <span class="order hidden-xs">Order online or via </span>
          <span class="underline cursor-pointer color-inherit hidden-xs" @click="openChat">
            live chat
          </span>
        </div>
        <div v-else class="phone" data-test="phone">
          <span class="order hidden-xs">Order online or call</span>
          <span class="telephone" itemprop="telephone" data-test="telephone">
            <a class="tel" href="tel:+1-800-558-6887">800-558-6887</a>
          </span>
        </div>
      </template>
      <ul id="trk-persistent-nav" class="persistent-nav" data-test="persistent-nav">
        <li class="search hidden-sm hidden-md hidden-lg">
          <a
            href="/search"
            aria-label="Search"
            data-test="search"
            class="search-toggler"
            @click.prevent="toggleMobileSearch"
          >
            <i />
            <span class="hidden-xs">Search</span>
          </a>
        </li>
        <li class="cart" data-test="cart">
          <div
            @click="toggleMiniCart"
            @keydown.enter="toggleMiniCart"
            tabindex="0"
            :aria-expanded="showMiniCart"
            class="cursor-pointer hidden-xs cart-top-shelf"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Utility Links: Your Cart"
            role="button"
            aria-label="cart count"
            aria-haspopup="true"
          >
            <i />
            <span class="badge" v-if="lineItems.length">{{ lineItems.length }}</span>
            <span class="label">Your Cart</span>
          </div>
          <RouteLink to="/cart" aria-label="cart count" class="visible-xs">
            <i />
            <span class="badge" v-if="lineItems.length">{{ lineItems.length }}</span>
          </RouteLink>
        </li>
        <li class="easy-reorder hidden-xs hidden-sm hidden-md" data-test="easy-order">
          <a
            href="/account/easy-reorder"
            @click.prevent="requireUser"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Utility Links: Easy Reorder"
          >
            <i />
            Easy Reorder
          </a>
        </li>
        <li class="help hidden-xs hidden-sm" data-test="help">
          <a
            href=""
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Utility Links: Need Help?"
          >
            <i />
            <span>Need help?</span>
          </a>
        </li>
        <li class="hidden-xs hidden-sm hidden-md">
          <a
            href="/business-signup"
            data-promo="1"
            data-promo-name="Header Links"
            data-promo-creative="Utility Links: Nuts for Business"
          >
            <img
              src="@/assets/white-lightbulb.svg"
              class="mb-1 mr-1 w-7"
              alt="Nuts for business icon"
            />
            <span>Nuts For Business</span>
          </a>
        </li>
        <li class="user hidden-xs" data-test="user">
          <template v-if="userFirstName">
            Hi
            <a href="/account" @click.prevent="requireUser">
              <i />
              {{ userFirstName }}
            </a>
            •
            <a class="g_id_signout" href="/sign/out">Sign out</a>
          </template>
          <template v-else>
            <a
              href=""
              @click.prevent="requireUser"
              data-test="sign-in-link"
              data-promo="1"
              data-promo-name="Header Links"
              data-promo-creative="Utility Links: Sign in to your account"
            >
              <i />
              Sign in to your account
            </a>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cart {
  i {
    @include retina-sprite($sprite-cart-white-1x);
  }

  .badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    border-radius: 10px;
    background-color: $topshelf-badge-color;
    position: relative;
    left: -12px;
    margin-right: -10px;
    top: -1px;
    @include respond-max($screen-xs-max) {
      margin-right: 0;
    }
  }
}
.cat-nav-toggler {
  float: left;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 10px 0;
  background-color: transparent;
  position: relative;
  z-index: 2;
  i {
    display: block;
    float: left;
    @include retina-sprite($sprite-triple-bars-white-1x);
  }
  span {
    display: block;
    float: left;
    color: white;
    line-height: 22px;
    font-size: 16px;
    padding-left: 10px;
  }
}
.easy-reorder {
  i {
    @include retina-sprite($sprite-easy-reorder-icon-1x);
  }
}
.flag {
  height: 20px;
  margin: -3px 5px 0 0;
}
.help {
  i {
    @include retina-sprite($sprite-question-mark-white-1x);
  }
}
.not-sr-only-focus {
  @apply focus:not-sr-only;
  position: absolute !important;
}
.persistent-nav {
  li {
    float: right;
    position: relative;
    margin-left: 30px;
    font-size: 14px;
    @include respond-max($screen-xs-max) {
      margin-left: 20px;
    }
  }
  i {
    float: left;
    margin: 10px 6px 0 0;
  }
  :deep(a),
  .cart-top-shelf {
    color: $link-on-dark-color;
    font-size: 14px;
  }
}
.phone {
  float: left;
  .telephone {
    padding-left: 8px;
    font-size: 16px;
    font-weight: bold;
  }
  @media screen and (max-width: $screen-xs-max) {
    float: none;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    .telephone {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.25px;
      padding-left: 0;
    }
  }
}
.search {
  i {
    @include retina-sprite($sprite-magnifier-white-1x);
  }
}
.top-shelf {
  background: $topshelf-background-color;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 12;
  @include respond-max($screen-xs-max) {
    top: 36px;
  }

  > div:first-child {
    height: $topshelf-height;
    color: $topshelf-text-color;
    line-height: $topshelf-height;
  }
}
.user {
  i {
    @include retina-sprite($sprite-tiny-peanut-white-1x);
  }
}
</style>
