<script setup lang="ts">
import { ref } from 'vue';

import Collapse from '@/components/base/layout/Collapse.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';

const props = defineProps<{ defaultExpanded?: boolean }>();

const isOpen = ref(!!props.defaultExpanded);
</script>

<template>
  <div>
    <UnstyledButton
      :aria-expanded="`${isOpen}`"
      class="flex justify-between px-4 py-2.5 hover:bg-background-secondary w-full"
      @click="isOpen = !isOpen"
    >
      <slot name="trigger" />
      <img
        v-show="!isOpen"
        :aria-hidden="isOpen"
        src="@/assets/add-sm.svg"
        alt="open submenu plus icon"
        class="h-6"
      />
      <img
        v-show="isOpen"
        :aria-hidden="!isOpen"
        src="@/assets/remove-sm.svg"
        alt="close submenu minus icon"
        class="h-6"
      />
    </UnstyledButton>
    <Collapse :expanded="isOpen">
      <slot />
    </Collapse>
  </div>
</template>
