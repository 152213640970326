import { Input } from '@builder.io/sdk';
import { computed, PropType, UnwrapNestedRefs } from 'vue';

export type SpacingDirection = 'bottom' | 'top' | 'vertical' | undefined;
export type SpacingLevel = 'xl' | 'lg' | 'md' | 'none' | 'sm' | 'xs' | undefined;

export const spacingInputRegistration: Input[] = [
  {
    name: 'spacingDirection',
    friendlyName: 'Spacing Direction',
    type: 'string',
    enum: ['top', 'bottom', 'vertical'],
    defaultValue: 'vertical',
    helperText:
      'Sets if spacing is added to the top, bottom or both top and bottom of the component',
  },
  {
    name: 'spacingLevel',
    friendlyName: 'Spacing Level',
    type: 'string',
    enum: ['none', 'xs', 'sm', 'md', 'lg', 'xl'],
    defaultValue: 'md',
    helperText: 'Sets the amount of spacing added to selected spacing direction(s)',
  },
];

export const spacingProps = {
  spacingDirection: { type: String as PropType<SpacingDirection> },
  spacingLevel: { type: String as PropType<SpacingLevel> },
};

interface SpacingProps {
  spacingDirection?: SpacingDirection;
  spacingLevel?: SpacingLevel;
}

export const useSpacing = (props: SpacingProps) => {
  const spacing = computed(() => {
    if (props.spacingLevel === 'xs') {
      if (props.spacingDirection === 'bottom') return 'mb-3 lg:mb-8';
      if (props.spacingDirection === 'top') return 'mt-3 lg:mt-8';
      return 'my-3 lg:my-8';
    }
    if (props.spacingLevel === 'sm') {
      if (props.spacingDirection === 'bottom') return 'mb-4 lg:mb-12';
      if (props.spacingDirection === 'top') return 'mt-4 lg:mt-12';
      return 'my-4 lg:my-12';
    }
    if (props.spacingLevel === 'md') {
      if (props.spacingDirection === 'bottom') return 'mb-8 lg:mb-16';
      if (props.spacingDirection === 'top') return 'mt-8 lg:mt-16';
      return 'my-8 lg:my-16';
    }
    if (props.spacingLevel === 'lg') {
      if (props.spacingDirection === 'bottom') return 'mb-12 lg:mb-20';
      if (props.spacingDirection === 'top') return 'mt-12 lg:mt-20';
      return 'my-12 lg:my-20';
    }
    if (props.spacingLevel === 'xl') {
      if (props.spacingDirection === 'bottom') return 'mb-16 lg:mb-24';
      if (props.spacingDirection === 'top') return 'mt-16 lg:mt-24';
      return 'my-16 lg:my-24';
    }
    return '';
  });

  return {
    spacing,
  };
};
