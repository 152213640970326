export const ProductKeysById = `query ProductKeysById ($idsClause: String!) {
  products(where: $idsClause) {
    count,
    results {
      id, key
    },
    total
  }
}`;

export default {};
