import { context, trace } from '@opentelemetry/api';
import { readonly, shallowReactive, unref } from 'vue';
import { Store } from 'vuex';

import { webstore } from '@/api';
import { useDynamicYield } from '@/composables/useDynamicYield';
import { useState } from '@/composables/useState';
import { dyEvent, GAEvent, gaEvent } from '@/utils/analytics';

interface FeatureFlags extends Record<string, boolean | undefined> {
  adSurvey?: boolean;
  autoDeliveryUpsellOneClick?: boolean;
  badgeRedesign?: boolean;
  cartAddedModal?: boolean;
  discountCodeForm?: boolean;
  expressCheckout?: boolean;
  giftContentList?: boolean;
  googleOneTapHomepage?: boolean;
  googleOneTapPDP?: boolean;
  guestCheckoutLastOption?: boolean;
  headerRedesign?: boolean;
  preCheckoutDeliveryEstimate?: boolean;
  referral?: boolean;
  searchableEasyReorder?: boolean;
  strikethroughPricing?: boolean;
  updatedNavigationTiles?: boolean;
}

/** This interface serves as a hopeful spec... We can't actually control what
 * gets entered in the DY UI.
 */
interface DyVariation {
  dyEvent?: GAEvent;
  flags?: string;
  gaEvent?: GAEvent;
}

const layers = ['local', 'session', 'site', 'visitor'] as const;
type Layer = (typeof layers)[number];

export type FlagsByLayer = Record<Layer, FeatureFlags>;

// roughly inspired by DY API response structure, to later
// simplify unification with real DY decision events (maybe)
const fakeDyChoices = [
  {
    id: 829474,
    name: '[A/B Test] Added to Cart Modal',
    type: 'DECISION',
    analyticsMetadataByFlags: {
      ['-cartAddedModal' as string]: {
        experienceId: 1625065,
        experienceName: 'Experience 1',
        variationId: 28182603,
        variationName: 'Control-Page redirect',
      },
      cartAddedModal: {
        experienceId: 1625065,
        experienceName: 'Experience 1',
        variationId: 28167468,
        variationName: 'Mini cart',
      },
    },
  },
];

export function fromFlagSpec(flagSpec: string): FeatureFlags {
  const entries = flagSpec.split(',').map((spec) => {
    const [_, setting, name] = /^(-?)(.+)$/.exec(spec)!;
    return [name, setting !== '-'];
  });
  return Object.fromEntries(entries);
}

export function toFlagSpec(flags: FeatureFlags, flagNames?: (keyof FeatureFlags)[]): string {
  return (flagNames ?? Object.keys(flags).sort())
    .map((name) => `${flags[name] ? '' : '-'}${name}`)
    .join();
}

export function useFeatureFlags(store: Store<any>) {
  const { fetchDyVariations, getDyVariations } = useDynamicYield(store);

  const state = useState('featureFlags', () => {
    const initial = unref(store.state.featureFlagsModule);

    const flagsByLayer: FlagsByLayer = {
      local: initial.flagsByLayer?.local ?? {},
      session: initial.flagsByLayer?.session ?? {},
      site: initial.flagsByLayer?.site ?? initial.flags ?? {},
      visitor: initial.flagsByLayer?.visitor ?? {},
    };

    const flattenedFlags = layers.reduceRight(
      (acc, layer) => ({ ...acc, ...flagsByLayer[layer] }),
      {} as FeatureFlags,
    );

    const flagProxy = new Proxy(flattenedFlags, {
      get: (target, property) => {
        const flagName = property.toString();
        const flagValue = target[property.toString()];
        if (flagName.substring(0, 2) !== '__' && flagName.substring(0, 7) !== 'Symbol(') {
          trace.getSpan(context.active())?.addEvent('feature_flag', {
            'feature_flag.key': flagName,
            'feature_flag.provider_name': 'ixclkxk.shop',
            'feature_flag.variant': (flagValue ?? 'null').toString(),
          });
        }
        return target[property.toString()];
      },
    });

    const flags = shallowReactive(flagProxy);

    return { flagsByLayer, flags };
  });

  const {
    flags: { value: flags },
    flagsByLayer,
  } = state;

  /**
   * Update the manually-maintained reactive `flags` object to match the current
   * state of the `flagsByLayer` object. Called by replaceFlags() and setFlags().
   */
  function updateReactiveFlags(updatedKeys: string[]) {
    updatedKeys.forEach((key) => {
      const newValue = layers.flatMap((l) => flagsByLayer.value[l][key] ?? [])[0];
      if (newValue === undefined) {
        delete flags[key];
      } else if (flags[key] !== newValue) {
        flags[key] = newValue;
      }
    });
  }

  /**
   * Replace all flags in all layers with the given flags. Meant for use client
   * side when a whole new set is received from the server, such as after
   * sign-in or other identification change.
   */
  function replaceFlagsByLayer(newFlagsByLayer: FlagsByLayer) {
    // update the canonical layered flag sets
    layers.forEach((layer) => {
      flagsByLayer.value[layer] = newFlagsByLayer[layer] ?? {};
    });

    // update the reactive object to match
    // (examine all flag keys, old and new, to make sure none are accidentally left around)
    const allKeys = [flags, ...Object.values(flagsByLayer.value)].flatMap(Object.keys);
    const uniqueKeys = [...new Set(allKeys)];
    updateReactiveFlags(uniqueKeys);
  }

  function sendFakeDyContentDecision(selector: string, flagNames: (keyof FeatureFlags)[]) {
    const fakeChoice = fakeDyChoices.find(({ name }) => name === selector);
    if (fakeChoice) {
      const flagSpec = toFlagSpec(flags, flagNames);
      const analyticsMetadata = fakeChoice.analyticsMetadataByFlags[flagSpec];
      if (analyticsMetadata) {
        gtag('event', 'content_decision', {
          cms: 'Dynamic Yield',
          content_id: fakeChoice.id,
          content_name: fakeChoice.name,
          content_type: fakeChoice.type,
          experience_id: analyticsMetadata.experienceId,
          experience_name: analyticsMetadata.experienceName,
          test_variation_id: analyticsMetadata.variationId,
          test_variation_name: analyticsMetadata.variationName,
          synthetic: true,
        });
      }
    }
  }

  /**
   * Set one or more specific flags in the given layer (merging with existing
   * flags) and propagate changes to the server be persisted. Used by
   * loadDyFlags().
   */
  function setFlags(updates: FeatureFlags, layer: Layer = 'local') {
    // update the canonical layered flag sets
    const updatedLayer = { ...flagsByLayer.value[layer], ...updates };
    Object.keys(updates).forEach((key) => updates[key] === undefined && delete updatedLayer[key]);
    const changed = toFlagSpec(updatedLayer) !== toFlagSpec(flagsByLayer.value[layer]);
    flagsByLayer.value[layer] = updatedLayer;

    // update the reactive object to match
    updateReactiveFlags(Object.keys(updates));

    // if the session or visitor layer changed, publish layers to the backend
    if (changed && ['session', 'visitor'].includes(layer)) {
      webstore.sessionSpecific.post('/api/me/flags', flagsByLayer.value);
    }
  }

  /**
   * Load visitor-level flags from a DY campaign
   *
   * DY will be queried if and only if we don't already have a value for at
   * least one of the listed flags. Flags are expected to be in a `flags` field
   * of the DY API campaign variation. After awaiting this promise, look at the
   * `flags` reactive object as usual for the latest flags.
   *
   * (Temporary feature: If we already have a value for at least one of the
   * listed flags, we'll try to send a fake `content_decision` event to
   * `gtag()`. See {@link sendFakeDyContentDecision} and {@link fakeDyChoices}.)
   *
   * @param selector  API Selector of the DY campaign that will (hopefully)
   * provide at least one of the flags in question
   * @param flagNames One or more flags that we hope DY can tell us about
   */
  async function loadDyFlags(selector: string, flagNames: (keyof FeatureFlags)[]) {
    if (fakeDyChoices.some(({ name }) => name === selector)) {
      sendFakeDyContentDecision(selector, flagNames);
      return;
    }

    if (flagNames.some((name) => name in flags)) {
      return;
    }

    let variation: DyVariation | undefined = getDyVariations.value[selector];
    if (!variation) {
      await fetchDyVariations([selector]);
      variation = getDyVariations.value[selector];
    }

    if (variation?.flags && typeof variation.flags === 'string') {
      setFlags(fromFlagSpec(variation.flags), 'visitor');
    }

    if (variation?.dyEvent && typeof variation.dyEvent === 'object') {
      dyEvent(variation.dyEvent);
    }

    if (variation?.gaEvent && typeof variation.gaEvent === 'object') {
      gaEvent(variation.gaEvent);
    }
  }

  return {
    flags: readonly(flags),
    loadDyFlags,
    setFlags,
    replaceFlagsByLayer,
  };
}

export default {};
