<template>
  <div :style="percentageWidth"></div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percentage: { required: true, type: Number },
  },
  computed: {
    percentageWidth() {
      return `width: ${this.percentage}%`;
    },
  },
};
</script>
