<template>
  <div>
    <nav class="flex flex-col">
      <ul
        class="flex flex-col overflow-hidden border border-solid rounded border-pearl"
        data-test="account-nav"
      >
        <NavItem
          :active="active === 'account'"
          href="/account"
          text="Your Account"
          data-test="your-account-nav"
        />
        <NavItem
          v-if="account?.is_b2b"
          :active="active === 'business'"
          href="/account/business"
          text="Business Account"
        />
        <NavItem
          v-for="{ active, href, text } in pages"
          :active="active"
          :href="href"
          :key="href"
          :text="text"
        />
        <NavItem
          v-if="account?.is_reseller"
          :active="active === 'reseller'"
          href="/account/reseller-certification"
          text="Reseller Certifications"
        />
        <NavItem
          v-if="account?.is_bulk_upload_available"
          :active="active === 'bulkorder'"
          href="/order-upload"
          text="Order Upload"
        />
      </ul>
      <RouteLink v-for="link in extraLinks" class="mt-2" :key="link.title" :to="link.url">
        {{ link.title }}
      </RouteLink>
    </nav>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import NavItem from '@/components/account/NavItem.vue';
import RouteLink from '@/components/base/RouteLink.vue';

interface ExtraLink {
  target: string;
  title: string;
  url: string;
}

export default defineComponent({
  name: 'NavMenu',
  props: {
    account: { required: false, type: Object },
    active: { required: true, type: String, default: '' },
    extraLinks: { required: true, type: Array as PropType<ExtraLink[]> },
  },
  components: {
    NavItem,
    RouteLink,
  },
  setup(props) {
    const pages = computed(() => [
      {
        href: '/account/communications',
        text: 'Email Communications',
        active: props.active === 'communications',
      },
      { href: '/account/address-book', text: 'Address Book', active: props.active === 'address' },
      { href: '/account/wallet', text: 'Wallet', active: props.active === 'wallet' },
      { href: '/account/order-history', text: 'Order History', active: props.active === 'orders' },
      {
        href: '/account/easy-reorder',
        text: 'Easy Reorder',
        active: props.active === 'easyreorder',
      },
      { href: '/account/favorites', text: 'Favorites', active: props.active === 'favorites' },
      {
        href: '/account/auto-delivery',
        text: 'Auto-Delivery',
        active: props.active === 'autodelivery',
      },
    ]);

    return {
      pages,
    };
  },
});
</script>

<style lang="scss" scoped>
.bg-old-lace {
  background-color: $old-lace;
}
.border-pearl {
  border-color: $quarter-pearl-lusta;
}
</style>
