/* eslint-disable camelcase */
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';

type FbContentType = 'product_group';
type FbEventName = 'AddToCart' | 'PageView' | 'Purchase' | 'ViewContent';

export interface FbAddToCartEvent {
  custom_data: {
    content_type: FbContentType;
    content_ids: string[];
    content_name: string;
    value: number;
    currency: string;
  };
}

export interface FbPurchaseEvent {
  custom_data: {
    content_type: FbContentType;
    content_ids: string[];
    value: number;
    currency: string;
  };
  user_data?: {
    em: string;
  };
}

export interface FbViewContentEvent {
  custom_data: {
    content_type: FbContentType;
    content_ids: string[];
    name: string;
  };
}

interface GtmProduct {
  id: string;
  itemExternalId: string;
  name: string;
  price: number | string;
  quantity: number;
}

export interface GtmAddToCartPayload {
  ecommerce: {
    add: {
      actionField: {
        list: string;
      };
      products: GtmProduct[];
    };
    currencyCode: string;
  };
}

export function adaptGtmDataForFacebookAddToCart(
  gtmData?: GtmAddToCartPayload,
): FbAddToCartEvent | undefined {
  if (!gtmData) return undefined;
  const {
    ecommerce: {
      add: {
        products: [product],
      },
      currencyCode,
    },
  } = gtmData;
  return {
    custom_data: {
      content_type: 'product_group',
      content_ids: [product.itemExternalId],
      content_name: product.name,
      value: dollars(from(Number(product.price))),
      currency: currencyCode,
    },
  };
}

export function sendConversionEvent<T = FbAddToCartEvent | FbPurchaseEvent | FbViewContentEvent>(
  _eventName?: FbEventName,
  _data?: T,
) {
  // if (eventName !== 'Purchase') return;
  // purchase events temporarily disabled until accuracy can be fixed / confirmed
}

export default {};
