<template>
  <div class="text-left">
    <div v-if="summary.occasionName"><strong>Style:</strong> {{ summary.occasionName }}</div>
    <div
      class="mt-2 bg-cover bg-center bg-true-gray-100 h-48 md:h-56 w-full md:w-2/3"
      :style="{ backgroundImage: 'url(' + previewUrl + ')' }"
    />
    <div class="mt-4"><strong>From:</strong> {{ summary.from }}</div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { OccasionFormData } from '@/components/digital-gift/gifter/occasion/OccasionForm.vue';
import { useLocalStorage } from '@/composables/useLocalStorage';

export default defineComponent({
  name: 'OccasionFormSummary',
  props: {
    summary: { required: true, type: Object as PropType<OccasionFormData> },
  },
  setup(props) {
    const previewUrl = computed(() => {
      const { imageUrl } = props.summary;
      if (/^[a-f0-9]{32}$/gi.test(imageUrl)) {
        const localStorage = useLocalStorage('digitalGifting');
        return localStorage.get(imageUrl);
      }
      return imageUrl;
    });

    return { previewUrl };
  },
});
</script>
