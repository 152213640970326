<template>
  <form data-test="keyword-filter" @submit.prevent>
    <label for="keyword" aria-label="Search Products"></label>
    <input type="text" id="keyword" placeholder="Search Products" @keyup="keywordChanged" />
  </form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'KeywordFilter',
  methods: {
    ...mapActions('customTraysModule', ['setActiveKeyword']),
    keywordChanged(e) {
      this.setActiveKeyword(e.target.value.toLowerCase());
      this.$emit('filters:changed');
    },
  },
};
</script>
