<template>
  <section id="faq" class="w-full">
    <FAQComponent class="w-full" :borderColors="borderColors" :title="title" :titleSize="titleSize">
      <template v-for="item in questionsAndAnswers" :key="item.question">
        <AccordionTab>
          <template v-slot:header>{{ item.question }}</template>
          <template v-slot:content>
            <div v-html="item.answer" />
          </template>
        </AccordionTab>
      </template>
    </FAQComponent>
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import FAQComponent from '@/components/base/FAQ.vue';
import AccordionTab from '@/components/base/layout/AccordionTab.vue';
import { CmsRegisteredComponent } from '@/utils/cms';

interface QuestionAndAnswer {
  question: string;
  answer: string;
}

const BaseFAQ = defineComponent({
  name: 'BaseFAQ',
  components: {
    AccordionTab,
    FAQComponent,
  },
  props: {
    borderColors: { required: false, type: String, default: 'black' },
    questionsAndAnswers: { required: true, type: Array as PropType<QuestionAndAnswer[]> },
    title: { required: false, type: String, default: 'Frequently Asked Questions' },
    titleSize: { required: false, type: String, default: 'large' },
  },
  setup() {
    return {};
  },
});

export const BaseFAQRegistration: CmsRegisteredComponent = {
  component: BaseFAQ,
  name: 'FAQ',
  inputs: [
    {
      name: 'borderColors',
      type: 'string',
      defaultValue: 'black',
      enum: ['black', 'gray'],
    },
    {
      name: 'questionsAndAnswers',
      type: 'list',
      defaultValue: [
        {
          question: '',
          answer: '',
        },
      ],
      subFields: [
        {
          name: 'question',
          type: 'string',
        },
        {
          name: 'answer',
          type: 'richText',
        },
      ],
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Frequently Asked Questions',
    },
  ],
};
export default BaseFAQ;
</script>
