<script setup lang="ts">
import { useAnnouncer } from '@vue-a11y/announcer';
import {
  breakpointsTailwind,
  onClickOutside,
  useBreakpoints,
  useMutationObserver,
} from '@vueuse/core';
import { computed, nextTick, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import ActionItemsButton from '@/components/layout/header/ActionItemsButton.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useOverlay } from '@/stores/overlay';

const props = defineProps<{
  triggerAttributes?: Record<string, any>;
  disabled?: boolean;
  buttonName?: string;
}>();

const actionGroup = ref<HTMLElement>();
const popOver = ref<HTMLElement>();
const isOpen = ref(false);
const { route } = useRouteChange(useRouter());
const emit = defineEmits(['click', 'popoverOpened']);

const breakpoints = useBreakpoints(breakpointsTailwind);
const mobileScreen = computed(() => breakpoints.smaller('lg').value);

const { polite } = useAnnouncer();

onClickOutside(actionGroup, () => {
  isOpen.value = false;
  polite('Popover closed');
});

const focusableElements = ref<NodeListOf<HTMLElement> | HTMLElement[]>([]);
const firstFocusableElement = computed(() => focusableElements.value[0]);
const lastFocusableElement = computed(
  () => focusableElements.value[focusableElements.value.length - 1],
);
const handleForwardKeyTab = (event: Event) => {
  if (lastFocusableElement.value === document.activeElement) {
    event.preventDefault();
    firstFocusableElement.value?.focus();
  }
};
const handleBackwardKeyTab = (event: Event) => {
  if (firstFocusableElement.value === document.activeElement) {
    event.preventDefault();
    lastFocusableElement.value?.focus();
  }
};
const refreshFocusableElements = () => {
  focusableElements.value =
    popOver.value?.querySelectorAll<HTMLElement>(
      `button,[href], input:not([disabled]),
        select:not([disabled]), textarea:not([disabled]):not([name="g-recaptcha-response"]),
        [tabindex]:not([tabindex="-1"]):not([disabled])`,
    ) ?? [];
};

const toggleOpen = async () => {
  if (mobileScreen.value) {
    emit('click');
    return;
  }
  if (props.disabled) return;
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    emit('popoverOpened');
    await nextTick();
    firstFocusableElement.value?.focus();
    polite('Popover open, use tab to navigate, and press Esc to close');
  }
};

watch(
  () => route?.path,
  () => {
    isOpen.value = false;
    polite('Popover closed');
  },
);

const overlay = useOverlay();
watch(isOpen, () => (isOpen.value ? overlay.show() : overlay.hide()));

useMutationObserver(popOver, refreshFocusableElements, {
  attributes: true,
  childList: true,
  subtree: true,
});
</script>

<template>
  <div class="relative z-30" ref="actionGroup">
    <ActionItemsButton
      class="py-1"
      :class="{
        'bg-background-secondary': isOpen,
      }"
      :aria-label="buttonName || 'Action Items'"
      v-bind="triggerAttributes"
      @click="toggleOpen"
    >
      <slot name="trigger" />
    </ActionItemsButton>
    <img
      v-if="isOpen"
      src="@/assets/popoverOriginPoint.svg"
      alt="chevron"
      class="absolute right-8 top-8"
    />
    <div
      v-show="isOpen"
      class="absolute right-0 flex-col bg-white border border-solid divide-y rounded-md shadow-lg cursor-default divide-nuts-neutral-200 divide-solid border-background-tertiary top-10"
      @keydown.esc.prevent="isOpen = false"
      @keydown.exact.tab="handleForwardKeyTab"
      @keydown.shift.tab="handleBackwardKeyTab"
      ref="popOver"
      data-test="action-items-popover"
    >
      <slot />
    </div>
  </div>
</template>
