/* eslint-disable no-shadow */
import createDebug from 'debug';
import isEmpty from 'lodash/isEmpty';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { ctApi, fromAxiosNutsJson } from '@/api';
import { addToFavorites, removeFromFavorites } from '@/api/favorites';
import nutritionFacts from '@/api/nutritionFacts';
import { expandProduct } from '@/api/product';
import { getAllProductTagCounts, getProductTags } from '@/api/productTags';
import { getReviews } from '@/api/reviews';
import { productQuery } from '@/graphql/productQuery';
import type { RootState } from '@/store/createStore';
import helpers from '@/utils/helpers';

const debug = createDebug('nuts:store:pdp');
const isNotNull = (val: any) => typeof val !== 'undefined' && val !== null;
const namespaced = true;

export const state = () => ({
  admin: {
    bagSizes: [],
    inventory: {},
  },
  allProductTagCounts: {},
  ancestors: [],
  buyBlock: {
    selectedMaximumPiecesPerOrder: null,
    selectedSku: null,
    selectedGroupSku: null,
    selectedQuantity: 1,
    selectedGiftVariation: 236,
    selectedAutoDeliveryMode: null,
    selectedAutoDeliveryFrequency: null,
    selectedDistributionChannel: null,
  },
  isFavorited: null,
  markedAsGift: null,
  nutritionFacts: null,
  predictedDeliveryParams: null,
  predictedShippingMessage: null,
  product: {
    description_html: '',
    key: '',
    name: '',
    slug: '',
    titleImage: {
      large: '',
      medium: '',
      small: '',
      thumb: '',
      url: '',
      zoom: '',
    },
    images: [],
    attributes: {
      health_tips_html: '',
    },
    variants: [],
    variantGroups: [] as any[],
    allBackordered: false,
  },
  productKey: null,
  productTags: null as [] | null,
  reviews: {
    averageRating: 0.0,
    totalRatings: 0,
    totalReviews: 0,
    reviews: [],
    featuredReview: null,
    starRatingsCount: null,
  },
  updating: false,
});

export type ProductState = ReturnType<typeof state>;

/** ********** */
// GETTERS //
/** ********** */

export const getters: GetterTree<ProductState, RootState> = {
  bagSizes: (state) => state.admin.bagSizes,
  inventory: (state) => state.admin.inventory,
  isGiftCertificate: (state) =>
    state.product.variantGroups[0]?.customProduct === 'Gift Certificate',
  productTagNames: (state) => state.productTags?.map((tag: any) => tag.value.name) || [],
  selectedVariant: (state) =>
    state.product.variants.find(({ sku }) => sku === state.buyBlock.selectedSku) || null,
  selectedSkuWithZeroPrefix: (state) => `0${state.buyBlock.selectedSku}`,
};

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations: MutationTree<ProductState> = {
  SET_ALL_PRODUCT_TAGS(state, productTags) {
    state.allProductTagCounts = productTags || {};
  },
  SET_ANCESTORS(state, ancestors) {
    state.ancestors = ancestors;
  },
  SET_DESCRIPTION_HTML(state, html) {
    state.product.description_html = html;
  },
  SET_EXPANDED_PRODUCT_INFO(state, { expandedProduct, productKey }) {
    const { admin, isFavorited, predictedDeliveryParams, predictedShippingMessage, reviews } =
      expandedProduct;
    state.productKey = productKey;
    state.admin = admin;
    state.isFavorited = isFavorited;
    state.predictedShippingMessage = predictedShippingMessage;
    state.predictedDeliveryParams = predictedDeliveryParams;
    state.reviews = reviews;
  },
  SET_HEALTH_TIPS_HTML(state, html) {
    state.product.attributes.health_tips_html = html;
  },
  SET_IS_FAVORITED(state, isFavorited) {
    state.isFavorited = isFavorited;
  },
  SET_MARKED_AS_GIFT(state, markedAsGift) {
    state.markedAsGift = markedAsGift;
  },
  SET_NUTRITION_FACTS(state, nutritionFacts) {
    state.nutritionFacts = nutritionFacts;
  },
  SET_PAGINATED_REVIEWS(state, reviews) {
    state.reviews.reviews = reviews;
  },
  SET_PRODUCT(state, product) {
    state.product = product;
  },
  SET_PRODUCT_TAGS(state, productTags) {
    state.productTags = productTags || [];
  },
  SET_REVIEWS(state, reviews) {
    state.reviews = reviews;
  },
  SET_SELECTED_AUTO_DELIVERY_FREQUENCY(state, frequency) {
    state.buyBlock.selectedAutoDeliveryFrequency = frequency;
  },
  SET_SELECTED_AUTO_DELIVERY_MODE(state, mode) {
    state.buyBlock.selectedAutoDeliveryMode = mode;
  },
  SET_SELECTED_DISTRIBUTION_CHANNEL(state, channel) {
    state.buyBlock.selectedDistributionChannel = channel;
  },
  SET_SELECTED_GIFT_VARIATION(state, id) {
    state.buyBlock.selectedGiftVariation = id;
  },
  SET_SELECTED_MAXIMUM_PIECES_PER_ORDER(state, maximumPiecesPerOrder) {
    state.buyBlock.selectedMaximumPiecesPerOrder = maximumPiecesPerOrder;
  },
  SET_SELECTED_QUANTITY(state, quantity) {
    state.buyBlock.selectedQuantity = quantity;
  },
  SET_SELECTED_VARIANT_SKU(state, sku) {
    state.buyBlock.selectedSku = sku;
  },
  SET_UPDATING(state, updating) {
    state.updating = updating;
  },
};

/** ******** */
// ACTIONS //
/** ******** */
export const actions: ActionTree<ProductState, RootState> = {
  async addFavorite({ commit }, productKey) {
    try {
      const { data } = await addToFavorites(productKey);
      if (data.redirect_to) {
        return window.location.assign(data.redirect_to.url);
      }
      commit('SET_IS_FAVORITED', true);
    } catch (err) {
      debug('failed to add item to favorites', err);
    }
    return undefined;
  },

  async expandProduct({ commit, state }, productKey) {
    if (state.productKey === productKey) {
      debug('expandProduct alread done');
      return;
    }
    try {
      const expandedProduct = await fromAxiosNutsJson(expandProduct(productKey), {});
      commit('SET_EXPANDED_PRODUCT_INFO', { expandedProduct, productKey });
    } catch (err) {
      debug('failed to expand product info', err);
    }
  },

  async getNutritionFacts({ commit, state }, { productKey, forceRefresh = false }) {
    if (state.nutritionFacts !== null && !forceRefresh) {
      debug('getNutritionFacts already done');
      return;
    }
    try {
      const { data } = await nutritionFacts(productKey);

      commit('SET_NUTRITION_FACTS', data.value);
    } catch (err: any) {
      if (!err.response || err.response.status !== 404) {
        debug('failed to load product nutrition facts', err);
      }
      if (err.response?.status === 404) {
        commit('SET_NUTRITION_FACTS', null);
      }
    }
  },

  async getProductInfo(
    { commit, rootState, state },
    {
      key,
      preselectedVariantSku = null,
      snackPackMode = false,
      cleanHtml = true,
      forceRefresh = false,
    },
  ) {
    if (state.product.key === key && !forceRefresh) {
      // FIXME: this should also check if cleanHtml is the same, or
      // split cleanHtml into a separate action or a computed property.
      debug('getProductInfo already done');
      return;
    }
    const { locale } = rootState;
    const {
      body: { data },
    } = await ctApi
      .graphql()
      .post({
        body: {
          query: productQuery,
          variables: {
            key,
            locale,
          },
        },
      })
      .execute();
    debug('received product data');

    const { product } = data;
    const productState = await helpers.buildProductStateObject(product, cleanHtml);
    debug('built product state object');

    let { variantGroups, variants } = productState;
    const allSnackPack = variantGroups.every((group) => group.snackPack);

    let selectedVariant: any;
    if (preselectedVariantSku) {
      commit('SET_SELECTED_VARIANT_SKU', preselectedVariantSku);
      selectedVariant = variants.find((x) => x.sku === preselectedVariantSku);
      if (selectedVariant) {
        commit('SET_SELECTED_MAXIMUM_PIECES_PER_ORDER', selectedVariant.maximumPiecesPerOrder);
      }
    } else if (!state.buyBlock.selectedSku || forceRefresh) {
      selectedVariant = variants.find((x) => !x.backordered) || variants[0];
      if (selectedVariant) {
        commit('SET_SELECTED_VARIANT_SKU', selectedVariant.sku);
        commit('SET_SELECTED_MAXIMUM_PIECES_PER_ORDER', selectedVariant.maximumPiecesPerOrder);
      }
    }

    if (!snackPackMode && !allSnackPack) {
      variantGroups = variantGroups.filter(
        (group) => !group.snackPack || (selectedVariant && group.sku === selectedVariant.sku),
      );
      productState.variantGroups = variantGroups;

      variants = variants.filter(
        (variant) => !variant.snackPack || (selectedVariant && variant.sku === selectedVariant.sku),
      );
      productState.variants = variants;
    }

    commit('SET_MARKED_AS_GIFT', null);
    commit('SET_PRODUCT', productState);
    commit('SET_ANCESTORS', productState.ancestors);
    // Jest Vue component test fails to re-render if the following state fields gets
    // changed via its parent`product` object re - allocation.
    // Not a blocker thing, as changes in the product does re-render the component on
    // actual browsers but does not in Jest unit tests only.
    // For now workaround is to explicitly mutate the properties again to make tests happy,
    // but that investigation will be done separately.
    commit('SET_DESCRIPTION_HTML', productState.description_html);
    commit('SET_HEALTH_TIPS_HTML', productState.attributes.health_tips_html);
  },

  async loadAllProductTagCounts({ commit, state }) {
    if (!isEmpty(state.allProductTagCounts)) {
      debug('allProductTagCounts already done');
      return;
    }
    try {
      const allProductTagCounts = await getAllProductTagCounts();
      commit('SET_ALL_PRODUCT_TAGS', allProductTagCounts);
    } catch (err) {
      debug('failed to load all product tags with counts', err);
    }
  },

  async loadMoreReviews({ commit }, { productKey, offset, limit }) {
    const { data } = await getReviews(productKey, offset, limit);
    const {
      data: { reviews },
    } = data;
    commit('SET_PAGINATED_REVIEWS', reviews);
  },

  async loadProductTags({ commit, state }, { productKey, forceRefresh = false }) {
    if (!forceRefresh && isNotNull(state.productTags)) {
      debug('getProductTags already done');
      return;
    }
    try {
      const productTags = await getProductTags(productKey);
      commit('SET_PRODUCT_TAGS', productTags);
    } catch (err) {
      debug('failed to load product tags', err);
    }
  },

  async removeFavorite({ commit }, productKey) {
    try {
      await removeFromFavorites(productKey);
      commit('SET_IS_FAVORITED', false);
    } catch (err) {
      debug('failed to remove item from favorites', err);
    }
  },

  setMarkedAsGift({ commit }, markedAsGift) {
    commit('SET_MARKED_AS_GIFT', markedAsGift);
  },

  setSelectedAutoDeliveryFrequency({ commit }, frequency) {
    commit('SET_SELECTED_AUTO_DELIVERY_FREQUENCY', frequency);
  },

  setSelectedAutoDeliveryMode({ commit }, mode) {
    commit('SET_SELECTED_AUTO_DELIVERY_MODE', mode);
  },

  setSelectedDistributionChannel({ commit }, channel) {
    commit('SET_SELECTED_DISTRIBUTION_CHANNEL', channel);
  },

  setSelectedMaximumPiecePerOrder({ commit }, maximumPiecesPerOrder) {
    commit('SET_SELECTED_MAXIMUM_PIECES_PER_ORDER', maximumPiecesPerOrder);
  },

  setSelectedQuantity({ commit }, quantity) {
    commit('SET_SELECTED_QUANTITY', quantity);
  },

  setSelectedVariantSku({ commit }, sku) {
    commit('SET_SELECTED_VARIANT_SKU', sku);
  },

  setSelectedGiftVariation({ commit }, id) {
    commit('SET_SELECTED_GIFT_VARIATION', id);
  },
};

export default {
  actions,
  getters,
  namespaced,
  mutations,
  state,
};
