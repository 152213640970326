<script setup lang="ts">
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, onMounted, watchEffect } from 'vue';
import Popper from 'vue3-popper';
import { useStore } from 'vuex';

import InfoIcon from '@/components/base/assets/InfoIcon.vue';
import ProgressBar from '@/components/base/ProgressBar.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import ShippingMessageToolTip from '@/components/cart/ShippingMessageToolTip.vue';
import { Money } from '@/utils/money';

const props = defineProps<{
  freeShippingThreshold: string;
  stackVertically?: boolean;
  totalPrice: Money;
}>();

const emit = defineEmits({
  'free-shipping-qualified': (_: boolean) => true,
});

const store = useStore();

const freeShippingCouponThreshold = computed<number | undefined>(
  () => store.state.headerModule.freeShippingCouponThreshold ?? undefined,
);

const thresholdAmount = computed(() => {
  const threshold = Number(props.freeShippingThreshold.replace('$', ''));
  if (!freeShippingCouponThreshold.value) return from(threshold);
  return from(Math.min(threshold, freeShippingCouponThreshold.value));
});

const remaining = computed(() =>
  dollars(props.totalPrice) >= dollars(thresholdAmount.value)
    ? from(0)
    : Money.subtract(thresholdAmount.value, props.totalPrice),
);

const formattedRemaining = computed(() =>
  dollars(remaining.value) ? dollars(remaining.value).toFixed(2) : '',
);

const percentage = computed(
  () => 100 - (dollars(remaining.value) / dollars(thresholdAmount.value)) * 100,
);

const qualified = computed(() => dollars(remaining.value) === 0);

onMounted(() => {
  watchEffect(() => {
    emit('free-shipping-qualified', qualified.value);
  });
});
</script>

<template>
  <div
    class="flex flex-col px-4 py-2 md:py-3 shipping-message-section bg-nuts-stone-100"
    :class="{ 'ws-lg:flex-row ws-lg:gap-4': !stackVertically }"
  >
    <div
      class="flex shipping-message"
      data-test="shipping-message"
      :class="stackVertically ? 'mb-1 w-full' : 'shrink-0 justify-center pb-1 md:pb-0'"
    >
      <div class="flex items-center">
        <SmallBodyText v-if="qualified">
          YAY! You qualify for <strong>FREE</strong> shipping!
        </SmallBodyText>
        <SmallBodyText v-else>
          Add <strong>${{ formattedRemaining }} more</strong> for FREE shipping!
        </SmallBodyText>
      </div>
      <div class="flex align-middle tiny-note popover-container" data-test="shipping-popover-icon">
        <Popper arrow offsetDistance="10" placement="bottom" :locked="true">
          <template v-slot:content="{ close }">
            <div class="popper shipping-message" data-test="shipping-message-popper">
              <a
                aria-label="Close tooltip"
                class="close-popper"
                data-test="close-popper"
                href="#"
                role="button"
                @click.prevent="close()"
              >
                ✕
              </a>
              <ShippingMessageToolTip :freeShippingThreshold="freeShippingThreshold" />
            </div>
          </template>
          <UnstyledButton
            aria-label="shipping message details are shown in a tooltip"
            class="flex items-center justify-center w-6 h-6 p-1 shrink-0"
          >
            <InfoIcon :size="16" />
          </UnstyledButton>
        </Popper>
      </div>
    </div>
    <div class="flex items-center w-full" data-test="shipping-progress-bar">
      <div class="w-full h-4 bg-true-gray-300 rounded-xl">
        <ProgressBar :percentage="percentage" class="h-full bg-black rounded-xl" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tooltip {
  :deep(.details-content-wrapper) {
    @apply p-3;
    .popup-main {
      span.content {
        @apply text-true-gray-lighter text-xs;
      }
    }
  }
}

.shipping-message {
  @apply font-proxima-nova text-sm text-black z-[1];
}
</style>
