<template>
  <li
    class="h-10 pl-6 pr-4 border-t border-solid first:border-t-0 border-pearl"
    :class="active ? 'bg-white' : 'bg-old-lace'"
  >
    <RouteLink class="flex items-center justify-between h-full link" :to="href">
      <span class="pr-4 text-base ws-lg:text-base" :class="{ 'font-semibold': active }">
        {{ text }}
      </span>
      <img class="w-2.5 transform -rotate-90" :src="arrowDownIcon" alt="arrow right" />
    </RouteLink>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import RouteLink from '@/components/base/RouteLink.vue';

export default defineComponent({
  name: 'NavItem',
  props: {
    active: { required: false, type: Boolean, default: false },
    href: { required: true, type: String },
    text: { requried: true, type: String },
  },
  components: { RouteLink },
  setup() {
    return {
      arrowDownIcon: nutshell['img/arrow-down.svg'],
    };
  },
});
</script>

<style lang="scss" scoped>
.link {
  @apply text-black;
}
</style>
