<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import ExpandableMenuLinks from '@/components/layout/header/ExpandableMenuLinks.vue';
import NavHeader from '@/components/layout/header/mobile/NavHeader.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { LargeMenu, mobileAttribution } from '@/utils/navMenu';

defineEmits(['close']);
defineProps<{ defaultExpanded?: boolean; menu?: LargeMenu }>();

const rootElement = ref<HTMLElement>();
useRouterLinks(rootElement, useRouter());
</script>

<template>
  <nav v-if="menu" ref="rootElement">
    <div
      class="relative flex items-center justify-center px-4 text-center border-b border-solid h-14 border-nuts-neutral-200"
    >
      <UnstyledButton class="absolute p-1 left-4" @click="$emit('close')">
        <img
          src="@/assets/back-button.svg"
          alt="return to category list back button"
          class="left-0 object-contain w-6 h-6"
        />
      </UnstyledButton>
      <NavHeader :header="menu.header" />
    </div>
    <template v-for="section in menu.sections" :key="section">
      <template v-for="{ group } in section.linkGroups" :key="group">
        <template v-if="group.headerText">
          <ExpandableMenuLinks :defaultExpanded="defaultExpanded">
            <template #trigger>
              <p class="text-base font-medium text-black font-sofia-pro no-mb">
                {{ group.headerText }}
              </p>
            </template>
            <ul>
              <li
                v-for="{ link } in group.links"
                :key="link.url"
                class="hover:bg-background-secondary hover:cursor-pointer"
              >
                <a :href="link.url" v-bind="mobileAttribution(menu, group.headerText, link.text)">
                  <BaseBodyText class="px-4 py-2 mx-2 no-link-styles">
                    {{ link.text }}
                  </BaseBodyText>
                </a>
              </li>
            </ul>
          </ExpandableMenuLinks>
        </template>

        <template v-else>
          <div
            v-for="{ link } in group.links"
            :key="link.url"
            class="hover:bg-background-secondary hover:cursor-pointer"
          >
            <a :href="link.url" v-bind="mobileAttribution(menu, group.headerText, link.text)">
              <BaseBodyText class="px-4 py-2 mx-2 no-link-styles">
                {{ link.text }}
              </BaseBodyText>
            </a>
          </div>
        </template>
      </template>
    </template>

    <div v-if="menu.promoZones?.length" class="px-4 py-4">
      <div v-for="(zone, i) in menu.promoZones" :key="zone.url" :class="{ 'mt-4': i > 0 }">
        <a
          :href="zone.url"
          v-bind="mobileAttribution(menu, menu.header.text, zone.text)"
          :data-test="`sub-nav-promo-${zone.text}`"
        >
          <img :src="zone.image" alt="promo image" />
        </a>
        <a
          :href="zone.url"
          class="promo-link-styles"
          v-bind="mobileAttribution(menu, menu.header.text, zone.text)"
          :data-test="`sub-nav-promo-${zone.text}`"
        >
          <p class="mt-1 font-semibold no-mb">{{ zone.text }}</p>
        </a>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.no-mb {
  @apply mb-0;
}
.no-link-styles {
  @apply text-black;
  @apply hover:no-underline;
  @apply focus:no-underline;
  @apply cursor-pointer;
}
.promo-link-styles {
  @apply text-black text-base hover:underline hover:font-semibold decoration-yellow decoration-2 underline-offset-4;
}
</style>
