<script setup lang="ts">
import {
  breakpointsTailwind,
  onClickOutside,
  useBreakpoints,
  useElementBounding,
  useMutationObserver,
  useVModel,
} from '@vueuse/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Collapse from '@/components/base/layout/Collapse.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import LargeMenu from '@/components/layout/header/mobile/LargeMenu.vue';
import NavHeader from '@/components/layout/header/mobile/NavHeader.vue';
import SmallMenu from '@/components/layout/header/mobile/SmallMenu.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useChat } from '@/composables/useChat';
import { useCheckout } from '@/composables/useCheckout';
import { useCustomer } from '@/composables/useCustomer';
import { useLoginModal } from '@/composables/useLoginModal';
import { ClickEvent } from '@/utils/browser';
import { LargeMenu as LargeMenuModel, mobileAttribution, SiteNavigation } from '@/utils/navMenu';

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    siteNavigation: SiteNavigation;
  }>(),
  {
    modelValue: false,
  },
);

const emit = defineEmits(['update:modelValue']);
const menuOpened = useVModel(props, 'modelValue', emit);

const { navigateTo } = useRouteChange(useRouter());
const { handleOpen } = useLoginModal();

const menuTop = ref<HTMLElement>();
const { height } = useElementBounding(menuTop);
const menuTopHeight = computed(() => `${height.value ? height.value : 0}px`);
const popOver = ref<HTMLElement>();

const { displayChatInfoText, isChatStatusPending, openChat } = useChat();

const store = useStore();
const { customer, customerProfileImage, hasBusinessAccount } = useCustomer(store);
const { cxMode } = useCheckout(store);
const isSignedIn = computed(() => !!customer.value || cxMode.value);

const openCategoryList = ref(true);
const selectedLargeMenu = ref<LargeMenuModel>();

const toggleCategoryList = () => {
  openCategoryList.value = !openCategoryList.value;
};

const userCreatedAt = computed<string | undefined>(
  () => store.state.headerModule.userCreatedAt ?? undefined,
);
const userFirstName = computed<string | undefined>(
  () => store.state.headerModule.userFirstName ?? undefined,
);

dayjs.extend(relativeTime);

const currentDate = dayjs().startOf('day');
const createdAtDate = computed(() => dayjs(userCreatedAt.value));
const dateDiffInDays = currentDate.diff(createdAtDate.value, 'days');

const timeWithUs = computed<string>(() => {
  const dateDiffInMonths = currentDate.diff(createdAtDate.value, 'months');
  const yearsWithUs = Math.floor(dateDiffInMonths / 12);
  const leftOverMonths = dateDiffInMonths % 12;

  if (yearsWithUs === 0) {
    if (leftOverMonths === 0) return '';
    return leftOverMonths > 1 ? `${leftOverMonths} months` : `${leftOverMonths} month`;
  }

  return `${yearsWithUs} year${yearsWithUs > 1 ? 's' : ''}${
    leftOverMonths > 0 ? ` ${leftOverMonths} month${leftOverMonths > 1 ? 's' : ''}` : ''
  }`;
});

const personalizedMessage = computed<string>(() => {
  if (timeWithUs.value === '') return 'Welcome back! Great to see you again!';
  return `You've been with us for ${timeWithUs.value}`;
});

const route = useRoute();

const closeMenu = () => {
  menuOpened.value = false;
};
const breakpoints = useBreakpoints(breakpointsTailwind);
onClickOutside(popOver, () => {
  if (breakpoints.isSmaller('lg')) {
    closeMenu();
  }
});
watch(menuOpened, (isOpen) => {
  if (isOpen) {
    selectedLargeMenu.value = undefined;
  }
});

watch(
  () => route?.path,
  () => {
    selectedLargeMenu.value = undefined;
    closeMenu();
  },
);

const focusableElements = ref<NodeListOf<HTMLElement> | HTMLElement[]>([]);
const firstFocusableElement = computed(() => focusableElements.value[0]);
const lastFocusableElement = computed(
  () => focusableElements.value[focusableElements.value.length - 1],
);
const handleForwardKeyTab = (event: Event) => {
  if (lastFocusableElement.value === document.activeElement) {
    event.preventDefault();
    firstFocusableElement.value?.focus();
  }
};
const handleBackwardKeyTab = (event: Event) => {
  if (firstFocusableElement.value === document.activeElement) {
    event.preventDefault();
    lastFocusableElement.value?.focus();
  }
};
const refreshFocusableElements = () => {
  focusableElements.value =
    popOver.value?.querySelectorAll<HTMLElement>(
      `button:not([disabled]), [href], input:not([disabled]),
        select:not([disabled]), textarea:not([disabled]):not([name="g-recaptcha-response"]),
        [tabindex]:not([tabindex="-1"]):not([disabled]),
        details:not([disabled]), summary:not(:disabled)`,
    ) ?? [];
};
const requireUser = (e: ClickEvent) => {
  const destination = e.currentTarget?.getAttribute('href') ?? undefined;
  if (isSignedIn.value) {
    if (!destination) return;
    navigateTo(destination);
  } else {
    handleOpen({ destination });
  }
};
onMounted(() => {
  if (menuOpened.value) {
    setTimeout(() => {
      refreshFocusableElements();
      firstFocusableElement.value?.focus();
    }, 50);
  }
});

useMutationObserver(popOver, refreshFocusableElements, {
  attributes: true,
  childList: true,
  subtree: true,
});

const handleOpenChat = async () => {
  closeMenu();
  await nextTick();
  window.Kustomer.start({}, openChat);
};
</script>

<template>
  <div
    class="fixed top-0 left-0 z-30 flex flex-col w-full h-full bg-white md:w-80 lg:hidden"
    @keydown.exact.tab="handleForwardKeyTab"
    @keydown.shift.tab="handleBackwardKeyTab"
    ref="popOver"
  >
    <div ref="menuTop" class="flex justify-between px-3 py-3 bg-black">
      <div v-if="isSignedIn" class="flex">
        <img class="h-8 mr-2" :src="customerProfileImage" alt="User avatar" />
        <p class="py-2 text-sm font-semibold text-white no-mb">
          {{ dateDiffInDays <= 1 ? `Hello, ${userFirstName}!` : `Welcome back, ${userFirstName}!` }}
        </p>
      </div>
      <div v-else class="flex font-semibold">
        <img class="h-8 mr-2" src="@/assets/account-white.svg" alt="User avatar" />
        <p class="py-2 text-sm text-white no-mb">
          <a class="sign-in-link-styles" href="/sign/in" data-test="sign-in-link">Sign in</a>
          to Account
        </p>
      </div>
      <button class="bg-transparent border-none cursor-pointer" @click="closeMenu">
        <img class="h-6" src="@/assets/close-white.svg" alt="Close menu icon" />
      </button>
    </div>
    <div class="flex px-3 bg-black">
      <div class="px-4 py-2 mr-2 bg-white rounded-t" data-test="nuts-tab">
        <p class="text-sm no-mb">ixclkxk.shop</p>
      </div>
      <RouteLink
        :to="hasBusinessAccount ? '/business' : '/business-signup'"
        class="flex px-4 py-2 bg-black"
        data-test="business-tab"
        :class="{ 'text-black bg-black': true }"
      >
        <p class="text-sm text-neutral-400 no-mb">For Business</p>
        <img
          src="@/assets/search/nuts-mobile-business-icon.svg"
          class="ml-1"
          alt="Close menu icon"
        />
      </RouteLink>
    </div>
    <div class="flex flex-col h-full overflow-y-scroll bg-white" data-test="mobile-menu-nav">
      <div
        class="flex flex-col py-2 border-b border-solid border-nuts-neutral-200"
        data-test="shop-by-category"
        :class="{ 'order-2': isSignedIn }"
      >
        <button
          class="flex items-center justify-between w-full px-4 py-2.5 bg-transparent border-none cursor-pointer"
          data-test="shop-by-category-button"
          @click="toggleCategoryList"
        >
          <p class="text-base font-semibold text-black no-mb font-sofia-pro">Shop By Category</p>
          <img
            v-show="!openCategoryList"
            :aria-hidden="openCategoryList"
            src="@/assets/add-sm.svg"
            alt="open category list plus icon"
            class="h-6"
            data-test="open-category-list"
          />
          <img
            v-show="openCategoryList"
            :aria-hidden="!openCategoryList"
            src="@/assets/remove-sm.svg"
            alt="close category list minus icon"
            class="h-6"
            data-test="close-category-list"
          />
        </button>
        <Collapse :expanded="openCategoryList">
          <nav>
            <ul>
              <li
                v-for="{ menu } in siteNavigation.categories"
                :key="menu.header.url || menu.header.text"
                class="hover:bg-background-secondary hover:cursor-pointer"
              >
                <UnstyledButton
                  class="flex pr-4 items-center w-full pl-7 py-2.5"
                  @click="selectedLargeMenu = menu"
                >
                  <NavHeader
                    class="justify-between w-full"
                    :header="menu.header"
                    opensSubMenu
                    mediumFont
                  />
                </UnstyledButton>
              </li>
            </ul>
          </nav>
        </Collapse>

        <template v-for="(item, i) in siteNavigation.topMenus" :key="`${item.type}_${i}`">
          <LargeMenu
            v-if="item.type === 'LargeMenu'"
            :menu="item"
            @click="selectedLargeMenu = item"
          />
          <SmallMenu v-else :menu="item" />
        </template>

        <div
          v-if="siteNavigation.featuredPage?.text"
          :class="{ 'order-first': siteNavigation.featuredPage.placement === 'first' }"
        >
          <RouteLink
            :to="siteNavigation.featuredPage.url"
            class="flex items-center px-4 py-2 hover:bg-background-secondary no-link-styles"
            v-bind="mobileAttribution(siteNavigation.featuredPage)"
          >
            <NavHeader :header="siteNavigation.featuredPage" mediumFont />
          </RouteLink>
        </div>
      </div>
      <div
        class="py-2 border-b border-solid border-nuts-neutral-200"
        data-test="my-account"
        :class="{ 'order-1': isSignedIn }"
      >
        <div class="px-4" data-test="personalized-message">
          <div
            v-if="isSignedIn && dateDiffInDays > 1"
            class="flex h-10 mb-2 px-2.5 bg-background-secondary items-center border rounded mt-2"
          >
            <p class="mr-2.5 no-mb">🥳</p>
            <p class="text-sm sm:text-xs no-mb">
              {{ personalizedMessage }}
            </p>
          </div>
        </div>
        <div>
          <a
            v-for="{ icon, link } in siteNavigation.accountLinks"
            :key="link.url"
            :href="link.url"
            @click.prevent="requireUser"
            class="flex items-center px-4 py-2 hover:bg-background-secondary no-link-styles"
            data-promo="1"
            data-promo-name="Mobile Menu Nav Links"
            :data-promo-creative="`Account > Link: ${link.text}`"
          >
            <img class="h-6 mr-4" :src="icon" :alt="`${link.text} Icon`" />
            <span class="text-sm no-mb">{{ link.text }}</span>
          </a>
        </div>
      </div>
      <div class="order-3 px-4 py-4">
        <div class="flex flex-col">
          <a class="mb-4 text-sm no-link-styles" href="/orders">Track Order</a>
          <a v-if="isSignedIn" class="mb-4 text-sm sign-out-link-styles" href="/sign/out">
            Sign Out
          </a>
        </div>
        <div class="px-6 py-4 min-w-56 bg-cream sm:px-5" data-test="live-chat">
          <p class="text-sm no-mb">Got Questions? We are here to help!</p>
          <div class="flex">
            <p
              v-if="displayChatInfoText && !isChatStatusPending"
              @click="handleOpenChat"
              class="text-sm font-semibold text-black underline cursor-pointer no-mb decoration-nuts-neutral-200"
            >
              Chat Live Now
            </p>
            <p v-else class="text-sm font-semibold no-mb">1-800-558-6887</p>
            <p class="mx-1 text-sm no-mb">or</p>
            <a href="/" class="text-sm font-semibold visit-help-link-styles">
              Visit Help Center
            </a>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col h-full overflow-y-scroll bg-white slide-sub-menu"
        :class="{ 'translate-x-full': selectedLargeMenu }"
      >
        <LargeMenu
          :defaultExpanded="!isSignedIn"
          :menu="selectedLargeMenu"
          @close="selectedLargeMenu = undefined"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-mb {
  @apply mb-0;
}
.no-link-styles {
  @apply text-black hover:no-underline focus:no-underline cursor-pointer;
}

.sign-in-link-styles {
  @apply text-white underline decoration-true-gray-400 focus:no-underline cursor-pointer;
}

.sign-out-link-styles {
  @apply text-error hover:no-underline focus:no-underline cursor-pointer;
}

.visit-help-link-styles {
  @apply text-black underline decoration-nuts-neutral-200 cursor-pointer;
}

.slide-sub-menu {
  @apply absolute w-full right-full transition-all duration-300 ease-in-out;
  height: calc(100% - 60px);
  top: v-bind('menuTopHeight');
}
</style>
