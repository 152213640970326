<script setup lang="ts">
import { useStore } from 'vuex';

import { useCustomer } from '@/composables/useCustomer';

const store = useStore();
const { customer } = useCustomer(store);
</script>

<template>
  <div
    id="swell-customer-identification"
    :data-authenticated="!!customer"
    :data-email="customer?.email"
    :data-id="customer?.id"
    :data-tags="[]"
    style="display: none"
  ></div>
</template>
