<template>
  <div class="relative flex items-center">
    <div class="border-t border-solid grow border-nuts-neutral-200"></div>
    <span class="mx-4 shrink text-true-gray-500 md:leading-5" :class="fontSizeClass">
      <slot />
    </span>
    <div class="border-t border-solid grow border-nuts-neutral-200"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SeparatorWithText',
  props: {
    fontSizeClass: { required: false, type: String, default: 'text-xs md:text-sm' },
  },
});
</script>
