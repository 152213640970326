<script setup lang="ts">
import { email, required } from '@vee-validate/rules';
import { Form } from 'vee-validate';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { fromAxiosNutsJson, NutsJson, webstore } from '@/api';
import NutsLogo from '@/components/base/assets/NutsLogo.vue';
import EmailTypoSuggestion from '@/components/base/form/EmailTypoSuggestion.vue';
import FormInput, { InputAttributes } from '@/components/base/form/FormInput.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useCustomer } from '@/composables/useCustomer';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';

const store = useStore();
const { customer } = useCustomer(store);

const emailInput = ref(customer.value?.email ?? '');
const { errorMessages, validatorFailed } = useForm();

const subscribed = ref(false);
const isLoading = ref(false);

const rootElement = ref<HTMLElement>();
useRouterLinks(rootElement, useRouter());

const formInputAttributes: Record<'email', InputAttributes> = {
  email: {
    autocomplete: 'email',
    name: 'email',
    placeholder: 'Your Email',
    type: 'email',
  },
};

const rules: Record<'email', FlexibleMessageValidator<string>> = {
  email: (value) => {
    if (!required(value)) return errorMessages.email.required;
    if (!email(value)) return errorMessages.email.email;
    return true;
  },
};

const onSubmit = async () => {
  isLoading.value = true;
  const formData = new FormData();
  formData.set('email', emailInput.value);
  formData.set('detailsForm', '1');
  formData.set('reason', 'footer');
  formData.set('inputFirstName', '');
  await fromAxiosNutsJson<NutsJson>(webstore.post('/subscribe', formData), {});
  subscribed.value = true;
  isLoading.value = false;
};
</script>

<template>
  <div class="bg-nuts-neutral-950">
    <div
      class="flex flex-col items-center self-stretch p-8 mx-auto md:justify-between md:flex-row lg:px-16 2xl:max-w-screen-2xl"
      ref="rootElement"
    >
      <a href="/" data-test="footer-logo">
        <NutsLogo class="w-24 text-white h-9 lg:w-32 lg:h-12" />
      </a>
      <Form
        v-if="!subscribed"
        class="flex flex-col md:flex-row md:items-center"
        @submit="onSubmit"
        v-slot="{ meta: formEmailMeta }"
      >
        <SmallBodyText
          class="pt-6 text-center text-white md:text-right md:mr-3 md:pt-0 md:w-full force-text-sm"
        >
          Subscribe to our newsletter and get exclusive offers!
        </SmallBodyText>
        <div class="relative w-full h-full">
          <div class="md:mr-3">
            <FormInput
              v-model="emailInput"
              aria-label="email"
              class="w-full mt-3 md:mt-0"
              :inputAttributes="formInputAttributes.email"
              :validator="rules.email"
              showPlaceholder
              showLabel
            />
          </div>
          <EmailTypoSuggestion v-model="emailInput" />
        </div>
        <ThemedButton
          type="submit"
          size="large"
          theme="dark-yellow"
          class="w-full mt-4 md:mt-0 md:w-min"
          :disabled="(formEmailMeta.touched && validatorFailed(formEmailMeta)) ?? false"
          :isLoading="isLoading"
        >
          Subscribe
        </ThemedButton>
      </Form>
      <BaseBodyText
        v-else
        class="pt-6 font-semibold text-center text-white md:text-right md:mr-3 md:pt-0 md:w-full"
      >
        Thank you for subscribing!
      </BaseBodyText>
    </div>
    <div class="py-6 border-t border-white border-solid">
      <ul
        class="flex flex-wrap justify-center mx-auto text-center 2xl:max-w-screen-2xl"
        data-test="bottom-shelf"
      >
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/about-us">About us</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/shipping">Shipping</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="">Help</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/privacy">Privacy policy</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/california-privacy-notice"
            >CA privacy notice</a
          >
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/accessibility.html">Accessibility</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/terms-and-conditions">T & C</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/ccpa-opt-out"
            >Do Not Sell or Share My Personal Information</a
          >
        </li>
        <li class="mt-2 mr-6">
          <a
            class="text-base no-link-styles"
            href="/opt-out-of-use-sharing-of-sensitive-information-form"
          >
            Limit the Use of My Sensitive Personal Information
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-link-styles {
  @apply text-white hover:no-underline focus:no-underline cursor-pointer;
}

.force-text-sm {
  @apply text-sm;
}
</style>
