<template>
  <div
    class="flex items-start px-5 py-4 text-sm font-normal border-solid shadow font-proxima-nova rounded-3xl"
    :class="themeClasses"
  >
    <img v-if="theme === 'error'" :src="errorIcon" alt="error icon" class="mr-4" />
    <img v-if="theme === 'warning'" :src="warningIcon" alt="warning icon" class="mr-4" />
    <img v-else :src="infoIcon" alt="info icon" class="mr-4" />
    <slot />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'Notification',
  props: {
    theme: { required: false, type: String },
  },
  setup(props) {
    const themeClasses = computed(() => {
      if (props.theme === 'error') {
        return ['border-2 bg-red-50 border-red-700'];
      }
      if (props.theme === 'warning') {
        return ['border-2 bg-orange-50 border-yellow-200 text-orange-700'];
      }

      return ['border border-true-gray-200'];
    });

    return {
      errorIcon: nutshell['img/field_error.svg'],
      warningIcon: nutshell['img/warning.svg'],
      infoIcon: nutshell['img/info.svg'],
      themeClasses,
    };
  },
});
</script>
