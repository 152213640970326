/* eslint-disable import/prefer-default-export */
import { multiply } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, Ref } from 'vue';

import {
  addToFavorites as addToFavoritesApi,
  removeFromFavorites as removeFromFavoritesApi,
} from '@/api/favorites';
import { isGiftCertificate as isGiftCertificateUtil } from '@/utils/gift';
import { computeSavings } from '@/utils/money';
import {
  getPrice,
  isCustomizableProduct,
  NutsProduct,
  NutsProductVariant,
  priceForQuantity,
  PurchaseOptions,
} from '@/utils/product';

export interface CostSaving {
  label: string;
  type: 'auto-delivery' | 'bulk' | 'cart' | 'product' | 'wholesale';
  value?: number;
}

export const useProductDetail = (product: Ref<NutsProduct>) => {
  const allBackordered = computed(() => product.value.variantGroups.every((v) => v.backordered));
  const bulkVariants = computed(() =>
    product.value.variants
      .filter((v) => v.prices.some((p) => !!p.tiers))
      .sort((a, b) => a.weight - b.weight),
  );
  const isCustomizable = computed(() => isCustomizableProduct(product.value));
  const isGiftCertificate = computed(() => isGiftCertificateUtil(product.value));

  const addToFavorites = () => addToFavoritesApi(product.value.key);
  const removeFromFavorites = () => removeFromFavoritesApi(product.value.key);

  const getRelativeBasePrice = (
    variant: NutsProductVariant,
    selections?: Pick<PurchaseOptions, 'autoDelivery'>,
  ): NutsProductVariant['prices'][number] => {
    const prices = variant.prices.filter((p) => !!p.tiers);
    const distributionChannelKey = variant.wholesale ? undefined : selections?.autoDelivery?.key;
    const fallbackPrice = getPrice(variant, distributionChannelKey);

    if (!prices.length && !variant.wholesale) return fallbackPrice;

    const baseVariant = bulkVariants.value.find((v) => v.weight === 1) ?? bulkVariants.value[0];
    if (!baseVariant) return fallbackPrice;

    const price = getPrice(baseVariant, distributionChannelKey);

    return {
      ...price,
      value: multiply(price.value, variant.weight / baseVariant.weight),
    };
  };

  const calculateCostSavings = (
    variant: NutsProductVariant,
    selections: Pick<PurchaseOptions, 'quantity' | 'autoDelivery'>,
  ) => {
    const savings: CostSaving[] = [];
    const price = getPrice(variant, selections.autoDelivery?.key);

    const autoDeliveryDiscount = variant.autoDeliveryEligible && !variant.wholesale;

    if (selections.autoDelivery && autoDeliveryDiscount) {
      savings.push({
        label: `${selections.autoDelivery?.discount}% off Auto-Delivery`,
        type: 'auto-delivery',
        value: selections.autoDelivery?.discount,
      });
    }

    if (price.discounted) {
      const saving = computeSavings(price.value, price.discounted.value);
      const description = price.discounted.discount.description ?? '';
      let label = saving.percent > 0 ? `${saving.percent}% off` : '';

      if (description.length) label += label.length ? ` - ${description}` : description;

      savings.push({
        label: label.length ? label : 'Sale Discount',
        type: 'product',
        value: saving.percent,
      });
    } else {
      const basePrice = getRelativeBasePrice(variant, selections).value;
      const unitPrice = priceForQuantity(variant, selections);
      const tierSavings = computeSavings(basePrice, unitPrice).percent;

      if (tierSavings > 0) {
        const tier = price.tiers?.filter((t) => t.minimumQuantity <= selections.quantity).pop();
        const minimumQuantity = tier?.minimumQuantity ?? 1;

        savings.push({
          label: `${tierSavings}% off Bulk Discount${
            minimumQuantity - 1 ? ` for ${minimumQuantity}+ units` : ''
          }`,
          type: variant.wholesale ? 'wholesale' : 'bulk',
          value: tierSavings,
        });
      }
    }

    return savings;
  };

  return {
    addToFavorites,
    allBackordered,
    bulkVariants,
    calculateCostSavings,
    getRelativeBasePrice,
    isCustomizable,
    isGiftCertificate,
    removeFromFavorites,
  };
};
