<script setup lang="ts">
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { SearchResponse } from 'instantsearch.js';
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';

import { AlgoliaVariant } from '@/api/algolia';
import RouteLink from '@/components/base/RouteLink.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { gtag } from '@/utils/analytics';
import { ProductCard } from '@/utils/productCard';

const props = defineProps<{ index?: SearchResponse<AlgoliaVariant> }>();
const emit = defineEmits(['navigating']);

const indexHits = computed(() => props.index?.hits);
const { navigateTo } = useRouteChange(useRouter());
const redirect = (path: string) => {
  emit('navigating');
  navigateTo(path);
};

const handleEvent = (item: AlgoliaVariant, index: number, eventName: string) => {
  const productCard = ProductCard.fromAlgolia(item);
  gtag('event', eventName, {
    item_list_name: 'Autocomplete',
    items: [
      {
        index,
        indexName: 'Products',
        item_id: item.Product.key,
        item_name: item.Product.name,
        item_variant: item.sku,
        item_variant_name: item.shortVariantName,
        price: item.singlePiecePrice,
        price_before_discount: item.singlePiecePrice,
        cost: item.cost,
        coupon: productCard.totalSavings?.description?.en,
        discount: dollars(productCard.totalSavings?.value || from(0)),
        quantity: 1,
        autodelivery_interval: undefined,
        merchandising_category: item.Product.merchandisingCategory,
        weight: item.weight,
      },
    ],
  });
  if (eventName === 'select_item') redirect(item.path);
};

watch(
  indexHits,
  (hits) => {
    hits?.forEach((hit, index) => {
      handleEvent(hit, index, 'view_item_list');
    });
  },
  { deep: true },
);
</script>

<template>
  <div class="suggestions">
    <div
      v-for="(hit, i) in index?.hits ?? []"
      :key="hit.objectID"
      class="suggestion"
      @click="handleEvent(hit, i, 'select_item')"
    >
      <img
        class="algolia-search-featured-image"
        :src="hit.Product.listingImageUrl"
        :alt="hit.Product.name"
      />
      <div class="algolia-search-product-description-container">
        <h4>
          <RouteLink :to="hit.path">{{ hit.Product.name }}</RouteLink>
        </h4>
        <span class="algolia-search-product-description">{{ hit.Product.shortDescription }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.algolia-search-product-description {
  font-size: 12px;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-height: 200px;
}
.algolia-search-product-description-container {
  width: 73%;
  padding: 12px 1%;
  vertical-align: top;
  max-height: 236px;
  float: right;
  overflow: hidden;
}
.algolia-search-featured-image {
  max-width: 25%;
  max-height: 248px;
}
.suggestion {
  clear: both;
  cursor: pointer;
  &:hover {
    background-color: $coconut-creamapprox;
  }
}
:deep(a) {
  text-decoration: underline;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: $line-height-large;
}
</style>
