<template>
  <div data-test="digital-gifting-saved-recipient">
    <div class="w-full">
      <div
        class="flex content-center justify-between mb-2"
        data-test="digital-gifting-saved-recipient-label"
      >
        <strong class="text-sm md:text-base">Recipient {{ recipientNumber }}:</strong>
        <div class="flex text-xs md:text-sm">
          <div
            v-if="editable"
            class="underline cursor-pointer"
            @click="handleEditClick"
            @keydown.enter="handleEditClick"
            tabindex="0"
          >
            Edit
          </div>
          <div
            v-if="preview"
            class="underline cursor-pointer ml-4"
            @click="handleOpenPreviewEcard"
            @keydown.enter="handleOpenPreviewEcard"
            :tabindex="isEditing ? -1 : 0"
          >
            Preview eCard
          </div>
        </div>
      </div>
      <div
        class="text-sm md:text-base text-left hyphens"
        data-test="digital-gifting-saved-recipient-info"
      >
        <div>{{ recipient.name }}</div>
        <div>{{ recipient.email }}</div>
        <div>{{ recipient.message }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { DigitalGiftRecipient } from '@/utils/cart';

export default defineComponent({
  name: 'RecipientSummary',
  props: {
    editable: { required: true, type: Boolean, default: true },
    isEditing: { required: false, type: Boolean, default: false },
    preview: { required: false, type: Boolean, default: false },
    recipient: { required: true, type: Object as PropType<DigitalGiftRecipient> },
    recipientNumber: { required: true, type: Number as PropType<number> },
  },
  emits: ['edit-recipient', 'open-preview-ecard'],
  setup(props, { emit }) {
    const handleEditClick = () => emit('edit-recipient', props.recipientNumber);
    const handleOpenPreviewEcard = () => emit('open-preview-ecard', props.recipient);

    return {
      handleEditClick,
      handleOpenPreviewEcard,
    };
  },
});
</script>
<style lang="scss" scoped>
.hyphens {
  hyphens: auto;
}
</style>
