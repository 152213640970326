<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useExpressCheckout } from '@/composables/useExpressCheckout';
import { CheckoutEvents } from '@/utils/analytics';
import { retry } from '@/utils/retry';

const props = defineProps({
  buttonHeight: { required: false, type: Number, default: 48 },
  trackingEventLocation: { required: true, type: String },
});

const router = useRouter();
const store = useStore();
const { requestPayPalCheckout } = useExpressCheckout(store, router);

const el = ref<HTMLElement>();

onMounted(() => {
  retry(async () => {
    const { useBraintree } = await import('@/stores/braintree');
    const braintreeStore = useBraintree();
    const dataCollector = await braintreeStore.initDataCollector();
    const paypalCheckout = await braintreeStore.initPayPal();

    if (!el.value) throw Error('no el after mount?');
    if (!window.paypal || !paypalCheckout) {
      throw Error('Failed to load PayPal!');
    }

    window.paypal
      .Buttons({
        ...requestPayPalCheckout(paypalCheckout, dataCollector.deviceData),
        style: {
          color: 'white',
          layout: 'vertical',
          label: 'paypal',
          height: props.buttonHeight,
        },
        onClick() {
          CheckoutEvents.event({
            category: 'Express Checkout',
            action: 'PayPal',
            label: `Click in ${props.trackingEventLocation}`,
          });
        },
      })
      .render(el.value!);
  });
});
</script>

<template>
  <div ref="el" class="relative z-0 h-10 md:h-12" data-test="paypal-button" />
</template>
