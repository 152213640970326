<script setup lang="ts">
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { useIntersectionObserver } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import fetchProduct from '@/api/productDetail';
import AddToCartOrCustomize from '@/components/base/add-to-cart/AddToCartOrCustomize.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import TagBadge from '@/components/base/TagBadge.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import BulkDiscountModal from '@/components/new-pdp/buy-block/BulkDiscountsModal.vue';
import PriceStrikethrough from '@/components/product-card/PriceStrikethrough.vue';
import ProductImage from '@/components/product-card/ProductImage.vue';
import ProductName from '@/components/product-card/ProductName.vue';
import Ratings from '@/components/product-card/Ratings.vue';
import SizeInfoAndSelection from '@/components/product-card/SizeInfoAndSelection.vue';
import { useDynamicYield } from '@/composables/useDynamicYield';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useAutoDelivery } from '@/stores/autoDelivery';
import analytics, { getListMetadata, gtag, ListMetadata, setListMetadata } from '@/utils/analytics';
import { NutsProduct } from '@/utils/product';
import { ProductCardData } from '@/utils/productCard';

export type Layout = 'plp' | 'recommendations';

const props = withDefaults(
  defineProps<{
    analyticsList?: string;
    clickTrackable?: boolean;
    impressionTrackable?: boolean;
    index: number;
    layout?: Layout;
    product: ProductCardData;
    title?: string;
    trackingEventLocation?: string;
    variants?: ProductCardData[];
  }>(),
  {
    clickTrackable: true,
    layout: 'recommendations',
    impressionTrackable: true,
    trackingEventLocation: '',
  },
);
const store = useStore();
const { flags } = useFeatureFlags(store);
const { autoDeliveryChannel } = storeToRefs(useAutoDelivery());

const existingListMetadata = ref<ListMetadata>();
const selectedSku = ref(props.product.sku);
const selectedItem = computed(
  () => props.variants?.find((variant) => variant.sku === selectedSku.value) ?? props.product,
);
const displayDiscount = computed(
  () =>
    !!selectedItem.value?.displayDiscountPercent &&
    (selectedItem.value.bulk || selectedItem.value.wholesale),
);

const sortedVariants = (variants: ProductCardData[]) => {
  const collator = new Intl.Collator('en', { numeric: true });
  return variants.sort((a, b) => collator.compare(a.unitName, b.unitName));
};

const sizesAvailable = computed(() => {
  if (props.variants && props.variants.length > 1) {
    return sortedVariants(props.variants).map((variant) => ({
      label: variant.unitName,
      value: variant.sku,
    }));
  }
  return [{ label: props.product.unitName, value: props.product.sku }];
});

const productCard = ref<HTMLElement>();

const isModalOpen = ref(false);
const productDetails = ref<NutsProduct>();

const getProductDetails = async () => {
  const [
    {
      body: { data: response },
    },
    { HTMLCleaner },
  ] = await Promise.all([fetchProduct(props.product.productKey), import('@/utils/html_cleaner')]);

  const rawProduct = {
    ...response.product,
    masterData: {
      current: {
        ...response.product.masterData.current,
        description: HTMLCleaner.clean(response.product.masterData.current.description),
        healthTips: HTMLCleaner.clean(
          response.product.masterData.current.variants[0]?.attributesRaw?.find(
            (attribute: { name: string }) => attribute.name === 'healthTips',
          )?.value.en,
        ),
        testDescription: HTMLCleaner.clean(
          response.product.masterData.current.masterVariant.attributesRaw.find(
            (attribute: { name: string }) => attribute.name === 'testDescription',
          )?.value.en,
        ),
      },
    },
  };

  productDetails.value = NutsProduct.fromCT(rawProduct);
  isModalOpen.value = true;
};

const microData = computed(() => ({
  id: `0${props.product.sku}`,
  position: props.index,
  name: props.product.name,
  price: props.product.price,
  list: (props.analyticsList || props.title) ?? '',
}));

const gtagItem = computed(() => ({
  items: [
    {
      index: props.index,
      item_id: props.product.productKey,
      item_list_name: props.analyticsList ?? props.title,
      item_name: props.product.name,
      item_variant: selectedItem.value.sku,
      item_variant_name: selectedItem.value.unitName,
      price: selectedItem.value.price,
      price_before_discount: dollars(
        selectedItem.value.totalSavings?.comparisonPrice ?? selectedItem.value.piecePrice,
      ),
      cost: selectedItem.value.cost,
      coupon: selectedItem.value.totalSavings?.description?.en,
      discount: dollars(selectedItem.value.totalSavings?.value || from(0)),
      quantity: 1,
      autodelivery_interval: undefined,
      merchandising_category: props.product.merchandisingCategory,
      weight: selectedItem.value.weight,
    },
  ],
}));

const { reportDyEngagement } = useDynamicYield(store);

const listMetadata = computed<ListMetadata>(() => ({
  ...existingListMetadata.value,
  list: props.analyticsList || props.title || '',
  indexName: 'Products',
  position: props.index,
  searchQueryID: props.product.searchQueryId,
}));

const reportClick = () => {
  if (!props.clickTrackable) return;
  const { analyticsList, index, product, title } = props;
  if (product.decisionId && product.slotId) {
    reportDyEngagement({
      decisionId: product.decisionId,
      slotId: product.slotId,
    });
  }

  analytics.sendEvent('productClick', (analyticsList || title) ?? '', {
    ecommerce: {
      click: {
        actionField: {
          list: microData.value.list,
        },
        products: [microData.value],
      },
    },
  });

  setListMetadata(listMetadata.value);

  gtag('event', 'select_item', gtagItem.value);
};

const impressionLogged = ref(false);
const reportImpression = () => {
  if (!props.impressionTrackable || impressionLogged.value) return;
  impressionLogged.value = true;
  analytics.sendEvent('productImpressions', props.title ?? '', {
    ecommerce: {
      currencyCode: 'USD',
      impressions: [microData.value],
    },
  });
  gtag('event', 'view_item_list', gtagItem.value);
};

useIntersectionObserver(productCard, ([{ isIntersecting }]) => {
  if (isIntersecting) reportImpression();
});

onMounted(() => {
  existingListMetadata.value = getListMetadata();
});

watch(
  () => props.product,
  () => {
    selectedSku.value = props.product.sku;
  },
);
</script>

<template>
  <div
    class="relative flex flex-col justify-between overflow-hidden bg-white rounded-xl"
    :class="layout === 'recommendations' ? 'recommendations-slide p-2 md:p-3' : 'plp-slide h-full'"
  >
    <TagBadge
      :tags="product.keywords ?? []"
      class="absolute"
      :class="flags.badgeRedesign ? 'right-0 top-0' : 'right-3 top-3'"
    />
    <div
      ref="productCard"
      :class="layout === 'recommendations' ? 'contents' : 'flex flex-col h-full'"
    >
      <RouteLink :to="product.path" class="mb-2.5" @click="reportClick">
        <ProductImage :imageUrl="product.imageUrl" :productName="product.name" />
      </RouteLink>
      <RouteLink
        :to="product.path"
        class="flex-initial overflow-hidden no-underline hover:no-underline"
        :class="{ 'order-3': layout === 'plp' }"
        @click="reportClick"
      >
        <ProductName :productName="product.name" />
        <Ratings :averageRating="product.averageRating" :totalRatings="product.totalRatings" />
        <PriceStrikethrough
          class="mt-2"
          :comparisonPrice="selectedItem.displayComparisonPrice"
          :discountPercent="selectedItem.displayDiscountPercent"
          :displayDiscount="displayDiscount"
          :piecePrice="selectedItem.price"
        />
      </RouteLink>
      <div v-if="layout === 'plp'" class="order-4">
        <div
          v-if="selectedItem.autoDeliveryEligible && !selectedItem.wholesale"
          class="flex items-start mt-2"
        >
          <img
            src="@/assets/auto-delivery/arrows.svg"
            alt="auto delivery icon"
            class="mr-2 md:mt-0.5"
          />
          <SmallBodyText class="text-black">
            {{ `Save ${autoDeliveryChannel.discount}% with Auto-Delivery` }}
          </SmallBodyText>
        </div>
        <div v-if="selectedItem.bulk && !selectedItem.wholesale" class="flex items-start mt-1">
          <img src="@/assets/bulk.svg" alt="bulk icon" />
          <SmallBodyText class="ml-2 mr-1 text-black">Buy more, save more</SmallBodyText>
          <BulkDiscountModal
            :isOpen="isModalOpen"
            :product="productDetails"
            @open-modal="getProductDetails()"
            @close-modal="isModalOpen = false"
          >
            <img src="@/assets/question-circle.svg" alt="bulk icon" class="h-4 mb-1.5" />
          </BulkDiscountModal>
        </div>
      </div>
      <div class="flex flex-auto my-2" :class="{ 'order-5 items-end': layout === 'plp' }">
        <SizeInfoAndSelection
          v-if="!selectedItem.requiresCustomization"
          v-model:selectedSku="selectedSku"
          :hasSiblings="selectedItem.hasSiblings"
          :shortUnitName="selectedItem.unitName"
          :sizesAvailable="sizesAvailable"
        />
      </div>
      <AddToCartOrCustomize
        :class="{ 'order-2 my-2': layout === 'plp' }"
        :cost="selectedItem.cost"
        :coupon="selectedItem.totalSavings?.description?.en"
        data-test="add-to-cart-or-customize"
        :discount="selectedItem.totalSavings?.value"
        :listMetadata="listMetadata"
        :listName="analyticsList || title"
        :merchandisingCategory="selectedItem.merchandisingCategory"
        :name="selectedItem.name"
        :outOfStock="!selectedItem.inStock"
        :path="selectedItem.path"
        :productKey="selectedItem.productKey"
        :requiresCustomization="selectedItem.requiresCustomization"
        :singlePiecePrice="parseFloat(selectedItem.price)"
        :trackingEventLocation="trackingEventLocation"
        :variantName="selectedItem.unitName"
        :variantSku="selectedItem.sku"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.recommendations-slide {
  scroll-snap-align: start;
  min-width: 160px;
  @include respond-min($screen-md-min) {
    min-width: 173px;
  }
  @include respond-min($screen-lg-min) {
    min-width: 198px;
  }
  border: 1px solid $mercury-approx;
}
.plp-slide {
  scroll-snap-align: start;
  min-width: 150px;
  @include respond-min($screen-md-min) {
    min-width: 183px;
  }
  @include respond-min($screen-lg-min) {
    min-width: 227px;
  }
}
</style>
