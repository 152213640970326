/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { computed, ref } from 'vue';
import { Store } from 'vuex';

import { AlgoliaVariant } from '@/api/algolia';
import { getRecommendations } from '@/api/customer';
import money from '@/filters/money';
import { BooleanOrBooleanString } from '@/utils/cms';

interface CampaignBannerReference {
  dySelector: string;
  isCampaign: true;
  containerId: string;
  ssr?: boolean;
}

interface CampaignBannerVariation extends CampaignBannerReference {
  key: number;
  type: 'banner';
  variation:
    | {
        css?: string;
        decisionId?: string;
        html: string;
      }
    | undefined;
}

interface ComponentReference {
  component: string;
  fullWidth?: boolean;
  promoCreative?: string;
  promoName?: string;
  props: Record<string, any>;
  slots?: ComponentReference[];
}

interface ComponentVariation extends ComponentReference {
  key: number;
  type: 'component';
}

export interface Recommendation {
  all_variants_out_of_stock?: BooleanOrBooleanString;
  auto_delivery_eligible?: BooleanOrBooleanString;
  average_rating: string;
  bulk?: BooleanOrBooleanString;
  cost: string;
  decisionId?: string;
  display_comparison_price?: string;
  display_discount_percent?: string;
  display_price: string;
  group_id: string;
  has_siblings?: BooleanOrBooleanString;
  image_url: string;
  in_stock: boolean;
  keywords: string[];
  merchandising_category?: string;
  name: string;
  path_name: string;
  purchased_at?: string;
  price: number;
  prices_json: string;
  primary_category_key?: string;
  primary_merchandising_category?: string;
  product_key: string;
  requires_customization: BooleanOrBooleanString;
  short_unit_name: string;
  sku: string;
  slotId?: string;
  total_ratings: string;
  total_reviews: string;
  unit_name: string;
  wholesale?: BooleanOrBooleanString;
}

interface RecommendationsReference {
  analyticsList?: string;
  containerId?: string;
  enableAddToCart: boolean;
  isCampaign?: false;
  link?: string;
  ruleContext?: string;
  ssr?: boolean;
  title: string;
}

export interface DyRecommendationsReference extends RecommendationsReference {
  algoliaTag: string;
  dySelector: string;
}

export interface WebstoreRecommendation {
  average_rating: string;
  display_price: string;
  image_url: string;
  in_stock: boolean;
  keywords: string[];
  name: string;
  path_name: string;
  purchased_at?: string;
  price: string;
  requires_customization: BooleanOrBooleanString;
  sku: string;
  total_ratings: string;
  total_reviews: string;
  // TODO: add to API response
  bulk?: BooleanOrBooleanString;
  cost?: string;
  group_id?: string;
  prices_json?: string;
  product_key?: string;
  short_unit_name?: string;
  unit_name?: string;
  wholesale?: BooleanOrBooleanString;
}

interface WebstoreRecommendationsReference extends RecommendationsReference {
  recommendationsEndpoint: {
    params?: Record<string, any>;
    path: string;
  };
}

export interface DyRecommendationsVariation extends DyRecommendationsReference {
  key: number;
  type: 'recommendations';
  variation: Recommendation[] | undefined;
}

interface WebstoreRecommendationsVariation extends WebstoreRecommendationsReference {
  key: number;
  type: 'recommendations';
  variation: WebstoreRecommendation[] | undefined;
}

interface DyConfigurationVariation {
  config: (
    | CampaignBannerReference
    | ComponentVariation
    | DyRecommendationsReference
    | WebstoreRecommendationsReference
  )[];
}

interface DyRecommendationsDecision {
  decisionId: any;
  id: any;
  type: 'RECS_DECISION';
  variations: [
    {
      payload: {
        data: Recommendation[];
      };
    },
  ];
}

interface DyVariationsDecision {
  decisionId: any;
  id: any;
  type: 'DECISION';
  variations: [
    {
      payload: {
        data: CampaignBannerVariation['variation'] | any | any[];
      };
    },
  ];
}

interface DyChooseResponse {
  choices: DyRecommendationsDecision | DyVariationsDecision;
}

interface PageAttributes {
  isB2bContact: 'false' | 'true';
  isContact: 'false' | 'true';
  isCustomer: 'false' | 'true';
  newSession: 'false' | 'true';
}

export type PageType = 'CART' | 'CATEGORY' | 'HOMEPAGE' | 'OTHER' | 'PRODUCT';

export const hasProducts = (list?: Recommendation[]): list is Recommendation[] =>
  (list?.length ?? 0) > 0;

export const algoliaToDyProductModels = (algoliaProducts: AlgoliaVariant[]) => {
  const dyProductModels = (algoliaProducts ?? []).map<Recommendation>((product) => {
    const {
      bulk,
      comparisonPrice,
      cost,
      discountPercent,
      hasSiblings,
      path,
      sku,
      singlePiecePrice,
      shortVariantName,
      formattedUnitPrice,
      Product_allVariantsOutOfStock: allVariantsOutOfStock,
      Product: {
        key,
        name,
        listingImageUrl,
        merchandisingCategory,
        requiresCustomization,
        reviews: { averageRating, totalRatings, totalReviews },
        tags,
      },
    } = product;

    return {
      average_rating: `${averageRating ?? 0}`,
      bulk: bulk ? 'true' : 'false',
      cost,
      display_comparison_price:
        comparisonPrice && comparisonPrice > 0 ? money(comparisonPrice) : undefined,
      display_discount_percent:
        discountPercent && discountPercent > 0 ? `${discountPercent}%` : undefined,
      display_price: formattedUnitPrice,
      group_id: key,
      has_siblings: hasSiblings,
      image_url: listingImageUrl,
      in_stock: !allVariantsOutOfStock,
      keywords: tags,
      name,
      path_name: path,
      price: singlePiecePrice,
      prices_json: JSON.stringify(product.prices),
      merchandising_category: merchandisingCategory,
      product_key: key,
      primary_merchandising_category: merchandisingCategory,
      requires_customization: requiresCustomization ? 'true' : 'false',
      short_unit_name: shortVariantName,
      sku,
      total_ratings: `${totalRatings}`,
      total_reviews: `${totalReviews}`,
      unit_name: shortVariantName,
    };
  });

  return dyProductModels;
};

// TODO: remove after updating API response
export function webstoreToDyRecommendations(webstoreRecommendations: WebstoreRecommendation[]) {
  return webstoreRecommendations.map<Recommendation>((recommendation) => ({
    bulk: false,
    cost: '',
    group_id: '',
    prices_json: '[]',
    product_key: '',
    short_unit_name: '',
    unit_name: '',
    wholesale: false,
    ...recommendation,
    price: Number(recommendation.price),
  }));
}

export function useDynamicYield(
  store: Store<any>,
  dyConfigSelector?: string,
  hydrateRows?: boolean,
) {
  const namespace = 'dynamicYieldModule';
  const dyRows = ref<
    (
      | CampaignBannerVariation
      | ComponentVariation
      | DyRecommendationsVariation
      | WebstoreRecommendationsVariation
    )[]
  >([]);

  const getDyVariations = computed(() => store.getters[`${namespace}/getDyVariations`]);
  const getContext = computed(() => store.state[namespace].context);
  const pageAttributes = computed<PageAttributes>(() => store.state[namespace].pageAttributes);
  const pageType = computed<PageType>(() => store.state[namespace].context.type ?? 'OTHER');
  const webstoreRecommendationsByPath = computed<Record<string, Recommendation[] | undefined>>(
    () => store.state[namespace].webstoreRecommendations,
  );

  if (hydrateRows && dyConfigSelector?.includes('[CONFIG]')) {
    const { config } = <DyConfigurationVariation>(
      (getDyVariations.value[dyConfigSelector] ?? { config: [] })
    );
    if (config.length) {
      dyRows.value = config.reduce<typeof dyRows.value>((hydratedRows, rawRow, i) => {
        const key = i;
        let row: (typeof dyRows.value)[0] | undefined;
        if ('component' in rawRow) {
          row = <ComponentVariation>{ ...rawRow, key, type: 'component' };
        }
        const type = 'isCampaign' in rawRow && rawRow.isCampaign ? 'banner' : 'recommendations';
        if ('dySelector' in rawRow) {
          row = <CampaignBannerVariation | DyRecommendationsVariation>{
            ...rawRow,
            key,
            type,
            variation: getDyVariations.value[rawRow.dySelector],
          };
        } else if ('recommendationsEndpoint' in rawRow) {
          row = <WebstoreRecommendationsVariation>{
            ...rawRow,
            key,
            type,
            variation: webstoreRecommendationsByPath.value[rawRow.recommendationsEndpoint.path],
          };
        }
        return row ? [...hydratedRows, row] : hydratedRows;
      }, []);
    }
  }

  const fetchDyVariations = (
    campaignSelectors?: string[],
    reportPageView?: boolean,
    page?: { locale: string },
  ): Promise<AxiosResponse<DyChooseResponse>> => {
    const campaigns = campaignSelectors ?? [dyConfigSelector];
    return store.dispatch(`${namespace}/fetchDyVariations`, { campaigns, page, reportPageView });
  };

  const setWebstoreRecommendations = (
    recommendationsByPath: typeof webstoreRecommendationsByPath.value,
  ) => store.commit(`${namespace}/SET_WEBSTORE_RECOMMENDATIONS`, recommendationsByPath);

  const loadRecommendationRows = async (campaignSelector?: string) => {
    const isSSR = import.meta.env.SSR;
    const campaign = campaignSelector ?? dyConfigSelector;
    if (!campaign)
      throw Error(
        'must provide a campaign selector to `useDynamicYield()` or `loadRecommendationRows()`',
      );
    await fetchDyVariations();
    const { config } = <DyConfigurationVariation>(
      (getDyVariations.value[campaign] ?? { config: [] })
    );
    if (!config?.length) {
      dyRows.value = [];
      return;
    }
    const dyRowsWithoutVariation = dyRows.value.filter(
      (row) => 'variation' in row && !row.variation && 'dySelector' in row && row.dySelector,
    );
    const allExperiencesPreloaded = !dyRowsWithoutVariation.length;
    const dySelectors = config
      .filter((experience): experience is CampaignBannerReference | DyRecommendationsReference => {
        if (!('dySelector' in experience)) return false;
        const thisExperiencePreloaded = !dyRowsWithoutVariation.find(
          (row) => 'dySelector' in row && row.dySelector === experience.dySelector,
        );
        if (thisExperiencePreloaded && !allExperiencesPreloaded) return false;
        if (isSSR && !experience.ssr) return false;
        return !!experience.dySelector;
      })
      .map((c) => c.dySelector);
    const promises: (ReturnType<typeof fetchDyVariations> | Promise<Recommendation[]>)[] = [
      fetchDyVariations(dySelectors),
    ];

    config.forEach((el, index) => {
      if (!('recommendationsEndpoint' in el)) return;
      if (isSSR && !el.ssr) return;
      const { path, params } = el.recommendationsEndpoint;
      promises.push(
        getRecommendations(path, params)
          .then((webstoreRecommendations) => {
            const recommendations = webstoreToDyRecommendations(webstoreRecommendations);
            setWebstoreRecommendations({ [path]: recommendations });
            return recommendations;
          })
          .catch((e) => {
            console.error('error while fetching recommendation', e);
            return [];
          }),
      );
    });
    await Promise.all(promises).then(() => {
      dyRows.value = config.map((el, index) => {
        const key = index;
        if ('component' in el) return <ComponentVariation>{ ...el, key, type: 'component' };
        if ('dySelector' in el) {
          const type = el.isCampaign ? 'banner' : 'recommendations';
          return <CampaignBannerVariation | DyRecommendationsVariation>{
            ...el,
            key,
            type,
            variation: getDyVariations.value[el.dySelector],
          };
        }
        return <WebstoreRecommendationsVariation>{
          ...el,
          key,
          type: 'recommendations',
          variation: webstoreRecommendationsByPath.value[el.recommendationsEndpoint.path],
        };
      });
    });
  };
  const reportDyEngagement = (context: { decisionId?: string; slotId?: string }) =>
    store.dispatch(`${namespace}/reportDyEngagement`, context);
  const reportEngagement = (selector: string) => {
    const variation = getDyVariations.value[selector];
    if (variation) {
      store.dispatch(`${namespace}/reportEngagement`, {
        decisionId: variation.decisionId,
        variationId: variation.id,
      });
    }
  };
  const setDyPageContext = (context: {
    mode?: string;
    countAsPageview?: boolean;
    path?: string;
    type?: string;
    data?: any[];
  }) => store.dispatch(`${namespace}/setDyPageContext`, context);

  return {
    dyRows,
    fetchDyVariations,
    getContext,
    getDyVariations,
    loadRecommendationRows,
    reportDyEngagement,
    reportEngagement,
    setDyPageContext,
    pageAttributes,
    pageType,
  };
}

export default {};
