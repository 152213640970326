<template>
  <div class="w-full h-24 border border-solid border-true-gray-300 rounded-xl">
    <label
      :for="label"
      class="flex items-center justify-between h-full my-0 ml-4 cursor-pointer"
      data-test="curate-list-label"
    >
      <div class="flex items-center gap-4">
        <input
          type="checkbox"
          :id="label"
          :name="label"
          class="inline-block w-4 h-4 m-0 base-checkbox"
          @change="$emit('change', label.replace(/\s/g, ''))"
        />
        <div class="text-base font-semibold">{{ label }}</div>
      </div>
      <div class="w-2/5 h-full bg-center bg-cover rounded-r-lg checkbox-image" />
    </label>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckboxWithImage',
  props: {
    label: { required: true, type: String },
    imageSrc: { required: true, type: String },
  },
  setup(props) {
    return {
      image: computed(() => `url(${props.imageSrc})`),
    };
  },
});
</script>

<style lang="scss" scoped>
.base-checkbox {
  accent-color: #4a8500;
}
.checkbox-image {
  background-image: v-bind(image);
}
</style>
