<script setup lang="ts">
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import AddToCartOrCustomize from '@/components/base/add-to-cart/AddToCartOrCustomize.vue';
import HorizontalProductCard from '@/components/base/layout/HorizontalProductCard.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import WindowObserver from '@/components/base/WindowObserver.vue';
import { Recommendation } from '@/composables/useDynamicYield';
import money from '@/filters/money';
import analytics, { gtag, setListMetadata } from '@/utils/analytics';
import { displayPrice, ProductCard, ProductCardData } from '@/utils/productCard';

const props = withDefaults(
  defineProps<{
    analyticsList?: string;
    enableAddToCart?: boolean;
    list?: Recommendation[];
    skipCartAdded?: boolean;
    title?: string;
    trackingEventLocation?: string;
  }>(),
  {
    analyticsList: '',
    trackingEventLocation: '',
  },
);
const emit = defineEmits(['click']);

const store = useStore();

const addedItems = ref<{ sku: string }[]>([]);
const isAdded = (sku: string) => addedItems.value.some((item) => item.sku === sku);

const handleClick = (sku: ProductCardData['sku'], emitClick?: boolean) => {
  addedItems.value.push({ sku });

  if (emitClick) emit('click');
};

const recommendations = computed(() =>
  (props.list ?? []).map((recommendation) => {
    const productCard = ProductCard.fromDY(recommendation);
    return {
      displayPrice: displayPrice(productCard),
      productCard,
      recommendation,
    };
  }),
);

const microData = (product: Recommendation, position: number) => ({
  id: `0${product.sku}`,
  list: props.analyticsList || props.title,
  name: product.name,
  position,
  price: product.price,
});

const reportDyEngagement = (context: object) =>
  store.dispatch('dynamicYieldModule/reportDyEngagement', context);

const reportClick = (product: Recommendation, productCard: ProductCardData, index: number) => {
  reportDyEngagement({
    decisionId: product.decisionId,
    slotId: product.slotId,
  });

  analytics.sendEvent('productClick', props.analyticsList || props.title || '', {
    ecommerce: {
      click: {
        actionField: { list: props.analyticsList || props.title },
        products: [microData(product, index)],
      },
    },
  });
  setListMetadata({
    list: props.analyticsList || props.title || '',
    position: index,
  });
  gtag('event', 'select_item', {
    items: [
      {
        item_id: product.product_key,
        item_list_name: props.analyticsList || props.title,
        item_name: product.name,
        item_variant: product.sku,
        item_variant_name: product.unit_name,
        price: product.price,
        price_before_discount: dollars(
          productCard.totalSavings?.comparisonPrice ?? productCard.piecePrice,
        ),
        cost: product.cost,
        coupon: productCard.totalSavings?.description?.en,
        discount: dollars(productCard.totalSavings?.value || from(0)),
        quantity: 1,
        autodelivery_interval: undefined,
        merchandising_category: product.primary_merchandising_category,
        weight: undefined,
      },
    ],
  });
  emit('click');
};
const impressionLogged = ref(new Map<string, boolean>());
const reportVisibility = (product: Recommendation, productCard: ProductCardData, index: number) => {
  if (impressionLogged.value.has(product.sku)) return;
  impressionLogged.value.set(product.sku, true);
  analytics.sendEvent('productImpressions', props.title ?? '', {
    ecommerce: {
      currencyCode: 'USD',
      impressions: [microData(product, index)],
    },
  });
  gtag('event', 'view_item_list', {
    items: [
      {
        item_id: product.product_key,
        item_list_name: props.analyticsList || props.title,
        item_name: product.name,
        item_variant: product.sku,
        item_variant_name: product.unit_name,
        price: product.price,
        price_before_discount: dollars(
          productCard.totalSavings?.comparisonPrice ?? productCard.piecePrice,
        ),
        cost: product.cost,
        coupon: productCard.totalSavings?.description?.en,
        discount: dollars(productCard.totalSavings?.value || from(0)),
        quantity: 1,
        autodelivery_interval: undefined,
        merchandising_category: product.primary_merchandising_category,
        weight: undefined,
      },
    ],
  });
};
</script>

<template>
  <div v-if="recommendations.length" data-test="recommendations">
    <div
      v-for="({ displayPrice, productCard, recommendation }, index) in recommendations"
      :key="productCard.productKey"
      class="z-10 flex justify-between px-4 py-3 border-b border-solid shrink-0 md:py-2 border-neutral-300"
    >
      <WindowObserver @intersect="reportVisibility(recommendation, productCard, index)">
        <HorizontalProductCard
          :averageRating="productCard.averageRating"
          class="mr-3"
          data-test="recommendation-product-card"
          imageSize="medium"
          :imageUrl="productCard.imageUrl"
          :name="productCard.name"
          :path="productCard.path"
          @click="reportClick(recommendation, productCard, index)"
        >
          <template v-slot:price>
            <BaseBodyText
              :class="
                productCard.totalSavings?.onSale ? 'text-error font-semibold' : 'text-neutral-500'
              "
            >
              {{ productCard.hidePrice ? 'Build your own' : displayPrice }}
            </BaseBodyText>
            <SmallBodyText
              v-if="productCard.totalSavings"
              class="ml-1.5 line-through text-neutral-500"
            >
              {{ money(productCard.totalSavings?.comparisonPrice) }}
            </SmallBodyText>
          </template>
        </HorizontalProductCard>
      </WindowObserver>
      <div class="flex items-center justify-center">
        <AddToCartOrCustomize
          v-if="enableAddToCart"
          class="w-20 md:w-24"
          :cost="productCard.cost"
          :coupon="productCard.totalSavings?.description?.en"
          :disabled="isAdded(productCard.sku)"
          :discount="productCard.totalSavings?.value"
          :listMetadata="{ list: analyticsList || title || '', position: index }"
          :listName="analyticsList || title"
          :merchandisingCategory="productCard.merchandisingCategory"
          :name="productCard.name"
          :outOfStock="!productCard.inStock"
          :path="productCard.path"
          :productKey="productCard.productKey"
          :requiresCustomization="recommendation.requires_customization === 'true'"
          :singlePiecePrice="productCard.price"
          size="default"
          skipCartAdded
          :trackingEventLocation="trackingEventLocation"
          :variantName="recommendation.unit_name"
          :variantSku="productCard.sku"
          @added="handleClick(productCard.sku, recommendation.requires_customization === 'true')"
        >
          <template #add-to-cart-text>
            {{ isAdded(productCard.sku) ? 'Added' : 'Add' }}
          </template>
        </AddToCartOrCustomize>
      </div>
    </div>
  </div>
</template>
