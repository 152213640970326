<script setup lang="ts">
import { dollars, from as fromConversion } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { useElementVisibility } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';

import RatingStars from '@/components/base/assets/RatingStars.vue';
import ProductTitle from '@/components/base/ProductTitle.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import TagBadge from '@/components/base/TagBadge.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import PriceStrikethrough from '@/components/product-card/PriceStrikethrough.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import money from '@/filters/money';
import { useAutoDelivery } from '@/stores/autoDelivery';
import analytics, { gtag, ListMetadata } from '@/utils/analytics';
import { Money } from '@/utils/money';
import { MoneyRange, ProductCardData } from '@/utils/productCard';

const props = withDefaults(
  defineProps<{
    autoDeliveryEligible?: boolean;
    averageRating?: number;
    clickTrackable?: boolean;
    cost?: string;
    hidePrice: boolean;
    impressionTrackable?: boolean;
    isWholeSaleProduct?: boolean;
    listingImageUrl?: string;
    listMetadata?: ListMetadata;
    listName?: string;
    name?: string;
    onePoundBulk?: boolean;
    merchandisingCategory?: string;
    pageName: string;
    path?: string;
    piecePrice: Money;
    piecePriceRange?: MoneyRange;
    position?: number;
    productKey?: string;
    promoteShortVariantName?: boolean;
    requestUrl?: string;
    requiresCustomization?: boolean;
    reviewCount?: number;
    shortDescription?: string;
    shortVariantName?: string;
    tags?: string[];
    totalSavings?: ProductCardData['totalSavings'];
    trackingEventLocation?: string;
    variantSku?: string;
    weight?: string;
  }>(),
  {
    clickTrackable: false,
    impressionTrackable: false,
    isWholeSaleProduct: false,
    promoteShortVariantName: false,
  },
);
const store = useStore();
const { flags } = useFeatureFlags(store);
const { autoDeliveryChannel } = storeToRefs(useAutoDelivery());

const visibilityObserver = ref<HTMLElement>();
const displayShortVariantName = computed(
  () => props.promoteShortVariantName && !props.requiresCustomization,
);

const displayPrice = computed<string>(() => {
  if (props.piecePriceRange) {
    const { from, to } = props.piecePriceRange;
    return `${money(from)} - ${money(to)}`;
  }
  const price = money(props.piecePrice);
  if (props.onePoundBulk) return `${price}/lb`;
  return price;
});

const displayStars = computed(() => typeof props.averageRating === 'number');
const gtagItem = computed(() => ({
  items: [
    {
      index: props.position,
      indexName: props.listMetadata?.indexName,
      item_id: props.productKey,
      item_list_name: props.listName ?? props.pageName,
      item_name: props.name,
      item_variant: props.variantSku,
      item_variant_name: props.shortVariantName,
      price: dollars(props.piecePrice),
      price_before_discount: dollars(props.totalSavings?.comparisonPrice ?? props.piecePrice),
      cost: props.cost,
      coupon: props.totalSavings?.description?.en,
      discount: dollars(props.totalSavings?.value || fromConversion(0)),
      quantity: 1,
      autodelivery_interval: undefined,
      merchandising_category: props.merchandisingCategory,
      weight: props.weight,
      searchQueryID: props.listMetadata?.searchQueryID,
    },
  ],
}));

const microData = computed(() => ({
  id: `0${props.variantSku}`,
  index: props.position,
  name: props.name,
  price: dollars(props.piecePrice),
  list: props.listName ?? props.pageName,
}));

const reportClick = () => {
  if (!props.clickTrackable) return;
  analytics.sendEvent('productClick', props.pageName, {
    ecommerce: {
      click: {
        actionField: {
          list: microData.value.list,
        },
        products: [microData.value],
      },
    },
  });
  gtag('event', 'select_item', gtagItem.value);
};

const reportImpression = () => {
  analytics.sendEvent('productImpressions', props.pageName, {
    ecommerce: {
      currencyCode: 'USD',
      impressions: [microData.value],
    },
  });
  gtag('event', 'view_item_list', gtagItem.value);
};

onMounted(() => {
  const impressionLogged = ref(false);
  const inView = useElementVisibility(visibilityObserver);
  watchEffect(() => {
    if (!inView.value || !props.impressionTrackable || impressionLogged.value) return;
    impressionLogged.value = true;
    reportImpression();
  });
});
</script>

<template>
  <div
    itemprop="item"
    itemscope
    itemtype="http://schema.org/Product"
    :data-index="position"
    :data-list="listName"
    ref="visibilityObserver"
  >
    <meta itemprop="brand" content="ixclkxk.shop" />
    <meta itemprop="productID" :content="productKey" />
    <meta itemprop="url" :content="`${requestUrl}#${position}`" />
    <RouteLink
      :to="path"
      :aria-label="`View ${name} details`"
      :data-index="position"
      @click="reportClick"
      class="relative block dy-pdp-link"
    >
      <TagBadge
        :tags="tags"
        class="absolute z-10"
        :class="flags.badgeRedesign ? 'right-0 top-0' : 'right-3 top-3'"
      />
      <div class="flex justify-center photo-container vertical-align-middle prop-3x2">
        <img :src="listingImageUrl" alt="" class="photo" itemprop="image" loading="lazy" />
      </div>
    </RouteLink>
    <slot name="button" />
    <RouteLink :to="path" :data-index="position" @click="reportClick" class="dy-pdp-link">
      <ProductTitle :name="name" />
      <meta itemprop="description" :content="shortDescription" />
    </RouteLink>

    <div v-if="displayStars">
      <RatingStars :rating="averageRating ?? 0" />
      <template v-if="reviewCount && reviewCount > 0">
        <div :aria-label="`${reviewCount} reviews`" class="rating-note" data-test="rating-note">
          ({{ reviewCount }})
        </div>
        <div itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating">
          <meta itemprop="ratingValue" :content="averageRating" />
          <meta itemprop="bestRating" content="5" />
          <meta itemprop="reviewCount" :content="reviewCount" />
        </div>
      </template>
    </div>

    <template v-if="!hidePrice">
      <div v-if="totalSavings" class="flex items-center mt-2">
        <PriceStrikethrough
          :comparisonPrice="totalSavings.comparisonPrice"
          :discountPercent="`${totalSavings.percent}%`"
          discountType="product"
          displayDiscount
          :onePoundBulk="onePoundBulk"
          :piecePrice="piecePrice"
          :piecePriceRange="piecePriceRange"
        />
      </div>
      <div v-else>
        <h5 data-test="product-price" class="price-display">
          {{ displayPrice }}
        </h5>
      </div>
    </template>
    <SmallBodyText v-if="totalSavings?.description" class="mt-2">
      {{ totalSavings.description.en }}
    </SmallBodyText>

    <div
      v-if="!hidePrice && autoDeliveryEligible && !isWholeSaleProduct && !totalSavings"
      class="flex items-start"
      data-test="auto-delivery-callout"
    >
      <img
        src="@/assets/auto-delivery/arrows.svg"
        alt="auto delivery icon"
        class="mr-2 md:mt-0.5"
      />
      <SmallBodyText class="text-black">
        {{ `Save ${autoDeliveryChannel.discount}% with Auto-Delivery` }}
      </SmallBodyText>
    </div>

    <div v-if="displayShortVariantName" class="mt-2 unit-name">{{ shortVariantName }}</div>
    <meta itemprop="sku" :content="`0${variantSku}`" />
    <meta itemprop="mpn" :content="`0${variantSku}`" />
    <div v-once class="acadia-product-card" />
  </div>
</template>

<style scoped lang="scss">
.price-display {
  @apply text-sm font-semibold leading-4 font-sofia-pro py-2 md:text-base md:leading-5 text-black;
}
</style>
