<template>
  <Modal
    data-test="ingredients-modal"
    backgroundColor="bg-true-gray-100"
    :isOpen="isOpen"
    :hasControls="false"
    @handle-close="handleClose"
  >
    <template v-slot:header>
      <h3 class="text-left">Ingredient Choices for Your Recipient(s)</h3>
    </template>
    <template v-slot:body>
      <div class="flex flex-col h-screen lg:flex-row sm:max-w-6xl lg:w-screen">
        <div v-if="showBox" class="mt-8 page-custom-trays md:flex-4 md:h-full lg:h-full">
          <div class="h-full md:w-72">
            <CustomBuilderCategoryFilterControlPanel
              :activeFilters="activeFilters"
              :catalogCategoryTree="catalogCategoryTree"
              class="mb-15"
              @toggle-category="toggleActiveCategoryFilter"
            />
            <CustomBuilderTagFilterControlPanel
              :activeFilters="activeFilters"
              @toggle-filter="toggleActiveFilter"
            />
            <div class="flex-row gutters wrapped mb-15">
              <div class="flex-xs-12 flex-md-10">
                <CustomBuilderKeywordFilter v-model="activeKeyword" />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-8 page-custom-trays md:flex-4 md:h-full lg:h-full">
          <div class="h-full md:w-80" id="custom-tray-builder">
            <CategoryFilterControlPanel :catalogCategoryTree="catalogCategoryTree" class="mb-15" />
            <div class="mobile-container mb-15" :class="{ expanded }">
              <h2 class="toggler visible-xs visible-sm" @click="toggleTrayFilters">
                Filter &amp; Sort
              </h2>
              <TagFilterControlPanel />
            </div>
            <div class="flex-row gutters wrapped mb-15">
              <div class="flex-xs-12 flex-md-10">
                <KeywordFilter />
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4 md:flex-8 md:grid-cols-4 lg:max-h-48">
          <div
            v-for="ingredient in ingredients"
            v-show="ingredient.show"
            :key="ingredient.id"
            class="relative flex flex-col items-center w-full h-full p-2 bg-white rounded-lg"
            :data-test="ingredient.name"
          >
            <div
              v-if="ingredient.premium"
              class="absolute top-0 right-0 p-1 ml-auto text-xs font-bold rounded-tr-lg rounded-bl-lg bg-yellow-300/50 custom-fit"
            >
              Premium
            </div>
            <div class="flex items-center h-4/5">
              <img :src="ingredient.assetPath" alt="" class="max-w-full" loading="lazy" />
            </div>
            <div class="mt-2 text-sm font-semibold whitespace-normal">
              {{ ingredient.name }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, PropType, ref } from 'vue';
import { useStore } from 'vuex';

import { catalog, GiftedTrayIngredient } from '@/api/gifted-tray';
import Modal from '@/components/base/layout/Modal.vue';
import CustomBuilderCategoryFilterControlPanel from '@/components/custom-builder/CustomBuilderCategoryFilterControlPanel.vue';
import CustomBuilderKeywordFilter from '@/components/custom-builder/CustomBuilderKeywordFilter.vue';
import CustomBuilderTagFilterControlPanel from '@/components/custom-builder/CustomBuilderTagFilterControlPanel.vue';
import CategoryFilterControlPanel from '@/components/custom-trays/CategoryFilterControlPanel.vue';
import KeywordFilter from '@/components/custom-trays/KeywordFilter.vue';
import TagFilterControlPanel from '@/components/custom-trays/TagFilterControlPanel.vue';
import { CustomProductType, useCustomProducts } from '@/composables/useCustomProducts';
import { PopularitySort, PriceSort } from '@/store/modules/customTrays';

export default defineComponent({
  name: 'IngredientsModal',
  props: {
    isOpen: { required: true, type: Boolean, default: false },
    type: {
      required: true,
      type: String as PropType<CustomProductType>,
      default: CustomProductType.Tray,
    },
  },
  components: {
    Modal,
    CategoryFilterControlPanel,
    CustomBuilderCategoryFilterControlPanel,
    CustomBuilderKeywordFilter,
    CustomBuilderTagFilterControlPanel,
    KeywordFilter,
    TagFilterControlPanel,
  },
  emits: ['close-modal'],
  setup(props, { emit }) {
    const store = useStore();
    const {
      activeFilters,
      activeKeyword,
      activeSort,
      buildIngredientCatalog,
      categoryTree,
      filteredIngredientCatalog,
      toggleActiveCategoryFilter,
      toggleActiveFilter,
    } = useCustomProducts();
    const trayActiveFilters = computed(() => store.state.customTraysModule.activeFilters);
    const trayActiveKeyword = computed(() => store.state.customTraysModule.activeKeyword);
    const trayActiveIngredientId = computed(() => store.state.customTraysModule.activeIngredientId);
    const trayActiveSort = computed(() => store.state.customTraysModule.activeSort);
    const fullIngredientsCatalog = ref<GiftedTrayIngredient[]>();
    const catalogCategoryTree = ref();

    const showBox = computed(() => props.type === CustomProductType.Box);

    const ingredients = computed(() => {
      if (props.type === CustomProductType.Box && filteredIngredientCatalog.value) {
        return filteredIngredientCatalog.value;
      }

      if (fullIngredientsCatalog.value) {
        return fullIngredientsCatalog.value
          .map((ingredient) => {
            let show = trayActiveFilters.value?.every((v: string) =>
              ingredient.filters.includes(v),
            );

            if (trayActiveKeyword.value?.length > 1) {
              const nameContainsKeyword =
                ingredient.name.toLowerCase().indexOf(trayActiveKeyword.value) !== -1;
              const filtersContainKeyword =
                ingredient.filters.filter(
                  (val) => val.toLowerCase().indexOf(trayActiveKeyword.value) !== -1,
                ).length > 0;
              show = show && (nameContainsKeyword || filtersContainKeyword);
            }
            return {
              ...ingredient,
              active: trayActiveIngredientId.value === ingredient.id,
              show,
            };
          })
          .sort((ingredientL, ingredientR) => {
            let diff = 0;

            if (trayActiveSort.value === PopularitySort) {
              diff = ingredientR.popularity - ingredientL.popularity;
            } else if (trayActiveSort.value === PriceSort) {
              diff =
                ingredientR.priceRange.length - ingredientL.priceRange.length ||
                ingredientR.priceLo - ingredientL.priceLo;
            }

            if (Math.abs(diff) > 0.0000001) {
              return diff;
            }

            return ingredientL.name.toLowerCase() > ingredientR.name.toLowerCase() ? 1 : -1;
          });
      }
      return [];
    });

    const expanded = ref(false);
    const toggleTrayFilters = () => {
      expanded.value = !expanded.value;
    };

    onBeforeMount(async () => {
      if (props.type === CustomProductType.Tray) {
        const { data } = await catalog();
        fullIngredientsCatalog.value = data.ingredients;
        catalogCategoryTree.value = data.categoryTree;
      } else if (props.type === CustomProductType.Box) {
        await buildIngredientCatalog();
        catalogCategoryTree.value = categoryTree.value;
      }
    });

    return {
      activeFilters,
      activeKeyword,
      activeSort,
      catalogCategoryTree,
      expanded,
      handleClose: () => emit('close-modal'),
      ingredients,
      showBox,
      toggleTrayFilters,
      toggleActiveCategoryFilter,
      toggleActiveFilter,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-fit {
  width: fit-content;
}
</style>
