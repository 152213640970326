<script setup lang="ts"></script>

<template>
  <svg
    width="91"
    height="36"
    viewBox="0 0 91 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="ixclkxk.shop logo"
    alt="ixclkxk.shop logo"
  >
    <g clip-path="url(#clip0_120_8974)">
      <path
        d="M50.9067 26.5913C50.7295 27.4839 51.0446 28.3967 51.938 28.9698C52.8314 29.5429 55.049 29.3324 55.6126 28.379C56.1762 27.4255 56.4445 25.0926 55.2434 24.6159C54.0424 24.1392 51.2981 24.6387 50.9067 26.5913Z"
        fill="currentColor"
      />
      <path
        d="M72.8307 25.0471C72.9685 26.6954 75.1738 27.0174 76.1755 25.6811C77.1773 24.3447 76.8696 22.0296 76.7785 20.6247C76.685 19.2199 77.384 16.5979 77.7532 16.499C78.1224 16.4001 77.9821 19.1464 78.0633 20.0466C78.1765 21.2739 78.3587 23.2543 79.764 23.622C81.1694 23.9897 81.7035 23.0946 82.3975 22.1538C83.4805 20.6881 83.9506 18.8548 84.8046 17.2547C86.973 13.0605 84.8957 21.0685 84.8957 24.3067C84.8957 25.5517 84.9449 26.8095 85.9171 27.6513C86.4684 28.1281 88.2848 28.8964 89.2595 27.4536C89.86 26.5635 88.4768 24.8519 88.2134 23.7665C87.9353 22.1639 89.0404 18.1092 89.1339 16.4534C88.7795 8.81818 84.6939 9.28984 81.3047 15.1551C81.386 12.3809 80.5541 9.62962 78.3587 9.69809C77.7556 9.71584 76.1952 10.1672 75.1123 12.5863C73.1039 17.0721 72.7175 23.6423 72.8356 25.0471H72.8307Z"
        fill="currentColor"
      />
      <path
        d="M53.0803 20.7364C53.3559 23.1327 55.5809 24.9787 58.4704 24.7074C61.2934 24.4411 61.9777 22.4987 62.78 21.5098C62.9498 21.3018 61.6405 25.3616 65.6818 26.1654C67.9585 26.6193 70.176 25.3134 71.5543 23.2823C73.6193 20.2393 73.7251 15.8854 69.1521 14.3589C67.9437 13.9557 65.5588 14.1104 64.2075 16.6081C64.1386 16.7349 64.1657 16.1745 62.9252 16.1948C61.259 16.2227 60.4689 22.9095 57.417 22.1082C54.0845 21.2334 56.1839 16.3748 58.1455 14.9776C59.3515 14.118 61.4436 15.0993 61.67 13.4181C61.8964 11.7369 59.6296 10.8063 57.7443 11.5264C54.6924 12.6904 52.6422 17.1026 53.0778 20.7364H53.0803ZM65.1846 22.1843C64.8893 20.9874 65.0542 19.9274 65.3791 19.1211C65.795 18.0941 66.8361 17.0265 67.8379 17.3663C68.0594 17.4399 69.9447 17.7492 68.9946 20.7465C68.3006 22.9374 65.7236 25.1638 65.1822 22.1868L65.1846 22.1843Z"
        fill="currentColor"
      />
      <path
        d="M22.7442 9.82993C25.7002 12.9058 30.1993 11.5821 33.9601 11.5796C33.7139 16.1009 32.7221 22.4352 33.6327 27.6767C33.7189 28.1737 34.2111 29.0004 36.0226 29.5151C37.204 29.8498 37.8931 29.2717 37.9743 28.7797C38.0506 25.4832 37.4427 21.601 37.9227 18.0965C38.3115 14.2599 38.2598 11.9017 38.3435 11.4351C38.853 10.9685 41.1124 11.0725 44.4302 11.6912C45.0332 11.7622 45.5722 11.8129 46.2589 11.6532C47.5855 11.3463 47.6519 7.77596 46.1063 7.6162C44.7772 7.47927 39.584 7.6162 38.4272 7.52238C38.2894 5.48362 38.1516 3.67815 38.0777 1.93608C37.999 0.112859 35.7248 -0.767052 35.2744 0.843162C34.7255 2.30884 34.3982 7.48688 34.2579 7.65677C34.1619 7.77342 33.2463 8.09546 32.3086 8.08278C30.187 8.21718 27.2237 9.02355 25.7962 8.77251C25.2498 8.67615 24.3489 8.64319 23.7632 8.53923C23.1134 8.42512 22.3086 9.03877 22.7491 9.82739L22.7442 9.82993Z"
        fill="currentColor"
      />
      <path
        d="M1.98404 28.8102C4.9769 29.9437 6.15583 28.4755 6.018 25.463C5.79403 20.5537 5.45192 19.0094 5.08274 15.5557C4.88338 13.6868 5.14427 10.1418 5.57498 10.0911C5.78173 10.0683 6.02539 10.4487 6.3232 11.0395C8.15436 14.9471 9.95106 18.3425 12.0283 21.9814C13.6528 24.5653 13.3476 27.7046 16.813 27.7578C20.1086 27.3876 18.2233 8.8486 20.0987 4.66458C20.8445 3.00112 20.6574 2.16938 19.1118 1.78394C18.4571 1.62165 16.1731 0.888818 15.5504 5.08298C15.2624 7.02031 15.1935 8.0194 15.0458 10.0201C13.6306 24.0734 14.94 19.9603 9.86738 10.2052C8.60969 7.9484 5.93679 5.53688 3.68476 7.42603C2.5329 8.39216 2.39999 11.0294 1.86836 12.9134C1.08323 16.3114 0.669738 19.1007 0.556521 22.5443C0.620513 24.7606 -0.263068 27.9861 1.98158 28.8076L1.98404 28.8102Z"
        fill="currentColor"
      />
      <path
        d="M90.5626 33.0322C90.6069 31.7162 88.9062 31.5184 87.267 31.5184C63.9148 31.4955 38.5001 31.7846 32.6177 31.8962C26.7354 32.0078 10.6807 31.7162 6.61231 32.0306C5.85179 32.0382 3.88281 32.3121 2.88354 32.2233C2.22147 32.165 -0.461276 32.061 2.44298 34.3711C5.40385 36.3313 8.63545 35.3499 11.7489 35.3854C27.806 34.9366 44.9904 35.2916 61.2665 35.3753C68.1653 35.4108 74.0969 35.2028 81.013 35.1826C84.284 35.1623 85.3497 35.1572 88.2884 35.1927C89.327 35.2054 90.5183 34.3508 90.5626 33.0322Z"
        fill="currentColor"
      />
      <path
        d="M20.3179 18.2968C19.9537 21.5375 19.55 26.3681 21.3713 28.3765C22.6339 29.7686 26.0501 30.4533 27.3546 28.5159C29.4983 25.3259 30.7831 22.2374 32.2672 17.7262C33.5397 13.8592 27.7976 12.9666 27.5687 15.9461C27.5047 16.3442 27.042 20.3939 26.7565 21.3575C25.6588 25.3437 24.4011 26.6116 24.0048 26.4366C22.5306 25.7849 23.4806 16.6866 24.091 14.696C24.7014 12.7054 22.4518 11.5466 21.5559 13.8769C21.0341 15.2386 20.532 17.1303 20.3155 18.2968H20.3179Z"
        fill="currentColor"
      />
      <path
        d="M46.4024 13.6031C41.6793 11.5035 37.5198 17.0061 41.7014 21.0279C42.9493 22.1487 44.7927 22.4454 46.2005 23.3304C47.9382 24.829 44.4924 26.2364 42.1247 25.4553C40.0105 24.7555 39.4371 25.1739 38.7972 25.8002C37.9308 26.6471 39.1073 26.959 39.8801 27.4687C41.534 28.5617 42.7179 28.7518 44.266 28.9395C46.7002 29.2362 48.4575 28.1686 49.7866 26.3099C51.6177 24.0835 48.8144 21.2815 46.6928 20.863C44.5687 20.4446 41.1132 17.6832 45.1791 15.9132C46.4294 15.4517 48.0908 16.6511 49.0014 16.2327C50.995 15.472 47.9677 13.981 46.4048 13.5981L46.4024 13.6031Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_120_8974">
        <rect width="90" height="36" fill="currentColor" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
