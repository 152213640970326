import { Cart } from '@commercetools/platform-sdk';
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, readonly, ref } from 'vue';

import {
  AlgoliaCategory,
  AlgoliaVariant,
  getIndex,
  init as initAlgolia,
  searchCategories,
  searchProducts,
  SynthesizedAlgoliaCategory,
} from '@/api/algolia';
import fetchProduct from '@/api/productDetail';
import { NutsLineItem } from '@/utils/cart';
import encoding from '@/utils/encoding';
import { Money } from '@/utils/money';

// eslint-disable-next-line no-shadow
export enum Sort {
  Popularity = 'popularity',
  Price = 'price',
}

export interface Ingredient {
  readonly assetPath: string;
  readonly filters: string[];
  readonly id: string;
  readonly sku: string;
  readonly name: string;
  readonly path: string;
  readonly popularity: number;
  readonly price: number;
  readonly priceRange: '$' | '$$' | '$$$';
}

export interface TrayIngredient {
  id: string;
  name: string;
  premium: boolean;
  assetPath: string;
  filters: string[];
  popularity: number;
  priceLi: number;
  priceLo: number;
  priceMi: number;
  priceMo: number;
  priceRange: string;
}

export interface Category {
  readonly name: string;
  readonly filter: string;
  readonly children: Category[];
}

export interface BundleLineItem {
  description: string;
  unitPrice: Money;
  totalPrice: Money;
}

export interface CustomProductResponse {
  cart: Cart;
}

// eslint-disable-next-line no-shadow
export enum CustomProductType {
  Tray = 'Tray',
  Box = 'Box',
}

export interface DigitalGifting {
  giftCertificateCode: string | undefined;
  digitalGiftSku: string | undefined;
  giftedProduct: {
    sku: string | undefined;
    name: string | undefined;
    tier: 'classic' | 'premium';
  };
}

export function useCustomProducts() {
  const activeFilters = ref<string[]>([]);
  const activeIngredientId = ref();
  const activeKeyword = ref<string>();
  const activeSlot = ref<number>();
  const activeSort = ref<Sort | undefined>();
  const basePrice = ref();
  const categories = ref<(AlgoliaCategory | SynthesizedAlgoliaCategory)[]>([]);
  const contents = ref<(Ingredient | null)[]>([]);
  const ingredientCatalog = ref<Ingredient[]>([]);
  const maxIngredientCount = ref(6);

  contents.value = new Array(maxIngredientCount.value).fill(null);

  const canAddIngredient = computed(() => {
    if (contents.value.some((i) => i === null)) {
      return true;
    }
    return contents.value.length < maxIngredientCount.value;
  });

  const categoryTree = computed(() =>
    categories.value
      .filter((c) =>
        ingredientCatalog.value.find((p) => p.filters.includes(`cat:${c.objectID}:${c.name}`)),
      )
      .map((c) => ({
        name: c.name,
        filter: `cat:${c.objectID}:${c.name}`,
        children: [], // Limited to the top-level cats for the MVP
      })),
  );

  const encodedIngredientIds = computed(() => {
    const code = new Array(maxIngredientCount.value).fill('000');
    contents.value.forEach((ingredient: Ingredient | null, slot: number) => {
      if (ingredient) {
        const hash = encoding.numToSxg(ingredient.id, 3);
        if (hash !== 0) {
          code[slot] = hash;
        }
      }
    });
    return code.join('');
  });

  const filteredIngredientCatalog = computed(() => {
    const defaultSorted = ingredientCatalog.value.map((ingredient) => {
      let show = activeFilters.value.every((val) => ingredient.filters.includes(val));
      if (!!activeKeyword.value && activeKeyword.value.length > 1) {
        const nameContainsKeyword =
          ingredient.name.toLowerCase().indexOf(activeKeyword.value) !== -1;
        const filtersContainKeyword =
          ingredient.filters.filter((val) => val.toLowerCase().indexOf(activeKeyword.value!) !== -1)
            .length > 0;
        show = show && (nameContainsKeyword || filtersContainKeyword);
      }
      return {
        ...ingredient,
        active: activeIngredientId.value === ingredient.id,
        show,
      };
    });
    if (!activeSort.value) {
      return defaultSorted;
    }
    return defaultSorted.sort((ingredientL, ingredientR) => {
      let diff = 0;

      if (activeSort.value === Sort.Popularity) {
        diff = parseFloat(`${ingredientR.popularity}`) - parseFloat(`${ingredientL.popularity}`);
      } else if (activeSort.value === Sort.Price) {
        diff =
          (!!ingredientR.priceRange &&
            !!ingredientL.priceRange &&
            ingredientR.priceRange.length - ingredientL.priceRange.length) ||
          parseFloat(`${ingredientR.price}`) - parseFloat(`${ingredientL.price}`);
      }

      if (Math.abs(diff) > 0.0000001) {
        return diff;
      }

      return ingredientL.name.toLowerCase() > ingredientR.name.toLowerCase() ? 1 : -1;
    });
  });

  const filters = computed(() =>
    ingredientCatalog.value.reduce<string[]>(
      (acc, ingredient) =>
        acc
          .concat(ingredient.filters.filter((filter) => !filter.startsWith('cat:')))
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort(),
      [],
    ),
  );

  const addIngredientToSlot = (ingredient: Ingredient, slot: number) => {
    if (canAddIngredient.value) {
      contents.value.splice(slot, 1, ingredient);
    }
  };

  const activateIngredientAndSlotIfReady = () => {
    if (!activeIngredientId.value || activeSlot.value === undefined || !canAddIngredient.value) {
      return;
    }

    const ingredient = ingredientCatalog.value.find((ing) => ing.id === activeIngredientId.value);
    if (ingredient) {
      addIngredientToSlot(ingredient, activeSlot.value);
      activeIngredientId.value = undefined;
      activeSlot.value = undefined;
    }
  };

  const prefillContent = (encodedIds: string) => {
    if (!encodedIds) {
      return;
    }

    const content = [];
    for (let i = 0; i < encodedIds.length; i += 3) {
      const skuId = encoding.sxgToNum(encodedIds.substring(i, i + 3));
      const ingredient = ingredientCatalog.value.find((ing) => ing.id === skuId.toString()) || null;
      content.push(ingredient);
    }
    contents.value = content;
  };

  const freeSlot = (slot: number) => contents.value.splice(slot, 1, null);

  const ingredientAtSlot = (slot: number) => contents.value[slot];

  const setIngredientAndSlot = ({ ingredientId, slot }: { ingredientId: string; slot: number }) => {
    activeIngredientId.value = ingredientId;
    activeSlot.value = slot;
    activateIngredientAndSlotIfReady();
  };

  const setActiveIngredientId = (id: string) => {
    activeIngredientId.value = id;
    activateIngredientAndSlotIfReady();
  };

  const setActiveSlot = (slot: number) => {
    activeSlot.value = slot;
    activateIngredientAndSlotIfReady();
  };

  const setContents = (content: (Ingredient | null)[]) => {
    contents.value = content;
  };

  const toggleActiveCategoryFilter = (filter: string) => {
    if (activeFilters.value.includes(filter)) {
      activeFilters.value.splice(activeFilters.value.indexOf(filter), 1);
    } else {
      activeFilters.value = [
        ...activeFilters.value.filter((activeFilter) => !activeFilter.startsWith('cat:')),
        filter,
      ];
    }
  };

  const toggleActiveFilter = (filter: string) => {
    if (activeFilters.value.includes(filter)) {
      activeFilters.value.splice(activeFilters.value.indexOf(filter), 1);
    } else {
      activeFilters.value.push(filter);
    }
  };

  const algoliaClient = initAlgolia();
  const productsIndex = getIndex(algoliaClient, 'Products');

  const getBasePrice = async () => {
    const {
      body: { data: response },
    } = await fetchProduct('3719');
    basePrice.value = dollars(response.product.masterData.current.masterVariant.prices[0].value);
  };

  const buildIngredientCatalog = async () => {
    const departmentsIndex = getIndex(algoliaClient, 'Departments');
    categories.value = (
      await searchCategories(departmentsIndex, { facetFilters: ['depth:0'] })
    ).filter((c) => ['Nuts', 'Chocolates & Sweets', 'Dried Fruit', 'Snacks'].includes(c.name));

    const products = await searchProducts(productsIndex, {
      query: 'mini box',
      restrictSearchableAttributes: ['shortVariantName'],
    });

    ingredientCatalog.value = products
      .filter((product: AlgoliaVariant) => !product.outOfStock)
      .map((product: AlgoliaVariant) => {
        const {
          popularity,
          Product: { ancestryPairs, key, listingImageUrl: assetPath, name, path, tags },
          singlePiecePrice: price,
          sku,
        } = product;
        const productFilters: string[] = tags
          .filter((f) =>
            ['gluten-free', 'kosher', 'organic', 'raw', 'sugar-free', 'vegan'].includes(f),
          )
          .concat(tags.includes('unsalted') || tags.includes('50% less salt') ? ['less salt'] : [])
          .sort()
          .concat(
            categories.value
              .filter(
                (tlc) =>
                  ancestryPairs.filter((ap) => ap.startsWith(`cat${tlc.objectID}subcat`)).length >
                  0,
              )
              .map((tlc) => `cat:${tlc.objectID}:${tlc.name}`)
              .sort(),
          );
        // this is a temp price range rule that will be updated later
        let priceRange = '$';
        if (price >= 20) {
          priceRange = '$$$';
        } else if (price >= 10) {
          priceRange = '$$';
        }
        return <Ingredient>{
          assetPath,
          filters: productFilters,
          id: key,
          name,
          sku,
          path,
          popularity,
          price,
          priceRange,
        };
      });
  };

  const formatBundleLineItem = (lineItem?: NutsLineItem): BundleLineItem | null => {
    if (!lineItem?.children?.length) {
      return null;
    }
    const { children, piecePrice, totalPrice } = lineItem;

    const childrenNames = children.map((e) => e.name.en);
    const counts: { [key: string]: number } = childrenNames.reduce<{ [key: string]: number }>(
      (c, e) => {
        const newCounts = { ...c };
        newCounts[e] = newCounts[e] ? newCounts[e] + 1 : 1;
        return newCounts;
      },
      {},
    );

    const description = Object.entries(counts)
      .map((a) => (a[1] > 1 ? `${a[1]}x ${a[0]}` : `${a[0]}`))
      .join(', ');

    return {
      description,
      unitPrice: piecePrice,
      totalPrice,
    };
  };

  return {
    activateIngredientAndSlotIfReady,
    activeFilters,
    activeIngredientId: readonly(activeIngredientId),
    activeKeyword,
    activeSlot: readonly(activeSlot),
    activeSort,
    addIngredientToSlot,
    basePrice,
    buildIngredientCatalog,
    canAddIngredient,
    categoryTree: readonly(categoryTree),
    contents: readonly(contents),
    encodedIngredientIds,
    filteredIngredientCatalog,
    filters,
    formatBundleLineItem,
    freeSlot,
    getBasePrice,
    ingredientAtSlot,
    ingredientCatalog,
    maxIngredientCount,
    prefillContent,
    setActiveIngredientId,
    setActiveSlot,
    setContents,
    setIngredientAndSlot,
    toggleActiveCategoryFilter,
    toggleActiveFilter,
  };
}

export default {};
