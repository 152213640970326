<script setup lang="ts">
import { ApplePay, DataCollector } from 'braintree-web';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useExpressCheckout } from '@/composables/useExpressCheckout';
import { CheckoutEvents } from '@/utils/analytics';

const props = defineProps({
  componentName: { required: true, type: String },
  expressCheckout: { required: false, type: Boolean },
});

const router = useRouter();
const store = useStore();
const { offerApplePay, requestApplePayPayment } = useExpressCheckout(store, router);

const applePay = ref<ApplePay>();
const braintreeDataCollector = ref<DataCollector>();

const renderApplePay = computed(() => offerApplePay.value && !!applePay.value);

const handlePayment = () => {
  requestApplePayPayment(applePay.value, braintreeDataCollector.value?.deviceData);

  CheckoutEvents.event({
    category: 'Express Checkout',
    action: 'Apple Pay',
    label: `Click in ${props.componentName}`,
  });
};

onMounted(async () => {
  const { useBraintree } = await import('@/stores/braintree');
  const braintreeStore = useBraintree();
  watch(
    offerApplePay,
    async (enabled) => {
      if (!enabled) return;
      const applePayInstance = await braintreeStore.initApplePay();
      // @ts-ignore (@types/braintree-web is wrong and attribute does exist)
      const { merchantIdentifier } = applePayInstance;
      // eslint-disable-next-line no-undef -- using `window` loses typing for some reason
      if (!(await ApplePaySession?.canMakePaymentsWithActiveCard(merchantIdentifier))) {
        console.error('Apple Pay unable to finish initialization');
        return;
      }
      applePay.value = applePayInstance;
      braintreeDataCollector.value = await braintreeStore.initDataCollector();
    },
    { immediate: true },
  );
});
</script>

<template>
  <button
    v-if="renderApplePay"
    :class="expressCheckout ? 'apple-pay-button' : 'button apple-pay'"
    data-test="apple-pay"
    @click.prevent="handlePayment"
  >
    Apple Pay
  </button>
</template>

<style lang="scss">
.apple-pay-button {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-style: white-outline;
  -apple-pay-button-type: plain;
  text-indent: -90000px;
  border-radius: 4px;
  width: 100%;
  min-width: 200px;
  max-width: 750px;
  cursor: pointer;
  margin-bottom: 8px;
}

.apple-pay-button:hover {
  opacity: 0.6;
}
</style>
