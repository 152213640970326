import { Sampler, SamplingDecision, SamplingResult } from '@opentelemetry/sdk-trace-base';

// eslint-disable-next-line import/prefer-default-export
export class FeatureFlagSampler implements Sampler {
  // eslint-disable-next-line class-methods-use-this
  shouldSample(): SamplingResult {
    const hasFlag =
      window.document.querySelector('body')?.classList.contains('enableBrowserTelemetry') ?? false;

    return {
      decision: hasFlag ? SamplingDecision.RECORD_AND_SAMPLED : SamplingDecision.NOT_RECORD,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  toString(): string {
    return 'FeatureFlagSampler';
  }
}
