/* eslint-disable max-classes-per-file */
import { Context, Options } from '@/api/dy/client/context';
import DyError from '@/api/dy/client/error';
import { ChoosePayload, EngagementPayload } from '@/api/dy/client/payload';

class DyRequest {
  constructor() {
    this.endpoint = undefined;
    this.method = undefined;
    this.data = {};
  }
}

class DyPostRequest extends DyRequest {
  constructor() {
    super();

    this.method = 'post';
  }
}

class ChooseRequest extends DyPostRequest {
  constructor(
    userId,
    sessionId,
    campaignNames,
    pageContext,
    deviceContext,
    pageAttributes,
    reportPageView = true,
    previewIds = '',
  ) {
    super();

    if (!userId) {
      throw new DyError(`Invalid user id: ${userId}`);
    }

    if (!sessionId) {
      throw new DyError(`Invalid session id: ${sessionId}`);
    }

    if (!campaignNames.length) {
      throw new DyError('Missing campaign names');
    }

    if (!pageContext) {
      throw new DyError('Invalid page context');
    }

    if (!deviceContext) {
      throw new DyError('Invalid device context');
    }

    const context = new Context(pageContext, deviceContext, pageAttributes, reportPageView);
    const options = new Options(reportPageView);
    const data = new ChoosePayload(
      userId,
      sessionId,
      campaignNames,
      context,
      previewIds,
      options,
    ).get();

    this.endpoint = '/serve/user/choose';
    this.data = data;
  }
}

class EngagementRequest extends DyPostRequest {
  constructor({ userId, sessionId, decisionId = null, slotId = null }) {
    super();

    if (!userId) {
      throw new DyError(`Invalid user id: ${userId}`);
    }

    if (!sessionId) {
      throw new DyError(`Invalid session id: ${sessionId}`);
    }

    if (!decisionId && !slotId) {
      throw new DyError('Invalid decision or slot id');
    }

    const data = new EngagementPayload({
      userId,
      sessionId,
      decisionId,
      slotId,
    }).get();

    this.endpoint = '/collect/user/engagement';
    this.data = data;
  }
}

export { ChooseRequest, EngagementRequest };
