<script lang="ts">
import { defineComponent } from 'vue';

import { CmsRegisteredComponent } from '@/utils/cms';

const BaseBodyText = defineComponent({
  name: 'BaseBodyText',
  props: {
    underline: { required: false, type: Boolean, default: false },
  },
});

export const BaseBodyTextRegistration: CmsRegisteredComponent = {
  component: BaseBodyText,
  name: 'Base Body Text',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Base Body text here' } },
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      name: 'underline',
      type: 'boolean',
      defaultValue: false,
    },
  ],
};

export default BaseBodyText;
</script>

<template>
  <p class="text-sm base-text md:text-base md:leading-6" :class="{ underline: underline }">
    <slot />
  </p>
</template>

<style scoped lang="scss">
.base-text {
  @apply mb-0;
  @media (max-width: 768px) {
    line-height: 22px;
  }
}
</style>
