<template>
  <div>
    <ThemedButton v-if="showViewDetailsButton" theme="gray" @click="goToBusinessAccount">
      {{ viewAccountText }}
    </ThemedButton>
    <ThemedButton v-else-if="showConvertButton" theme="gray" @click="handleConvert">
      {{ convertAccountText }}
    </ThemedButton>
    <ThemedButton v-else theme="gray" @click="handleCreateAccount">
      {{ createAccountText }}
    </ThemedButton>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ThemedButton from '@/components/base/ThemedButton.vue';
import { useCustomer } from '@/composables/useCustomer';
import { useLoginModal } from '@/composables/useLoginModal';
import { CmsRegisteredComponent } from '@/utils/cms';

const B2BRegistrationButton = defineComponent({
  name: 'B2BRegistrationButton',
  props: {
    createAccountText: { required: false, type: String, default: 'Create Business Account' },
    convertAccountText: { required: false, type: String, default: 'Convert to Business Account' },
    viewAccountText: { required: false, type: String, default: 'View Business Account' },
  },
  components: {
    ThemedButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { convertToBusinessAccount, customer, hasBusinessAccount } = useCustomer(store);
    const { handleOpen } = useLoginModal();

    const showConvertButton = computed(() => customer.value && !hasBusinessAccount.value);
    const showViewDetailsButton = computed(() => customer.value && hasBusinessAccount.value);

    const goToBusinessAccount = () => {
      router.push('/account/business');
    };

    const handleConvert = async () => {
      await convertToBusinessAccount();
      goToBusinessAccount();
    };

    const handleCreateAccount = () => {
      handleOpen({
        destination: '/account/business',
        initialStep: 'sign-up',
        selectBusiness: true,
      });
    };

    return {
      customer,
      goToBusinessAccount,
      handleCreateAccount,
      handleConvert,
      hasBusinessAccount,
      showConvertButton,
      showViewDetailsButton,
    };
  },
});

export const B2BRegistrationButtonRegistration: CmsRegisteredComponent = {
  component: B2BRegistrationButton,
  name: 'B2BRegistrationButton',
  inputs: [
    {
      name: 'createAccountText',
      type: 'string',
      defaultValue: 'Create Business Account',
    },
    {
      name: 'convertAccountText',
      type: 'string',
      defaultValue: 'Convert to Business Account',
    },
    {
      name: 'viewAccountText',
      type: 'string',
      defaultValue: 'View Business Account',
    },
  ],
};

export default B2BRegistrationButton;
</script>
