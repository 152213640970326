import { LineItem, Order } from '@commercetools/platform-sdk';
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import createDebug from 'debug';
import compact from 'lodash/compact';
import mapValues from 'lodash/mapValues';
import sum from 'lodash/sum';
import type { SetRequired } from 'type-fest';

import { getIsFirstOrder } from '@/api/checkout/payment';
import {
  FbPurchaseEvent,
  sendConversionEvent as facebookConversionEvent,
} from '@/api/trackingEvents/facebook';
import type { PaymentItem } from '@/composables/useCheckout';
import { useLocalStorage } from '@/composables/useLocalStorage';
import { NutsAddress } from '@/utils/address';
import { isPackingSlipMessageCustomLineItem, NutsLineItem } from '@/utils/cart';
import { sha256 } from '@/utils/crypto';
import { Money } from '@/utils/money';
import { getSsrState } from '@/utils/ssrStorage';

const debug = createDebug('nuts:analytics');

export interface AnalyticsProduct {
  readonly autoDeliveryInterval?: number;
  readonly id: string;
  readonly itemExternalId?: string;
  readonly name: string;
  readonly price: number;
  readonly primaryMerchandisingCategory?: string;
  readonly quantity: number;
}

export interface DataPromo {
  readonly creative: string;
  readonly name: string;
  readonly type: string;
}

export interface GAEvent {
  action: string;
  category: string;
  ecommerce?: object;
  label?: string | null;
}

export interface GTMRecord {
  ecommerce?: object;
  event: string;
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string | null;
  [key: string]: any;
}

export interface ListMetadata {
  category?: {
    indexName: string;
    objectID: string;
    position: number;
    searchQueryID?: string;
  };
  indexName?: string;
  list: string;
  position: number;
  searchQueryID?: string;
}

interface NutsPurchaseItemParams extends Gtag.PurchaseItemParams {
  autodelivery_interval?: number;
  cost?: number;
  item_variant_name?: string;
  marked_as_gift?: boolean;
  merchandising_category?: string;
  price_before_discount: number;
  weight: number;
}

interface NutsPurchaseParams
  extends SetRequired<Gtag.PurchaseParams, 'currency' | 'items' | 'shipping' | 'tax' | 'value'> {
  credit?: number;
  discount?: number;
  gift_message: boolean;
  hrp?: number;
  items: NutsPurchaseItemParams[];
  new_customer?: boolean;
  placed_by: 'Customer' | 'Employee' | 'System';
  subtotal: number;
  surcharge?: number;
}

const parseListMetadata = (listMetadata: string): ListMetadata | undefined => {
  try {
    return JSON.parse(listMetadata);
  } catch {
    return undefined;
  }
};

export const getListMetadata = () => {
  const data = useLocalStorage().get<ListMetadata>('listMetadata');
  return data ?? undefined;
};

export const setListMetadata = (data: ListMetadata) => {
  useLocalStorage().set('listMetadata', data);
};

export const flattenCategoryMetadata = (
  categoryMetadata: ListMetadata['category'],
  fieldPrefix: string,
) => {
  const flattened: { [key: string]: any } = {};
  if (categoryMetadata) {
    Object.entries(categoryMetadata).forEach(([key, value]) => {
      flattened[`${fieldPrefix}_${key}`] = value;
    });
  }
  return flattened;
};

const formatListMetadata = (
  listMetadata: ListMetadata | undefined,
):
  | {
      index: number;
      indexName: string | null;
      item_list_name: string;
      searchQueryID?: string;
    }
  | undefined => {
  if (!listMetadata) return undefined;
  const categoryMetadata = flattenCategoryMetadata(listMetadata.category, 'listMetadata_category');
  return {
    index: listMetadata.position,
    indexName: listMetadata.indexName ?? null,
    item_list_name: listMetadata.list,
    searchQueryID: listMetadata.searchQueryID,
    ...categoryMetadata,
  };
};

export const formatPurchaseItem = (
  lineItem: NutsLineItem,
  productKeysById: { [productId: string]: string },
): NutsPurchaseItemParams => ({
  item_id: productKeysById[lineItem.productId],
  item_name: lineItem.name.en,
  item_variant: lineItem.variant.sku,
  item_variant_name: lineItem.variant.variantName?.en,
  marked_as_gift: lineItem.custom?.fields.markedAsGift,
  price: dollars(lineItem.piecePrice),
  price_before_discount: dollars(lineItem.variant.prices?.find((p) => !p.channel)?.value),
  cost: dollars(lineItem.variant.pieceCost),
  coupon: lineItem.totalSavings?.description?.en,
  discount: dollars(lineItem.totalSavings?.value || from(0)),
  quantity: lineItem.quantity,
  autodelivery_interval: lineItem.custom?.fields.autoDeliveryInterval,
  merchandising_category: lineItem.variant.attributes?.find(
    ({ name }) => name === 'merchandisingCategory',
  )?.value.label,
  weight: lineItem.variant.weight,
  ...(lineItem.custom?.fields.listMetadata
    ? formatListMetadata(parseListMetadata(lineItem.custom.fields.listMetadata)) ?? {}
    : {}),
});

export function formatPurchase({
  newCustomer,
  order: { discountCodes, lineItems, orderNumber, taxedPrice },
  paymentItems,
  placedBy,
  productKeysById,
}: {
  newCustomer?: boolean;
  order: Order;
  paymentItems: PaymentItem[];
  placedBy: 'Employee' | 'Customer' | 'System';
  productKeysById: { [productId: string]: string };
}): NutsPurchaseParams {
  // pivot payment items by label and convert to dollars
  const pi = Object.fromEntries(
    paymentItems.map((item) => [item.label, dollars(item.amount)]),
  ) as Record<PaymentItem['label'], number | undefined>;

  return {
    currency: 'USD',
    transaction_id: orderNumber!,
    coupon: discountCodes?.[0]?.discountCode.obj?.name?.en,
    value: dollars(taxedPrice!.totalNet),
    subtotal: pi.Subtotal || 0,
    shipping: pi.Shipping || 0,
    hrp: pi['Heat-Resistant Packaging'],
    tax: sum([pi.Tax, pi.Duties, pi['GST/HST']]) || 0,
    surcharge: pi['Temporary Surcharge'],
    discount: sum([pi.Discount, pi.Adjustment]), // ask David if he cares about omitting vs $0
    credit: pi['Store Credit'],
    items: lineItems.map((li) => formatPurchaseItem(NutsLineItem.fromCt(li), productKeysById)),
    new_customer: newCustomer,
    gift_message: false,
    placed_by: placedBy,
  };
}

const formatAnalyticsProductsForUA = (
  lineItems: LineItem[],
  productKeysById: { [productId: string]: string },
): AnalyticsProduct[] =>
  lineItems.map((lineItem) => ({
    id: `0${lineItem.variant.sku}`,
    itemExternalId: productKeysById[lineItem.productId],
    name: lineItem.name.en,
    quantity: lineItem.quantity,
    autoDeliveryInterval: lineItem.custom?.fields.autoDeliveryInterval,
    pieceCost: dollars(
      lineItem.variant.attributes?.find((a: { name: string }) => a.name === 'pieceCost')?.value,
    ),
    price: dollars(lineItem.price.value),
    primaryMerchandisingCategory: lineItem.variant.attributes?.find(
      (a: { name: string }) => a.name === 'merchandisingCategory',
    )?.value.label,
  }));

const formatPurchaseForUA = (context: {
  newCustomer?: boolean;
  order: Order;
  paymentItems: PaymentItem[];
  placedByAdmin?: boolean;
}): {
  id: string;
  revenue: number;
  tax: number;
  shipping: number;
  coupon: string;
  discounts: number;
  newCustomer?: boolean;
  estimatedProfit?: number; // TODO
  postalCode?: string;
  placedByAdmin: boolean;
  subtotalMinusCoupons: number;
  containsAutoDelivery: boolean;
  containsGiftMessage: boolean;
  containsMarkedAsGift: boolean;
  containsGreetingCard: boolean;
  sameBillingAddress: boolean;
} => {
  const { order, paymentItems } = context;

  const discount = paymentItems.find((item) => item.label === 'Discount');
  const shipping = paymentItems.find((item) => item.label === 'Shipping');
  const subtotal = paymentItems.find((item) => item.label === 'Subtotal');
  const tax = paymentItems.find((item) => item.label === 'Tax');

  let coupon = '';
  const { discountCodes } = order;
  if (discountCodes) {
    coupon = discountCodes[0]?.discountCode.obj?.name?.en ?? '';
  }

  let postalCode;
  if (order.billingAddress) {
    ({ postalCode } = NutsAddress.fromCt(order.billingAddress));
  }

  const subtotalMinusCoupons = dollars(
    Money.subtract(subtotal?.amount ?? from(0), discount?.amount ?? from(0)),
  );

  return {
    id: order.orderNumber!,
    revenue: dollars(order.taxedPrice?.totalGross),
    tax: dollars(tax!.amount),
    shipping: dollars(shipping!.amount),
    coupon,
    discounts: dollars(discount?.amount) || 0,
    newCustomer: context.newCustomer,
    postalCode,
    placedByAdmin: context.placedByAdmin ?? false,
    subtotalMinusCoupons,
    containsAutoDelivery: order.lineItems.some((i) => !!i.custom?.fields.autoDeliveryInterval),
    containsGiftMessage: order.customLineItems.some(isPackingSlipMessageCustomLineItem),
    containsMarkedAsGift: order.lineItems.some((i) => i.custom?.fields.markedAsGift),
    containsGreetingCard: order.lineItems.some(
      (i) => !!i.custom && 'greetingCardMessage' in i.custom.fields,
    ),
    sameBillingAddress: order.billingAddress?.streetName === order.shippingAddress?.streetName,
  };
};

/**
 * Call window.gtag() safely
 *
 * Checks if window and window.gtag are defined first. If called during SSR,
 * attempts to store for later rendering into index.html where it'll get
 * reinjected into window.gtag().
 */
export const gtag = <Gtag.Gtag>((...args: Parameters<Gtag.Gtag>) => {
  if (import.meta.env.SSR) {
    getSsrState().dataLayer?.push(args);
  } else if (typeof window !== 'undefined' && window.gtag) {
    window.gtag(...args);
  }
});

export async function hashEmailAddress(rawEmail: string) {
  const email = rawEmail.trim().toLowerCase();
  const hashedEmail = await sha256(email);
  return hashedEmail;
}

export const pushToDataLayer = (record: GTMRecord) => {
  if (typeof window === 'undefined') {
    debug('window is undefined');
    return;
  }

  if (!window.dataLayer) {
    debug('window.dataLayer is undefined');
    return;
  }

  const { event, ...fields } = record;
  const resetFields = mapValues(fields, (_) => null);

  window.dataLayer.push(resetFields);
  window.dataLayer.push(record);
  window.dataLayer.push(resetFields);
};

const pushEvent = (event: string, record: GAEvent) => {
  const { action, category, label = null, ...other } = record;
  pushToDataLayer({
    event,
    eventAction: action,
    eventCategory: category,
    eventLabel: label,
    ...other,
  });
};

export const dyEvent = (event: GAEvent) => {
  pushEvent('dyEvent', event);
};

export const gaEvent = (event: GAEvent) => {
  pushEvent('gaEvent', event);
};

export const CartEvents = {
  remove: (removedLineItem: AnalyticsProduct) => {
    pushToDataLayer({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [removedLineItem],
        },
      },
    });
  },
};

export function event(
  eventName: 'purchase',
  eventParams?: NutsPurchaseParams | Gtag.ControlParams | Gtag.CustomParams,
): void;
export function event(
  eventName: Gtag.EventNames | string,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
) {
  gtag('event', eventName, eventParams);
}

export const CheckoutEvents = {
  event: ({
    action,
    category = 'Checkout Event',
    label,
  }: {
    action: string;
    category?: string;
    label: string;
  }) => {
    pushEvent('checkoutEvent', { category, action, label });
  },
  option: (stepNumber: number, option: string) => {
    pushEvent('checkoutOption', {
      category: 'Ecommerce',
      action: 'Checkout Option',
      label: null,
      ecommerce: {
        checkout_option: {
          actionField: { step: stepNumber, option },
        },
      },
    });
  },
  async purchase({
    isAdmin,
    order,
    paymentItems,
    productKeysById,
    receiptToken,
  }: {
    isAdmin?: boolean;
    order: Order;
    paymentItems: PaymentItem[];
    productKeysById: { [productId: string]: string };
    receiptToken: string;
  }) {
    let newCustomer: boolean | undefined;
    try {
      newCustomer = await getIsFirstOrder(receiptToken);
    } catch (e) {
      console.error(e);
    }

    const actionField = formatPurchaseForUA({
      newCustomer,
      order,
      paymentItems,
      placedByAdmin: isAdmin,
    });
    const products = formatAnalyticsProductsForUA(order.lineItems, productKeysById);

    event(
      'purchase',
      formatPurchase({
        newCustomer,
        order,
        paymentItems,
        placedBy: isAdmin ? 'Employee' : 'Customer',
        productKeysById,
      }),
    );

    pushEvent('orderPlaced', {
      action: 'Purchase',
      category: 'Ecommerce',
      label: null,
      ecommerce: {
        purchase: { actionField, products },
      },
    });
    let hashedEmail = '';
    try {
      hashedEmail = await hashEmailAddress(order.customerEmail ?? '');
    } catch {
      // browser may not support the `crypto.subtle` APIs
    }
    facebookConversionEvent<FbPurchaseEvent>('Purchase', {
      custom_data: {
        content_type: 'product_group',
        content_ids: compact(products.map((p) => p.itemExternalId)),
        value: actionField.subtotalMinusCoupons,
        currency: 'USD',
      },
      user_data: {
        em: hashedEmail,
      },
    });
  },
  step: (stepNumber: number, products: AnalyticsProduct[]) => {
    pushToDataLayer({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: stepNumber },
          products,
        },
      },
    });
  },
  validation: ({
    stepNumber,
    field,
    error,
  }: {
    stepNumber: number;
    field: string;
    error: string;
  }) => {
    pushEvent('checkoutValidation', {
      action: `Step ${stepNumber}: ${field}`,
      category: 'Checkout Validation Error',
      label: `Error: ${error}`,
    });
  },
  validInput: ({ stepNumber, field }: { stepNumber: number; field: string }) => {
    pushEvent('checkoutValidation', {
      action: `Step ${stepNumber}: ${field}`,
      category: 'Checkout Validation Success',
      label: 'Success',
    });
  },
};

const sendEvent = (evt: string, location: string, body: any) => {
  let activity = {
    event: evt,
    ...body,
  };
  if (location) {
    activity = { ...activity, location };
  }
  window.dataLayer.push(activity);
};

export default {
  sendEvent,
};
