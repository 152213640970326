<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { desktopAttribution, LargeMenu } from '@/utils/navMenu';

const emit = defineEmits<{
  (e: 'update:showSubMenu', value: boolean): void;
}>();
const props = defineProps<{ menu: LargeMenu; showSubMenu: boolean }>();
const showSubMenu = useVModel(props, 'showSubMenu', emit);

const linksContainer = ref<HTMLElement>();
// necessary for parent component keyboard accessibility ref list
defineExpose({ linksContainer });
useRouterLinks(linksContainer, useRouter());
</script>

<template>
  <div class="pt-5 pb-8 bg-white">
    <div ref="linksContainer" class="justify-center gap-12 xl:flex">
      <nav class="flex justify-center gap-12 xl:justify-end">
        <div v-for="(section, si) in menu.sections" :key="si" class="w-[160px]">
          <ul
            v-for="(groupField, gi) in section.linkGroups"
            :key="groupField.group.headerText || gi"
            :class="{ 'mt-8': gi > 0 }"
          >
            <li v-if="groupField.group.headerText" class="font-semibold">
              <a
                v-if="groupField.group.headerUrl"
                :href="groupField.group.headerUrl"
                class="text-base promo-link-styles"
                @click="showSubMenu = false"
                v-bind="desktopAttribution(menu, groupField.group.headerText)"
                :data-test="`sub-nav-item-${groupField.group.headerText}`"
              >
                {{ groupField.group.headerText }}
              </a>
              <span v-else class="text-base">
                {{ groupField.group.headerText }}
              </span>
            </li>
            <li
              v-for="(linkField, i) in groupField.group.links"
              :key="linkField.link.url"
              class="hover:underline hover:font-semibold decoration-yellow decoration-2 underline-offset-4"
              :class="{ 'mt-3': groupField.group.headerText || i > 0 }"
            >
              <a
                :href="linkField.link.url"
                class="text-base no-link-styles-sub"
                :class="{ 'font-semibold promo-link-styles': linkField.emphasized }"
                @click="showSubMenu = false"
                v-bind="desktopAttribution(menu, groupField.group.headerText, linkField.link.text)"
                :data-test="`sub-nav-item-${groupField.group.headerText}`"
              >
                {{ linkField.link.text }}
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div
        v-if="menu.promoZones?.length"
        class="flex justify-center gap-6 mt-12 xl:flex-col xl:mt-0"
      >
        <div v-for="zone in menu.promoZones" :key="zone.url" class="flex flex-col max-w-xs">
          <a
            :href="zone.url"
            @click="showSubMenu = false"
            v-bind="desktopAttribution(menu, zone.text)"
            :data-test="`sub-nav-promo-${zone.text}`"
          >
            <img :src="zone.image" :alt="`${zone.text} Image`" class="h-40 w-80" loading="lazy" />
          </a>
          <a
            :href="zone.url"
            class="mt-1 promo-link-styles"
            @click="showSubMenu = false"
            v-bind="desktopAttribution(menu, zone.text)"
            :data-test="`sub-nav-promo-${zone.text}`"
          >
            {{ zone.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-link-styles-sub {
  @apply text-neutral-600 hover:no-underline focus:no-underline cursor-pointer;
}

.promo-link-styles {
  @apply text-black text-base hover:underline hover:font-semibold decoration-yellow decoration-2 underline-offset-4;
}
</style>
