<script setup lang="ts">
import logo from '@/assets/yellow_box_logo.svg';
import CashewIcon from '@/components/base/assets/CashewIcon.vue';
import Caption from '@/components/base/typography/Caption.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';

withDefaults(
  defineProps<{
    fullWidth?: boolean;
    logoAlt?: string;
    logoImage?: string;
    logoLink?: string;
  }>(),
  {
    fullWidth: false,
    logoAlt: 'Go to Homepage',
    logoLink: '/',
  },
);
</script>

<script lang="ts">
// eslint-disable-next-line import/first
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const SimplifiedHeaderRegistration: BuilderComponent = {
  name: 'Simplified Header',
  canHaveChildren: true,
  inputs: [
    {
      name: 'fullWidth',
      type: 'boolean',
      helperText: 'Should the header be full width or has a pre-defined max width?',
    },
    {
      name: 'logoAlt',
      type: 'string',
      helperText: 'Alt text for the logo',
    },
    {
      name: 'logoImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
    },
    {
      name: 'logoLink',
      type: 'string',
      helperText: 'Where should the logo redirect to?',
    },
  ],
};
</script>

<template>
  <div role="navigation" id="appHeader" class="relative z-10 shadow-b">
    <div
      class="flex items-center justify-between w-full h-16 px-4 mx-auto md:h-20 md:px-10"
      :class="{ '2xl:max-w-screen-2xl': !fullWidth }"
    >
      <div class="flex items-center">
        <RouterLink :to="logoLink">
          <img :src="logoImage ?? logo" :alt="logoAlt" class="mr-8 h-9 md:h-14" />
        </RouterLink>
        <slot name="title"></slot>
      </div>
      <slot name="right">
        <div class="flex items-center">
          <CashewIcon class="mr-2" />
          <div>
            <Caption>Need help?</Caption>
            <SmallBodyText>
              <a href="tel:8005586887" class="block tel">800-558-6887</a>
            </SmallBodyText>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.shadow-b {
  @media screen and (min-width: 768px) {
    box-shadow: 0px 1px 5px 0px rgb(161 161 161 / 50%);
  }
}
</style>
