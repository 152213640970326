/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import { webstore } from '@/api';

const namespaced = true;

export const state = () => ({
  cartBanner: {
    background_color: null,
    message_html: null,
  },
  /** @type {string | null} */
  countryCode: null,
  declinedNewsletter: false,
  freeShippingCouponThreshold: null,
  subscribed: false,
  userCreatedAt: null,
  userFirstName: '',
});

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations = {
  SET_FROM_DYNAMIC_CONTENT(
    state,
    {
      topShelf: { userCreatedAt = null, userFirstName = '' } = {},
      miniCart: {
        banner: { background_color = null, message_html = null } = {},
        freeShippingCouponThreshold = null,
      } = {},
    },
  ) {
    state.userCreatedAt = userCreatedAt;
    state.userFirstName = userFirstName;
    state.cartBanner.background_color = background_color;
    state.cartBanner.message_html = message_html;
    if (freeShippingCouponThreshold !== null) {
      state.freeShippingCouponThreshold = Number(freeShippingCouponThreshold).toFixed(2);
    }
  },
  SET_NEWSLETTER_STATUS(state, { declinedNewsletter = false, subscribed = false }) {
    state.declinedNewsletter = declinedNewsletter;
    state.subscribed = subscribed;
  },
};

/** ******** */
// ACTIONS //
/** ******** */
export const actions = {
  async getDynamicHeaderContent({ commit }) {
    try {
      const { data } = await webstore.sessionSpecific.get('/dynamic-content.json');
      commit('SET_FROM_DYNAMIC_CONTENT', data.data);
      commit('cartModule/SET_FROM_DYNAMIC_CONTENT_MINICART', data.data.miniCart, { root: true });
    } catch (ex) {
      console.error('failed to retrieve dynamic header content');
      console.error(ex);
    }
  },
  async setNewsletterStatus({ commit }, newsletterStatus) {
    commit('SET_NEWSLETTER_STATUS', newsletterStatus);
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
