<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { onMounted, ref, watch } from 'vue';

import RouteLink from '@/components/base/RouteLink.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import LargeMenu from '@/components/layout/header/desktop/LargeMenu.vue';
import { desktopAttribution, LargeMenu as LargeMenuModel, LargeMenuGroup } from '@/utils/navMenu';

const emit = defineEmits<{
  (e: 'exit-menu'): void;
  (e: 'update:showSubMenu', value: boolean): void;
}>();
const props = defineProps<{
  active: boolean;
  menus: LargeMenuGroup['menus'];
  showSubMenu: boolean;
}>();
const showSubMenu = useVModel(props, 'showSubMenu', emit);

const activeMenu = ref<LargeMenuModel>();
const menuRoot = ref<HTMLElement>();

const cancelButtonClick = (navEntry: LargeMenuModel, e: Event) => {
  if (navEntry.header.url) return;
  e.preventDefault();
  e.stopPropagation();
};

const enterMenu = (i: number) => {
  if (activeMenu.value === props.menus[i]?.menu && menuRoot.value) {
    menuRoot.value.querySelector<HTMLElement>(`[data-menu="${i}"] [href]`)?.focus();
  }
};

const tabToNextMenu = (e: KeyboardEvent) => {
  const activeIndex = props.menus.findIndex(({ menu }) => menu === activeMenu.value);
  const activePanel = menuRoot.value?.querySelector<HTMLElement>(`[data-menu="${activeIndex}"]`);
  if (!e.target || !(e.target instanceof HTMLElement)) return;
  const links = activePanel?.querySelectorAll<HTMLElement>('[href]');
  const lastLink = links?.[links.length - 1];
  const nextTrigger = menuRoot.value?.querySelector<HTMLElement>(
    `[data-menu-trigger="${activeIndex + 1}"]`,
  );
  if (lastLink === e.target && nextTrigger) {
    e.preventDefault();
    e.stopPropagation();
    nextTrigger.focus();
  }
};

const tabToPreviousMenu = (e: KeyboardEvent) => {
  const activeIndex = props.menus.findIndex(({ menu }) => menu === activeMenu.value);
  const activePanel = menuRoot.value?.querySelector<HTMLElement>(`[data-menu="${activeIndex}"]`);
  if (!e.target || !(e.target instanceof HTMLElement)) return;
  const firstLink = activePanel?.querySelector<HTMLElement>('[href]');
  const previousTrigger = menuRoot.value?.querySelector<HTMLElement>(
    `[data-menu-trigger="${activeIndex - 1}"]`,
  );
  if (firstLink === e.target) {
    e.preventDefault();
    e.stopPropagation();
    if (!previousTrigger) {
      emit('exit-menu');
      return;
    }
    previousTrigger.focus();
  }
};

onMounted(() => {
  watch(
    () => props.active,
    (active) => {
      if (!active) activeMenu.value = undefined;
      else if (!activeMenu.value) activeMenu.value = props.menus[0]?.menu;
    },
  );
});
</script>

<template>
  <div
    class="absolute flex pr-2.5 bg-white shadow-md shadow-black/10 border-b border-solid border-nuts-neutral-200 rounded-md min-w-max max-w-[1360px]"
    ref="menuRoot"
  >
    <ul
      class="flex flex-col py-4 border-r border-solid bg-background-secondary w-52 border-nuts-neutral-200 max-h-[30rem] overflow-y-scroll"
    >
      <li v-for="({ menu }, i) in menus" :key="menu.header.text">
        <component
          :is="menu.header.url ? RouteLink : UnstyledButton"
          :to="menu.header.url"
          class="relative flex items-center justify-between gap-1 py-2 pl-4 pr-1 text-base font-semibold hover:no-underline font-sofia-pro hover:bg-white after:content-[''] after:absolute"
          :class="{
            'bg-white after:bg-white after:w-px after:h-full after:-right-px after:top-0':
              activeMenu === menu,
          }"
          :data-menu-trigger="i"
          v-bind="desktopAttribution(menu)"
          :data-test="`base-nav-item-${menu.header.text}`"
          @click="cancelButtonClick(menu, $event)"
          @focusin="activeMenu = menu"
          @keydown.right="enterMenu(i)"
          @keydown.tab="activeMenu = undefined"
          @mouseover="activeMenu = menu"
        >
          <div class="flex items-center">
            <img
              v-if="menu.header.icon"
              class="w-5 h-5 mr-2.5"
              alt="Emphasized top menu link icon"
              :src="menu.header.icon"
            />
            <span class="text-black" :style="{ color: menu.header.color }">
              {{ menu.header.text }}
              <span class="sr-only">Press arrow right key to enter submenu</span>
            </span>
          </div>
          <img
            aria-hidden="true"
            src="@/assets/arrow-right-sm.svg"
            alt="right chevron icon"
            class="h-6"
          />
        </component>
      </li>
    </ul>

    <div class="flex flex-col max-h-[30rem] overflow-y-scroll">
      <template v-for="({ menu }, i) in menus" :key="menu.header.text">
        <LargeMenu
          v-show="activeMenu === menu"
          :menu="menu"
          v-model:showSubMenu="showSubMenu"
          class="px-10"
          :data-menu="i"
          @keydown.exact.tab="tabToNextMenu"
          @keydown.shift.tab="tabToPreviousMenu"
        />
      </template>
    </div>
  </div>
</template>