<script setup lang="ts">
import { useClipboard } from '@vueuse/core';
import { onMounted, ref } from 'vue';

import RouteLink from '@/components/base/RouteLink.vue';
import { useChat } from '@/composables/useChat';

withDefaults(
  defineProps<{
    brandSelector?: { label: string; link: string }[];
    announcement?: { text: string; link: string }[];
  }>(),
  {
    announcement: () => [{ text: 'Free shipping on all orders over $59. See Details', link: '/' }],
    brandSelector: () => [{ label: 'ixclkxk.shop', link: '/' }],
  },
);
const source = ref('care@ixclkxk.shop');
const { copy, copied, isSupported } = useClipboard({ source });
const { displayChatInfoText, initChat, isChatStatusPending, openChat } = useChat();
const isHovering = ref(false);

const toggleCopyHover = (hovering: boolean) => {
  isHovering.value = hovering;
};

onMounted(initChat);
</script>

<template>
  <div ref="popUp">
    <img src="@/assets/popoverOriginPoint.svg" alt="chevron" class="absolute right-[-3px] top-5" />
    <div class="absolute z-40 bg-white rounded-md shadow-lg -right-4 top-7 popover">
      <div class="flex flex-col justify-center" data-test="help-popover">
        <span class="flex justify-center mt-8 mb-2 text-base font-normal font-bold text-black">
          We are here to help!
        </span>
        <div class="block pb-8">
          <span
            v-if="isHovering && isSupported"
            class="absolute flex items-center justify-center px-2 py-1 text-sm font-normal text-black bg-white rounded shadow-lg copy right-8 bottom-32 bg-red"
          >
            <img src="@/assets/copy_icon.svg" alt="copy icon" class="mr" />
            {{ !copied ? 'Click to copy' : 'Copied!' }}
          </span>
          <span
            v-if="displayChatInfoText && !isChatStatusPending"
            @click="openChat"
            class="flex justify-center mb-3 text-xl font-bold text-black underline cursor-pointer decoration-nuts-neutral-200 hover:decoration-yellow"
          >
            <img src="@/assets/chat_icon.svg" alt="chat icon" class="mr-2" /> Chat Live Now
          </span>
          <span
            v-else
            @click="isSupported && copy(source)"
            @mouseover="toggleCopyHover(true)"
            @mouseleave="toggleCopyHover(false)"
            class="flex justify-center text-xl font-bold text-black underline cursor-pointer decoration-nuts-neutral-200 hover:decoration-yellow font-sofia-pro"
          >
            <img src="@/assets/paper_plane.svg" alt="paper plane icon" class="mr-2" />
            <a
              @click="($event) => (isSupported ? $event.preventDefault() : undefined)"
              :href="`mailto:${source}`"
            >
              {{ source }}
            </a>
          </span>
          <span
            v-if="displayChatInfoText && !isChatStatusPending"
            class="flex justify-center text-xs font-bold text-green"
          >
            Typically responds within 3 minutes
          </span>
        </div>
      </div>
      <div class="flex items-center w-full h-12 border-t border-solid border-nuts-neutral-200">
        <RouteLink
          to="/contact"
          class="flex items-center justify-center w-1/2 h-full border-r border-solid border-nuts-neutral-200 link hover:bg-background-secondary"
          data-test="contact-us"
        >
          Contact Us
        </RouteLink>
        <RouteLink
          to=""
          class="flex items-center justify-center w-1/2 h-full link hover:bg-background-secondary"
          data-test="help-center"
        >
          Help Center
        </RouteLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(a) {
  @apply text-black;
}
.popover {
  width: 280px;
}

.hidden {
  visibility: visible;
}
.copy {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
}
</style>
