/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import encoding from '@/utils/encoding';

const namespaced = true;

export const PopularitySort = 'popularity';
export const PriceSort = 'price';

export const state = () => ({
  activeFilters: [],
  activeIngredientId: null,
  activeKeyword: '',
  activeSlot: null,
  activeSort: PopularitySort,
  fullIngredientCatalog: [],
  trayContents: [null, null, null, null, null],
  quantity: null,
  size: '9900-03',
});

/** ********** */
// HELPERS //
/** ********** */

function calculateTraySectionPrice(traySize, slot, ingredient) {
  const outerOrInner = slot === 4 ? 'i' : 'o';
  const field = `price${traySize}${outerOrInner}`;
  return (ingredient && ingredient[field]) || 0;
}

function calculateTrayPrice(traySize, trayContents) {
  if (trayContents.filter(Boolean).length === 0) {
    return 0;
  }

  const basePrice = traySize === 'L' ? 19 : 19;
  const ingredientPrice = trayContents
    .map((ingredient, i) => calculateTraySectionPrice(traySize, i, ingredient))
    .reduce((acc, value) => acc + value, 0);
  return basePrice + ingredientPrice;
}

/** ********** */
// GETTERS //
/** ********** */
export const getters = {
  currentLargeTrayPrice: (state) => calculateTrayPrice('L', state.trayContents),
  currentMediumTrayPrice: (state) => calculateTrayPrice('M', state.trayContents),
  encodedIngredientIds: (state) => {
    const code = ['000', '000', '000', '000', '000'];
    state.trayContents.forEach((ingredient, slot) => {
      if (ingredient) {
        code[slot] = encoding.numToSxg(ingredient.id, 3);
      }
    });

    if (state.size === '9901-05') {
      code[6] = 'l';
    }

    return code.join('');
  },
  ingredientAtSlot: (state) => (slot) => state.trayContents[slot - 1],
};

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations = {
  ADD_INGREDIENT_TO_SLOT(state, { ingredient, slot }) {
    state.trayContents.splice(slot, 1, ingredient);
  },
  FREE_SLOT(state, slot) {
    state.trayContents.splice(slot, 1, null);
  },
  SET_ACTIVE_INGREDIENT_ID(state, id) {
    state.activeIngredientId = id;
  },
  SET_ACTIVE_KEYWORD(state, keyword) {
    state.activeKeyword = keyword;
  },
  SET_ACTIVE_SLOT(state, slot) {
    state.activeSlot = slot;
  },
  SET_ACTIVE_SORT(state, sort) {
    state.activeSort = sort;
  },
  SET_FULL_INGREDIENT_CATALOG(state, fullIngredientCatalog) {
    state.fullIngredientCatalog = fullIngredientCatalog;
  },
  SET_TRAY_CONTENTS(state, trayContents) {
    state.trayContents = trayContents;
  },
  SET_SELECTED_QUANTITY(state, quantity) {
    state.quantity = quantity;
  },
  SET_SELECTED_SIZE(state, size) {
    state.size = size;
  },
  TOGGLE_ACTIVE_CATEGORY_FILTER(state, filter) {
    if (state.activeFilters.includes(filter)) {
      state.activeFilters.splice(state.activeFilters.indexOf(filter), 1);
    } else {
      state.activeFilters = [
        ...state.activeFilters.filter((activeFilter) => !activeFilter.startsWith('cat:')),
        filter,
      ];
    }
  },
  TOGGLE_ACTIVE_FILTER(state, filter) {
    if (state.activeFilters.includes(filter)) {
      state.activeFilters.splice(state.activeFilters.indexOf(filter), 1);
    } else {
      state.activeFilters.push(filter);
    }
  },
};

/** ******** */
// ACTIONS //
/** ******** */
export const actions = {
  activateIngredientAndSlotIfReady({ commit, dispatch, state }) {
    if (state.activeIngredientId && state.activeSlot) {
      const ingredient = state.fullIngredientCatalog.find(
        (ingredient) => ingredient.id === state.activeIngredientId,
      );
      dispatch('addIngredientToSlot', {
        ingredient,
        slot: state.activeSlot,
      });
      commit('SET_ACTIVE_INGREDIENT_ID', null);
      commit('SET_ACTIVE_SLOT', null);
    }
  },
  addIngredientToSlot({ commit }, { ingredient, slot }) {
    commit('ADD_INGREDIENT_TO_SLOT', { ingredient, slot: slot - 1 });
  },
  freeSlot({ commit }, slot) {
    commit('FREE_SLOT', slot - 1);
  },
  prefillTrayContents({ dispatch, state }, encodedIngredientIds) {
    if (!encodedIngredientIds) {
      return;
    }

    // eslint-disable-next-line no-unused-vars
    let size = 'm';
    let code = encodedIngredientIds;

    if (code.length > 15) {
      size = code.substring(15, code.length);
      code = code.substring(0, 15);
    }

    dispatch('setSelectedSize', size === 'l' ? '9901-05' : '9900-03');

    const content = [];
    for (let i = 0; i < code.length; i += 3) {
      const skuId = encoding.sxgToNum(code.substring(i, i + 3));
      const ingredient =
        state.fullIngredientCatalog.find((ing) => ing.id === skuId.toString()) || null;
      content.push(ingredient);
    }
    dispatch('setTrayContents', content);
  },
  setActiveIngredientId({ commit, dispatch }, id) {
    commit('SET_ACTIVE_INGREDIENT_ID', id);
    dispatch('activateIngredientAndSlotIfReady');
  },
  setActiveKeyword({ commit }, keyword) {
    commit('SET_ACTIVE_KEYWORD', keyword);
  },
  setActiveSlot({ commit, dispatch }, slot) {
    commit('SET_ACTIVE_SLOT', slot);
    dispatch('activateIngredientAndSlotIfReady');
  },
  setActiveSort({ commit }, sort) {
    commit('SET_ACTIVE_SORT', sort);
  },
  setFullIngredientCatalog({ commit }, fullIngredientCatalog) {
    commit('SET_FULL_INGREDIENT_CATALOG', fullIngredientCatalog);
  },
  setTrayContents({ commit }, trayContents) {
    commit('SET_TRAY_CONTENTS', trayContents);
  },
  setSelectedQuantity({ commit }, quantity) {
    commit('SET_SELECTED_QUANTITY', quantity);
  },
  setSelectedSize({ commit }, size) {
    commit('SET_SELECTED_SIZE', size);
  },
  toggleActiveCategoryFilter({ commit }, filter) {
    commit('TOGGLE_ACTIVE_CATEGORY_FILTER', filter);
  },
  toggleActiveFilter({ commit }, filter) {
    commit('TOGGLE_ACTIVE_FILTER', filter);
  },
  unsetActiveSlot({ commit }) {
    commit('SET_ACTIVE_SLOT', null);
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
  PopularitySort,
  PriceSort,
};
