<template>
  <div class="mt-2 ml-2 lg:ml-0 mb-3">
    <h3 class="lg:mb-2 lg:text-sm lg:font-normal mt-2 mb-1 text-lg font-proxima-nova">Filter By</h3>
    <div class="flex flex-wrap mb-5">
      <label
        v-for="filter in filters"
        :key="filter.key"
        class="flex flex-wrap text-sm font-normal bg-white rounded-full border border-solid mr-1.5 mb-1.5 py-2 px-2.5 cursor-pointer relative"
        :class="filter.active ? 'border-black' : 'border-true-gray-bright'"
        :data-test="filter.key"
        @click.prevent="toggleFilters(filter.key)"
        @keydown.enter="toggleFilters(filter.key)"
        tabindex="0"
      >
        <input
          type="checkbox"
          :id="`tag-filter-${filter.key}`"
          class="sr-only"
          :aria-checked="filter.active"
        />
        {{ filter.displayName }}
      </label>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'CustomBuilderTagFilterControlPanel',
  props: {
    activeFilters: { required: true, type: Array },
  },
  emits: ['toggle-filter'],
  setup(props, { emit }) {
    const allFilters = [
      { displayName: 'Gluten-free', key: 'gluten-free' },
      { displayName: 'Kosher', key: 'kosher' },
      { displayName: 'Less Salt', key: 'less salt' },
      { displayName: 'Raw', key: 'raw' },
      { displayName: 'Sugar-free', key: 'sugar-free' },
    ];

    const filters = computed(() =>
      allFilters.map((filter) => ({
        ...filter,
        active: props.activeFilters.includes(filter.key),
      })),
    );

    return {
      filters,
      toggleFilters: (key) => emit('toggle-filter', key),
    };
  },
};
</script>
