import { computed, PropType } from 'vue';

export type Size = 'default' | 'large' | 'small';
export type Theme = 'gray' | 'green' | 'red' | 'transparent' | 'white' | 'yellow' | 'dark-yellow';

export const buttonStyleProps = {
  disabled: { required: false, type: Boolean, default: false },
  fullWidth: { required: false, type: Boolean, default: false },
  isLoading: { required: false, type: Boolean, default: false },
  size: { required: false, type: String as PropType<Size>, default: 'default' },
  theme: { required: false, type: String as PropType<Theme>, default: 'green' },
};

interface ButtonStyleProps {
  fullWidth?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  size?: Size;
  theme?: Theme;
}

export default function useButtonStyle(props: ButtonStyleProps) {
  const borderUtilities = computed(() => {
    const utilities: string[] = ['border'];
    if (props.theme === 'white') {
      utilities.push('border-solid', 'hover:border-transparent', 'border-true-gray-300');
    } else if (props.theme === 'transparent') {
      utilities.push('border-solid', 'border-black');
    } else {
      utilities.push('border-none');
    }
    utilities.push('rounded-full');
    return utilities.join(' ');
  });

  const colorUtilities = computed(() => {
    const utilities: string[] = [];
    if (props.theme === 'green') {
      utilities.push('bg-green', 'text-white');
      if (!props.disabled) utilities.push('hover:bg-green-darker');
    }
    if (props.theme === 'gray') {
      utilities.push('bg-true-gray', 'text-white');
      if (!props.disabled) utilities.push('hover:bg-true-gray-lighter');
    }
    if (props.theme === 'red') {
      utilities.push('bg-red', 'text-white');
      if (!props.disabled) utilities.push('hover:bg-red-darker');
    }
    if (props.theme === 'transparent') {
      utilities.push('bg-transparent', 'text-black');
    }
    if (props.theme === 'white') {
      utilities.push('bg-white', 'text-true-gray');
      if (!props.disabled) utilities.push('hover:bg-true-gray-100');
    }
    if (props.theme === 'yellow') {
      utilities.push('bg-yellow-200', 'text-black');
    }
    if (props.theme === 'dark-yellow') {
      utilities.push('bg-nuts-amber-400', 'text-black');
    }

    if (props.disabled || props.isLoading) utilities.push('opacity-50');

    utilities.push('duration-400', 'hover:shadow-sharp', 'transition-colors');

    return utilities.join(' ');
  });

  const fontColorUtilities = computed(() => {
    const utilities: string[] = [];
    if (props.theme === 'green') {
      utilities.push('text-white');
    }
    if (props.theme === 'gray') {
      utilities.push('text-white');
    }
    if (props.theme === 'red') {
      utilities.push('text-white');
    }
    if (props.theme === 'white') {
      utilities.push('text-true-gray');
    }
    if (props.theme === 'yellow') {
      utilities.push('text-black');
    }
    return utilities.join(' ');
  });

  const widthUtilities = computed(() => {
    if (props.fullWidth) return 'w-full';
    if (!props.size) return '';

    const buttonVariants: Record<Size, string> = {
      default: 'px-7 md:px-8',
      large: 'px-8 md:px-9',
      small: 'px-5 md:px-6',
    };
    const buttonLoadingVariants: Record<Size, string> = {
      default: 'px-5',
      large: 'px-6',
      small: 'px-3',
    };

    if (props.isLoading) return buttonLoadingVariants[props.size];
    return buttonVariants[props.size];
  });

  const heightUtilities = computed(() => {
    if (!props.size) return '';

    const buttonHeightVariants: { readonly [key: string]: string } = {
      default: 'py-3',
      large: 'py-3',
      small: 'py-2 md:py-3',
    };

    return buttonHeightVariants[props.size];
  });

  const fontSizeUtilities = computed(() => {
    const defaults = 'md:leading-none leading-none font-semibold';
    if (!props.size) return defaults;

    const buttonFontSizeVariants: Record<Size, string> = {
      default: 'text-sm md:text-base',
      large: 'text-base md:text-lg',
      small: 'text-xs',
    };

    return `${buttonFontSizeVariants[props.size]} ${defaults}`;
  });

  return {
    borderUtilities,
    colorUtilities,
    cursorUtilities: ['md:cursor-pointer', 'disabled:cursor-not-allowed'],
    fontColorUtilities,
    fontSizeUtilities,
    heightUtilities,
    widthUtilities,
  };
}
