import { webstore } from '@/api';

async function isFavorited(key) {
  return webstore.get(`/api/items/${key}/is-favorited`);
}

async function addToFavorites(key) {
  const formData = new FormData();
  formData.set('ACTION', 'ADD_BASKET');
  formData.set('button_wishlist', '');
  formData.set('item', key);

  return webstore.post('/contents.html', formData);
}

async function removeFromFavorites(key) {
  const formData = new FormData();
  formData.set('ACTION', 'ADD_BASKET');
  formData.set(`button_delete_wishlist-${key}`, '');

  return webstore.post('/contents.html', formData);
}

export { isFavorited, addToFavorites, removeFromFavorites };
