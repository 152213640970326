<template>
  <h4 itemprop="name" data-test="product-title" :class="classNames">{{ name }}</h4>
</template>

<script>
export default {
  name: 'ProductTitle',
  props: {
    name: { required: true, type: String },
    classNames: { required: false, type: String, default: '' },
  },
};
</script>
