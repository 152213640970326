/* eslint-disable no-shadow */
import createDebug from 'debug';

import { webstore } from '@/api';
import { Client } from '@/api/dy/client/client';
import {
  ClientSidePageContext,
  DeviceContext,
  PageAttributes,
  ServerSidePageContext,
} from '@/api/dy/client/context';
import { ChooseRequest, EngagementRequest } from '@/api/dy/client/request';
import { gtag } from '@/utils/analytics';

const debug = createDebug('nuts:dynamicYield');

const namespaced = true;

export const state = () => ({
  client: {},
  context: {},
  userId: '',
  sessionId: '',
  variations: {},
  pageAttributes: {
    newSession: 'true',
    isB2bContact: 'false',
    isContact: 'false',
    isCustomer: 'false',
  },
  // currently only Easy Reorder; key by path until a broader pattern emerges
  webstoreRecommendations: {},
});

export const SpaMode = {
  Automatic: 'spa',
  Manual: {
    Start: 'spa_start',
    End: 'spa_end',
  },
};

const removePadding = (sku) => (sku && sku.startsWith('0') ? sku.substring(1) : sku);
/** ********** */
// GETTERS //
/** ********** */
export const getters = {
  isClientReady: (state) => state.client.constructor === Client,
  getDyVariations: (state) => {
    const variations = {};
    // eslint-disable-next-line no-restricted-syntax,no-unused-vars
    Object.entries(state.variations).forEach(([name, campaign]) => {
      if (campaign.variations.length > 0) {
        switch (campaign.type) {
          case 'DECISION':
            // eslint-disable-next-line
            variations[name] = {
              id: campaign.variations[0].id,
              campaignId: campaign.id,
              decisionId: campaign.decisionId,
              ...campaign.variations[0].payload.data,
            };
            break;
          case 'RECS_DECISION':
            // eslint-disable-next-line
            variations[name] = campaign.variations[0].payload.data.slots.map((slot) => ({
              ...slot.productData,
              sku: removePadding(slot.sku),
              slotId: slot.slotId,
              decisionId: campaign.decisionId,
            }));
            break;
          default:
            throw new Error(`Unknown choice type: ${campaign.type}`);
        }
      }
    });
    return variations;
  },

  ip: (state, getters) => getters.request.ip || '',
  location: (state, getters) => {
    const { path } = state.context;
    if (path && typeof window !== 'undefined' && window.location) {
      return window.location.origin + path + window.location.search;
    }
    return getters.request.url ?? '';
  },
  previewIds: (state, getters) => new URL(getters.location).searchParams.get('dyApiPreview') || '',
  referrer: (state, getters) => getters.request.referer,
  request: (state, getters, rootState) => rootState.requestObj,
  userAgent: (state, getters) => getters.request.userAgent,
};

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations = {
  SET_CONTEXT(state, context) {
    state.context = context;
  },
  SET_CLIENT(state, client) {
    state.client = client;
  },
  SET_USER_ID(state, userId) {
    state.userId = userId;
  },
  SET_SESSION_ID(state, sessionId) {
    state.sessionId = sessionId;
  },
  SET_VARIATIONS(state, variations) {
    variations.forEach((campaign) => {
      state.variations[campaign.name] = campaign;
    });
  },
  SET_WEBSTORE_RECOMMENDATIONS(state, recommendationsByPath) {
    state.webstoreRecommendations = {
      ...state.webstoreRecommendations,
      ...recommendationsByPath,
    };
  },
};

/** ******** */
// ACTIONS //
/** ******** */
export const actions = {
  initDyClient({ commit }, clientSide = false) {
    let apiKey = import.meta.env.VITE_DY_SERVER_SIDE_KEY;
    let baseURL = import.meta.env.VITE_DY_SERVER_SIDE_BASE_URI;
    let engagementURL = import.meta.env.VITE_DY_SERVER_SIDE_BASE_URI;
    let connectionTimeout = import.meta.env.VITE_DY_SERVER_SIDE_CONNECT_TIMEOUT;
    let requestTimeout = import.meta.env.VITE_DY_SERVER_SIDE_TIMEOUT;
    if (clientSide) {
      apiKey = import.meta.env.VITE_DY_CLIENT_SIDE_KEY;
      baseURL = import.meta.env.VITE_DY_CLIENT_SIDE_BASE_URI;
      engagementURL = import.meta.env.VITE_DY_CLIENT_SIDE_ENGAGEMENT_URI;
      connectionTimeout = import.meta.env.VITE_DY_CLIENT_SIDE_CONNECT_TIMEOUT;
      requestTimeout = import.meta.env.VITE_DY_CLIENT_SIDE_TIMEOUT;
      debug('DY Client switched to client-side');
    }

    const client = new Client({
      apiKey,
      baseURL,
      engagementURL,
      connectionTimeout,
      requestTimeout,
    });
    commit('SET_CLIENT', client);
  },
  async fetchDyVariations(
    { commit, getters, state, rootState },
    { campaigns, page = {}, setDyPageContext = true },
  ) {
    if (state.client.constructor !== Client) {
      throw new Error('DY client is not instantiated');
    }
    const { sessionId } = state;
    const { ip, location, previewIds, referrer, userAgent, request: req } = getters;
    const { locale = 'en_US' } = page;
    const {
      context: { type, data = [] },
      userId,
      pageAttributes: { newSession, isB2bContact, isContact, isCustomer },
    } = state;
    const {
      customerModule: { businessIndustry, hasBusinessAccount },
    } = rootState;

    let response;
    try {
      const pageContext = new ServerSidePageContext(type, data, location, referrer, locale);
      const deviceContext = new DeviceContext(ip, userAgent);
      const pageAttributes = new PageAttributes({
        businessIndustry: String(businessIndustry),
        hasBusinessAccount: String(hasBusinessAccount),
        hasBusinessIndustry: String(!!businessIndustry),
        isContact,
        isB2bContact,
        isCustomer,
        landingPage: req.url,
        location,
        newSession,
      });
      const request = new ChooseRequest(
        userId,
        sessionId,
        campaigns,
        pageContext,
        deviceContext,
        pageAttributes,
        setDyPageContext,
        previewIds,
      );

      response = await state.client.execute(request);
      const choices = response.data?.choices
        .filter((choice) => choice.variations.length)
        .map((choice) => choice);

      choices.forEach((choice) => {
        gtag('event', 'content_decision', {
          cms: 'Dynamic Yield',
          content_id: choice.id,
          content_name: choice.name,
          content_type: choice.type,
          experience_id: choice.variations[0].analyticsMetadata.experienceId,
          experience_name: choice.variations[0].analyticsMetadata.experienceName,
          test_variation_id: choice.variations[0].analyticsMetadata.variationId,
          test_variation_name: choice.variations[0].analyticsMetadata.variationName,
          synthetic: false,
        });
      });
      commit('SET_VARIATIONS', response.data.choices);
    } catch (err) {
      debug('DY choose failed: ', err);
    }
    return response;
  },
  setDyPageContext(
    { commit, state },
    { mode = SpaMode.Automatic, countAsPageview = true, path = '', data = [], type = '' },
  ) {
    if (typeof window === 'undefined') {
      debug(`DY setDyPageContext with mode '${mode}' failed: invalid window object`);
      return;
    }
    if (!window.DY) {
      debug(`DY setDyPageContext with mode '${mode}' failed: invalid window.DY object`);
      return;
    }
    try {
      if (mode === SpaMode.Manual.End) {
        window.DY.API(mode);
      } else {
        window.DY.API(mode, {
          context: {
            type,
            data,
          },
          url: path,
          countAsPageview,
        });
      }
    } catch (err) {
      debug(`DY setDyPageContext with mode '${mode}' failed: `, err);
    }
    commit('SET_CONTEXT', { mode, countAsPageview, path, data, type });
  },

  async reportDyEngagement({ state }, { decisionId, slotId }) {
    const { sessionId, userId } = state;

    try {
      const request = new EngagementRequest({
        userId,
        sessionId,
        decisionId,
        slotId,
      });

      await state.client.execute(request);
    } catch (err) {
      debug('DY engagement request failed: ', err);
    }
  },

  async reportEngagement(_, { decisionId, variationId }) {
    try {
      let { baseURL } = webstore.defaults;
      if (!baseURL) {
        baseURL = window.location.origin;
      }
      const url = new URL(`${baseURL}/api/dy/engagement`);
      const searchParams = new URLSearchParams();
      searchParams.append('decision_id', decisionId);
      searchParams.append('variation_id', variationId);

      await webstore.post(url.pathname, searchParams, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });
    } catch (err) {
      debug('DY reportEngagement failed: ', err);
    }
  },
};

export default {
  actions,
  getters,
  namespaced,
  mutations,
  state,
};
