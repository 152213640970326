<template>
  <div class="relative">
    <input
      v-model="value"
      v-bind="attributes"
      :aria-describedby="`inputHelperText${inputName ? '-' + inputName : ''}`"
      :aria-label="attributes.placeholder"
      :aria-invalid="inputFailed"
      :class="getInputStyles"
      :data-test="`${dataTest}-${attributes.name}`"
      @blur="handleInputBlur"
    />
    <label
      v-if="showLabel && !showPlaceholder"
      :for="attributes.name"
      class="pointer-events-none floating-label"
      :data-test="`${dataTest}-${attributes.name}-label`"
      :class="getLabelStyles"
    >
      {{ attributes.placeholder }}
    </label>
    <div class="absolute py-3 right-2 md:right-3.5 top-0">
      <transition name="fade">
        <span v-if="$slots.fallbackIcon" key="fallbackIcon">
          <slot name="fallbackIcon" />
        </span>
        <WarningIcon
          v-else-if="inputFailed"
          class="text-nuts-red-800"
          aria-hidden="true"
          :data-test="`${dataTest}-${attributes.name}-field-error-icon`"
          key="fieldError"
        />
      </transition>
    </div>
    <transition name="fade">
      <div
        v-if="inputFailed || helperText"
        class="absolute pt-1 pl-px text-xs leading-3"
        :class="inputFailed ? 'text-error' : 'text-true-gray-500'"
        :id="`inputHelperText${inputName ? '-' + inputName : ''}`"
        :role="inputFailed ? 'alert' : undefined"
        :data-test="`${dataTest}-${attributes.name}-validation-error`"
      >
        {{ errorMessage || helperText }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { useField } from 'vee-validate';
import { computed, defineComponent, InputHTMLAttributes, PropType, ref, toRef } from 'vue';

import WarningIcon from '@/components/base/assets/WarningIcon.vue';
import { useForm } from '@/composables/useForm';

export const borderRules = (invalid: boolean) => ({
  width: 'border',
  style: 'border-solid',
  color: invalid ? 'border-error' : 'border-true-gray-300',
  focusColor: 'focus:border-true-gray',
  focusOutline: 'focus:outline-none',
});

export const fontRules = ({
  invalid,
  showPlaceholder,
}: {
  invalid: boolean;
  showPlaceholder: boolean;
}) => {
  let placeholderColor;
  if (showPlaceholder) {
    if (invalid) placeholderColor = 'placeholder-red-700';
  } else {
    placeholderColor = 'placeholder-transparent';
  }

  return {
    letterSpacing: 'leading-4 md:leading-4',
    placeholderColor,
    fontSize: 'text-base',
    color: invalid ? 'text-error' : 'text-true-gray',
  };
};

export const sizeRules = () => ({
  height: 'h-11 md:h-12',
  padding: 'px-4',
  width: 'w-full',
});

type RequiredAttributes = Required<Pick<InputHTMLAttributes, 'name' | 'placeholder'>>;
export type InputAttributes = InputHTMLAttributes &
  RequiredAttributes & { type?: 'email' | 'number' | 'password' | 'phone' | 'text' };

export default defineComponent({
  name: 'FormInput',
  props: {
    autocomplete: { required: false, type: String },
    alternateBackground: { required: false, type: Boolean, default: false },
    dataTest: { required: false, type: String },
    error: { required: false, type: Boolean },
    helperText: { required: false, type: String },
    inputAttributes: { required: true, type: Object as PropType<InputAttributes> },
    modelValue: { required: true, type: String },
    showLabel: { required: false, type: Boolean, default: false },
    showPlaceholder: { required: false, type: Boolean, default: false },
    validator: { required: false, type: Function },
  },
  components: { WarningIcon },
  setup(props) {
    const { validatorFailed } = useForm();

    const inputName = ref(props.inputAttributes.name);
    const { errorMessage, handleBlur, meta, setErrors, value } = useField(
      inputName,
      props.validator,
      {
        initialValue: props.modelValue,
      },
    );

    const inputFailed = computed(() => validatorFailed(meta) || props.error);
    const attributes = computed<InputAttributes>(() => ({
      ...props.inputAttributes,
      type: props.inputAttributes.type ?? 'text',
    }));

    const getInputStyles = computed(() => {
      const styles = [];

      styles.push([
        Object.values(borderRules(inputFailed.value)).join(' '),
        Object.values(
          fontRules({ invalid: inputFailed.value, showPlaceholder: props.showPlaceholder }),
        ).join(' '),
        Object.values(sizeRules()).join(' '),
      ]);

      if (attributes.value.type === 'password') styles.push('tracking-widest');

      return styles;
    });

    const getLabelStyles = computed(() => {
      const styles = [
        'font-light',
        'leading-4 md:leading-4',
        'text-sm md:text-base',
        'absolute m-4 top-0 left-0',
        'transition duration-150',
      ];

      styles.push(inputFailed.value ? 'text-error' : 'text-true-gray-500');
      if (props.alternateBackground) styles.push('gradient-background');

      return styles;
    });

    const handleInputBlur = () => {
      handleBlur();
      if (props.validator && !meta.valid) setErrors(props.validator());
    };

    return {
      attributes,
      errorMessage,
      fieldErrorSvg: nutshell['img/field_error.svg'],
      getInputStyles,
      getLabelStyles,
      handleInputBlur,
      inputFailed,
      inputName,
      validatorFailed,
      value,
    };
  },
});
</script>

<style lang="scss">
.floating-label.active {
  transform: translateY(-1.25rem);
  font-size: 0.75rem;
  line-height: 0.625rem;
  background: #fff;
  padding: 0 0.125rem;
  touch-action: manipulation;
  &.gradient-background {
    background: linear-gradient(#f5f5f5, #fff);
  }
}

.placeholder-transparent:-webkit-autofill,
select.placeholder-transparent:valid,
input.placeholder-transparent:not(:placeholder-shown) {
  touch-action: manipulation;
  & + label {
    transform: translateY(-1.25rem);
    font-size: 0.75rem;
    line-height: 0.625rem;
    background: #fff;
    padding: 0 0.125rem;
  }
  &.gradient-background {
    background: linear-gradient(#f5f5f5, #fff);
  }
}
</style>
