<template>
  <input
    :class="[borderUtilities, fontUtilities, sizeUtilities]"
    :value="typeof $attrs.value !== 'undefined' ? $attrs.value : modelValue"
    @blur="$emit('blur', $event)"
    @input="$emit('input', $event.target.value), $emit('update:modelValue', $event.target.value)"
    @change="$emit('change', $event.target.value)"
  />
</template>

<script lang="ts">
import { computed, toRef, unref } from 'vue';

import { useForm } from '@/composables/useForm';

export default {
  name: 'Input',
  props: {
    invalid: { required: false, type: Boolean, default: false },
    showPlaceholder: { required: false, type: Boolean, default: false },
    modelValue: { required: false, type: [Number, String], default: '' },
  },
  emits: ['blur', 'change', 'input', 'update:modelValue'],
  setup(props, context) {
    const { useStyles } = useForm();

    const { borderRules, fontRules, sizeRules } = useStyles(toRef(props, 'invalid'));
    const borderUtilities = computed(() => unref(borderRules).join(' '));
    const fontUtilities = computed(() => {
      let utilities = [...unref(fontRules)];
      if (context.attrs.type === 'password') utilities.push('tracking-widest');
      if (props.showPlaceholder) {
        utilities = utilities.filter((util) => !util.startsWith('placeholder'));
        if (props.invalid) utilities.push('placeholder-red-700');
      }
      return utilities.join(' ');
    });
    const sizeUtilities = computed(() => [...unref(sizeRules)].join(' '));

    return {
      borderUtilities,
      fontUtilities,
      sizeUtilities,
    };
  },
};
</script>
