/* eslint-disable camelcase */
import { LocalizedString, Money, Price } from '@commercetools/platform-sdk';
import { cents, from } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { AlgoliaVariant } from '@/api/algolia';
import { Recommendation } from '@/composables/useDynamicYield';
import money from '@/filters/money';
import { adaptLegacyBoolean } from '@/utils/cms';
import { computeSavings, LineSavingsSummary as SavingsSummary } from '@/utils/money';

interface BaseProductCardData {
  allVariantsOutOfStock: boolean;
  autoDeliveryEligible: boolean;
  averageRating?: number;
  bulk?: boolean;
  cost: string;
  decisionId?: string;
  displayComparisonPrice?: string;
  displayDiscountPercent?: string;
  displayPrice: string;
  hasSiblings?: boolean;
  imageUrl: string;
  inStock: boolean;
  keywords: string[];
  merchandisingCategory?: string;
  name: string;
  path: string;
  price: number;
  productKey: string;
  requiresCustomization: boolean;
  sku: string;
  searchQueryId?: string;
  slotId?: string;
  totalRatings: number;
  totalReviews: number;
  unitName: string;
  weight?: string;
  wholesale?: boolean;
}

export interface MoneyRange {
  from: Money;
  to: Money;
}

/**
 * VariantPrice parallels the CT {@link Price} interface except with
 * certain fields omitted and `obj` always populated. Don't add anything here
 * which isn't present in CT's {@link Price}.
 *
 * This is produced by webstore's
 * `Nuts\Commercetools\Helpers\ProductVariantSimplifier`.
 */
export interface VariantPrice {
  value: Money;
  channel?: {
    obj: {
      key: string;
      name?: LocalizedString;
    };
  };
  discounted?: {
    value: Money;
    discount: {
      obj?: {
        name: LocalizedString;
        description?: LocalizedString;
      };
    };
  };
  tiers?: {
    minimumQuantity: number;
    value: Money;
  }[];
}

export interface ProductCardData extends BaseProductCardData {
  hidePrice: boolean;
  listPrice: VariantPrice;
  onePoundBulk: boolean;
  piecePrice: Money;
  piecePriceRange?: MoneyRange;
  prices: VariantPrice[];
  totalSavings?: Omit<SavingsSummary, 'breakdown'>;
}

export const displayPrice = (productCard: ProductCardData) => {
  if (productCard.piecePriceRange) {
    return `${money(productCard.piecePriceRange.from)} - ${money(productCard.piecePriceRange.to)}`;
  }
  const price = money(productCard.piecePrice);
  if (productCard.onePoundBulk) return `${price}/lb`;
  return price;
};

const isBuildYourOwnBox = (variant: BaseProductCardData) => variant.productKey === '3719'; // ported from `ProductAdapter.php`
const isGiftCertificate = (variant: BaseProductCardData) => variant.productKey === '9990'; // ported from `Item.php`
const computePiecePrice = (price: VariantPrice, priceRange?: MoneyRange) => {
  if (priceRange) return priceRange.from;
  return price.discounted?.value ?? price.value;
};
const computePiecePriceRange = (variant: BaseProductCardData) => {
  // ported from `ProductAdapter.php`
  if (isGiftCertificate(variant)) return { from: from(25), to: from(200) };
  return undefined;
};
const isOnePoundBulk = (variant: BaseProductCardData) =>
  !!variant.bulk && Number(variant.weight) === 1;
const computeTotalSavings = (
  listPrice: VariantPrice,
  otherVariant?: ProductCardData,
): Omit<SavingsSummary, 'breakdown'> | undefined => {
  if (otherVariant) {
    // TODO: phase out `displayComparisonPrice` and `displayDiscountPercent`; may need a breakdown
    return undefined;
  }
  if (!listPrice.discounted) return undefined;
  return {
    ...computeSavings(listPrice.value, listPrice.discounted.value),
    comparisonPrice: listPrice.value,
    description: listPrice.discounted.discount.obj?.description,
    onSale: true,
  };
};

export const ProductCard = {
  fromAlgolia(algoliaProduct: AlgoliaVariant): ProductCardData {
    const {
      autoDeliveryEligible,
      bulk,
      comparisonPrice,
      cost,
      discountPercent,
      formattedUnitPrice,
      hasSiblings,
      outOfStock,
      path,
      Product_allVariantsOutOfStock: allVariantsOutOfStock,
      Product: {
        key,
        name,
        listingImageUrl,
        merchandisingCategory,
        requiresCustomization,
        reviews: { averageRating, totalRatings, totalReviews },
        tags,
      },
      sku,
      singlePiecePrice,
      shortVariantName,
      weight,
      wholesale,
    } = algoliaProduct;

    const productData: BaseProductCardData = {
      allVariantsOutOfStock,
      autoDeliveryEligible,
      averageRating,
      bulk,
      cost,
      displayComparisonPrice:
        comparisonPrice && comparisonPrice > 0 ? money(comparisonPrice) : undefined,
      displayDiscountPercent:
        discountPercent && discountPercent > 0 ? `${discountPercent}%` : undefined,
      displayPrice: formattedUnitPrice,
      hasSiblings,
      imageUrl: listingImageUrl,
      inStock: !outOfStock,
      keywords: tags,
      merchandisingCategory,
      name,
      path,
      price: singlePiecePrice,
      productKey: key,
      requiresCustomization,
      // eslint-disable-next-line no-underscore-dangle
      searchQueryId: algoliaProduct.__queryID,
      sku,
      totalRatings,
      totalReviews,
      unitName: shortVariantName,
      weight,
      wholesale,
    };

    const listPrice = algoliaProduct.prices.find((p) => !p.channel)!;
    const piecePriceRange = computePiecePriceRange(productData);
    const piecePrice = computePiecePrice(listPrice, piecePriceRange);

    return {
      ...productData,
      hidePrice: cents(piecePrice) === 0 || isBuildYourOwnBox(productData),
      listPrice,
      onePoundBulk: isOnePoundBulk(productData),
      piecePrice,
      piecePriceRange,
      prices: algoliaProduct.prices,
      totalSavings: computeTotalSavings(listPrice),
    };
  },

  fromDY(dyProduct: Recommendation): ProductCardData {
    const {
      all_variants_out_of_stock,
      auto_delivery_eligible,
      average_rating,
      bulk,
      cost,
      decisionId,
      display_price,
      display_comparison_price,
      display_discount_percent,
      has_siblings,
      sku,
      price,
      image_url,
      in_stock,
      keywords,
      name,
      path_name,
      product_key,
      primary_merchandising_category,
      requires_customization,
      short_unit_name,
      slotId,
      total_ratings,
      total_reviews,
      wholesale,
    } = dyProduct;

    const productData: BaseProductCardData = {
      allVariantsOutOfStock: !!adaptLegacyBoolean(all_variants_out_of_stock),
      autoDeliveryEligible: !!adaptLegacyBoolean(auto_delivery_eligible),
      averageRating: parseFloat(average_rating),
      bulk: adaptLegacyBoolean(bulk),
      cost,
      decisionId,
      displayComparisonPrice: display_comparison_price,
      displayDiscountPercent: display_discount_percent,
      displayPrice: display_price,
      hasSiblings: adaptLegacyBoolean(has_siblings),
      imageUrl: image_url,
      inStock: !!adaptLegacyBoolean(in_stock),
      keywords,
      merchandisingCategory: primary_merchandising_category,
      name,
      path: path_name,
      price,
      productKey: product_key,
      requiresCustomization: !!adaptLegacyBoolean(requires_customization),
      sku,
      slotId,
      totalRatings: parseFloat(total_ratings),
      totalReviews: parseFloat(total_reviews),
      unitName: short_unit_name,
      weight: dyProduct.short_unit_name === '1lb bag' ? '1' : '0', // TODO: get from data feed
      wholesale: adaptLegacyBoolean(wholesale),
    };

    let prices: VariantPrice[] | undefined;
    try {
      prices = JSON.parse(dyProduct.prices_json);
    } catch (e) {
      // This error will be handled in below if block
    }
    if (!prices?.length) {
      console.error('Failed to parse prices_json; deriving a baseline', dyProduct.prices_json);
      prices = [{ value: from(dyProduct.price) }];
    }
    const listPrice = prices.find((p) => !p.channel)!;
    const piecePriceRange = computePiecePriceRange(productData);
    const piecePrice = computePiecePrice(listPrice, piecePriceRange);

    return {
      ...productData,
      hidePrice: cents(piecePrice) === 0 || isBuildYourOwnBox(productData),
      listPrice,
      onePoundBulk: isOnePoundBulk(productData),
      piecePrice,
      piecePriceRange,
      prices,
      totalSavings: computeTotalSavings(listPrice),
    };
  },
};
