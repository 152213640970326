<template>
  <div class="flex flex-col w-full h-auto">
    <div class="text-xl md:text-3xl font-semibold mb-4 font-sofia-pro">
      Search Your Favorite Snacks
    </div>
    <div class="relative">
      <form
        @submit.prevent="search"
        class="border-black flex-col md:flex-row flex items-center border-none md:border-solid md:border rounded-lg md:p-3 md:h-20 md:text-lg z-10 relative bg-white"
      >
        <input
          type="search"
          class="flex-1 w-full bg-transparent text-lg outline-none p-3 md:mr-3 border-solid border rounded-lg border-black md:border-none text-true-gray"
          placeholder="Search by product or category"
          name="query"
          title="Search"
          aria-label="Search"
          v-model="searchQuery"
        />
        <input
          type="submit"
          class="w-full md:w-auto bg-yellow box-border border-none rounded-lg mt-2 md:mt-0 md:h-14 md:text-lg p-3 md:px-24 font-semibold cursor-pointer"
          value="Search"
        />
      </form>
    </div>
    <div class="flex flex-col">
      <div class="text-xl md:text-xl font-semibold mt-7 md:mt-8 mb-0 md:mb-3">Popular Searches</div>
      <div class="flex flex-wrap">
        <RouterLink
          :to="item.link"
          v-for="item in popularSearches"
          :key="item.label"
          class="flex items-center bg-cream px-4 md:px-10 py-6 md:py-4 h-7 md:h-12 hover:no-underline color-inherit text-sm md:text-base leading-none md:leading-none rounded-full font-semibold mr-2 mt-6"
        >
          {{ item.label }}
        </RouterLink>
      </div>
      <div class="text-xl md:text-xl font-semibold mt-7 md:mt-8 mb-0 md:mb-3">Favorite Flavors</div>
      <div class="flex flex-wrap">
        <RouterLink
          :to="item.link"
          v-for="item in favoriteFlavors"
          :key="item.label"
          class="flex items-center bg-cream px-4 md:px-10 py-6 md:py-4 h-7 md:h-12 hover:no-underline color-inherit text-sm md:text-base leading-none md:leading-none rounded-full font-semibold mr-2 mt-6"
        >
          {{ item.label }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';

const SearchFooter = defineComponent({
  name: 'SearchFooter',
  props: {
    favoriteFlavors: { required: true, type: Array as PropType<{ label: string; link: string }[]> },
    popularSearches: { required: true, type: Array as PropType<{ label: string; link: string }[]> },
  },
  setup() {
    const router = useRouter();
    const searchQuery = ref('');
    const search = () => {
      const query = searchQuery.value || '';
      let href = '/search/instant';
      if (query) {
        href += `?query=${query}`;
      }
      router.push(href);
    };
    return {
      search,
      searchQuery,
      searchPeanut: nutshell['img/sprites@2x-248167fc.png'],
    };
  },
});

export const SearchFooterRegistration = {
  component: SearchFooter,
  name: 'Search Footer',
  inputs: [
    {
      name: 'favoriteFlavors',
      type: 'list',
      defaultValue: [
        { label: 'Cinnamon', link: '/search/instant?query=cinnamon' },
        { label: 'Peanut', link: '/search/instant?query=peanut' },
      ],
      subFields: [
        {
          name: 'label',
          type: 'string',
        },
        {
          name: 'link',
          type: 'string',
        },
      ],
    },
    {
      name: 'popularSearches',
      type: 'list',
      defaultValue: [
        { label: 'Chocolate', link: '/search/instant?query=chocolate' },
        { label: 'Peanut Butter', link: '/search/instant?query=peanut butter' },
      ],
      subFields: [
        {
          name: 'label',
          type: 'string',
        },
        {
          name: 'link',
          type: 'string',
        },
      ],
    },
  ],
};

export default SearchFooter;
</script>

<style lang="scss" scoped>
input[type='search']::-ms-clear {
  cursor: pointer;
}
input[type='search']::-ms-reveal {
  cursor: pointer;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  cursor: pointer;
}
</style>
