<template>
  <RouterLink :to="link" class="flex w-full h-full grow md:h-auto">
    <div
      class="flex justify-center w-full p-4 bg-no-repeat bg-cover grow background lg:bg-contain lg:justify-start"
      :class="bgColor"
    >
      <div
        class="flex flex-col items-center justify-end h-full pl-1 text-center bg-transparent lg:justify-center lg:text-left lg:items-start lg:pl-10 lg:w-1/2"
      >
        <div class="mb-4 font-semibold banner-headline font-sofia-pro" :class="textColor">
          {{ headline }}
        </div>
        <slot />
      </div>
    </div>
  </RouterLink>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { CmsRegisteredComponent, TWColorsBackground, TWColorsText } from '@/utils/cms';

const ShelfBannerSection = defineComponent({
  name: 'ShelfBannerSection',
  props: {
    backgroundColor: { required: false, type: String, default: 'background (secondary)' },
    bgDesktopImg: { required: false, type: String },
    bgMobileImg: { required: false, type: String },
    headline: { required: true, type: String, default: 'Headline' },
    headlineColor: { required: true, type: String, default: 'black' },
    img: { required: false, type: String, default: '' },
    link: { required: true, type: String, default: '/' },
  },
  setup(props) {
    return {
      textColor: computed(() => TWColorsText[props.headlineColor]),
      bgColor: computed(() => TWColorsBackground[props.backgroundColor]),
      desktopImage: computed(() => `url(${props.bgDesktopImg})`),
      mobileImage: computed(() => `url(${props.bgMobileImg})`),
    };
  },
});
export const ShelfBannerRegistration: CmsRegisteredComponent = {
  component: ShelfBannerSection,
  name: 'Shelf Banner Section',
  canHaveChildren: true,
  inputs: [
    {
      name: 'backgroundColor',
      type: 'string',
      enum: Object.keys(TWColorsBackground),
      defaultValue: 'background (secondary)',
    },
    {
      name: 'bgDesktopImg',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
    },
    {
      name: 'bgMobileImg',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
    },
    {
      name: 'headline',
      type: 'string',
    },
    {
      name: 'headlineColor',
      type: 'string',
      enum: Object.keys(TWColorsText),
      defaultValue: 'black',
    },
    {
      name: 'img',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
    },
    {
      name: 'link',
      type: 'string',
    },
  ],
};
export default ShelfBannerSection;
</script>

<style lang="scss" scoped>
.background {
  background-image: v-bind(mobileImage);
  background-position: center top;
}
.banner-headline {
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .banner-headline {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (min-width: 1024px) {
  .background {
    background-image: v-bind(desktopImage);
    background-position: center;
  }
  .link-container {
    max-width: 1040px;
  }
  .banner-headline {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
