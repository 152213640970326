import {
  Cart,
  Customer,
  CustomerSignInResult,
  DiscountCode,
  Money,
} from '@commercetools/platform-sdk';
import { AxiosRequestConfig } from 'axios';
import { FetchPaymentMethodsPayload } from 'braintree-web';
import { CallbackTypes } from 'vue3-google-login';

import { fromNutsJson, NutsJson, webstore } from '@/api';
import type { WebstoreRecommendation } from '@/composables/useDynamicYield';
import { FlagsByLayer } from '@/composables/useFeatureFlags';
import { NutsAddress } from '@/utils/address';

export interface CreditAccount {
  readonly active: boolean;
  readonly balance: Money;
  readonly id: string;
  readonly limit: Money;
  readonly nonce: string;
}

export interface CreditAccountResponse {
  readonly creditAccount: CreditAccount | null;
  readonly storeCreditAccount: CreditAccount | null;
}

export interface Issue {
  readonly adminUrl: string;
  readonly createdAt: string;
  readonly description: string;
  readonly id: string;
}

export interface CarrierOptions {
  readonly allowBlueStreak: boolean;
  readonly allowCdl: boolean;
  readonly allowGrandHusky: boolean;
  readonly allowLasership: boolean;
  readonly allowOntrac: boolean;
  readonly allowTforce: boolean;
  readonly allowUds: boolean;
}

export interface DescribeCustomerResponse {
  readonly addresses: NutsAddress[];
  readonly adminUrl: string;
  readonly allowSplitShipments: boolean;
  readonly carrierOptions: CarrierOptions;
  readonly creditAccounts: CreditAccountResponse;
  readonly customer: Customer;
  readonly discountCodes: DiscountCode[];
  readonly issues: Issue[];
  readonly hasBusinessAccount: boolean;
  readonly notes?: string;
  readonly signedUpAt: string;
  readonly subscribed: boolean;
}

export type DescribeGuestCustomerResponse = Pick<DescribeCustomerResponse, 'subscribed'>;

export interface NewCustomer {
  discardCustomerCart?: boolean;
  email: string;
  isB2b?: boolean;
  name: string;
  password: string;
  triggerSetPasswordFlow?: boolean;
}

export interface WebstoreCustomerSignInResult extends CustomerSignInResult {
  readonly businessAccountInfo?: {
    hasBusinessAccount: boolean;
    industry: string | null;
  };
  readonly newsletterStatus?: {
    declinedNewsletter: boolean;
    subscribed: boolean;
  };
  readonly flagsByLayer?: FlagsByLayer;
}

export interface SocialSignInOptions {
  attachGuestOrders?: boolean;
  preserveCart?: boolean;
}

async function get<T>(url: string, config?: AxiosRequestConfig) {
  return (await webstore.sessionSpecific.get<NutsJson<T>>(url, config)).data;
}

async function post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return (await webstore.sessionSpecific.post<NutsJson<T>>(url, data, config)).data;
}

export async function addAddress(address: NutsAddress) {
  return post<{ address: NutsAddress; addresses: NutsAddress[] }>('/api/addresses', { address });
}

export async function getAddresses() {
  return get<{ addresses: NutsAddress[] }>('/api/addresses');
}

export async function getCreditAccounts(customerId: string) {
  return get<CreditAccountResponse>('/api/credit-accounts', { params: { customerId } });
}

export async function updateAddress(address: NutsAddress) {
  return post<{ address: NutsAddress; addresses: NutsAddress[] }>(
    `/api/addresses/${address.id}/update`,
    { address },
  );
}

export async function getPaymentMethods(customerId?: string) {
  return get<{ paymentMethods: FetchPaymentMethodsPayload[] }>('/api/payment-methods', {
    params: { customerId },
  });
}

export async function createCustomer(customer: NewCustomer) {
  return post<WebstoreCustomerSignInResult>('/api/customers', customer);
}

export interface SignInOptions {
  gRecaptchaResponse?: string;
  keepLoggedIn?: boolean;
  preserveCart?: boolean;
}
export async function signIn(email: string, password: string, options?: SignInOptions) {
  return post<WebstoreCustomerSignInResult>('/api/sign/in', {
    email,
    'g-recaptcha-response': options?.gRecaptchaResponse,
    keepLoggedIn: options?.keepLoggedIn ?? false,
    password,
    preserveCart: options?.preserveCart ?? false,
  });
}

export type WebstoreSignUpCustomer = Omit<
  NewCustomer,
  'triggerSetPasswordFlow' | 'discardCustomerCart'
>;
export interface WebstoreSignUpOptions {
  attachGuestOrders?: boolean;
  keepSignedIn?: boolean; // Not implemented yet
  optInNewsletter?: boolean;
  preserveCart?: boolean;
}
export async function signUp(
  customer: WebstoreSignUpCustomer,
  gRecaptchaResponse: string,
  options?: WebstoreSignUpOptions,
) {
  return post<WebstoreCustomerSignInResult>('/api/sign/up', {
    ...customer,
    'g-recaptcha-response': gRecaptchaResponse,
    attachGuestOrders: options?.attachGuestOrders ?? true,
    optInNewsletter: options?.optInNewsletter ?? true,
    preserveCart: options?.preserveCart ?? false,
  });
}

export async function appleSignIn(
  data: {
    code: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  },
  options?: SocialSignInOptions,
) {
  return post<WebstoreCustomerSignInResult>('/api/sign/apple', {
    ...data,
    attachGuestOrders: options?.attachGuestOrders ?? true,
    preserveCart: options?.preserveCart ?? false,
  });
}

export async function googleSignIn(
  data: {
    credential?: CallbackTypes.CredentialPopupResponse['credential'];
    code?: string;
    selectBy?: CallbackTypes.CredentialPopupResponse['select_by'];
  },
  options?: SocialSignInOptions,
) {
  return post<WebstoreCustomerSignInResult>('/api/sign/google', {
    ...data,
    attachGuestOrders: options?.attachGuestOrders ?? true,
    preserveCart: options?.preserveCart ?? false,
  });
}

export async function facebookSignIn(
  data: { accessToken?: string; userID?: string },
  options?: SocialSignInOptions,
) {
  return post<WebstoreCustomerSignInResult>('/api/sign/facebook', {
    ...data,
    attachGuestOrders: options?.attachGuestOrders ?? true,
    preserveCart: options?.preserveCart ?? false,
  });
}

export async function forgotPassword(email: string) {
  return post('/api/sign/forgot-password', { email });
}

export async function resetPassword(key: string, password: string) {
  return post('/api/sign/reset-password', { key, password });
}

export async function sendSignInLinkEmail(email: string, redirectPath: string) {
  return post('/api/sign/send-sign-in-link-email', { email, redirectPath });
}

export async function signOut(copyCartToAnonymousCart = false) {
  return post<{ cart?: Cart }>('/api/sign/out', { copyCartToAnonymousCart });
}

export async function describeCustomer(email: string) {
  return post<DescribeCustomerResponse | DescribeGuestCustomerResponse>(
    `/api/customers/email=${encodeURIComponent(email)}/describe`,
  );
}

export async function describeMe() {
  return post<DescribeCustomerResponse>('/api/me/describe');
}

export async function getRecommendations(path: string, params: any) {
  const { recommendations } = await fromNutsJson(
    get<{ recommendations: WebstoreRecommendation[] }>(path, { params }),
  );
  return recommendations;
}

/**
 * Check if a customer exists by email
 * @param email customer's email
 * @param captchaResponse captcha response
 * @returns `user` or `null`. User has a `guest` boolean and may contain a `firstName` attribute
 */
export async function findExistingCustomer(email: string, captchaResponse: string) {
  const { user } = await fromNutsJson(
    get<{ user: { firstName?: string; guest: boolean } }>(`/api/users/find`, {
      params: {
        email,
        'g-recaptcha-response': captchaResponse,
      },
    }),
  );

  return user;
}

export async function signInByLinkKey(key: string, options?: Omit<SignInOptions, 'keepLoggedIn'>) {
  return post<WebstoreCustomerSignInResult>('/api/sign/in-by-link-key', {
    key,
    preserveCart: options?.preserveCart ?? false,
  });
}

export default {};
