<template>
  <div
    data-test="pagination"
    class="text-center flex items-center justify-center text-true-gray-600 my-4"
  >
    <span class="cursor-pointer mr-4" @click="handleClickPrevious">&laquo;</span>
    <span
      v-for="(page, pi) in pages"
      :key="page"
      class="cursor-pointer mx-4 flex items-center justify-center rounded-full h-8 w-8"
      :class="pi + 1 === selectedPage ? 'bg-true-gray-200' : ''"
      @click="handleClickPage(pi + 1)"
    >
      {{ page }}
    </span>
    <span class="cursor-pointer ml-4" @click="handleClickNext">&raquo;</span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'Pagination',
  props: {
    pages: { required: true, type: Number },
    modelValue: { required: false, type: Number, default: 1 },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedPage = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const handleClickPage = (page: number) => {
      selectedPage.value = page;
    };

    const handleClickNext = () => {
      if (selectedPage.value === props.pages) return;
      selectedPage.value += 1;
    };

    const handleClickPrevious = () => {
      if (selectedPage.value === 1) return;
      selectedPage.value -= 1;
    };

    return {
      handleClickNext,
      handleClickPage,
      handleClickPrevious,
      selectedPage,
    };
  },
});
</script>
