<template>
  <div
    class="flex items-center justify-between h-20 p-4 border rounded-2xl md:h-28"
    :class="borderStyles"
    @click="handleSelect"
    @keydown.enter="handleSelect"
    tabindex="0"
    :data-test="dataTest"
  >
    <div class="flex items-center">
      <img :src="imageSource" class="w-1/3 h-full mr-4" alt />
      <div class="text-left">
        <div
          class="text-sm font-semibold md:text-base"
          :class="isDisabled ? 'text-true-gray-500' : 'text-inherit'"
        >
          {{ title }}
        </div>
        <div v-if="description" class="text-xs md:text-sm text-true-gray-500">
          {{ description }}
        </div>
      </div>
    </div>
    <div v-if="extraDetails" class="text-sm font-semibold md:text-base">{{ extraDetails }}</div>
    <div v-else class="hidden md:inline" :class="{ 'bg-white opacity-30': isDisabled }">
      <img :src="arrow" class="w-3 h-3" alt />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SelectionButton',
  props: {
    dataTest: { required: true, type: String as PropType<String> },
    description: { required: false, type: String as PropType<String> },
    extraDetails: { required: false, type: String as PropType<String> },
    imageSource: { required: true, type: String as PropType<String> },
    isDisabled: { required: false, type: Boolean, default: false },
    isSelected: { required: false, type: Boolean as PropType<Boolean>, default: false },
    title: { required: true, type: String as PropType<String> },
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const borderStyles = computed(() => {
      if (props.isDisabled) {
        return 'border-dashed border-true-gray-300';
      }
      if (props.isSelected) {
        return 'border-solid border-true-gray cursor-pointer';
      }
      if (!props.isSelected) {
        return 'border-solid border-true-gray-300 cursor-pointer';
      }

      return null;
    });

    return {
      borderStyles,
      handleSelect: () => emit('selected'),
      arrow: nutshell['img/caret-right.svg'],
    };
  },
});
</script>

<style lang="scss" scoped>
.hidden {
  visibility: visible;
}
</style>
