import { ctApi } from '@/api';
import { productQuery } from '@/graphql/productQuery';

const fetchProduct = async (productKey: string) =>
  ctApi
    .graphql()
    .post({
      body: {
        query: productQuery,
        variables: {
          key: productKey,
          locale: 'en',
        },
      },
    })
    .execute();

export default fetchProduct;
