<template>
  <div>
    <div class="ais-SearchBox">
      <form role="search" novalidate="novalidate" class="ais-SearchBox-form" @submit.prevent>
        <input
          v-model="query"
          type="search"
          autocorrect="off"
          autocapitalize="off"
          autocomplete="off"
          placeholder="Search..."
          spellcheck="false"
          required="required"
          maxlength="512"
          aria-label="Search"
          class="ais-SearchBox-input"
        />
      </form>
    </div>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import { useState } from '@/composables/useState';
import { adaptMultipleQueryValues } from '@/utils/search/createAlgoliaRouter';

export default defineComponent({
  name: 'SynchronizedSearchBar',
  setup() {
    const route = useRoute();
    const { query } = useState('synchronizedSearchBar', () => ({ query: '' }));
    query.value = route.query.query ? adaptMultipleQueryValues(route.query) : '';

    return { query };
  },
});
</script>

<style lang="scss" scoped>
.ais-SearchBox-input {
  height: 39px;
}
</style>
