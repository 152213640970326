<template>
  <div class="tag-filter-control-panel">
    <h3>Filter By</h3>
    <label
      v-for="filter in filters"
      :key="filter.key"
      class="rounded-full tag-filter-control"
      :class="{ active: filter.active }"
      :data-test="filter.key"
      @click.prevent="toggleFilter(filter.key)"
    >
      {{ filter.displayName }}
      <input type="checkbox" :id="`tag-filter-${filter.key}`" class="sr-only" />
    </label>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TagFilterControlPanel',
  data: () => ({
    allFilters: [
      { displayName: 'Gluten-free', key: 'gluten-free' },
      { displayName: 'Kosher', key: 'kosher' },
      { displayName: 'Less Salt', key: 'less salt' },
      { displayName: 'Organic', key: 'organic' },
      { displayName: 'Raw', key: 'raw' },
      { displayName: 'Sugar-free', key: 'sugar-free' },
      { displayName: 'Vegan', key: 'vegan' },
    ],
  }),
  computed: {
    ...mapState('customTraysModule', ['activeFilters']),
    filters() {
      return this.allFilters.map((filter) => ({
        ...filter,
        active: this.activeFilters.includes(filter.key),
      }));
    },
  },
  methods: {
    ...mapActions('customTraysModule', ['toggleActiveFilter']),
    toggleFilter(key) {
      this.toggleActiveFilter(key);
      this.$emit('filters:changed');
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: $dark-grey;
  font-family: $font-family-proxima;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
  margin-bottom: 5px;

  @include respond-max($screen-sm-max) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
</style>
