import { defineStore } from 'pinia';
import { readonly, ref } from 'vue';

// eslint-disable-next-line import/prefer-default-export
export const useOverlay = defineStore('overlay', () => {
  const visible = ref(false);

  const hide = () => {
    visible.value = false;
  };
  const show = () => {
    visible.value = true;
  };
  const toggle = () => {
    visible.value = !visible.value;
  };

  return {
    hide,
    show,
    toggle,
    visible: readonly(visible),
  };
});
