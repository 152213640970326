<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import { useFeatureFlags } from '@/composables/useFeatureFlags';

const badges: Record<string, string> = {
  'badge-limited': 'Limited',
  'badge-shipsfree': 'Ships Free',
  'badge-bestseller': 'Best Seller',
  'badge-onsale': 'Sale',
};

const badgesStyles: Record<string, string> = {
  'badge-limited': 'bg-cream text-yellow-700',
  'badge-shipsfree': 'bg-baby-blue text-nuts-sky-800',
  'badge-bestseller': 'bg-nuts-lime-100 text-kale',
  'badge-onsale': 'bg-error text-white',
};

const props = defineProps<{ tags: string[] }>();
const store = useStore();
const { flags } = useFeatureFlags(store);
if (flags.badgeRedesign) badges['badge-limited'] = 'Seasonal';
const badge = computed(() => {
  const badgeKey = props.tags?.find((tag) => badges[tag]);
  if (!badgeKey) return {};
  return { label: badges[badgeKey], key: badgeKey, colorClass: badgesStyles[badgeKey] };
});
</script>

<template>
  <div
    v-show="badge.key"
    class="text-[10px] leading-4 md:text-sm box-border h-5 font-semibold md:leading-5 px-1.5 text-xs"
    data-test="badge"
    :class="
      flags.badgeRedesign
        ? ['rounded-bl rounded-tr', badge.colorClass]
        : 'bg-true-gray rounded-full text-white'
    "
  >
    {{ badge.label }}
  </div>
</template>
