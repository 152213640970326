<template>
  <div class="zone-three">
    <div id="slider">
      <div class="slides">
        <div class="free-shipping slide default" data-test="dy-zone-three">
          <div data-test="default-content" @click.prevent="toggleShippingDetails">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZoneThree',
  methods: {
    toggleShippingDetails() {
      this.$emit('zone3-details-toggle');
    },
  },
};
</script>

<style lang="scss" scoped>
.zone-three {
  display: inline;
  height: 100%;
  background-color: $malibu;
  z-index: 7;
  @include respond-min($screen-lg-min) {
    left: 845px;
    position: absolute;
    right: 0;
    top: 0;
  }
  @include respond-min-max($screen-md-min, $screen-md-max) {
    position: absolute;
    top: 0;
    width: 180px;
    right: 0;
  }
  @include respond-max($screen-xs-max) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 36px;
  }
}

#slider {
  .slides {
    height: 100%;
    @include respond-min-max($screen-sm-min, $screen-sm-max) {
      left: 0;
      right: 0;
      top: 0;
      height: 36px;
      position: absolute;
    }
    @include respond-max($screen-xs-max) {
      position: relative;
      height: 36px;
    }

    .slide {
      cursor: pointer;
      background-color: $malibu;
      border: 0px;
      z-index: 8;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      @include respond-min($screen-lg-min) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: inherit;
        padding: 10px 10px 0;
        box-shadow: unset;
      }
      @include respond-min-max($screen-sm-min, $screen-sm-max) {
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        height: 36px;
        margin: 0 calc(50% - 50vw);
        box-shadow: unset;
      }
      @include respond-min-max($screen-md-min, $screen-md-max) {
        left: 0;
        height: inherit;
        padding: 16px 10px;
        position: absolute;
        top: 0;
        right: 0;
        box-shadow: unset;
      }
      @include respond-max($screen-xs-max) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 0;
        box-shadow: unset;
      }
    }
  }
}
</style>
