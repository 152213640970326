import { NutsJson, webstore } from '@/api';

export interface Review {
  author: string;
  content: string;
  date: string;
  helpfulScore: number;
  id: number;
  locality: string | null;
  rating: number | null;
  ratingClass: string | null;
  size: string | null;
}

export type RatingTier = 1 | 2 | 3 | 4 | 5;

export interface Reviews {
  averageRating: number;
  count: number;
  featuredReview: Review | null;
  limit: number;
  mostRecentTopRatedReview: Review | null;
  mostRecentTopRatedReviewsOver100Chars: Review[];
  offset: number;
  reviews: Review[];
  starRatingsCount: Record<RatingTier, { percentage: number; count: number }> | null;
  totalRatings: number;
  totalReviews: number;
}

export async function getReviews(key: string, offset = 0, limit = 20) {
  return webstore.get<NutsJson<Reviews>>(
    `/api/items/${key}/reviews?offset=${offset}&limit=${limit}`,
  );
}

export async function getRecentReviews(key: string, rating?: RatingTier, offset = 0, limit = 20) {
  const searchParams = new URLSearchParams({ offset: String(offset), limit: String(limit) });
  if (rating) {
    searchParams.append('rating', String(rating));
  }
  return webstore.get<NutsJson<Reviews>>(
    `/api/items/${key}/recent-reviews?${searchParams.toString()}`,
  );
}

export async function setHelpfulReview(id: Review['id'], isHelpful: boolean) {
  return webstore.post<NutsJson<Review>>('/api/reviews/update-helpful', {
    id,
    isHelpful,
  });
}
