/* eslint-disable import/prefer-default-export */
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { ApplePay, PayPalCheckout } from 'braintree-web';
import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import { Router } from 'vue-router';
import { Store } from 'vuex';

import { ShippingOffer } from '@/api/shippingCalculator';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCart } from '@/composables/useCart';
import { useCheckout } from '@/composables/useCheckout';
import { useCustomer } from '@/composables/useCustomer';
import { useDelivery } from '@/composables/useDelivery';
import { useDiscount } from '@/composables/useDiscount';
import {
  applePayEnabled,
  applePayPaymentItem,
  PaymentItem,
  usePayment,
} from '@/composables/usePayment';
import money from '@/filters/money';
import { NutsAddress } from '@/utils/address';
import { CheckoutEvents, formatPurchaseItem, gtag } from '@/utils/analytics';
import { generateRecaptcha } from '@/utils/captcha';
import { Money } from '@/utils/money';
import { reportError } from '@/utils/reportError';

interface PayPalMoney {
  currency_code: 'USD';
  value: string;
}

interface PayPalPaymentItems {
  discount?: PayPalMoney;
  handling?: PayPalMoney;
  item_total?: PayPalMoney;
  shipping?: PayPalMoney;
  tax_total?: PayPalMoney;
}

function payPalPaymentItem(
  paymentItem: PaymentItem | { amount: Money; label: 'ixclkxk.shop' },
): PayPalMoney {
  if (!paymentItem.amount) throw Error('only payment items with values are accepted');
  const value = Math.abs(dollars(paymentItem.amount)).toFixed(2);
  return { currency_code: 'USD', value };
}

function shippingOffersToApplePayShippingMethods(
  shippingOffers: ShippingOffer[],
): ApplePayJS.ApplePayShippingMethod[] {
  return shippingOffers.map((offer) => ({
    label: offer.name,
    amount: dollars(offer.price).toFixed(2),
    detail: dayjs(offer.earliestArrivalOn).format('ddd, MMMM D'),
    identifier: `${offer.carrier}:${offer.carrierCode}`,
  }));
}

function validateShippingAddress(address: NutsAddress) {
  return address.city && address.state && address.postalCode && address.country;
}

export function useExpressCheckout(store: Store<any>, router?: Router) {
  const {
    containsAutoDelivery,
    customLineItems,
    lineItems,
    loadProductKeysById,
    productKeysById,
    setCustomerEmail,
  } = useCart(store);
  const { cxMode, placeOrder, prepareCartForCheckout, purgeGiftOptions, resetCheckout } =
    useCheckout(store);
  const { customer, customerRegionalCarriersAllowed } = useCustomer(store);
  const {
    applyShippingOffers,
    calculateShippingOffers,
    hasMultipleShipments,
    setShippingAddress,
    standardShipments,
  } = useDelivery(store);
  const { estimatedAmountToCharge, getSavedPaymentMethods, offerThirdPartyPayments, paymentItems } =
    usePayment(store, cxMode);
  const { appliedDiscountCodes } = useDiscount(store);
  const { navigateTo } = useRouteChange(router);

  const offerExpressCheckout = computed<boolean>(
    () => lineItems.value.length > 0 && offerThirdPartyPayments.value,
  );

  const offerApplePay = computed<boolean>(() => {
    if (!offerExpressCheckout.value) return false;
    if (containsAutoDelivery.value && (!customer.value || cxMode.value)) {
      return false;
    }
    return applePayEnabled();
  });

  return {
    offerApplePay,
    offerExpressCheckout,

    async requestApplePayPayment(applePay?: ApplePay, deviceData?: string): Promise<void> {
      const checkoutEvent = computed(() => ({
        checkout_type: 'Apple Pay',
        currency: 'USD',
        value: money(estimatedAmountToCharge.value),
        coupon: appliedDiscountCodes,
        items: lineItems.value.map((li) => formatPurchaseItem(li, productKeysById.value)),
      }));
      gtag('event', 'begin_checkout', checkoutEvent.value);
      if (!applePay) throw Error('Missing required Apple Pay instance!');

      const merchantName = 'ixclkxk.shop';

      const shippingOffers = ref<ShippingOffer[]>();

      const paymentLineItems = computed(() =>
        paymentItems.value
          .filter((p) => p.amount !== undefined)
          .map((p) => applePayPaymentItem(p, 'final')),
      );

      const total = computed(() =>
        applePayPaymentItem({
          amount: estimatedAmountToCharge.value,
          label: merchantName,
        }),
      );

      type BraintreeApplePayConfig = Pick<
        ApplePayJS.ApplePayPaymentRequest,
        'merchantCapabilities' | 'supportedNetworks'
      >;
      // @ts-ignore (@types/braintree-web is wrong and exposes a Partial<ApplePayPaymentRequest>)
      const braintreeConfig: BraintreeApplePayConfig = applePay.createPaymentRequest({});
      const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
        ...braintreeConfig,
        countryCode: 'US',
        currencyCode: 'USD',
        lineItems: paymentLineItems.value,
        requiredBillingContactFields: ['postalAddress', 'name'],
        requiredShippingContactFields: ['postalAddress', 'phone', 'email', 'name'],
        total: total.value,
      };

      const session = new ApplePaySession(3, paymentRequest);

      loadProductKeysById();
      await prepareCartForCheckout();
      if (hasMultipleShipments.value) await resetCheckout();
      else await purgeGiftOptions();

      // Merchant Validation
      session.onvalidatemerchant = async (event) => {
        try {
          // @ts-ignore (@types/braintree-web is wrong and works with promises)
          const merchantSession = await applePay.performValidation({
            validationURL: event.validationURL,
            displayName: merchantName,
          });
          session.completeMerchantValidation(merchantSession);
        } catch (err) {
          reportError(err, 'Apple Pay failed to load');
          // eslint-disable-next-line no-alert
          alert('Apple Pay failed to load');
        }
      };

      // Shipping Contact Selection
      session.onshippingcontactselected = async (event) => {
        let errors: ApplePayJS.ApplePayError[] | undefined;
        let newShippingMethods: ApplePayJS.ApplePayShippingMethod[] = [];
        const { shippingContact } = event;
        gtag('event', 'add_address_info', checkoutEvent.value);
        try {
          const shippingAddress = NutsAddress.fromApplePay(shippingContact);

          if (!validateShippingAddress(shippingAddress)) throw Error('invalid address');

          await setShippingAddress(shippingAddress);
          const requestShipments = standardShipments.value.slice(0, 1);
          gtag('event', 'add_shipping_info', {
            ...checkoutEvent.value,
            shipping_tier: requestShipments[0].customFields?.shipmentPickupShippingOption,
          });
          const {
            offerSets: [{ key, shippingOffers: offers }],
          } = await calculateShippingOffers({
            requestShipments,
            regionalCarriersAllowed: customerRegionalCarriersAllowed.value,
            ontracSaturdayNonResidentialAllowed: true,
          });
          shippingOffers.value = offers;
          const addHeatResistantPacking =
            offers[0].containsMeltables || offers[0].heatResistantIncluded;
          await applyShippingOffers([
            {
              key,
              offer: offers[0],
              addHeatResistantPacking,
            },
          ]);
          newShippingMethods = shippingOffersToApplePayShippingMethods(offers);
        } catch (err) {
          errors = [{ code: 'addressUnserviceable', message: 'Cannot ship to this location' }];
        }
        session.completeShippingContactSelection({
          errors,
          newLineItems: paymentLineItems.value,
          newShippingMethods,
          newTotal: total.value,
        });
      };

      // Shipping Method Selection
      session.onshippingmethodselected = async (event) => {
        const { identifier } = event.shippingMethod;
        const shippingMethod = shippingOffers.value?.find(
          (o) => `${o.carrier}:${o.carrierCode}` === identifier,
        );
        if (!shippingMethod) {
          const err = Error('invalid shipping method selected');
          reportError(err, 'Shipping method no longer available');
          session.abort();
          // eslint-disable-next-line no-alert
          alert('Shipping method no longer available');
          throw err;
        }
        const [{ key }] = standardShipments.value;
        const addHeatResistantPacking =
          shippingMethod.containsMeltables || shippingMethod.heatResistantIncluded;
        await applyShippingOffers([
          {
            key,
            offer: shippingMethod,
            addHeatResistantPacking,
          },
        ]);
        session.completeShippingMethodSelection({
          newLineItems: paymentLineItems.value,
          newTotal: total.value,
        });
      };

      // Payment Authorized
      session.onpaymentauthorized = async (event) => {
        let nonce: string | undefined;
        try {
          // @ts-ignore (@types/braintree-web is wrong; now takes an object and handle promises)
          ({ nonce } = await applePay.tokenize({ token: event.payment.token }));
        } catch (err) {
          const label = reportError(err, 'Apple Pay failed to tokenize');
          CheckoutEvents.event({ action: 'Express Checkout', label, category: 'Checkout Error' });
          session.completePayment({ status: ApplePaySession.STATUS_FAILURE });
          return;
        }
        const maybeAddress = (contactAddress?: ApplePayJS.ApplePayPaymentContact) => {
          if (!contactAddress) return undefined;
          return NutsAddress.fromApplePay(contactAddress);
        };

        const shippingAddress = maybeAddress(event.payment.shippingContact);
        try {
          if (!shippingAddress) throw Error('shipping address missing');
          await setCustomerEmail(shippingAddress.email);
          await setShippingAddress({ ...standardShipments.value[0].address, ...shippingAddress });
        } catch (err) {
          const label = reportError(err, 'Apple Pay unable to finalize shipping address');
          CheckoutEvents.event({ action: 'Express Checkout', label, category: 'Checkout Error' });
          session.completePayment({ status: ApplePaySession.STATUS_FAILURE });
          // eslint-disable-next-line no-alert
          alert('Failed to finalize order');
          return;
        }

        const saveToWallet = containsAutoDelivery.value;
        let setAsDefaultInWallet = false;
        if (containsAutoDelivery.value) {
          try {
            const paymentMethods = await getSavedPaymentMethods();
            const hasDefaultMethod = paymentMethods.some((m) => m.default);
            setAsDefaultInWallet = saveToWallet && !hasDefaultMethod;
          } catch (err) {
            const label = reportError(err, 'Apple Pay unable to get wallet for Auto Delivery user');
            CheckoutEvents.event({ action: 'Express Checkout', label, category: 'Checkout Error' });
            session.completePayment({ status: ApplePaySession.STATUS_FAILURE });
            // eslint-disable-next-line no-alert
            alert('Failed to finalize order');
            return;
          }
        }

        try {
          const captchaResponse = await generateRecaptcha();
          const { order, receiptToken } = await placeOrder({
            billingAddress: {
              ...NutsAddress.fromApplePay(event.payment.billingContact ?? {}),
              email: shippingAddress.email,
            },
            deviceData,
            'g-recaptcha-response': captchaResponse,
            saveToWallet,
            setAsDefaultInWallet,
            paymentNonces: [`apple_pay_card:${nonce}`],
          });

          gtag('event', 'add_payment_info', {
            ...checkoutEvent.value,
            payment_type: order?.paymentInfo?.payments[0]?.obj?.paymentMethodInfo?.name?.en,
          });

          CheckoutEvents.purchase({
            order,
            paymentItems: paymentItems.value,
            productKeysById: productKeysById.value,
            receiptToken,
          });

          session.completePayment({ status: ApplePaySession.STATUS_SUCCESS });
          if (router) window.scrollTo({ top: 0, behavior: 'smooth' });
          navigateTo(`/checkout/receipt?token=${receiptToken}`);
        } catch (err) {
          const label = reportError(err, 'Apple Pay failed to finalize order');
          CheckoutEvents.event({ action: 'Express Checkout', label, category: 'Checkout Error' });
          session.completePayment({ status: ApplePaySession.STATUS_FAILURE });
          session.abort();
          // eslint-disable-next-line no-alert
          alert('Apple Pay failed to finalize order');
        }
      };

      return session.begin();
    },

    requestPayPalCheckout(
      paypalCheckout: PayPalCheckout,
      deviceData?: string,
    ): Parameters<typeof window.paypal.Buttons>[0] {
      loadProductKeysById();

      const shippingOffers = ref<ShippingOffer[]>();

      const surchargeLineItem = computed(() =>
        customLineItems.value.find((l) => l.slug === 'surcharge'),
      );
      const adjustedSubTotal = computed(() => {
        const subTotal = paymentItems.value.find((i) => i.label === 'Subtotal')!;
        return {
          ...subTotal,
          amount: Money.add(subTotal.amount!, surchargeLineItem.value?.money ?? from(0)),
        };
      });

      const paymentLineItems = computed(() => {
        const keys: Record<string, keyof PayPalPaymentItems> = {
          Discount: 'discount',
          Subtotal: 'item_total',
          Shipping: 'shipping',
          Tax: 'tax_total',
        };

        return paymentItems.value
          .filter((p) => p.amount !== undefined)
          .reduce<PayPalPaymentItems>((breakdown, item) => {
            if (item.label === 'Subtotal') {
              return { ...breakdown, item_total: payPalPaymentItem(adjustedSubTotal.value) };
            }
            if (item.label in keys) {
              return { ...breakdown, [keys[item.label]]: payPalPaymentItem(item) };
            }
            if (item.label === 'Heat-Resistant Packaging' && dollars(item.amount!)) {
              return { ...breakdown, handling: payPalPaymentItem(item) };
            }
            return breakdown;
          }, {});
      });

      const addressChanged = (shippingAddress: NutsAddress) => {
        if (!standardShipments.value.length) return true;
        const stringify = (address: NutsAddress) =>
          `${address.city} ${address.state} ${address.postalCode}`;
        return stringify(shippingAddress) !== stringify(standardShipments.value[0].address);
      };

      const checkoutEvent = computed(() => ({
        checkout_type: 'Paypal',
        currency: 'USD',
        value: money(estimatedAmountToCharge.value),
        coupon: appliedDiscountCodes,
        items: lineItems.value.map((li) => formatPurchaseItem(li, productKeysById.value)),
      }));

      return {
        fundingSource: 'paypal',
        async createOrder() {
          gtag('event', 'begin_checkout', checkoutEvent.value);
          await resetCheckout();
          await prepareCartForCheckout();

          const payPalLineItems = lineItems.value.map((lineItem) => ({
            description: undefined,
            kind: 'debit' as const as paypal.LineItemKind,
            name: lineItem.name.en,
            productCode: undefined,
            quantity: String(lineItem.quantity),
            unitAmount: dollars(lineItem.piecePrice).toFixed(2),
            unitTaxAmount: undefined,
            url: undefined,
          }));
          if (surchargeLineItem.value) {
            payPalLineItems.push({
              description: undefined,
              kind: 'debit' as const as paypal.LineItemKind,
              name: paymentItems.value.find((i) => i.label.includes('Surcharge'))!.label,
              productCode: undefined,
              quantity: String(surchargeLineItem.value.quantity),
              unitAmount: dollars(surchargeLineItem.value.money).toFixed(2),
              unitTaxAmount: undefined,
              url: undefined,
            });
          }

          return paypalCheckout.createPayment({
            amount: dollars(adjustedSubTotal.value.amount),
            currency: 'USD',
            enableShippingAddress: true,
            flow: 'checkout' as const as typeof window.paypal.FlowType.Checkout,
            intent: 'authorize' as const as typeof window.paypal.Intent.Authorize,
            lineItems: payPalLineItems,
            shippingAddressEditable: true,
          });
        },
        async onApprove(data) {
          const payload = await paypalCheckout.tokenizePayment(data);
          try {
            await setCustomerEmail(payload.details.email);
            if (payload.details.shippingAddress) {
              const phone = payload.details.shippingAddress.phone ?? payload.details.phone;
              await setShippingAddress({
                ...standardShipments.value[0].address,
                ...NutsAddress.fromPayPal(payload.details.shippingAddress),
                phone,
              });
            }
            const captcha = await generateRecaptcha();
            const { order, receiptToken } = await placeOrder({
              deviceData,
              paymentNonces: [`paypal_account:${payload.nonce}`],
              'g-recaptcha-response': captcha,
            });

            gtag('event', 'add_payment_info', {
              ...checkoutEvent.value,
              payment_type: order?.paymentInfo?.payments[0]?.obj?.paymentMethodInfo?.name?.en,
            });

            CheckoutEvents.purchase({
              order,
              paymentItems: paymentItems.value,
              productKeysById: productKeysById.value,
              receiptToken,
            });

            if (router) window.scrollTo({ top: 0, behavior: 'smooth' });
            navigateTo(`/checkout/receipt?token=${receiptToken}`);
          } catch (err) {
            const label = reportError(err, 'PayPal failed to finalize order');
            CheckoutEvents.event({ action: 'Express Checkout', label, category: 'Checkout Error' });
            // eslint-disable-next-line no-alert
            alert('PayPal failed to finalize order');
          }
        },
        onError(error) {
          reportError(error);
        },
        async onShippingChange(data, actions) {
          const shippingAddress = NutsAddress.fromPayPal(data.shipping_address);
          const needShippingOffers =
            !data.selected_shipping_option || addressChanged(shippingAddress);

          await setShippingAddress(shippingAddress);

          gtag('event', 'add_address_info', checkoutEvent.value);

          let identifier = data?.selected_shipping_option?.id;
          try {
            if (needShippingOffers) {
              const requestShipments = standardShipments.value.slice(0, 1);
              const {
                offerSets: [{ shippingOffers: offers }],
              } = await calculateShippingOffers({
                requestShipments,
                regionalCarriersAllowed: customerRegionalCarriersAllowed.value,
                ontracSaturdayNonResidentialAllowed: true,
              });
              shippingOffers.value = offers;
              identifier = `${offers[0].carrier}:${offers[0].carrierCode}`;
              gtag('event', 'add_shipping_info', {
                ...checkoutEvent.value,
                shipping_tier: offers[0].name,
              });
            } else {
              gtag('event', 'add_shipping_info', {
                ...checkoutEvent.value,
                shipping_tier: data?.selected_shipping_option?.label,
              });
            }

            const shippingMethod = shippingOffers.value?.find(
              (o) => `${o.carrier}:${o.carrierCode}` === identifier,
            );
            if (!shippingMethod) throw Error('invalid shipping method selected');

            const [{ key }] = standardShipments.value;
            const addHeatResistantPacking =
              shippingMethod.containsMeltables || shippingMethod.heatResistantIncluded;
            await applyShippingOffers([
              {
                key,
                offer: shippingMethod,
                addHeatResistantPacking,
              },
            ]);
          } catch (err) {
            reportError(err, 'Shipping method no longer available');
            // eslint-disable-next-line no-alert
            alert('Shipping method no longer available');
            actions.reject();
            throw err;
          }

          return actions.order.patch([
            {
              op: 'replace',
              path: "/purchase_units/@reference_id=='default'/amount",
              value: {
                currency_code: 'USD',
                value: dollars(estimatedAmountToCharge.value).toFixed(2),
                breakdown: paymentLineItems.value,
              },
            },
            {
              op: data.selected_shipping_option ? 'replace' : 'add',
              path: "/purchase_units/@reference_id=='default'/shipping/options",
              value:
                shippingOffers.value?.map((offer) => ({
                  amount: { currency_code: 'USD', value: dollars(offer.price).toFixed(2) },
                  id: `${offer.carrier}:${offer.carrierCode}`,
                  label: offer.name,
                  selected: `${offer.carrier}:${offer.carrierCode}` === identifier,
                  type: 'SHIPPING',
                })) ?? [],
            },
          ]);
        },
      };
    },
  };
}
