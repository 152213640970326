<script setup lang="ts">
import { useElementBounding } from '@vueuse/core';
import { computed, markRaw, ref, watch } from 'vue';

import RouteLink from '@/components/base/RouteLink.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import LargeMenu from '@/components/layout/header/desktop/LargeMenu.vue';
import LargeMenuGroup from '@/components/layout/header/desktop/LargeMenuGroup.vue';
import NavHeader from '@/components/layout/header/desktop/NavHeader.vue';
import SmallMenu from '@/components/layout/header/desktop/SmallMenu.vue';
import { useOverlay } from '@/stores/overlay';
import {
  desktopAttribution,
  LargeMenuGroup as LargeMenuGroupModel,
  MenuByType,
  SiteNavigation,
} from '@/utils/navMenu';

const props = withDefaults(
  defineProps<{
    actionBarHeightOffset?: number;
    isFixed?: boolean;
    isOpen?: boolean;
    siteNavigation: SiteNavigation;
  }>(),
  {
    actionBarHeightOffset: 0,
    isFixed: false,
    isOpen: false,
  },
);

const extendedNavigation = computed(() => !!props.siteNavigation.topMenus);
const mainNav = computed<MenuByType[]>(
  () =>
    props.siteNavigation.topMenus ??
    props.siteNavigation.categories.map(({ menu }) => ({ ...menu, type: 'LargeMenu' })),
);

const activeMenu = ref<MenuByType | LargeMenuGroupModel['menus']>();
const showSubMenu = ref(false);

const categoriesButton = ref<InstanceType<typeof UnstyledButton>>();
const categoriesMenu = ref<InstanceType<typeof LargeMenuGroup>>();

const menuGroupList = ref<HTMLElement>();
const { height } = useElementBounding(menuGroupList);
const subMenuOffset = computed(() => `${height.value ? height.value : 0}px`);

const subMenus: Map<MenuByType, HTMLElement> = new Map();
const currentParentElement = ref<HTMLElement>();

const focusIntoMenu = (navEntry: MenuByType, target: EventTarget | null) => {
  if (target instanceof HTMLElement) {
    currentParentElement.value = target;
  }
  if (activeMenu.value) {
    subMenus.get(navEntry)?.querySelector<HTMLElement>('[href]')?.focus();
  }
};

const escapeSubMenu = () => {
  showSubMenu.value = false;
  currentParentElement.value?.focus();
  currentParentElement.value = undefined;
};

const hideSubMenu = () => {
  showSubMenu.value = false;
  activeMenu.value = undefined;
};

const selectSubMenu = (navEntry: MenuByType | LargeMenuGroupModel['menus']) => {
  const rawNavEntry = markRaw(navEntry);
  if (activeMenu.value !== rawNavEntry) {
    showSubMenu.value = true;
  }
  activeMenu.value = rawNavEntry;
};

const setSubMenuRef = (
  navMenu: MenuByType,
  subComponent?: InstanceType<typeof LargeMenu> | InstanceType<typeof SmallMenu>,
) => {
  if (subComponent?.linksContainer) {
    subMenus.set(navMenu, subComponent.linksContainer);
  }
};

const cancelButtonClick = (navEntry: MenuByType, e: Event) => {
  if (navEntry.header.url) return;
  e.preventDefault();
  e.stopPropagation();
};

const overlay = useOverlay();
watch(showSubMenu, () => (showSubMenu.value ? overlay.show() : overlay.hide()));
</script>

<template>
  <div :class="{ 'sub-menu-height': isFixed }" />
  <div
    class="justify-center hidden bg-white border-b border-solid lg:flex border-nuts-neutral-200 lg:visible"
    :class="{
      'slide-from-top drop-shadow': isOpen && isFixed,
      'fixed top-0 right-0 left-0 transition ease-in-out duration-500': isFixed,
    }"
    data-test="main-nav"
  >
    <nav
      id="main-nav"
      role="navigation"
      aria-label="Main"
      class="relative w-full 2xl:max-w-screen-2xl"
    >
      <ul
        class="flex px-10 2xl:px-0"
        :class="[
          extendedNavigation ? 'justify-start' : 'justify-center',
          { 'flex-wrap': !isFixed || isOpen },
        ]"
        ref="menuGroupList"
        @mouseleave="hideSubMenu"
      >
        <li
          v-if="extendedNavigation"
          class="relative hover:underline decoration-yellow decoration-4 underline-offset-8 hover:bg-nuts-stone-100"
          :class="{
            'bg-nuts-stone-100': showSubMenu && activeMenu === siteNavigation.categories,
            'underline decoration-yellow decoration-4 underline-offset-8':
              showSubMenu && activeMenu === siteNavigation.categories,
          }"
          @click="hideSubMenu"
          @keydown.enter="hideSubMenu"
          @keydown.passive.esc="escapeSubMenu"
        >
          <div
            class="flex items-center"
            data-test="menu-categories-wrapper"
            @focusin="selectSubMenu(siteNavigation.categories)"
            @mouseover="selectSubMenu(siteNavigation.categories)"
          >
            <UnstyledButton
              class="px-4 py-3 text-base font-semibold hover:no-underline font-sofia-pro"
              ref="categoriesButton"
              @click.prevent.stop
              @keydown.down="
                (currentParentElement = $event.target) &&
                  categoriesMenu?.$el?.querySelector('a')?.focus()
              "
              @keydown.passive.tab="hideSubMenu"
            >
              <NavHeader
                :active="activeMenu === siteNavigation.categories"
                extendedNavigation
                :header="{ text: 'Categories' }"
                :showSubMenu="showSubMenu"
              />
            </UnstyledButton>
          </div>
          <LargeMenuGroup
            v-show="showSubMenu && activeMenu === siteNavigation.categories"
            :active="activeMenu === siteNavigation.categories"
            :menus="siteNavigation.categories"
            v-model:showSubMenu="showSubMenu"
            class="left-1 sub-menu-offset"
            ref="categoriesMenu"
            @exit-menu="categoriesButton?.$el?.focus()"
          />
        </li>

        <li
          v-for="navEntry in mainNav"
          :key="navEntry.header.url || navEntry.header.text"
          class="hover:underline decoration-yellow decoration-4 underline-offset-8"
          :class="{
            relative: navEntry.type === 'SmallMenu',
            'hover:bg-nuts-stone-100': extendedNavigation,
            'bg-nuts-stone-100': extendedNavigation && activeMenu === navEntry && showSubMenu,
            'underline decoration-yellow decoration-4 underline-offset-8':
              activeMenu === navEntry && showSubMenu,
          }"
          @click="hideSubMenu"
          @keydown.enter="hideSubMenu"
          @keydown.passive.esc="escapeSubMenu"
        >
          <div
            class="flex items-center"
            data-test="menu-item-wrapper"
            @focusin="selectSubMenu(navEntry)"
            @keydown.capture.tab="hideSubMenu"
            @keydown.prevent.down="focusIntoMenu(navEntry, $event.target)"
            @mouseover="selectSubMenu(navEntry)"
          >
            <component
              :is="navEntry.header.url ? RouteLink : UnstyledButton"
              :to="navEntry.header.url"
              class="px-4 py-3 text-base font-semibold hover:no-underline font-sofia-pro"
              v-bind="desktopAttribution(navEntry)"
              :data-test="`base-nav-item-${navEntry.header.text}`"
              @click="cancelButtonClick(navEntry, $event)"
            >
              <NavHeader
                :active="activeMenu === navEntry && showSubMenu"
                :extendedNavigation="extendedNavigation"
                :header="navEntry.header"
                :showSubMenu="showSubMenu"
              />
            </component>
          </div>

          <LargeMenu
            v-if="navEntry.type === 'LargeMenu'"
            v-show="showSubMenu && activeMenu === navEntry"
            v-model:showSubMenu="showSubMenu"
            :menu="navEntry"
            :ref="(el) => setSubMenuRef(navEntry, el)"
            class="absolute w-full border-b border-solid shadow-md border-nuts-neutral-200 shadow-black/10 sub-menu-offset"
          />
          <SmallMenu
            v-else
            v-show="showSubMenu && activeMenu === navEntry"
            v-model:showSubMenu="showSubMenu"
            :menu="navEntry"
            :ref="(el) => setSubMenuRef(navEntry, el)"
            class="absolute shadow-md shadow-black/10 sub-menu-offset"
          />
        </li>

        <li
          v-if="siteNavigation.featuredPage?.text"
          class="my-3 hover:underline decoration-yellow decoration-4 underline-offset-8"
          :class="{ 'order-first': siteNavigation.featuredPage.placement === 'first' }"
          @focusin="hideSubMenu"
          @mouseover="hideSubMenu"
        >
          <div class="flex items-center" data-test="menu-item-wrapper">
            <RouteLink
              v-if="siteNavigation.featuredPage.url"
              :to="siteNavigation.featuredPage.url"
              class="text-base font-semibold hover:no-underline font-sofia-pro"
              v-bind="desktopAttribution(siteNavigation.featuredPage)"
              data-test="base-nav-item-featured-page"
            >
              <NavHeader
                :active="false"
                :extendedNavigation="extendedNavigation"
                :header="siteNavigation.featuredPage"
                :showSubMenu="showSubMenu"
              />
            </RouteLink>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.slide-from-top {
  @apply fixed left-0 right-0;
  top: 0;
  z-index: -1;
  transform: translateY(v-bind('`${actionBarHeightOffset}px`'));
}
.sub-menu-offset {
  top: v-bind('subMenuOffset');
  left: 0;
}

.sub-menu-height {
  height: v-bind('subMenuOffset');
}
</style>
