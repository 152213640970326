<template>
  <div data-test="dy-server-side-variation" :data-variation-id="variationId">
    <div v-if="hasHtml" v-html="htmlContent" data-test="variation-html" />
  </div>
</template>

<script>
export default {
  name: 'DyHtmlCssVariation',
  props: {
    variation: { type: Object, required: false },
  },
  metaInfo() {
    const { backgroundImages = [] } = this;
    return {
      link: backgroundImages.map((href) => ({ rel: 'preload', as: 'image', href })),
      script: this.variation?.script ? [{ children: this.variation.script, body: true }] : [],
      style: this.hasStyle ? [{ children: this.styleContent }] : [],
    };
  },
  computed: {
    backgroundImages() {
      const pattern = /background-image:[^;]*\burl\(['"]?([^'");]+)['"]?\);/g;
      return [...this.styleContent.matchAll(pattern)].map((m) => m[1]);
    },
    hasData() {
      return !!this.variation;
    },
    hasHtml() {
      return !!(this.hasData && this.variation.html);
    },
    hasStyle() {
      return this.hasData && !!this.variation.css;
    },
    htmlContent() {
      return this.hasHtml ? this.variation.html : '';
    },
    styleContent() {
      return this.hasStyle ? `${this.variation.css}` : '';
    },
    variationId() {
      return this.variation ? `variation-id-${this.variation.id}` : '';
    },
  },
};
</script>
