<template>
  <div
    class="flex p-4 line-item"
    :class="{ 'hover:bg-background-secondary': linkToItem }"
    data-test="order-summary-line-item"
  >
    <RouteLink
      :to="lineItem.productPath ?? '#'"
      class="flex w-full text-black focus:no-underline hover:no-underline link"
      :class="{ 'pointer-events-none': !linkToItem }"
    >
      <div class="w-12">
        <img :src="imageUrl" :alt="lineItem.name.en" />
      </div>
      <div class="w-full ml-4">
        <h4 class="text-sm font-semibold font-sofia-pro text-true-gray">
          {{ lineItem.name.en }}
        </h4>
        <div class="my-1 text-xs font-proxima-nova">
          <template v-if="lineItem.variant.variantName">
            {{ lineItem.variant.variantName.en }} -
          </template>
          <template v-if="showPricing"> {{ money(lineItem.piecePrice) }} each </template>
        </div>
        <p
          v-if="lineItem.custom?.fields.customizationsDescription || hasChildLineItems"
          class="my-1 text-xs leading-tight font-proxima-nova"
        >
          {{ lineItemDescription }}
        </p>
        <div v-if="lineItem.custom?.fields.markedAsGift" class="flex items-center my-4 text-xs">
          <img :src="giftIcon" class="w-4 mr-2" alt="gift" />
          This is a gift
        </div>
        <div class="flex items-center justify-between price">
          <span
            :aria-label="`Quantity ${lineItem.quantity}`"
            class="text-sm font-proxima-nova text-true-gray font-regular"
          >
            Qty: {{ lineItem.quantity }}
          </span>
          <div
            v-if="showPricing"
            :aria-label="
              lineItem.totalSavings
                ? `Price reduced from ${money(
                    lineItem.totalSavings.comparisonPrice,
                  )} to ${displayPrice}`
                : `Price: ${displayPrice}`
            "
            class="flex"
          >
            <del v-if="lineItem.totalSavings">
              <SmallBodyText class="mr-1 text-right text-true-gray-500" data-test="strikethrough">
                {{ money(lineItem.totalSavings.comparisonPrice) }}
              </SmallBodyText>
            </del>
            <SmallBodyText
              class="font-semibold text-right"
              data-test="discounted-price"
              :class="[lineItem.totalSavings?.onSale ? 'text-error' : 'text-true-gray']"
            >
              {{ displayPrice }}
            </SmallBodyText>
          </div>
        </div>
        <p
          v-if="isDigitalGift"
          class="mt-4 text-sm underline cursor-pointer pointer-events-auto"
          data-test="digital-gift-see-details-checkout"
          @click.prevent="handleOpenDetails"
        >
          View gift details
        </p>
        <div v-if="autoDeliveryIntervalWeeks" class="auto-delivery">
          <img src="@/assets/auto-delivery/auto_dellivery_logo.svg" alt="auto delivery icon" />
          <span
            class="ml-2 text-xs text-true-gray font-proxima-nova"
            :class="{ 'line-through': multiship }"
          >
            Ships every {{ autoDeliveryIntervalWeeks }} weeks
          </span>
          <RouteLink
            v-if="isReceiptPage"
            class="pl-6 text-base pointer-events-auto edit-text"
            to="/account/auto-delivery"
            @click.stop
          >
            Edit
          </RouteLink>
          <div v-if="multiship" class="text-xs italic bold font-proxima-nova">
            Auto-Delivery is not available when shipping to multiple addresses
          </div>
        </div>
      </div>
      <Teleport v-if="isDigitalGift" to="body">
        <GiftDetailsModal
          :isOpen="showGiftDetails"
          :digitalGiftingFields="digitalGiftingFields"
          @close-details="handleCloseDetails"
        />
      </Teleport>
    </RouteLink>
  </div>
</template>

<script lang="ts">
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, defineComponent, PropType, ref } from 'vue';
import { useRoute } from 'vue-router';

import brokenPeanut from '@/assets/broken-peanut.png';
import RouteLink from '@/components/base/RouteLink.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import GiftDetailsModal from '@/components/digital-gift/GiftDetailsModal.vue';
import { NutsLineItem } from '@/composables/useCheckout';
import { useCustomProducts } from '@/composables/useCustomProducts';
import money from '@/filters/money';

export default defineComponent({
  name: 'LineItem',
  props: {
    lineItem: { required: true, type: Object as PropType<NutsLineItem> },
    multiship: { required: false, type: Boolean, default: false },
    showPricing: { required: false, type: Boolean, default: true },
    linkToItem: { required: false, type: Boolean, default: false },
  },
  components: {
    GiftDetailsModal,
    RouteLink,
    SmallBodyText,
  },
  setup(props) {
    const route = useRoute();
    const { formatBundleLineItem } = useCustomProducts();
    const isReceiptPage = computed(() => route?.name === 'CheckoutReceipt');

    const isDigitalGift = computed(() => !!props.lineItem.custom?.fields.recipientsJson);
    const digitalGiftingFields = computed(() => ({
      occasionImageUrl: props.lineItem.custom?.fields.occasionImageUrl,
      senderName: props.lineItem.custom?.fields.senderName,
      sendAt: props.lineItem.custom?.fields.sendAt,
      recipients: props.lineItem.custom?.fields.recipientsJson
        ? JSON.parse(props.lineItem.custom?.fields.recipientsJson)
        : '',
    }));
    const showGiftDetails = ref(false);

    const handleOpenDetails = () => {
      showGiftDetails.value = true;
    };
    const handleCloseDetails = () => {
      showGiftDetails.value = false;
    };

    const autoDeliveryIntervalWeeks = computed(() =>
      props.lineItem.custom?.fields.autoDeliveryInterval
        ? props.lineItem.custom.fields.autoDeliveryInterval / 7
        : undefined,
    );

    const displayPrice = computed(() => {
      const { totalPriceBeforeCartLevelDiscount } = props.lineItem;
      return dollars(totalPriceBeforeCartLevelDiscount) === 0
        ? 'FREE'
        : money(totalPriceBeforeCartLevelDiscount);
    });

    const imageUrl = computed(() => props.lineItem.titleImage?.small ?? brokenPeanut);

    const hasChildLineItems = computed(() => !!props.lineItem.children?.length);
    const formattedBundleLineItem = computed(() => formatBundleLineItem(props.lineItem));

    const lineItemDescription = computed(() => {
      if (props.lineItem.custom?.fields.customizationsDescription) {
        return props.lineItem.custom?.fields.customizationsDescription;
      }
      return formattedBundleLineItem.value?.description;
    });

    return {
      autoDeliveryIntervalWeeks,
      digitalGiftingFields,
      displayPrice,
      formattedBundleLineItem,
      giftIcon: nutshell['img/gift-box.svg'],
      handleCloseDetails,
      handleOpenDetails,
      hasChildLineItems,
      imageUrl,
      isDigitalGift,
      isReceiptPage,
      lineItemDescription,
      money,
      showGiftDetails,
    };
  },
});
</script>

<style lang="scss" scoped>
.edit-text {
  color: #333333;
  text-decoration: underline;
}
:deep(.link) {
  @apply text-black;
}
</style>
