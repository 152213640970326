<script setup lang="ts">
import { useStore } from 'vuex';

import ApplePayButton from '@/components/cart/ApplePayButton.vue';
import PaypalButton from '@/components/cart/PaypalButton.vue';
import { useExpressCheckout } from '@/composables/useExpressCheckout';

const props = defineProps({
  miniCart: { required: false, type: Boolean },
  trackingEventLocation: { required: true, type: String },
});

const store = useStore();
const { offerExpressCheckout } = useExpressCheckout(store);

const buttonHeight = props.miniCart ? 42 : 48;
</script>

<template>
  <transition name="fade">
    <div v-if="offerExpressCheckout" data-test="express-checkout">
      <div class="relative flex items-center w-full py-5 text-xs" data-test="divider">
        <div
          class="border-t border-solid grow"
          :class="miniCart ? 'border-gray-600' : 'border-gray-400'"
        />
        <span
          class="mx-2 font-extrabold shrink"
          :class="miniCart ? 'text-gray-600' : 'text-gray-500'"
        >
          EXPRESS CHECKOUT
        </span>
        <div
          class="border-t border-solid grow"
          :class="miniCart ? 'border-gray-600' : 'border-gray-400'"
        />
      </div>
      <div class="items-center">
        <ApplePayButton
          :style="`height: ${buttonHeight}px`"
          :componentName="trackingEventLocation"
          expressCheckout
        />
        <PaypalButton :buttonHeight="buttonHeight" :trackingEventLocation="trackingEventLocation" />
      </div>
      <p
        class="text-xs"
        :class="miniCart ? 'text-gray-600' : 'text-gray-500 mt-4'"
        data-test="disclaimer"
      >
        *Gift options, multiple addresses and other features are unavailable with express checkout
      </p>
    </div>
  </transition>
</template>
