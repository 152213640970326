<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import ExpandableMenuLinks from '@/components/layout/header/ExpandableMenuLinks.vue';
import NavHeader from '@/components/layout/header/mobile/NavHeader.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { mobileAttribution, SmallMenu } from '@/utils/navMenu';

defineProps<{ menu: SmallMenu }>();

const rootElement = ref<HTMLElement>();
useRouterLinks(rootElement, useRouter());
</script>

<template>
  <div ref="rootElement">
    <ExpandableMenuLinks>
      <template #trigger>
        <NavHeader class="justify-between w-full" :header="menu.header" />
      </template>

      <ul v-for="({ group }, index) in menu.linkGroups" :key="index">
        <li v-if="group.headerText">
          <a
            v-if="group.headerUrl"
            :href="group.headerUrl"
            v-bind="mobileAttribution(menu, group.headerText)"
            :data-test="`sub-nav-item-${group.headerText}`"
          >
            <BaseBodyText class="px-4 mx-2 py-2.5 text-sm no-link-styles">
              {{ group.headerText }}
            </BaseBodyText>
          </a>
          <span v-else class="text-base">
            {{ group.headerText }}
          </span>
        </li>

        <li v-for="({ link }, index) in group.links" :key="index">
          <div class="hover:bg-background-secondary hover:cursor-pointer">
            <a :href="link.url" v-bind="mobileAttribution(menu, group.headerText, link.text)">
              <BaseBodyText class="px-4 py-2 mx-2 no-link-styles">{{ link.text }}</BaseBodyText>
            </a>
          </div>
        </li>
      </ul>
    </ExpandableMenuLinks>
  </div>
</template>

<style scoped lang="scss">
.no-link-styles {
  @apply text-black hover:no-underline focus:no-underline cursor-pointer active:no-underline font-normal;
}
</style>
