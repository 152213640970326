<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { AlgoliaVariant, browseProducts, getIndex, init as initAlgolia } from '@/api/algolia';
import AddToCartOrCustomize from '@/components/base/add-to-cart/AddToCartOrCustomize.vue';
import ProductCard from '@/components/base/layout/ProductCard.vue';
import ProductCardWithOptions, {
  Layout,
} from '@/components/product-card/ProductCardWithOptions.vue';
import { getListMetadata, ListMetadata, setListMetadata } from '@/utils/analytics';
import { ProductCard as ProductCardModel, ProductCardData } from '@/utils/productCard';

interface WebstoreTag {
  facetGroup: string;
  hidden: boolean;
  showOnSearchPage: boolean;
  tagName: string;
}
interface Props {
  departmentHierarchy?: string[];
  item: AlgoliaVariant;
  layout?: Layout;
  page: string;
  showB2bProductCard: boolean;
  tags?: WebstoreTag[];
  trackingEventLocation: string;
}
const props = withDefaults(defineProps<Props>(), {
  layout: 'plp',
  showB2bProductCard: false,
  departmentHierarchy: () => [],
  tags: () => [],
});

const route = useRoute();

const existingListMetadata = ref<ListMetadata>();
const itemVariants = ref<ProductCardData[]>();

const breadCrumb = computed(() =>
  props.departmentHierarchy.length > 0
    ? props.departmentHierarchy.join(' > ')
    : props.tags.map((t) => t.tagName).join(' > '),
);

const pageBreadCrumb = computed(() => [props.page, breadCrumb.value].filter((s) => !!s).join(': '));

const isWholeSaleProduct = computed(() => props.item.traits.includes('wholesale'));

const productCardData = computed<ProductCardData>(() => ProductCardModel.fromAlgolia(props.item));

const requestUrl = computed(() => route.fullPath);

const indexName = computed<string>(
  () =>
    (Array.isArray(route.query.sortBy) ? route.query.sortBy[0] : route.query.sortBy) ?? 'Products',
);
// eslint-disable-next-line no-underscore-dangle
const resultPosition = computed(() => props.item.__position);
// eslint-disable-next-line no-underscore-dangle
const searchQueryID = computed(() => props.item.__queryID);
const listMetadata = computed<ListMetadata>(() => ({
  ...existingListMetadata.value,
  list: pageBreadCrumb.value,
  indexName: indexName.value,
  position: resultPosition.value,
  searchQueryID: searchQueryID.value,
}));

const onProductClick = () => {
  setListMetadata(listMetadata.value);
};

const getVariants = async () => {
  if (props.item.hasSiblings) {
    const algoliaClient = initAlgolia();
    const productsIndex = getIndex(algoliaClient, 'Products');
    const algoliaVariants = await browseProducts(productsIndex, {
      filters: `Product.key:${props.item.Product.key}`,
      facetFilters: ['shortVariantName:-sample', 'shortVariantName:-mini box'],
    });
    itemVariants.value = algoliaVariants.map(ProductCardModel.fromAlgolia);
  }
};

onMounted(() => {
  existingListMetadata.value = getListMetadata();
  if (props.showB2bProductCard) getVariants();
});

watch(
  () => props.showB2bProductCard,
  (showB2bProductCard) => {
    if (showB2bProductCard) getVariants();
  },
);
</script>

<script lang="ts">
// eslint-disable-next-line import/first
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const ResultCardRegistration: BuilderComponent = {
  name: 'Result Card',
  inputs: [
    {
      name: 'item',
      type: 'object',
      helperText: 'Algolia search hit',
    },
    {
      friendlyName: '(Optional) Show B2B Product Card',
      name: 'showB2bProductCard',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'page',
      type: 'string',
    },
    {
      name: 'trackingEventLocation',
      helperText: 'Page where the result card is displayed',
      type: 'string',
      advanced: true,
    },
  ],
};
</script>

<template>
  <ProductCardWithOptions
    v-if="showB2bProductCard"
    impressionTrackable
    :index="resultPosition"
    :layout="layout"
    :product="productCardData"
    :title="pageBreadCrumb"
    :trackingEventLocation="trackingEventLocation"
    :variants="itemVariants"
  />
  <ProductCard
    v-else
    class="product-search-hit-card"
    :autoDeliveryEligible="productCardData.autoDeliveryEligible"
    :averageRating="item.Product.reviews.averageRating"
    :cost="item.cost"
    :hidePrice="productCardData.hidePrice"
    :isWholeSaleProduct="isWholeSaleProduct"
    :listingImageUrl="item.Product.listingImageUrl"
    :listMetadata="listMetadata"
    :listName="pageBreadCrumb"
    :merchandisingCategory="item.Product.merchandisingCategory"
    :name="item.Product.name"
    :onePoundBulk="productCardData.onePoundBulk"
    :pageName="trackingEventLocation"
    :path="item.path"
    :piecePrice="productCardData.piecePrice"
    :position="resultPosition"
    :productKey="item.Product.key"
    :promoteShortVariantName="item.promoteShortVariantName"
    :requestUrl="requestUrl"
    :requiresCustomization="item.Product.requiresCustomization"
    :reviewCount="item.Product.reviews.totalReviews"
    :shortDescription="item.Product.shortDescription"
    :shortVariantName="item.shortVariantName"
    :tags="productCardData.totalSavings ? ['badge-onsale'] : item.Product.tags"
    :totalSavings="productCardData.totalSavings"
    :trackingEventLocation="trackingEventLocation"
    :variantSku="item.sku"
    :weight="item.weight"
    clickTrackable
    impressionTrackable
    @click="onProductClick"
  >
    <template v-slot:button>
      <AddToCartOrCustomize
        class="mt-4 mb-2"
        :cost="item.cost"
        :coupon="productCardData.totalSavings?.description?.en"
        :discount="productCardData.totalSavings?.value"
        :indexName="indexName"
        :listMetadata="listMetadata"
        :listName="pageBreadCrumb"
        :merchandisingCategory="item.Product.merchandisingCategory"
        :name="item.Product.name"
        :outOfStock="item.Product_allVariantsOutOfStock"
        :path="item.path"
        :position="resultPosition"
        :productKey="item.Product.key"
        :requiresCustomization="item.Product.requiresCustomization"
        :searchQueryID="searchQueryID"
        :singlePiecePrice="item.singlePiecePrice"
        :trackingEventLocation="trackingEventLocation"
        :variantName="item.shortVariantName"
        :variantSku="item.sku"
        :weight="item.weight"
      />
    </template>
  </ProductCard>
</template>
