<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ThemedButton from '@/components/base/ThemedButton.vue';
import ActionItemsPopover from '@/components/layout/header/ActionItemsPopover.vue';
import ActionItemsText from '@/components/layout/header/ActionItemsText.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCheckout } from '@/composables/useCheckout';
import { useCustomer } from '@/composables/useCustomer';
import { useLoginModal } from '@/composables/useLoginModal';
import { ClickEvent } from '@/utils/browser';

const store = useStore();
const { cxMode } = useCheckout(store);
const { handleOpen } = useLoginModal();
const { navigateTo } = useRouteChange(useRouter());

const { customer } = useCustomer(store);
const isSignedIn = computed(() => !!customer.value || cxMode.value);

const requireUser = (eventOrDestination: ClickEvent | string) => {
  const destination =
    typeof eventOrDestination === 'string'
      ? eventOrDestination
      : eventOrDestination.currentTarget?.getAttribute('href') ?? undefined;
  if (isSignedIn.value) {
    if (!destination) return;
    navigateTo(destination);
  } else {
    handleOpen({ destination });
  }
};

const triggerAttributes: Record<string, any> = {
  'data-test': 'reorder-action-items-trigger',
  'data-promo': 1,
  'data-promo-name': 'Header Links',
  'data-promo-creative': 'Reorder',
};

const breakpoints = useBreakpoints(breakpointsTailwind);
const mobileScreen = computed(() => breakpoints.smaller('lg').value);

const handleClick = () => {
  if (mobileScreen.value) {
    requireUser('/account/easy-reorder');
  }
};
</script>

<template>
  <ActionItemsPopover
    buttonName="Reorder"
    :triggerAttributes="triggerAttributes"
    @click="handleClick"
  >
    <template #trigger>
      <img class="object-contain w-6 h-6" src="@/assets/reorder.svg" alt="Reorder Icon" />
      <ActionItemsText class="mt-1 lg:mt-0 lg:ml-2">Reorder</ActionItemsText>
    </template>
    <div v-if="!isSignedIn" class="w-48 px-4 py-5" data-test="easy-reorder-popup">
      <p class="w-40 text-base force-mb-2">Sign in to see your past purchases</p>
      <a
        href="/account/easy-reorder"
        @click.prevent="requireUser"
        class="hover:no-underline focus:no-underline"
        data-promo="1"
        data-promo-name="Header Links"
        data-promo-creative="Reorder > Sign In"
      >
        <ThemedButton fullWidth theme="gray">Sign In</ThemedButton>
      </a>
    </div>
    <div class="w-48 py-2" data-test="easy-reorder-favorite">
      <a
        href="/account/easy-reorder"
        @click.prevent="requireUser"
        class="flex items-center py-2 pl-5 cursor-pointer hover:bg-background-secondary no-link-styles"
        data-promo="1"
        data-promo-name="Header Links"
        data-promo-creative="Reorder > Link: Easy Reorder"
      >
        <img class="h-6 mr-4" src="@/assets/reorder.svg" alt="Reorder Icon" />
        <p class="text-sm no-mb">Easy Reorder</p>
      </a>
      <a
        href="/account/favorites"
        @click.prevent="requireUser"
        class="flex items-center py-2 pl-5 cursor-pointer hover:bg-background-secondary no-link-styles"
        data-promo="1"
        data-promo-name="Header Links"
        data-promo-creative="Reorder > Link: Favorites"
      >
        <img class="h-6 mr-4" src="@/assets/favorites.svg" alt="Favorites Icon" />
        <p class="text-sm no-mb">Favorites</p>
      </a>
    </div>
  </ActionItemsPopover>
</template>

<style lang="scss" scoped>
.no-mb {
  @apply mb-0;
}
.force-mb-2 {
  @apply mb-2;
}
.no-link-styles {
  @apply text-black;
  @apply hover:no-underline;
  @apply focus:no-underline;
}
</style>
