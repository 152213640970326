import {
  Attribute,
  Category as CTCategory,
  Channel,
  Image as CTImage,
  LocalizedString,
  Price as CTPrice,
  Product as CTProduct,
  ProductVariant,
  TypedMoney,
} from '@commercetools/platform-sdk';

import { DateString } from '@/utils/dateTime';

const relatedProductsFragments = `
fragment relatedProductsFields on Product {
  id
  masterData {
    current {
      categories {
        ...categoryFields
      }
      masterVariant {
        id
        sku
        attributesRaw(includeNames: [
          "primaryCategory",
          "urlName",
          ]) {
          name
          value
        }
      }
      name(locale: "en")
    }
  }
}`;

export const variantFragments = `
fragment variantFields on ProductVariant {
  id
  sku
  prices {
    discounted {
      discount {
        description(locale: $locale)
        id
        name(locale: $locale)
        validUntil
      }
      value {
        centAmount
        currencyCode
        fractionDigits
        type
      }
    }
    tiers {
      minimumQuantity
      value {
        centAmount
        currencyCode
        fractionDigits
        type
      }
    }
    value {
      centAmount
      currencyCode
      fractionDigits
      type
    }
    channel {
      key
    }
  }
  attributesRawRelatedProducts: attributesRaw(includeNames: [
    "upsellingProducts",
  ]) {
    name
    referencedResourceSet {
      ... on Product {
        ...relatedProductsFields
      }
    }
  }
  attributesRaw(includeNames: [
    "primaryCategory",
    "variantName",
    "active",
    "bagSize",
    "bulk",
    "countPerPound",
    "countryOfOrigin",
    "customPageTitle",
    "customProduct",
    "googleProductCategory",
    "merchandisingCategory",
    "grindSize",
    "hiddenFromSearch",
    "maximumPiecesPerOrder",
    "ingredients",
    "tinColor",
    "wholesale",
    "sample",
    "snackPack",
    "healthTips",
    "servingsPerContainer",
    "storageRequirements",
    "backordered",
    "backorderedUntil",
    "weight",
    "autoDeliveryEligible",
    "urlName",
    "customPageTitle",
    "pieceCost",
    "oneLinerDescription",
    "testDescription",
    "contentList"
    ]) {
    name
    value
  }
  images {
    url
    label
  }
}
${relatedProductsFragments}`;

const detailedFragments = `
fragment productFields on Product {
  id
  key
  masterData {
    current {
      name(locale: $locale)
      description(locale: $locale)
      metaDescription(locale: $locale)
      categories {
        ...categoryFields
      }
      masterVariant {
        ...variantFields
      }
      variants {
        ...variantFields
      }
    }
  }
}

fragment categoryFields on Category {
  id
  version
  key
  name(locale: $locale)
  custom {
    customFieldsRaw(includeNames: ["urlName"]) {
      name
      value
    }
  }
  ancestors {
    id
    name(locale: $locale)
    custom {
      customFieldsRaw(includeNames: ["urlName"]) {
        name
        value
      }
    }
  }
}
${variantFragments}
`;

interface Price extends Pick<CTPrice, 'tiers' | 'value'> {
  channel?: Pick<Channel, 'key'> | null;
  discounted?: {
    discount: {
      description: string | null;
      id: string;
      name: string | null;
      validUntil: DateString | null;
    };
    value: TypedMoney;
  } | null;
}

export interface RelatedVariant extends Pick<ProductVariant, 'id' | 'sku'> {
  attributesRaw: Attribute[];
}

interface Custom {
  customFieldsRaw: { name: string; value: string }[];
}

interface Ancestor extends Pick<CTCategory, 'id'> {
  custom?: Custom | null;
  name: string;
}

interface Category extends Pick<CTCategory, 'id' | 'key' | 'version'> {
  ancestors: Ancestor[];
  custom?: Custom | null;
  name: string;
}

export interface RelatedProduct extends Pick<CTProduct, 'id' | 'key'> {
  masterData: {
    current: {
      name: string;
      masterVariant: RelatedVariant;
      categories: Category[];
    };
  };
}

export type AttributeResourceSet = {
  name: string;
  referencedResourceSet: RelatedProduct[];
};

export interface Variant extends Pick<ProductVariant, 'id' | 'sku'> {
  attributesRaw: Attribute[];
  attributesRawRelatedProducts?: AttributeResourceSet[];
  images?: Pick<CTImage, 'label' | 'url'>[];
  prices: Price[];
}

export interface ProductData {
  categories: Category[];
  contentList?: string;
  crossSellingProducts?: RelatedProduct[];
  customPageTitle?: LocalizedString;
  description?: string;
  healthTips?: string;
  hiddenFromSearch?: boolean;
  masterVariant: Variant;
  metaDescription?: string;
  name: string;
  testDescription?: string;
  upsellingProducts?: RelatedProduct[];
  variants: Variant[];
}

export interface Product extends Pick<CTProduct, 'id' | 'key'> {
  masterData: {
    current: ProductData;
  };
}

export interface ProductQueryResult {
  data: {
    product: Product;
  };
}

export function productsByCategoryQuery(id: any) {
  return `query ProductsByPrimaryCategory($locale: Locale!) {
    products (where:"masterData(current(categories(id in (\\"${id}\\")))) and masterData(published=true) and (masterData(current(masterVariant(attributes(name=\\"active\\" and value=true)))) or masterData(current(variants(attributes(name=\\"active\\" and value=true)))))") {
     total
     count
     results {
        ...productFields
     }
  }
}

${detailedFragments}
`;
}

export const productQuery = `query Product($locale: Locale!, $key: String) {
  product(key: $key) {
    ...productFields
  }
}

${detailedFragments}
`;

export const productQueryById = `query Product($locale: Locale!, $id: String) {
  product(id: $id) {
    ...productFields
  }
}

${detailedFragments}
`;

export const productQueryBySku = `query Product($locale: Locale!, $sku: String) {
  product(sku: $sku) {
    ...productFields
  }
}

${detailedFragments}
`;
