/* eslint-disable max-classes-per-file */
class ClientSidePageContext {
  constructor(type, data = []) {
    this.type = type;
    this.data = data;
  }

  get() {
    return {
      type: this.type,
      data: this.data,
    };
  }
}

class ServerSidePageContext extends ClientSidePageContext {
  constructor(type, data = [], location = null, referrer = null, locale = 'en_US') {
    super(type, data);

    this.location = location;
    this.referrer = referrer;
    this.locale = locale;
  }

  get() {
    return {
      type: this.type,
      data: this.data,
      location: this.location,
      referrer: this.referrer,
      locale: this.locale,
    };
  }
}

class DeviceContext {
  constructor(ip, userAgent) {
    this.ip = ip;
    this.userAgent = userAgent;
  }

  get() {
    return {
      ip: this.ip,
      userAgent: this.userAgent,
    };
  }
}

class PageAttributes {
  constructor(attributes) {
    this.attributes = attributes;
  }

  get() {
    return this.attributes;
  }
}

class Options {
  constructor(reportPageView = true, returnAnalyticsMetadata = true) {
    this.isImplicitPageview = reportPageView;
    this.returnAnalyticsMetadata = returnAnalyticsMetadata;
  }

  get() {
    return {
      isImplicitPageview: this.isImplicitPageview,
      returnAnalyticsMetadata: this.returnAnalyticsMetadata,
    };
  }
}

class Context {
  constructor(pageContext, deviceContext, pageAttributes) {
    this.pageContext = pageContext;
    this.deviceContext = deviceContext;
    this.pageAttributes = pageAttributes;
  }

  get() {
    return {
      page: this.pageContext.get(),
      device: this.deviceContext.get(),
      pageAttributes: this.pageAttributes.get(),
    };
  }
}

export {
  ClientSidePageContext,
  ServerSidePageContext,
  DeviceContext,
  PageAttributes,
  Context,
  Options,
};
