<script setup lang="ts">
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { getPredictedShippingCutoff, ShippingCutoff } from '@/api/predictedShipping';
import brokenPeanut from '@/assets/broken-peanut.png';
import CheckmarkIcon from '@/components/base/assets/CheckmarkIcon.vue';
import Modal from '@/components/base/layout/Modal.vue';
import PredictedShippingCutoffText from '@/components/base/PredictedShippingCutoffText.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import Skeleton from '@/components/base/Skeleton.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Header4 from '@/components/base/typography/Header4.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import LargeBodyText from '@/components/base/typography/LargeBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import QuantitySelector from '@/components/cart/QuantitySelector.vue';
import ShippingMessage from '@/components/cart/ShippingMessage.vue';
import VerticalProductRecommendations from '@/components/recommendations/VerticalProductRecommendations.vue';
import { useCurrentRoute } from '@/composables/navigation/useCurrentRoute';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { useCheckout } from '@/composables/useCheckout';
import { useDelivery } from '@/composables/useDelivery';
import { hasProducts, useDynamicYield } from '@/composables/useDynamicYield';
import { usePayment } from '@/composables/usePayment';
import { useProceedToCheckout } from '@/composables/useProceedToCheckout';
import { CartLineItemFields } from '@/store/modules/cart';
import { formatPurchaseItem, gtag } from '@/utils/analytics';
import { NutsLineItem } from '@/utils/cart';
import { Money } from '@/utils/money';

const props = defineProps<{
  isOpen: boolean;
  lineItem: NutsLineItem<CartLineItemFields>;
}>();

const emit = defineEmits(['handle-close']);

const route = useRoute();
const router = useRouter();
const store = useStore();

const {
  lastQuantityAdded,
  loadLineItemExpansions,
  lineItems,
  preCheckoutLineItems,
  productKeysById,
  updateLineItem,
} = useCart(store);
const { estimatedAmountToCharge } = useCheckout(store);
const { dyRows, loadRecommendationRows, setDyPageContext, getContext } = useDynamicYield(
  store,
  '[CONFIG] Cart Added - Rows',
);
const { navigateTo } = useRouteChange(router);
const { path } = useCurrentRoute(store.state.requestObj.url, route);

const { estimatedTotal } = usePayment(store, ref(false));
const { handleProceedToCheckout, isPending } = useProceedToCheckout();
const { freeShippingThreshold } = useDelivery(store);

const quantityInCart = computed(() => {
  const lineItem = preCheckoutLineItems.value.find((li) => li.id === props.lineItem.id);
  return lineItem?.quantity ?? props.lineItem.quantity;
});

const quantity = computed({
  get: () => lastQuantityAdded.value,
  set: (val) => {
    const difference = quantityInCart.value - props.lineItem.quantity;
    updateLineItem(props.lineItem.id, {
      quantity: val + difference,
    });
    lastQuantityAdded.value = val;
  },
});
const maximumPiecesPerOrder = computed<number | undefined>(
  () => props.lineItem.variant.attributes?.find((a) => a.name === 'maximumPiecesPerOrder')?.value,
);

const totalListPriceBeforeCartLevelDiscount = computed(() => {
  const listPrice = props.lineItem.variant.prices!.find((p) => !p.channel)!;
  const { value } =
    listPrice.tiers?.filter((t) => props.lineItem.quantity >= t.minimumQuantity).pop() ?? listPrice;

  return Money.multiply(value, props.lineItem.quantity);
});

const loadExpansions = useCallback(async () => {
  await loadLineItemExpansions();
});

const shippingCutoff = ref<ShippingCutoff>();

const loadRecommendationsRows = useCallback(async () => {
  const oldPageInfo = getContext.value;
  const data = [`0${props.lineItem.variant.sku}`];
  setDyPageContext({ type: 'CART', data, path: path.value });
  await loadRecommendationRows();
  setDyPageContext(oldPageInfo);
});

const refreshContent = async () => {
  await loadExpansions.execute();
  shippingCutoff.value = await getPredictedShippingCutoff();
  if (typeof window !== 'undefined') {
    window.globalShippingCutoff = shippingCutoff.value;
  }
};

onMounted(() => {
  refreshContent();
  loadRecommendationsRows.execute();
  gtag('event', 'view_cart', {
    location: path.value,
    currency: 'USD',
    value: money(estimatedAmountToCharge?.value),
    items: lineItems.value.map((li) => formatPurchaseItem(li, productKeysById.value)),
  });
});
watch(() => props.lineItem.id, refreshContent);

const handleClose = () => {
  emit('handle-close');
};

const onViewCart = async () => {
  await navigateTo('/cart');
  handleClose();
};

const onProceedToCheckout = async () => {
  await handleProceedToCheckout.execute({
    onSuccess: handleClose,
  });
};

const closeIcon = nutshell['img/close.svg'];
</script>

<template>
  <Modal
    anchor="right"
    aria-label="cart added modal"
    data-test="cart-added-modal"
    borderRadius="rounded-t-lg md:rounded-none"
    :hasControls="false"
    :hasDefaultPadding="false"
    :isOpen="isOpen"
    width="w-full md:max-w-lg"
    @handle-close="handleClose"
  >
    <template v-if="shippingCutoff" #close-button>
      <UnstyledButton
        class="absolute flex items-center justify-center w-6 h-6 overflow-hidden rounded top-8 md:top-12 right-4"
        @click="handleClose"
      >
        <img
          alt="close"
          class="h-3.5 w-3.5 md:w-4 md:h-4 shrink-0"
          data-test="close-modal"
          :src="closeIcon"
        />
      </UnstyledButton>
    </template>
    <template #body>
      <div v-if="lineItem" class="flex flex-col overflow-hidden h-80vh" data-test="modal-content">
        <BaseBodyText
          v-if="shippingCutoff"
          class="py-px text-center bg-nuts-amber-200 md:py-1"
          data-test="predicted-shipping-message"
        >
          <PredictedShippingCutoffText :shippingCutoff="shippingCutoff" />
        </BaseBodyText>

        <!-- Header -->
        <div class="flex items-center px-4 mt-3 md:mt-5" data-test="cart-modal-header">
          <CheckmarkIcon
            aria-hidden="true"
            class="text-green"
            data-test="check-mark"
            :size="[24, 32]"
          />
          <Header4 class="ml-2">Added to Cart</Header4>
        </div>

        <!-- Image, quantity and price -->
        <div
          class="inline-flex items-center justify-between w-full px-4 mt-4"
          data-test="inline-product"
        >
          <div class="flex items-center">
            <RouteLink
              :to="lineItem.productPath ?? '/'"
              class="flex items-center justify-center overflow-hidden rounded shrink-0"
              @click="handleClose"
            >
              <Skeleton
                v-if="loadExpansions.isPending && !lineItem.titleImage"
                class="w-24 h-16 md:w-[120px] md:h-20 shrink-0"
              />
              <img
                v-else-if="lineItem.titleImage"
                :alt="`${lineItem.name.en} photo`"
                class="object-contain w-16 h-12 md:w-24 md:h-16 shrink-0"
                :src="lineItem.titleImage.small"
              />
              <img
                v-else
                alt="placeholder image"
                class="object-contain w-16 h-12 md:w-24 md:h-16 shrink-0"
                :src="brokenPeanut"
              />
            </RouteLink>
            <QuantitySelector
              v-model="quantity"
              class="ml-3 max-w-[7rem] min-w-[7rem]"
              hasBorderRadius
              :id="lineItem.id"
              :maximumPiecesPerOrder="maximumPiecesPerOrder"
              data-test="quantity-selector"
            />
          </div>
          <div class="flex items-center" data-test="inline-product-price">
            <Header5
              :class="lineItem.totalSavings?.onSale ? 'text-error' : 'text-nuts-neutral-800'"
            >
              {{ money(lineItem.totalPriceBeforeCartLevelDiscount) }}
            </Header5>
            <SmallBodyText
              v-if="lineItem.totalSavings"
              class="ml-1.5 line-through text-nuts-neutral-800"
              data-test="strike-through-price"
            >
              {{
                money(
                  lineItem.totalSavings?.comparisonPrice ?? totalListPriceBeforeCartLevelDiscount,
                )
              }}
            </SmallBodyText>
          </div>
        </div>

        <ShippingMessage
          class="mt-2 md:mt-4"
          :freeShippingThreshold="freeShippingThreshold"
          stackVertically
          :totalPrice="estimatedTotal"
        />

        <div class="relative px-4 py-4 md:mt-2" data-test="frequently-bought">
          <Header5>Frequently bought together</Header5>
          <div class="absolute inset-0 shadow-md" />
        </div>
        <div class="flex-1 overflow-auto">
          <div v-if="loadRecommendationsRows.isPending">
            <div
              v-for="item in 12"
              :key="`recommendation-${item}`"
              class="z-10 px-4 py-3 border-b border-solid shrink-0 md:py-2 border-neutral-300"
            >
              <Skeleton class="h-20 md:h-24" />
            </div>
          </div>
          <div v-for="row in dyRows" :key="row.dySelector">
            <div v-if="row.type === 'recommendations'">
              <div v-if="hasProducts(row.variation)">
                <VerticalProductRecommendations
                  :analyticsList="row.analyticsList"
                  :enableAddToCart="row.enableAddToCart"
                  :list="row.variation"
                  :title="row.title"
                  trackingEventLocation="CART"
                  @click="handleClose"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Footer & CTAs -->
        <div class="relative p-4 pt-2 bg-white md:p-4">
          <div class="absolute inset-0 pointer-events-none footer-shadow" />
          <div class="flex items-center justify-between" data-test="estimated-total">
            <div class="flex items-center">
              <Header5>Estimated total</Header5>
              <BaseBodyText class="ml-1.5">
                ({{ lineItems.length }} item{{ lineItems.length > 1 ? 's' : '' }})
              </BaseBodyText>
            </div>
            <LargeBodyText>{{ money(estimatedTotal) }}</LargeBodyText>
          </div>
          <div class="flex items-center mt-2 md:mt-4 gap-2.5">
            <ThemedButton
              class="h-11 md:h-12 grow"
              data-test="view-cart"
              :disabled="isPending"
              theme="white"
              @click="onViewCart"
            >
              View Cart
            </ThemedButton>
            <ThemedButton
              class="h-11 md:h-12 grow"
              data-test="proceed-to-checkout"
              :isLoading="isPending"
              theme="gray"
              @click="onProceedToCheckout"
            >
              <span class="hidden mr-1 md:visible md:block">Proceed to</span>
              Checkout ({{ lineItems.length }} item{{ lineItems.length > 1 ? 's' : '' }})
            </ThemedButton>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.footer-shadow {
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px -8px 15px -3px rgba(0, 0, 0, 0.1);
}

.h-80vh {
  height: 80vh;
  @media screen and (min-width: 768px) {
    height: 100vh;
  }
}

:deep(.right-anchor-height) {
  overflow: hidden;
}
</style>
