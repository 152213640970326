/* eslint-disable import/prefer-default-export */
import { LocalizedString, Money } from '@commercetools/platform-sdk';
import { cents, fromCents } from '@nuts/auto-delivery-sdk/dist/utils/money';
import sumBy from 'lodash/sumBy';

export interface LineSavingsBreakdownItem {
  /** Type of savings represented by this item (names are CT type names where possible) */
  readonly type: 'Channel' | 'ProductDiscount' | 'PriceTier' | 'CartDiscount';
  /** Optional savings description to show near pricing, e.g. Product Discount's description */
  readonly description?: LocalizedString;
  /** Whether to visually highlight using our sale-specific styling (new price in red, pink backgrounds, etc) */
  readonly onSale: boolean;
  /** Integer percentage. Rounded down for now, but maybe within the breakdown it needn't be? */
  readonly percent: number;
  /** Amount saved */
  readonly value: Money;
}

/**
 * Summary of savings affecting the final price of a line item.
 *
 * This represents an aggregation of multiple types of savings, if applicable.
 * For example, a single line item might get 5% off for Auto-Delivery and 3% off
 * of _that_ via a Price Tier. Together these will yield 7.85% off (not 8%!)
 * which will be rounded down to `7` in this {@link percent} field.
 */
export interface LineSavingsSummary {
  /** Breakdown of types of savings contributing; note that percentages won't add up because they generally apply sequentially */
  readonly breakdown: LineSavingsBreakdownItem[];
  /** Price to show struck-through */
  readonly comparisonPrice: Money;
  /** Optional savings description to show near pricing, e.g. Product Discount's description */
  readonly description?: LocalizedString;
  /** Whether to visually highlight using our sale-specific styling (new price in red, pink backgrounds, etc) */
  readonly onSale: boolean;
  /** Integer percentage. Rounded down, i.e. Math.floor({@link value} / {@link comparisonPrice}) */
  readonly percent: number;
  /** Amount saved */
  readonly value: Money;
}

export interface CartSavingsSummaryBreakdownItem {
  /** Savings description to show near pricing, e.g. type of savings or Product Discount's description */
  readonly description: LocalizedString;
  /** Whether to visually highlight using our sale-specific styling (new price in red, pink backgrounds, etc) */
  readonly onSale: boolean;
  /** Amount saved */
  readonly value: Money;
}

export interface CartSavingsSummary {
  /** Breakdown of types of savings contributing */
  readonly breakdown: CartSavingsSummaryBreakdownItem[];
  /** Amount saved */
  readonly value: Money;
}

type MoneyWithCompanion = Money;
const MoneyWithCompanion = {
  add: (a: Money, b: Money) => fromCents(a.centAmount + b.centAmount),
  equals: (a: Money, b: Money) => a.centAmount === b.centAmount,
  min: (...amounts: Money[]) => fromCents(Math.min(...amounts.map((a) => a.centAmount))),
  multiply: (a: Money, b: number) => fromCents(Math.round(a.centAmount * b)),
  negate: (a: Money) => fromCents(-a.centAmount),
  subtract: (a: Money, b: Money) => fromCents(a.centAmount - b.centAmount),
  sum: (amounts: Money[]) => fromCents(sumBy(amounts, (a) => a.centAmount)),
  sumBy: <T>(collection: T[] | undefined, iteratee: (t: T) => Money | undefined) =>
    fromCents(sumBy(collection, (t) => iteratee(t)?.centAmount ?? 0)),
};

export function computeSavings(higher: Money, lower: Money) {
  const ROUNDING_ERROR_BIAS = 0.02;
  const value = MoneyWithCompanion.subtract(higher, lower);
  const percent = Math.floor((cents(value) / cents(higher)) * 100 + ROUNDING_ERROR_BIAS);
  return { percent, value };
}

export { MoneyWithCompanion as Money };
