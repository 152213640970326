import { computed } from 'vue';
import { CallbackTypes, googleLogout } from 'vue3-google-login';
import { Store } from 'vuex';

import { fromNutsJson, FromNutsJsonOptions, NutsJson } from '@/api';
import {
  appleSignIn,
  createCustomer,
  facebookSignIn,
  googleSignIn,
  NewCustomer,
  sendSignInLinkEmail,
  signIn,
  signInByLinkKey,
  SignInOptions,
  signOut,
  signUp,
  SocialSignInOptions,
  WebstoreCustomerSignInResult,
  WebstoreSignUpCustomer,
  WebstoreSignUpOptions,
} from '@/api/customer';
import { useCart } from '@/composables/useCart';
import { useCustomer } from '@/composables/useCustomer';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { Permissions } from '@/store/modules/session';
import { useNotifications } from '@/stores/notifications';

export function useAuth(store: Store<any>) {
  const { setCart, setSafcToken } = useCart(store);
  const { setCustomer, setCustomerInfo, setBusinessAccountInfo } = useCustomer(store);
  const { replaceFlagsByLayer } = useFeatureFlags(store);
  const { addNotifications } = useNotifications();

  async function handleSignInResult(
    promise: Promise<NutsJson<WebstoreCustomerSignInResult>>,
    onMessages?: FromNutsJsonOptions['onMessages'],
    triggerSetPasswordFlow?: boolean,
  ) {
    const {
      cart,
      customer,
      businessAccountInfo,
      newsletterStatus = {},
      flagsByLayer,
    } = await fromNutsJson(promise, {
      onMessages: onMessages ?? addNotifications,
      throwError: true,
    });

    setCart(cart);

    if (!customer) {
      throw new Error('No errors but no customer?');
    }

    setCustomer(customer);
    if (businessAccountInfo) {
      setBusinessAccountInfo(businessAccountInfo);
    }
    if (!triggerSetPasswordFlow) {
      store.commit('sessionModule/SET_EMAIL', customer.email);
      store.dispatch('headerModule/setNewsletterStatus', newsletterStatus);
    }

    if (flagsByLayer) {
      replaceFlagsByLayer(flagsByLayer);
    }

    return customer;
  }

  const permissions = computed<Permissions>(() => store.state.sessionModule.permissions);

  const sessionEmail = computed<string | undefined>(
    () => store.state.sessionModule.email ?? undefined,
  );

  return {
    permissions,
    sessionEmail,

    async createCustomer(newCustomer: NewCustomer, onMessages?: FromNutsJsonOptions['onMessages']) {
      return handleSignInResult(
        createCustomer(newCustomer),
        onMessages,
        newCustomer.triggerSetPasswordFlow,
      );
    },

    async signIn(
      email: string,
      password: string,
      options?: SignInOptions,
      onMessages?: FromNutsJsonOptions['onMessages'],
    ) {
      return handleSignInResult(signIn(email, password, options), onMessages);
    },

    async signUp(
      customer: WebstoreSignUpCustomer,
      captchaResponse: string,
      options?: WebstoreSignUpOptions,
      onMessages?: FromNutsJsonOptions['onMessages'],
    ) {
      const result = await handleSignInResult(
        signUp(customer, captchaResponse, options),
        onMessages,
      );

      return result;
    },

    async appleCodeSignIn(
      data: {
        authorization: { code: string };
        user?: {
          email: string;
          name: { firstName: string; lastName: string };
        };
      },
      options?: SocialSignInOptions,
    ) {
      return handleSignInResult(
        appleSignIn(
          {
            code: data.authorization.code,
            email: data.user?.email,
            firstName: data.user?.name.firstName,
            lastName: data.user?.name.lastName,
          },
          options,
        ),
      );
    },

    async facebookCodeSignIn(data: any, options?: SocialSignInOptions) {
      return handleSignInResult(
        facebookSignIn({ accessToken: data.accessToken, userID: data.userID }, options),
      );
    },

    async googleCodeSignIn(data: CallbackTypes.CodePopupResponse, options?: SocialSignInOptions) {
      return handleSignInResult(googleSignIn({ code: data.code }, options));
    },

    async googleCredentialSignIn(
      // eslint-disable-next-line camelcase
      { credential, select_by }: CallbackTypes.CredentialPopupResponse,
      options?: SocialSignInOptions,
    ) {
      // eslint-disable-next-line camelcase
      return handleSignInResult(googleSignIn({ credential, selectBy: select_by }, options));
    },

    async sendSignInLinkEmail(email: string, redirectPath: string) {
      const response = (await sendSignInLinkEmail(email, redirectPath)) ?? {};
      return fromNutsJson(response);
    },

    async signOut(onMessages?: FromNutsJsonOptions['onMessages']) {
      const { cart } = await fromNutsJson(signOut(true), {
        onMessages: onMessages ?? addNotifications,
        throwError: true,
      });
      setCustomerInfo();
      setCart(cart);
      store.commit('sessionModule/SET_PERMISSIONS', {});
      store.commit('sessionModule/SET_EMAIL', null);
      setSafcToken();
      googleLogout();
      window.google?.accounts.id.disableAutoSelect();
    },

    async signInByLinkKey(key: string, options?: Omit<SignInOptions, 'keepLoggedIn'>) {
      return handleSignInResult(signInByLinkKey(key, options));
    },
  };
}

export default {};
