import { ClientResponse, PagedQueryResponse } from '@commercetools/platform-sdk';

import { ctApi } from '@/api';
import { ProductKeysById } from '@/graphql/productKeysById';

export interface ProductKeysByIdQueryResults {
  data: {
    products: Pick<PagedQueryResponse, 'count' | 'total'> & {
      results: { id: string; key: string }[];
    };
  };
}

export async function productKeysById(ids: string[]) {
  const keysById: { [productId: string]: string } = {};

  try {
    const {
      body: { data },
    } = (await ctApi
      .graphql()
      .post({
        body: {
          query: ProductKeysById,
          variables: {
            idsClause: `id in ("${ids.join('","')}")`,
          },
        },
      })
      .execute()) as unknown as ClientResponse<ProductKeysByIdQueryResults>;
    data.products.results.forEach((partialProduct) => {
      const { id, key } = partialProduct;
      keysById[id] = key;
    });
  } catch (_) {
    console.error('Failed to fetch product keys for ids: %o', ids);
  }

  return keysById;
}
