<template>
  <div data-test="digital-gifting-recipients-summary">
    <div
      v-for="(chunk, chunkIndex) in paginatedRecipients"
      :key="chunkIndex"
      class="divide-y divide-solid divide-true-gray-300"
      :class="visibilityCssClass(chunkIndex + 1)"
    >
      <Recipient
        v-for="(recipient, recipientIndex) in chunk"
        :key="recipientNumber(chunkIndex, recipientIndex)"
        :editable="false"
        :preview="false"
        :recipient="recipient"
        :recipientNumber="recipientNumber(chunkIndex, recipientIndex)"
        class="py-4"
      />
    </div>
    <Pagination v-if="showPagination" :pages="paginatedRecipients.length" v-model="currentPage" />
  </div>
</template>

<script lang="ts">
import chunk from 'lodash/chunk';
import { computed, defineComponent, PropType, ref } from 'vue';

import Pagination from '@/components/base/layout/Pagination.vue';
import Recipient from '@/components/digital-gift/gifter/recipients/Recipient.vue';
import { DigitalGiftRecipient } from '@/utils/cart';

export default defineComponent({
  name: 'RecipientsSummary',
  props: {
    summary: { required: true, type: Array as PropType<DigitalGiftRecipient[]> },
  },
  components: {
    Pagination,
    Recipient,
  },
  setup(props) {
    const pageSize = ref(5);
    const currentPage = ref(1);

    const paginatedRecipients = computed(() => chunk(props.summary, pageSize.value));
    const showPagination = computed(() => paginatedRecipients.value?.length > 1);

    const visibilityCssClass = (page: number) => (page !== currentPage.value ? 'hidden' : '');
    const recipientNumber = (pageIndex: number, recipientIndex: number) =>
      pageIndex * pageSize.value + recipientIndex + 1;

    return {
      currentPage,
      paginatedRecipients,
      recipientNumber,
      showPagination,
      visibilityCssClass,

      pageSize, // for test purposes
    };
  },
});
</script>
