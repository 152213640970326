import { Image } from '@commercetools/platform-sdk';

import imageUtil from '@/utils/image';

type PartialImage = Pick<Image, 'url'>;

const proxiedImageUtil = {
  getVariants(images: PartialImage[] = []) {
    if (!images) return images;
    return imageUtil.getVariants(
      images.map((image) => ({
        ...image,
        url: image.url.replace('://', '/images/rackcdn/'),
      })),
    );
  },
};

export default proxiedImageUtil;
