<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import RouteLink from '@/components/base/RouteLink.vue';
import AnnouncementBar from '@/components/layout/header/AnnouncementBar.vue';
import HelpPopover from '@/components/layout/header/HelpPopover.vue';
import { useCheckout } from '@/composables/useCheckout';
import { useCustomer } from '@/composables/useCustomer';
import { useFeatureFlags } from '@/composables/useFeatureFlags';

defineProps<{
  announcements?: { htmlAnnouncements: string; codeAnnouncements?: string }[];
}>();

const store = useStore();
const { flags } = useFeatureFlags(store);
const { cxMode } = useCheckout(store);
const { customer, customerOrdersCount, hasBusinessAccount } = useCustomer(store);
const isSignedIn = computed(() => !!customer.value || cxMode.value);
const trackingLink = computed(() => (isSignedIn.value ? '/account/order-history' : '/orders'));
const showHelpPopup = ref(false);
const toggleShowHelpPopup = () => {
  showHelpPopup.value = !showHelpPopup.value;
};
const popup = ref<HTMLElement>();
onClickOutside(popup, () => {
  showHelpPopup.value = false;
});
</script>

<template>
  <div class="bg-black">
    <div
      class="flex justify-center w-full px-10 mx-auto lg:justify-between h-7 2xl:max-w-screen-2xl 2xl:px-0"
      data-test="top-shelf"
    >
      <div class="hidden w-1/6 h-full lg:flex" data-test="brand-logo">
        <RouteLink
          :to="hasBusinessAccount ? '/business' : '/business-signup'"
          class="flex items-center self-center justify-center px-2 text-sm text-white h-1/2 hover:no-underline utility-links shrink-0 w-fit"
          :class="{ 'text-black bg-black': true }"
        >
          <span class="flex text-white" :class="{ 'opacity-100': true }">
            <img src="@/assets/search/nuts-business-icon.svg" alt="paper plane icon" class="mr-1" />
            For Business
          </span>
        </RouteLink>
      </div>
      <AnnouncementBar :announcements="announcements" />
      <div class="justify-end hidden w-1/6 h-full lg:flex" data-test="track-order">
        <RouteLink
          v-if="flags.referral && isSignedIn && customerOrdersCount > 0"
          to="/account/refer"
          class="relative flex items-center justify-center h-full px-2 text-sm text-white no-underline utility-links shrink-0 w-fit"
        >
          <span class="text-white cursor-pointer hover:opacity-70"> Refer a Friend, Get $10 </span>
        </RouteLink>
        <RouteLink
          :to="trackingLink"
          class="relative flex items-center justify-center h-full px-2 text-sm text-white no-underline utility-links shrink-0 w-fit"
        >
          <span class="text-white cursor-pointer hover:opacity-70"> Track Order </span>
        </RouteLink>
        <div
          class="relative flex items-center justify-center h-full pl-2 text-sm text-white no-underline utility-links shrink-0 w-fit"
          ref="popup"
          data-test="help-menu"
        >
          <span @click="toggleShowHelpPopup" class="text-white cursor-pointer hover:opacity-70">
            Help <img src="@/assets/chevron_down.svg" alt="paper plane icon" class="ml-2" />
          </span>
          <HelpPopover v-show="showHelpPopup" ref="popUp" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.utility-links {
  text-decoration: none;
}

.hidden {
  visibility: visible;
}
</style>
