<script setup lang="ts">
import { useScrollLock } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { onMounted, watch } from 'vue';

import { useOverlay } from '@/stores/overlay';

const { hide } = useOverlay();
const { visible } = storeToRefs(useOverlay());

onMounted(() => {
  const isLocked = useScrollLock(document.querySelector('body'));
  // eslint-disable-next-line no-return-assign
  watch(visible, (b) => (isLocked.value = b));
});
</script>

<template>
  <div
    class="fixed left-0 top-0 bottom-0 right-0 bg-black/40"
    :class="{ hidden: !visible }"
    @click="hide"
  />
</template>
