<template>
  <div class="category-filter-control-panel">
    <h2 class="mb-10 visible-xs visible-sm">Choose a Category</h2>
    <div class="mobile-scroll-snap-container">
      <div
        v-for="category in categoryTree"
        :key="category.filter"
        class="category-filter-control"
        :class="{ active: category.active }"
        :data-test="category.filter"
      >
        <h3 @click.prevent="toggleFilter(category.filter)">
          <label>
            {{ category.name }}
            <input type="checkbox" :id="`category-filter-${category.filter}`" class="sr-only" />
          </label>
        </h3>
        <div
          v-for="subcategory in category.children"
          :key="subcategory.filter"
          v-show="category.active"
          class="subcategory-filter-control hidden-xs hidden-sm"
          :class="{ active: subcategory.active }"
          :data-test="subcategory.filter"
        >
          <h4 @click.prevent="toggleFilter(subcategory.filter)">
            <label>
              {{ subcategory.name }}
              <input
                type="checkbox"
                :id="`subcategory-filter-${subcategory.filter}`"
                class="sr-only"
              />
            </label>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CategoryFilterControlPanel',
  props: {
    catalogCategoryTree: { required: true, type: Array, default: () => [] },
  },
  computed: {
    ...mapState('customTraysModule', ['activeFilters']),
    categoryTree() {
      return this.catalogCategoryTree.map((category) => {
        const childrenWithActive = category.children.map((child) => ({
          ...child,
          active: this.activeFilters.includes(child.filter),
        }));
        return {
          ...category,
          active:
            this.activeFilters.includes(category.filter) ||
            childrenWithActive.find((child) => child.active),
          children: childrenWithActive,
        };
      });
    },
  },
  methods: {
    ...mapActions('customTraysModule', ['toggleActiveCategoryFilter']),
    toggleFilter(filter) {
      this.toggleActiveCategoryFilter(filter);
      this.$emit('filters:changed');
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-family: $font-family-proxima;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
}
h4 {
  font-family: $font-family-proxima;
  font-size: 18px;
  line-height: 1.22;
}
</style>
