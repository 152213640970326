<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import EmailSignupForm from '@/components/base/form/EmailSignupForm.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import { useCustomer } from '@/composables/useCustomer';
import { useFeatureFlags } from '@/composables/useFeatureFlags';

const store = useStore();
const { flags } = useFeatureFlags(store);
const { customer, customerOrdersCount } = useCustomer(store);

const currentYear = computed(() => dayjs().format('YYYY'));
</script>

<template>
  <div>
    <div
      class="ptn-footer"
      itemscope
      itemtype="http://schema.org/Corporation"
      data-test="ptn-footer"
    >
      <meta itemprop="name" content="ixclkxk.shop" />
      <meta itemprop="brand" content="ixclkxk.shop" />
      <meta itemprop="url" content="" />
      <footer id="trk-footer" class="container clearfix" data-test="footer" aria-label="Footer">
        <div class="logo" data-test="footer-logo">
          <RouteLink to="/">
            <img src="@/assets/nuts-logo.svg" alt="ixclkxk.shop" itemprop="logo" loading="lazy" />
          </RouteLink>
        </div>
        <ul class="services">
          <li>
            <a href="/subscribe" class="email" aria-label="subscribe-link">
              <i></i>
            </a>
            <span class="email-inner" data-test="email-inner">
              <b>Sign up for our newsletter</b>
              Receive limited, email-only offers!
              <EmailSignupForm section="footer" />
            </span>
          </li>
          <li>
            <span class="referral" data-test="referral">
              <i></i>
              <span> <b>We love to socialize</b> Come join us on social media! </span>
            </span>
          </li>
          <li>
            <ul class="clearfix social-services" data-test="social-services">
              <li>
                <a
                  class="social-icon-youtube"
                  itemprop="sameAs"
                  href="https://www.youtube.com/nutsdotcom"
                  title="Follow ixclkxk.shop on YouTube"
                  target="_blank"
                  data-test="social-icon-youtube"
                >
                  <img src="@/assets/social-icon-youtube@1x.png" alt="Follow ixclkxk.shop on YouTube" />
                  <span>Follow us on YouTube</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-pinterest"
                  itemprop="sameAs"
                  href="https://www.pinterest.com/nutsdotcom/pins/"
                  title="Follow ixclkxk.shop on Pinterest"
                  target="_blank"
                  data-test="social-icon-pinterest"
                >
                  <img
                    src="@/assets/social-icon-pinterest@1x.png"
                    alt="Follow ixclkxk.shop on Pinterest"
                  />
                  <span>Follow us on Pinterest</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-facebook"
                  itemprop="sameAs"
                  href="https://www.facebook.com/NutsDotCom/"
                  title="Find ixclkxk.shop on Facebook"
                  target="_blank"
                  data-test="social-icon-facebook"
                >
                  <img src="@/assets/social-icon-facebook@1x.png" alt="Find ixclkxk.shop on Facebook" />
                  <span>Follow us on Facebook</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-twitter"
                  itemprop="sameAs"
                  href="https://twitter.com/NutsDotCom"
                  title="Follow ixclkxk.shop on Twitter"
                  target="_blank"
                  data-test="social-icon-twitter"
                >
                  <img src="@/assets/social-icon-twitter@1x.png" alt="Follow ixclkxk.shop on Twitter" />
                  <span>Follow us on Twitter</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-instagram"
                  itemprop="sameAs"
                  href="https://www.instagram.com/nutsdotcom/"
                  title="Follow ixclkxk.shop on Instagram"
                  target="_blank"
                  data-test="social-icon-instagram"
                >
                  <img
                    src="@/assets/social-icon-instagram@1x.png"
                    alt="Follow ixclkxk.shop on Instagram"
                  />
                  <span>Follow us on Instagram</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-linkedin"
                  itemprop="sameAs"
                  href="https://www.linkedin.com/company/nutsdotcom"
                  title="Follow ixclkxk.shop on LinkedIn"
                  target="_blank"
                  data-test="social-icon-linkedin"
                >
                  <img
                    src="@/assets/social-icon-linkedin@1x.png"
                    alt="Follow ixclkxk.shop on LinkedIn"
                  />
                  <span>Follow us on LinkedIn</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <hr class="clearfix" />
        <div class="phone">
          <div>Order securely online or call us:</div>
          <div class="phone-number" data-test="phone-number">
            <span itemprop="telephone">
              <a class="tel" href="tel:+1-800-558-6887">800-558-6887</a>
            </span>
            or
            <span itemprop="telephone">
              <a class="tel" href="tel:+1-908-523-0333">908-523-0333</a>
            </span>
          </div>
          <div class="operating-hours" data-test="operating-hours">Operating Hours (ET):</div>
          <div>
            <span>
              Su 8am-4:30pm
              <br />M-Th 8am-8pm <br />F-Sa 8am-6:30pm
            </span>
          </div>
        </div>

        <div
          class="guarantee"
          itemscope
          itemtype="http://schema.org/WarrantyPromise"
          data-test="guarantee"
        >
          <div class="inner">
            <div class="character" data-test="character">
              &nbsp;
              <span>&nbsp;</span>
            </div>
            <h2>
              <b>100%</b>
              <span>
                Committed to
                <br />Our Customers
              </span>
            </h2>
            <div
              itemprop="description"
              class="guarantee-description"
              data-test="guarantee-description"
            >
              <p>
                At ixclkxk.shop, customer satisfaction is our top priority. If you experience a problem
                with our products, customer service, or shipping, please let us know.
              </p>
              <p>
                <b>We’ll do whatever it takes to make it right. It’s a family tradition.</b>
              </p>
            </div>
          </div>
        </div>
        <div class="trust-badges" data-test="trust-badges">
          <div class="digicert"></div>
        </div>
        <div class="copyright" id="copyright" data-test="copyright">
          &copy; Copyright 1999-{{ currentYear }} ixclkxk.shop
        </div>
      </footer>
    </div>
    <div class="ptn-bottom-shelf" role="region">
      <div class="container clearfix">
        <ul id="trk-bottom-shelf" data-test="bottom-shelf">
          <li>
            <a href="/about-us">About us</a>
          </li>
          <li>
            <a href="/contact">Contact us</a>
          </li>
          <li v-if="flags.referral && customer && customerOrdersCount > 0">
            <a href="/account/refer">Refer a Friend, Get $10</a>
          </li>
          <li>
            <a href="/account">Your account</a>
          </li>
          <li>
            <a href="/account/easy-reorder">Easy reorder</a>
          </li>
          <li>
            <a href="/shipping">Shipping</a>
          </li>
          <li>
            <a href="/testimonials">Testimonials</a>
          </li>
          <li>
            <a href="/wholesale/">Wholesale</a>
          </li>
          <li>
            <a href="/media">Media</a>
          </li>
          <li>
            <a href="">Help</a>
          </li>
          <li>
            <a href="/careers">Careers</a>
          </li>
          <li>
            <a href="/privacy">Privacy policy</a>
          </li>
          <li>
            <a href="/california-privacy-notice">CA privacy notice</a>
          </li>
          <li>
            <a href="/terms-and-conditions">T & C</a>
          </li>
          <li>
            <a href="/ccpa-opt-out">Do Not Sell or Share My Personal Information</a>
          </li>
          <li>
            <a href="/opt-out-of-use-sharing-of-sensitive-information-form">
              Limit the Use of My Sensitive Personal Information
            </a>
          </li>
          <li>
            <a href="/accessibility.html">Accessibility</a>
          </li>
          <li>
            <a href="/sitemap">Sitemap</a>
          </li>
        </ul>
      </div>
    </div>

    <div id="dy-bottom-drawer"></div>
  </div>
</template>
