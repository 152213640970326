/**
 * @deprecated Replace with @/composables/navigation/useRouterLinks
 */
export default {
  methods: {
    delegateLinksToRouter($event) {
      if (!this.$router) return;
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = $event;
      while (target && target.tagName !== 'A') target = target.parentNode;
      let isValid = false;
      // handle only links that occur inside the component and do not reference external resources
      const bases = ['.dynamic-content a', '.primary-surrogate-component a'];
      bases.forEach((base) => {
        const selector = `${base}:not([href*='://']), ${base}[href*='']`;
        if (target && target.matches(selector) && target.href) {
          isValid = !target.href.includes('mailto:') && !target.href.includes('tel:');
        }
      });

      if (isValid) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = $event;
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        // don't handle when preventDefault called
        if (defaultPrevented) return;
        // don't handle right clicks
        if (button !== undefined && button !== 0) return;
        // don't handle if `target="_blank"`
        if (target?.getAttribute) {
          const linkTarget = target.getAttribute('target');
          if (/\b_blank\b/i.test(linkTarget)) return;
        }
        // don't handle same page links/anchors
        const url = new URL(target.href);
        const to = `${url.pathname}${url.search}`;
        // eslint-disable-next-line no-script-url
        if (target.href.startsWith('javascript:')) return;
        if (url.pathname.includes('campaign-landing')) return;
        if (window.location.pathname !== to && $event.preventDefault) {
          $event.preventDefault();
          this.$router.push(to);
        }
      }
    },
  },
};
