<script setup lang="ts">
// eslint-disable-next-line simple-import-sort/imports
import { SearchIndex } from 'algoliasearch';
import { computed, onMounted, onServerPrefetch, ref } from 'vue';
import { useStore } from 'vuex';

import { AlgoliaVariant, browseProducts, getIndex, init as initAlgolia } from '@/api/algolia';
import ProductCardWithOptions from '@/components/product-card/ProductCardWithOptions.vue';
import Slider from '@/components/base/Slider.vue';
import { Recommendation, useDynamicYield } from '@/composables/useDynamicYield';
import { ProductCard } from '@/utils/productCard';
// eslint-disable-next-line import/no-duplicates
import { SpacingDirection, SpacingLevel, spacingProps, useSpacing } from '@/composables/useSpacing';

interface Props {
  analyticsList?: string;
  clickTrackable?: boolean;
  flushRightOnMobile?: boolean;
  impressionTrackable?: boolean;
  link?: string;
  prefetch?: boolean;
  selector: string;
  scrollButtonsOnSides?: boolean;
  spacingDirection: SpacingDirection;
  spacingLevel: SpacingLevel;
  subtitle?: string;
  title?: string;
  trackingEventLocation?: string;
}

const props = withDefaults(defineProps<Props>(), {
  analyticsList: '',
  clickTrackable: true,
  impressionTrackable: true,
  prefetch: false,
  scrollButtonsOnSides: false,
  trackingEventLocation: '',
});

const store = useStore();
const { getDyVariations, fetchDyVariations } = useDynamicYield(store, props.selector);
const { spacing } = useSpacing(props);

const productsList = computed<Recommendation[]>(
  () => getDyVariations.value[props.selector ?? ''] ?? [],
);
const productsVariants = ref<AlgoliaVariant[]>();

const loadRecommendations = async () => {
  await fetchDyVariations();
};

const loadVariants = async (productsIndex: SearchIndex) => {
  const productKeys = productsList.value?.map((product) => `Product.key:${product.product_key}`);
  productsVariants.value = await browseProducts(productsIndex, {
    filters: productKeys?.join(' OR '),
    facetFilters: ['shortVariantName:-sample', 'shortVariantName:-mini box'],
  });
};

const getVariantsByProductKey = (productKey: string) =>
  productsVariants.value
    ?.filter((variant) => variant.Product.key === productKey)
    .map(ProductCard.fromAlgolia);

onMounted(async () => {
  const algoliaClient = initAlgolia();
  const productsIndex = getIndex(algoliaClient, 'Products');

  await loadRecommendations();
  await loadVariants(productsIndex);
});
if (props.prefetch) onServerPrefetch(loadRecommendations);
</script>

<script lang="ts">
// eslint-disable-next-line import/first, import/no-duplicates
import { spacingInputRegistration } from '@/composables/useSpacing';
// eslint-disable-next-line import/first
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const ProductRecommendationsWithOptionsRegistration: BuilderComponent = {
  name: 'ProductRecommendationsWithOptions',
  inputs: [
    {
      name: 'selector',
      friendlyName: 'Experience Selector',
      type: 'string',
      defaultValue: '[TEST] Default Recommendations',
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Recommendations',
    },
    {
      name: 'subtitle',
      type: 'string',
      defaultValue: '(Optional) Recommendations Subtitle',
    },
    ...spacingInputRegistration,
    {
      name: 'link',
      friendlyName: 'View All link',
      type: 'string',
    },
    {
      name: 'prefetch',
      type: 'boolean',
      helperText:
        'Load prominent recommendations at the top of the page; otherwise loads after page is rendered',
    },
    {
      advanced: true,
      defaultValue: true,
      friendlyName: 'Mobile: flush with right',
      helperText: 'Scroll entries to be flush with the right side of mobile devices',
      name: 'flushRightOnMobile',
      type: 'boolean',
    },
    {
      name: 'analyticsList',
      friendlyName: 'Analytics: List Name',
      type: 'string',
      helperText:
        'Helps filter clicks/impressions in reporting; e.g. "GLP Seasonal Recommendations"',
      advanced: true,
    },
    {
      name: 'trackingEventLocation',
      friendlyName: 'Analytics: Page Type',
      type: 'string',
      helperText: 'Helps filter clicks/impressions in reporting; e.g. "PDP"',
      advanced: true,
    },
  ],
};
</script>

<template>
  <div
    v-if="productsList?.length"
    class="flex items-end"
    :class="spacing"
    data-test="recommendations-with-options"
  >
    <Slider
      :flushRightOnMobile="flushRightOnMobile"
      :list="productsList"
      :title="title"
      :link="link"
      :responsiveGaps="{ mobile: 8, tablet: 12 }"
      :scrollButtonsOnSides="scrollButtonsOnSides"
      useResponsiveGaps
    >
      <ProductCardWithOptions
        v-for="(product, index) in productsList"
        :key="product.id"
        :analyticsList="analyticsList"
        :impressionTrackable="impressionTrackable"
        :index="index"
        :product="ProductCard.fromDY(product)"
        :title="title"
        :subtitle="subtitle"
        :trackingEventLocation="trackingEventLocation"
        :variants="getVariantsByProductKey(product.product_key)"
      />
    </Slider>
  </div>
</template>
