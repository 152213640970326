import './opentelemetry';
import 'es6-promise/auto';
import 'intersection-observer';
import 'url-polyfill';

/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
// @ts-expect-error (module exists, reached out to support for assistance)
// eslint-disable-next-line import/no-unresolved
import { track } from '@builder.io/sdk-vue/vue3';
import VueAnnouncer from '@vue-a11y/announcer';
import { Buffer } from 'buffer';
import createDebug from 'debug';
import { createPinia, Pinia } from 'pinia';
import { HydrationSpec } from 'serverRoot/ssr_utils/createHydrationSpec';
import smoothscroll from 'smoothscroll-polyfill';
import { createSSRApp } from 'vue';
import { Store } from 'vuex';

import { setAndUseRuntimeConfig } from '@/api';
import { getConfigEntry } from '@/api/config';
import createSpaApp from '@/app/createApp';
import getComponent from '@/components/getComponent';
import BaseHeader from '@/components/layout/header/BaseHeader.vue';
import Header from '@/components/layout/header/Header.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import createStore from '@/store/createStore';

(window as any).Buffer = Buffer;

const debug = createDebug('nuts:entry-client');

const {
  __APP_SPEC__ = {},
  __HYDRATION_SPECS__ = [],
  __INITIAL_ROUTE__,
  __INITIAL_STATE__,
  __PINIA_STATE__,
  __WEBFRONT_RUNTIME__,
} = window as any;
if (__WEBFRONT_RUNTIME__) setAndUseRuntimeConfig(__WEBFRONT_RUNTIME__);

window.builderIo = {
  track: (event: Record<string, any>) =>
    track({
      ...event,
      apiKey: event.apiKey || getConfigEntry('builderIo').key,
    }),
};

smoothscroll.polyfill();

const createVueInstance = async (store: Store<any>, pinia: Pinia, hydrationSpec: HydrationSpec) => {
  const { componentName, props = {} } = hydrationSpec;
  const component = await getComponent(componentName);

  return createSSRApp(component, props).use(pinia).use(store).use(VueAnnouncer);
};

// The presence of __HYDRATION_SPECS__ indicates that the server
// used a mustache template and injected Vue components into the
// already-rendered html.
if (__HYDRATION_SPECS__.length) {
  const pinia = createPinia();
  const store = createStore();
  if (__INITIAL_STATE__) {
    store.replaceState(__INITIAL_STATE__);
  }
  if (__PINIA_STATE__) {
    pinia.state.value = __PINIA_STATE__;
  }
  store.dispatch('dynamicYieldModule/initDyClient', true);

  const isFeatureEnabled = store.getters['featureFlagsModule/isFeatureEnabled'];

  for (const hydrationSpec of __HYDRATION_SPECS__ as HydrationSpec[]) {
    try {
      createVueInstance(store, pinia, hydrationSpec).then((vm) => {
        // eslint-disable-next-line no-param-reassign
        vm.config.performance = vm.config.performance || isFeatureEnabled('vuePerformanceTiming');
        vm.mount(`#${hydrationSpec.id}`);
      });
    } catch (err) {
      debug('failed to mount component %s', hydrationSpec.componentName);
    }
  }
} else {
  const { app, router, store } = createSpaApp({
    props: __APP_SPEC__,
    piniaState: __PINIA_STATE__,
    initialRoute: __INITIAL_ROUTE__,
    initialState: __INITIAL_STATE__,
  });
  store.dispatch('dynamicYieldModule/initDyClient', true);

  const { flags } = useFeatureFlags(store);
  router.isReady().then(() => {
    // subscribe to the afterEach hook to request critical data for subsequent navigation
    router.afterEach(async (to, from) => {
      const GlobalHeader = flags.headerRedesign ? BaseHeader : Header;
      const components = [
        GlobalHeader,
        ...router.currentRoute.value.matched.flatMap((m) => Object.values(m.components ?? {})),
      ];
      await Promise.all(components.map((c: any) => c.criticalData?.(store, to, from)));
    });

    app.mount('#app');
  });
}
