<template>
  <div v-if="isValidVariation" :id="containerId" :data-test="containerId" @click="engagement">
    <DyServerSideRenderer :variation="banner" variation-type="DyHtmlCssVariation" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import DyServerSideRenderer from '@/components/dynamic-yield/DyServerSideRenderer.vue';

export default {
  name: 'DyBanner',
  props: {
    banner: { type: Object, required: false },
    containerId: { type: String, required: false },
  },
  components: {
    DyServerSideRenderer,
  },
  computed: {
    isValidVariation() {
      return !!this.banner && this.banner !== {};
    },
  },
  methods: {
    ...mapActions('dynamicYieldModule', ['reportEngagement']),
    async engagement() {
      if (!this.isValidVariation) return;

      await this.reportEngagement({
        decisionId: this.banner.decisionId,
        variationId: this.banner.id,
      });
    },
  },
};
</script>
