<template>
  <div class="ptn-search-box" :class="{ 'ais-search-box-container': isSearchPage }">
    <i class="searchy-the-peanut" loading="lazy" />
    <AutoComplete v-if="!isSearchPage" />
    <SynchronizedSearchBar v-else>
      <SearchBarStaticContent />
    </SynchronizedSearchBar>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import AutoComplete from '@/components/layout/header/AutoComplete.vue';
import SearchBarStaticContent from '@/components/layout/header/SearchBarStaticContent.vue';
import SynchronizedSearchBar from '@/components/layout/header/SynchronizedSearchBar.vue';

export default defineComponent({
  name: 'SearchBox',
  components: {
    AutoComplete,
    SearchBarStaticContent,
    SynchronizedSearchBar,
  },
  setup() {
    const route = useRoute();
    const isSearchPage = computed(() => route?.name === 'Search');

    return {
      isSearchPage,
    };
  },
});
</script>

<style lang="scss" scoped>
.ptn-search-box {
  &.ais-search-box-container {
    &:deep(.button) {
      @include respond-max($screen-sm-min) {
        display: none;
      }
    }
  }
}
.searchy-the-peanut {
  @include respond-min($screen-sm-min) {
    position: absolute;
    top: 0;
    right: 0;
    @include retina-sprite($sprite-searchy-the-peanut-1x);
    content: ' ';
    z-index: 101;
    pointer-events: none; // so he doesn't block clicks/touches
  }
}
</style>
