<script setup lang="ts">
import '@egjs/vue3-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/pagination.css';

import { AutoPlay, Pagination } from '@egjs/flicking-plugins';
import Flicking, { Plugin } from '@egjs/vue3-flicking';
import { computed, onMounted, ref } from 'vue';

import CaretArrowButton from '@/components/base/CaretArrowButton.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Header2 from '@/components/base/typography/Header2.vue';
import Header3 from '@/components/base/typography/Header3.vue';
import Header4 from '@/components/base/typography/Header4.vue';
import LargeBodyText from '@/components/base/typography/LargeBodyText.vue';

const TypographyTags = {
  Header2: 'Header2',
  Header3: 'Header3',
  Header4: 'Header4',
  BaseBodyText: 'BaseBodyText',
  LargeBodyText: 'LargeBodyText',
};

interface Slide {
  alt?: string;
  content: string;
  typographyStyle: keyof typeof TypographyTags;
  thumbnail?: string;
}

const props = withDefaults(
  defineProps<{
    autoPlay?: boolean;
    circular?: boolean;
    slides: Slide[];
    slidesPerView?: number;
    showControls?: boolean;
    showPagination?: boolean;
    showThumbnailsOnMobile?: boolean;
  }>(),
  {
    circular: true,
    slidesPerView: 1,
    showControls: true,
    showPagination: true,
  },
);

const carouselContainer = ref<Flicking>();

const plugins: Plugin[] = [];

const prev = () => carouselContainer.value?.prev();
const next = () => carouselContainer.value?.next();
const moveTo = (index: number) => carouselContainer.value?.moveTo(index);
const currentIndex = ref(0);
const carouselLength = computed(() => carouselContainer.value?.panelCount ?? 1);

const thumbnails = computed(() =>
  props.slides
    .map((slide) => {
      if (slide.thumbnail) return { image: slide.thumbnail, alt: slide.alt };
      return null;
    })
    .filter(Boolean),
);

const updateCurrentIndex = (event: { index: number }) => {
  currentIndex.value = event.index;
};

onMounted(() => {
  if (props.autoPlay) {
    plugins.push(new AutoPlay({ duration: 2000 }));
  }
  if (props.showPagination || thumbnails) {
    plugins.push(
      new Pagination({
        type: 'bullet',
      }),
    );
  }
});
</script>

<script lang="ts">
// eslint-disable-next-line import/first
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const CarouselRegistration: BuilderComponent = {
  name: 'Carousel',
  canHaveChildren: true,
  inputs: [
    {
      name: 'autoPlay',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'slides',
      type: 'list',
      defaultValue: [{ content: 'Slide 1 text here', typographyStyle: 'Header2' }],
      subFields: [
        {
          name: 'content',
          type: 'string',
        },
        {
          name: 'typographyStyle',
          type: 'string',
          enum: ['Header2', 'Header3', 'Header4', 'BaseBodyText', 'LargeBodyText'],
        },
        {
          name: 'thumbnail',
          type: 'file',
          helperText: 'Thumbnail images (url) shown below carousel and used as controls',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
        },
        { name: 'alt', type: 'string' },
      ],
    },
    {
      name: 'slidesPerView',
      type: 'number',
      defaultValue: 1,
    },
    {
      name: 'showControls',
      type: 'boolean',
      defaultValue: 'true',
      helperText: 'Show left and right arrows to control carousel',
    },
    {
      name: 'showPagination',
      type: 'boolean',
      defaultValue: 'true',
      helperText: 'Show pagination bullets',
    },
    {
      name: 'showThumbnailsOnMobile',
      type: 'boolean',
      defaultValue: 'false',
      helperText:
        'Show the thumbnail image as part of the slide on mobile (thumbnails are replaced by pagination/dots on mobile)',
    },
  ],
};
</script>

<template>
  <Flicking
    ref="carouselContainer"
    class="min-height-custom"
    :options="{
      align: {
        panel: 'center',
        camera: 'center',
      },
      circular: circular,
      panelsPerView: slidesPerView,
      resizeOnContentsReady: true,
    }"
    :plugins="plugins"
    @changed="updateCurrentIndex"
  >
    <div v-for="(slide, index) in slides" :key="index" class="mb-8 text-center">
      <component :is="Header2">{{ slide.content }}</component>
      <img
        v-if="showThumbnailsOnMobile && slide.thumbnail"
        class="h-5 mt-6 md:hidden"
        :src="slide.thumbnail"
        :alt="`thumbnail-${slide.alt}`"
      />
    </div>
    <template #viewport>
      <CaretArrowButton
        v-if="showControls"
        class="absolute left-0 z-10 top-1/2"
        direction="left"
        :disabled="currentIndex === 0"
        @action="prev"
      />
      <CaretArrowButton
        v-if="showControls"
        class="absolute right-0 z-10 top-1/2"
        direction="right"
        :disabled="currentIndex + 1 === carouselLength"
        @action="next"
      />
      <div class="flicking-pagination" :class="{ 'md:hidden': !!thumbnails }"></div>
    </template>
  </Flicking>
  <div v-if="thumbnails.length" class="justify-around hidden mt-8 md:flex">
    <img
      v-for="(thumbnail, index) in thumbnails"
      class="h-5 px-1 cursor-pointer"
      :class="{ 'opacity-50': index !== currentIndex }"
      :key="`thumbnail-${thumbnail?.alt}-${index}`"
      :src="thumbnail?.image"
      :alt="`thumbnail-${thumbnail?.alt}`"
      @click="moveTo(index)"
    />
  </div>
</template>

<style scoped lang="scss">
:deep(.flicking-pagination-bullet-active) {
  background-color: black;
}

:deep(.flicking-pagination) {
  bottom: 0;
}

.hidden {
  visibility: visible;
}

.min-height-custom {
  min-height: 120px;
}
</style>
