const lockOnOpen = () => {
  const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
  const { body } = document;
  body.style.position = 'fixed';
  body.style.top = `-${scrollY}`;
  body.style.right = '0';
  body.style.left = '0';
};

const unlockOnClose = () => {
  const { body } = document;
  const scrollY = body.style.top;
  body.style.position = '';
  body.style.top = '';
  body.style.right = '';
  body.style.left = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
};

const scrollListener = () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
};

const bindScrollListener = () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
  window.addEventListener('scroll', scrollListener);
};

const unbindScrollListener = () => {
  document.documentElement.style.removeProperty('--scroll-y');
  window.removeEventListener('scroll', scrollListener);
};

export default {
  bindScrollListener,
  lockOnOpen,
  unbindScrollListener,
  unlockOnClose,
};
