import axios from 'axios';

import DyError from '@/api/dy/client/error';
import { EngagementRequest } from '@/api/dy/client/request';

export class Client {
  constructor(config = {}) {
    const {
      apiKey,
      baseURL,
      engagementURL,
      requestTimeout = 500,
      connectionTimeout = 500,
    } = config;

    if (!apiKey) {
      throw new DyError('Invalid api key');
    }

    if (!baseURL || !engagementURL) {
      throw new DyError('Invalid base urls');
    }

    this.apiKey = apiKey;
    this.baseURL = baseURL;
    this.engagementURL = engagementURL;
    this.requestTimeout = requestTimeout;
    this.connectionTimeout = connectionTimeout;
    this.contentType = 'application/json';

    this.createHttpClient();
  }

  async execute(request) {
    const source = axios.CancelToken.source();
    setTimeout(() => {
      source.cancel('Timeout');
    }, this.connectionTimeout);

    let client = this.baseClient;

    if (request.constructor === EngagementRequest) {
      client = this.engagementClient;
    }

    return client({
      method: request.method,
      url: request.endpoint,
      data: request.data,
      cancelToken: source.token,
    });
  }

  createHttpClient() {
    this.baseClient = axios.create({
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      responseType: 'json',
      headers: {
        'DY-API-Key': this.apiKey,
        'Content-Type': this.contentType,
      },
    });

    this.engagementClient = axios.create({
      baseURL: this.engagementURL,
      timeout: this.requestTimeout,
      responseType: 'json',
      headers: {
        'DY-API-Key': this.apiKey,
        'Content-Type': this.contentType,
      },
    });
  }
}

export default {};
