/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import createDebug from 'debug';
import https from 'https';
import merge from 'lodash/merge';
import { ActionTree, createStore, GetterTree, ModuleTree, MutationTree } from 'vuex';

import api, { webstore } from '@/api';
import cartModule from '@/store/modules/cart';
import customerModule from '@/store/modules/customer';
import customTraysModule from '@/store/modules/customTrays';
import digitalGiftingModule from '@/store/modules/digitalGifting';
import dynamicYieldModule from '@/store/modules/dynamicYield';
import featureFlagsModule from '@/store/modules/featureFlags';
import headerModule from '@/store/modules/header';
import pdpModule from '@/store/modules/pdp';
import sessionModule from '@/store/modules/session';
import shippingModule from '@/store/modules/shipping';

declare global {
  interface Window {
    campTrig?: boolean;
    DY?: { API?: (...args: any[]) => any };
  }
}

const debug = createDebug('nuts:createStore');

export interface InitialRequestContext {
  ip: string;
  url: string;
  userAgent: string;
}

export interface GeoIp {
  /** two letter country code */
  country: string | null;
  latitude: number | null;
  longitude: number | null;
  postalCode: string | null;
  /** two letter state/prov code */
  state: string | null;
}

export const state = () => ({
  displayFooter: true,
  displayHeader: true,
  requestObj: {
    ip: '',
    url: '',
    userAgent: '',
  } as InitialRequestContext,
  locale: 'en',
  dySync: {
    header: false,
    content: null,
    footer: false,
    data: [],
  },
  geoIp: {
    country: null,
    latitude: null,
    longitude: null,
    postalCode: null,
    state: null,
  } as GeoIp,
});

export type RootState = ReturnType<typeof state>;

/** ********** */
// GETTERS //
/** ********** */

export const getters: GetterTree<RootState, RootState> = {};

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations: MutationTree<RootState> = {
  SET_DY_SYNC_CONTENT(state, { type, data }) {
    state.dySync.content = type;
    if (data) {
      state.dySync.data = data;
    }
  },
  setFooterDisplay(state, shouldBeDisplayed: boolean) {
    state.displayFooter = shouldBeDisplayed;
  },
  setHeaderDisplay(state, shouldBeDisplayed: boolean) {
    state.displayHeader = shouldBeDisplayed;
  },
};

/** ******** */
// ACTIONS //
/** ******** */
export const actions: ActionTree<RootState, RootState> = {};

const modules: ModuleTree<RootState> = {
  cartModule,
  customerModule,
  customTraysModule,
  digitalGiftingModule,
  dynamicYieldModule,
  featureFlagsModule,
  headerModule,
  pdpModule,
  sessionModule,
  shippingModule,
};

export default (
  sharedState:
    | {
        httpsAgent?: https.Agent;
        webstoreHttpsAgent?: https.Agent;
        authTokenInfo?: any;
      }
    | undefined = undefined,
  initialValues: { moduleStates?: Partial<typeof modules> } & Partial<RootState> = {},
) => {
  if (sharedState) {
    // Note: sharedState can go away once it is safe to reuse the same bundle
    // renderer (and runInNewContext: 'once' or false) across all requests.
    if (!sharedState.httpsAgent) {
      sharedState.httpsAgent = new https.Agent({
        ...https.globalAgent.options,
        keepAlive: true,
      });
    }
    https.globalAgent = sharedState.httpsAgent;
    api.defaults.httpsAgent = sharedState.httpsAgent;

    if (!sharedState.webstoreHttpsAgent) {
      sharedState.webstoreHttpsAgent = new https.Agent({
        ...webstore.defaults.httpsAgent.options,
        keepAlive: true,
      });
    }
    webstore.defaults.httpsAgent = sharedState.webstoreHttpsAgent;

    if (!sharedState.authTokenInfo) {
      sharedState.authTokenInfo = {};
    }
    api.setSharedTokenCache(sharedState.authTokenInfo);
  }

  debug('initialValues: %O', initialValues);
  const { moduleStates, ...stateValues } = initialValues;

  const store = createStore({
    state: () => ({ ...state(), ...stateValues }),
    actions,
    mutations,
    getters,
    modules,
    // FIXME: strict: process.env.NODE_ENV === 'development',
  });

  if (moduleStates && Object.entries(moduleStates).length) {
    Object.entries(moduleStates).forEach(([moduleKey, values]) => {
      if (!(moduleKey in modules) || !(moduleKey in store.state)) return;
      merge(store.state[moduleKey as keyof RootState], values);
    });
  }

  return store;
};
